import AuthEffect from "./AuthEffect";
import ActionUtility from "../../utilities/ActionUtility";
import AuthModel from "./models/AuthModel";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";

export default class AuthAction {
  static REQUEST_LOGIN = "AuthAction.REQUEST_LOGIN";
  static REQUEST_LOGIN_FINISHED = "AuthAction.REQUEST_LOGIN_FINISHED";

  static requestLogin(
    username,
    password,
    callBackFunction,
    callBackFunctiontwo,
    params,
    callBackFunctionthree
  ) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_LOGIN,
        AuthEffect.requestLogin,
        username,
        password,
        callBackFunction,
        callBackFunctiontwo,
        params,
        callBackFunctionthree
      );
    };
  }

  static InitLogin() {
    return async (dispatch) => {
      if (localStorage.getItem("Auth") != null) {
        let token = localStorage.getItem("Auth");

        const model = new AuthModel(JSON.parse(token));
        const isError = model instanceof HttpErrorResponseModel;
        dispatch(
          ActionUtility.createAction(
            `${AuthAction.REQUEST_LOGIN}_FINISHED`,
            model,
            isError
          )
        );

        return model;
      }
    };
  }
}
