import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../App";
import NoDataFoundReports from "../reports/NoDataFoundReports";
import NetRevenueByMonthAction from "../../stores/netRevenueReportByMonth/NetRevenueByMonthAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import ReactEcharts from "echarts-for-react";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH,
  ]),
  netRevenueByMonth: state.netRevenueByMonth.netRevenueByMonth || [],
});

function NetRevenueByMonthChart(props) {
  const {
    isRequesting,
    retailers,
    timeRange,
    setMonthSelected,
    monthSelected,
  } = props;
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = props.netRevenueByMonth;

  let monthList = [];
  let dataSetList = [];

  useEffect(() => {}, [data, retailers, timeRange]);

  function ConvertedName(name) {
    if (name.includes("_")) {
      return name
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return name;
  }

  data &&
    data &&
    data["Net Revenue By Month"] &&
    data["Net Revenue By Month"].length > 0 &&
    data["Net Revenue By Month"].map((item) => {
      monthList.push(item.key);

      let tempArray = [];
      item?.summary.forEach((summaryItem) => {
        tempArray.push({
          name:
            typeof summaryItem.key === "string"
              ? ConvertedName(summaryItem.key)
              : `${ConvertedName(summaryItem.key.name)}`,
          count: summaryItem.revenue,
        });
      });
      dataSetList.push(tempArray);
    });

  let uniqueNames = [
    ...new Set(
      dataSetList.flatMap((item) =>
        item.map((innerItem) => ConvertedName(innerItem.name))
      )
    ),
  ];

  let uniqueNamesWithCounts = [];

  function uniqueNamesWithCount() {
    const resultArray = [];
    uniqueNames.forEach((name) => {
      const filteredData = dataSetList.map((item) =>
        item.filter((innerItem) => innerItem.name === name)
      );

      const countArray = filteredData.map((item) =>
        item.length > 0 ? item[0].count : 0
      );

      const resultObject = {
        name: name,
        count: countArray,
      };

      resultArray.push(resultObject);
    });
    uniqueNamesWithCounts = resultArray;
  }
  uniqueNamesWithCount();

  const colors = [
    `${theme.pieChart.color1}`,
    `${theme.pieChart.color2}`,
    `${theme.pieChart.color3}`,
    `${theme.pieChart.color4}`,
    `${theme.pieChart.color5}`,
    `${theme.pieChart.color6}`,
  ];

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const state = {
    labels: monthList,
    datasets: uniqueNamesWithCounts.map((item, index) => ({
      label: item.name,
      data: item.count,
      backgroundColor: index < colors.length ? colors[index] : getRandomColor(),
      barThickness: 20,
    })),
  };
  const getOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };

    const series = state.datasets.map((item) => ({
      name: item.label,
      type: "bar",
      stack: "total",
      data: item.data,
      emphasis: {
        focus: "series",
      },
      itemStyle: {
        color: item.backgroundColor,
      },
      barWidth: item.barThickness,
    }));
    return {
      tooltip: {
        trigger: "item",

        confine: true,
        formatter: (item) => {
          if (item.value !== 0) {
            let val = item.value;
            let formattedvalue = formatValue(val);
            const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
            return `${colorSpan}${item.seriesName}: ${formattedvalue}<br/>`;
          }
        },
      },

      legend: {
        textStyle: {
          color: "#ddd", // Set the text color for the legend
        },
        icon: "circle",
        type: "scroll",
        data: state.datasets.map((item) => item.label),
        itemStyle: {
          borderColor: "#ffff",
          borderWidth: 1,
        },
      },
      grid: {
        top: "10%",

        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      labelLayout: {
        hideOverlap: true,
      },
      xAxis: {
        type: "category",
        data: state.labels,
        axisLabel: {
          show: true,
          padding: [0, 30, 0, 0],
          hideOverlap: true,
          textStyle: {
            color: "#ffffff",
          },
        },

        axisTick: {
          show: true,
        },
      },

      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],

      yAxis: {
        type: "value",

        axisLabel: {
          formatter: (value) => formatValue(value),
          hideOverlap: true,
          textStyle: {
            color: "#ffffff",
          },
        },
      },
      series: series,
    };
  };

  const finalData = data["Net Revenue By Month"]?.filter((item) =>
    item.summary
      .map((summaryItem) => summaryItem.revenue)
      .some((value) => value !== 0)
  );

  const totalRevenues = data["Net Revenue By Month"]?.map((item) => {
    const date = new Date(item.key);
    const formattedKey = `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getFullYear()}`;
    const formattedRevenue = parseFloat(item.total_revenue.toFixed(2));

    return {
      key: formattedKey,
      month: item.key,
      total_revenue: formattedRevenue,
    };
  });

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes popIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            }
            50% {
              transform: scale(1.05);
              box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
    `,
        }}
      />
      <Grid
        container
        item
        xs={12}
        xl={12}
        lg={12}
        md={12}
        justifyContent="center"
        alignItems={"center"}
        px={5}
        gap={3}
        pb={3}
      >
        <Grid
          sx={{
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
          }}
          gap={1}
        >
          <span style={{ fontWeight: "bold" }}>
            {formatName("Monthwise Revenue Report")}
          </span>
        </Grid>
        <hr className="p-0 m-0" style={{ color: theme.palette.text.gray }} />

        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting && (
            <>
              {finalData && finalData.length > 0 && (
                <>
                  <Typography
                    sx={{
                      // width: "90vh",
                      paddingTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      animation: "popIn 0.4s ease-in-out",
                      border: "2px solid rgba(0, 0, 0, 0.1)",
                      boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#212121",
                      borderRadius: "8px",
                    }}
                  >
                    <div style={{ marginLeft: "15%" }}>
                      <ReactEcharts
                        option={getOption()}
                        style={{
                          height: "43vh",
                          width: "100%",
                        }}
                      />
                    </div>
                    <Box
                      sx={{
                        paddingX: "12px",
                        paddingY: "5px",
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        width: "170px",
                        borderRadius: "2px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      TOTAL REVENUE
                    </Box>
                    <Box sx={{ marginX: "50px", marginY: "20px" }}>
                      <Grid container spacing={2}>
                        {totalRevenues.map((item) => (
                          <Grid item key={item.key} xs={2}>
                            <Typography
                              component={"div"}
                              onClick={() => {
                                props
                                  .dispatch(
                                    NetRevenueByMonthAction.requestNetRevenueByMonthFilterClear()
                                  )
                                  .then(() => {
                                    setMonthSelected(item?.month);
                                  });
                              }}
                              sx={{
                                cursor: "pointer",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                padding: "8px",
                                textAlign: "center",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              {item.key}:{` ${item.total_revenue.toFixed(2)}`}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Typography>
                </>
              )}
            </>
          )}
          {!isRequesting && finalData?.length == 0 && <NoDataFoundReports />}
        </LoadingIndicator>
      </Grid>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(NetRevenueByMonthChart);
