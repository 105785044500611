import BaseReducer from "../../utilities/BaseReducer";
import StatusAction from "./StatusAction";

export default class StatusFilterReduser extends BaseReducer {
  //initial state of StatusFilterReduser
  initialState = {
    statusFilter: [],
  };
  //StatusFilterReduser request action finish
  [StatusAction.REQUEST_STATUS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      statusFilter: action.payload,
    };
  }

  [StatusAction.REQUEST_STATUS_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      statusFilter: [],
    };
  }
}
