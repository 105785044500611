import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import AuthAction from "../../stores/Auth/AuthAction";
import { connect } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import "./signin.css";
import logo from "../../Images/A3Tunes.webp";
import { Grid, TextField, useTheme } from "@mui/material";
import MuiButton from "../../components/muiButton/MuiButton";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { createBrowserHistory } from "history";
import bgImage from "../../Images/MainDashboard.webp";
import LoginImage from "../../Images/LoginImage.webp";
import Tunes from "../../Images/A3Tunes.webp";
import { theme } from "../App";
import LoginBackground from "../../components/loginBackground/LoginBackground";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import NewReleaseDialog from "../release/NewReleaseDialog";
import ValidationDailoge from "./ValidationDailoge";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    AuthAction.REQUEST_LOGIN,
    UserProfileAction.REQUEST_USERPROFILE,
  ]),
  userprofile: state.userprofile.userprofile || [],
});

function SignIn(props) {
  console.log("propsssuser", props);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showSplashScreen, setShowSplashScreen] = React.useState(true);
  // const [openemailValidationDailoge, setOpenEmailValidationDailoge] =
  //   useState(false);
  const [openemailOTPDailoge, setOpenemailOTPDailoge] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let initial = {};

  initial = {
    email: "",
    password: "",
  };

  const textFieldForUsernameRef = React.useRef(null);
  const textFieldForPasswordRef = React.useRef(null);
  const buttonForLoginRef = React.useRef(null);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSplashScreen(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResizeLogin = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as per your requirement
    };

    // Initial check on mount
    handleResizeLogin();

    // Listen to window resize events
    window.addEventListener("resize", handleResizeLogin);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResizeLogin);
  }, []);

  function fetchUserprofile(inputCallback) {
    props.dispatch(UserProfileAction.requestUserprofile({}, inputCallback));
  }

  return (
    <Formik
      initialValues={initial}
      // validationSchema={validationSchema}
      validate={(values) => {
        let errors = {};
        if (!values.email) {
          errors.email = "UserName is required";
        }
        if (!values.password) {
          errors.password = "Password is required";
        }
        return errors;
      }}
      onSubmit={(values, formikHelpers) => {
        const callBackFunction = () => {
          const handleNavigation = (response) => {
            let planId = response?._source?.subscription_plan_id;
            let targetPath = "/myPlans";

            if (planId) {
              switch (location.state?.from) {
                case "/releaseInfo":
                case "/upload":
                case "/tracks":
                case "/releaseDate":
                case "/promotion":
                case "/submission":
                  targetPath = "/release";
                  break;
                case "/logout":
                  targetPath = "/dashboard";
                  break;
                default:
                  // targetPath = location.state?.from || "/dashboard";
                  targetPath = "/dashboard";
              }
            }

            navigate(targetPath, {
              state: { from: location.pathname },
            });
          };

          fetchUserprofile(handleNavigation);
        };

        const callBackFunctiontwo = () => {
          if (location.state.from !== "/logout") {
            const userProfileCallback = (response) => {
              let planId;
              if (response) {
                planId = response._source.subscription_plan_id;

                if (planId) {
                  navigate("/dashboard", {
                    state: { from: location.pathname },
                  });
                } else {
                  navigate("/myPlans", {
                    state: { from: location.pathname },
                  });
                }
              }
            };

            fetchUserprofile(userProfileCallback);
          }
        };

        const callBackFunctionthree = (response) => {
          console.log("responsethree", response);
          if (
            response?.raw?.data?.message === "mobile number is not verified"
          ) {
            navigate("/dashboard");
          }
        };

        const params = {
          // login_from: "mobile",
        };

        props.dispatch(
          AuthAction.requestLogin(
            values.email,
            values.password,
            callBackFunction,
            callBackFunctiontwo,
            params,
            callBackFunctionthree
          )
        );
      }}
    >
      {({ values, errors, isValid, touched, dirty, handleChange }) => (
        <Form style={{ width: "100%", height: "100vh" }}>
          <Box sx={{ width: "100%", height: "100%" }}>
            <Grid
              container
              style={{
                // overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                height: "100%",
              }}
            >
              <LoginBackground isMobile={isMobile} />

              <Grid
                item
                xs={12}
                md={isMobile ? 12 : 6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: theme.palette.background.loginbackground,
                  height: "100%",
                }}
              >
                <Grid container justifyContent="center" alignItems="center">
                  {isMobile && (
                    <img
                      src={Tunes}
                      alt="Tunes"
                      style={{
                        width: "40%",
                        height: "auto",
                        objectFit: "contain",
                        marginBottom: "80px",
                      }}
                    />
                  )}
                  <Grid
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    sm={8}
                    xs={10}
                    style={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    Username
                  </Grid>
                  <Grid item xl={8} lg={8} md={8} sm={8} xs={10}>
                    <Field
                      id="email"
                      as={MuiTextField}
                      name="email"
                      autoComplete="email"
                      placeholder="User Name"
                      inputRef={textFieldForUsernameRef}
                      // error={Boolean(errors.email) && Boolean(touched.email)}
                      // helperText={Boolean(touched.email) && errors.email}
                      fieldheight="44px"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon sx={{ color: "#e1e6e8" }} />
                          </InputAdornment>
                        ),
                        onKeyPress: (event) => {
                          if (event.key === "Enter") {
                            textFieldForPasswordRef.current.focus();
                          }
                        },
                      }}
                      fullWidth
                    />
                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "14px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="email" />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    sm={8}
                    xs={10}
                    style={{
                      textAlign: "left",
                      marginTop: "20px",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    Password
                  </Grid>
                  <Grid item xl={8} lg={8} md={8} sm={8} xs={10}>
                    <Field
                      name="password"
                      as={MuiTextField}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      inputRef={textFieldForPasswordRef}
                      placeholder="Password"
                      autoComplete="current-password"
                      fieldheight="44px"
                      // error={
                      //   Boolean(errors.password) && Boolean(touched.password)
                      // }
                      // helperText={Boolean(touched.password) && errors.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon sx={{ color: "#e1e6e8" }} />
                          </InputAdornment>
                        ),
                        onKeyPress: (event) => {
                          const { key } = event;
                          if (key === "Enter") {
                            buttonForLoginRef?.current?.click();
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ mr: 1 }}
                            >
                              {showPassword ? (
                                <VisibilityOff sx={{ color: "#e1e6e8" }} />
                              ) : (
                                <Visibility sx={{ color: "#e1e6e8" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "14px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="password" />
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    sm={8}
                    xs={10}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "48px",
                      // backgroundColor: "yellowgreen",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.background.drawerMenu,
                        }}
                      >
                        Do not have an account?&nbsp;
                        <button
                          type="button"
                          onClick={() => {
                            navigate("/ValidateMobile");
                            //   , {
                            //   state: {
                            //     action: "mobile_no",
                            //   }
                            // }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                          style={{
                            color: theme.palette.secondary.main,
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          Register
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            navigate("/ValidateMobile", {
                              state: { action: "forgot_password" },
                            });
                            //   , {
                            //   state: {
                            //     action: "mobile_no",
                            //   }
                            // }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                          style={{
                            color: theme.palette.secondary.main,
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          Forgot Password!
                        </button>
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <MuiButton
                        loading={props.isRequesting}
                        type="submit"
                        name={!props.isRequesting ? "Login" : ""}
                        width="110px"
                        height="37px"
                        buttonRef={buttonForLoginRef}
                        borderRadius={
                          theme.palette.buttonComponent.borderRadius.large
                        }
                        login
                        fontSize="18px"
                        textTransform="uppercase"
                        letterSpacing="1.85px"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* <ValidationDailoge
            title="Verify Your Email ID"
            subTitle={"Verify"}
            action="just_email"
            open={openemailValidationDailoge}
            field="mail_address"
            onClose={() => {
              setOpenEmailValidationDailoge(false);
              setOpenemailOTPDailoge(true);
            }}
          /> */}

          {/* <ValidationDailoge
            title="Enter OTP"
            subTitle={"Verify"}
            open={openemailOTPDailoge}
            action="email_otp"
            field="mail_address"
            field2="otp"
            onClose={() => {
              setOpenemailOTPDailoge(false);
            }}
          /> */}
        </Form>
      )}
    </Formik>
  );
}
export default connect(mapStateToProps)(SignIn);
