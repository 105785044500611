import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import {
  Grid,
  IconButton,
  useTheme,
  Typography,
  ListItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndustry, faTrash } from "@fortawesome/free-solid-svg-icons";
import AlbumIcon from "@mui/icons-material/Album";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PublicIcon from "@mui/icons-material/Public";
import DialogContent from "@mui/material/DialogContent";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DialogTitle from "@mui/material/DialogTitle";
import ReleasesAction from "../../../stores/releases/ReleasesAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import GenreAction from "../../../stores/genre/GenreAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../App";
import { makeStyles } from "@material-ui/core";
import { Palette } from "@mui/icons-material";
import TopReleaseAction from "../../../stores/topReleaseReport/TopReleaseAction";
import ImageIcon from "@mui/icons-material/Image";
// import Rettrive from "../../releases/Rettrive";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import SearchIcon from "@mui/icons-material/Search";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_RELEASES,
    GenreAction.REQUEST_GENRE,
    ReleasesAction.REQUEST_RELEASE_FILTER,
    TopReleaseAction.REQUEST_TOPRELEASE,
  ]),
  release: state.release.release || [],
  releaseFilter: state.releaseFilter.releaseFilter || [],
  genre: state.genre.genre || [],
  pagination: state.release.release || [],
  topRelease: state.topRelease.topRelease || [],
});

function TopReleases(props) {
  const theme = useTheme();
  const { isRequesting } = props;
  const navigate = useNavigate();

  const [editRowsModel, setEditRowsModel] = useState({});
  const [page_limit] = useState(1);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("body");
  const [title, setTitle] = useState(null);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let params = {
      // page_limit: page_limit,
      // order_by: '[["created_on", "desc"]]',
      current_page: "analytics",
    };

    fetchTopReleases(params);
  }, []);

  function fetchReleases(params) {
    props.dispatch(ReleasesAction.requesReleases(params));
  }

  function fetchReleasesFilter(params) {
    props.dispatch(ReleasesAction.requestReleaseFilter(params));
  }

  function fetchGenre() {
    const param = {
      page_limit: "none",
      order_by: `[["genre_name.keyword", "asc"]]`,
    };
    props.dispatch(GenreAction.requestGenre(param));
  }

  function fetchTopReleases(params) {
    props.dispatch(TopReleaseAction.requestTopRelease(params));
  }

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const genreList = [];
  if (props.genre && props.genre.hits) {
    props.genre.hits.hits &&
      props.genre.hits.hits.length > 0 &&
      props.genre.hits.hits.map((genre) => {
        genreList.push({
          genre_id: genre._id,
          genre_name: genre._source.genre_name,
        });
      });
  }

  const releaseTypeList = ["audio", "video", "ringtone"];

  function applyFilter() {
    const filter = [];

    if (title !== "") {
      filter.push(`["title.keyword","must","","wildcard","${title}"]`);
    }

    if (title !== "") {
      const params = {
        filters: `[${filter}]`,
        current_page: "analytics",
      };
      fetchTopReleases(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  }

  const ClearState = () => {
    // Clear the state
    if (title !== "") {
      setClearLoader(true);
      setFilterLoader(false);
      setTitle("");
    }
    setFilterClicked(false);
    const params = {
      current_page: "analytics",
    };
    FilterClicked && fetchTopReleases(params);
  };

  const rows = [];

  if (props.topRelease) {
    props.topRelease.top_releases_list &&
      props.topRelease.top_releases_list.length > 0 &&
      props.topRelease.top_releases_list.map((item, index) => {
        rows.push({
          sl_no: item.key ? ++index : "",

          title: item.title,
          artists: item.artists,
          id: item.key,
          audio_streams: item.audio_streams,
          main_release_date: item.main_release_date,
          stream_percentage: item.stream_percentage,
        });
      });
  }

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  //Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 280,
    },

    {
      width: 250,
      // flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0">
            <div
              style={{
                marginLeft: 5,
                width: "100%",
                display: "flex",
              }}
            >
              <div style={{ width: "60%" }}>
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {}
                  {params?.row?.artists?.artists?.length ? "By " : ""}
                  {params?.row?.artists?.artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id?.artist_name}
                      {index !== params?.row?.artists?.artists?.length - 1 &&
                        ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      width: 230,
      field: "main_release_date",
      // flex: 1,
      headerName: "Release date",
      renderCell: (params) => {
        return formatDate(params.row.main_release_date);
      },
    },
    {
      // flex: 1,
      width: 230,
      field: "audio_streams",
      headerName: "Audio Streams",
    },

    {
      // flex: 1,
      width: 200,
      field: "stream_percentage",
      headerName: "Stream Percentage",
    },
  ];

  const handleSearchChange = (event) => {
    setTitle(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      applyFilter();
    }
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      {/* <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={1}
      >
        <Grid item lg={2} md={5} sm={5} mt={1}>
          <MuiTextField
            variant="outlined"
            placeholder={"Search Title"}
            fieldheight="33px"
            sx={{ width: "100%" }}
            value={title}
            // autoComplete="off"
            size="small"
            onChange={handleSearchChange}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <SearchIcon
                  style={{ cursor: "pointer" }}
                  onClick={applyFilter}
                />
              ),
            }}
          />
        </Grid>
        <Grid item lg={6} md={7} sm={7} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            spacing={1}
            justifyContent="flex-end"
            mt={0}
          >
            <Grid
              item
              lg={8}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"end"}
              mt={1}
              mr={2}
              sx={{ gap: 2 }}
            >
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Filter"}
                  width="100%"
                  onClick={applyFilter}
                  loading={filterLoader && isRequesting}
                />
              </Grid>
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Clear"}
                  width="100%"
                  onClick={ClearState}
                  loading={clearLoader && isRequesting}
                />
              </Grid>
            </Grid>
            <Grid> */}
      {/* post button component*/}
      {/* <PostPlusButton
                open={open}
                openCloseModal={openCloseModal}
                editMode={editMode}
              /> */}
      {/* </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      {!isRequesting ? (
        <Grid
          container
          item
          lg={12}
          xl={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            height: "-webkit-fill-available",
            px: 2,
          }}
        >
          <LoadingIndicator isActive={isRequesting}>
            <DataGridFunction
              columns={columns}
              isRequesting={isRequesting}
              rows={rows}
              rowHeight={80}
              //pagelength={props.pagination.total_page_no}
              //page={props.pagination.current_page_no}
              handleChange={handleChange}
            />
          </LoadingIndicator>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(TopReleases);
