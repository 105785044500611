import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import environment from "environment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../views/App";
import { formatName } from "../../reUsedFuntions/ReUsedFunctions";
import MuiButton from "../../muiButton/MuiButton";
import Divider from "@mui/material/Divider";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UploadIcon from "@mui/icons-material/Upload";
import CircleLoader from "../../circularLoder/CircleLoader";

function CoverPicUploadDialog(props) {
  console.log("thumbnail_url", props);
  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle
        onClose={props.onClose}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingY: 1,
          paddingX: 2,
        }}
      >
        <Typography
          component={"span"}
          sx={{ fontSize: "20px", textAlign: "center" }}
        >
          Upload Cover
        </Typography>
        <IconButton aria-label="close" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ color: theme.palette.divider, borderWidth: "1px" }} />
      {/* Scope of Formik starts here */}
      <Formik
        initialValues={{}}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          props.onSave();
          setLoading(false);
          props.onClose();
        }}
        validateOnBlur={true}
        validateOnChange
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          // Form Tag from Formik
          <Form>
            {/* Content of the dialog Box  */}
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: "53px 55px",
              }}
            >
              <Grid
                conatiner
                sx={{
                  width: "100%",
                  display: "flex",
                  //   justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: theme.palette.text.light,
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: { xl: "273px" },
                      height: { xl: "255px" },
                      border: `1px solid ${theme.palette.borderColor.imageBox}`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      m: 0,
                      p: 0,
                    }}
                  >
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor="thumbnail-upload"
                    >
                      <Box
                        sx={{
                          width: { xl: "257px" },
                          height: { xl: "237px" },
                          backgroundColor: theme.palette.background.imageBox,
                        }}
                      >
                        {!props.highImageLoader && props.highImage ? (
                          <img
                            src={props.highImage}
                            alt="Cover Picture"
                            onError={() => {
                              // Error handling code goes here
                            }}
                            style={{
                              width: "100%",
                              height: "100%",
                              overflow: "hidden",
                              // objectFit: "cover",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {props.highImageLoader ? (
                              <>
                                <CircleLoader />
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  icon={faImage}
                                  style={{
                                    color: theme.palette.primary.light,
                                    width: "75px",
                                    height: "56px",
                                  }}
                                ></FontAwesomeIcon>
                                <Typography
                                  component={"span"}
                                  sx={{
                                    color: theme.palette.text.imageBox,
                                    fontSize: "16px",
                                    mt: "15px",
                                  }}
                                >
                                  No Image Available
                                </Typography>
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                    </label>
                  </Grid>
                  <Typography
                    component={"span"}
                    sx={{ textAlign: "center", fontSize: "16px" }}
                  >
                    Current Cover
                  </Typography>
                </Grid>

                <Grid item marginLeft={10}>
                  <Typography
                    component={"span"}
                    sx={{ fontSize: "17px", color: theme.palette.text.light }}
                  >
                    Change /Import a cover
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{ color: theme.palette.primary.light }}
                  >
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor="thumbnail-upload"
                    >
                      <UploadIcon />

                      <Typography className="p-2" component={"span"}>
                        From your computer
                      </Typography>
                    </label>
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  color: theme.palette.text.primary,
                }}
                mt={1}
              >
                <Grid
                  item
                  container
                  sm={3}
                  alignItems={"center"}
                  justifyContent="flex-end"
                  gap={1}
                >
                  <Grid item>
                    <MuiButton
                      name={"Save"}
                      type="submit"
                      loading={loading}
                      size="small"
                      width="100%"
                    />
                  </Grid>

                  {props?.thumbnail_url && (
                    <Grid item>
                      <MuiButton
                        name={"Delete"}
                        // type="submit"
                        onClick={() => {
                          props.onDelete();
                        }}
                        loading={props.dltLoading}
                        size="small"
                        width="100%"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            {/* <DialogActions>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  
                </Grid>
              </Grid>
            </DialogActions> */}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default CoverPicUploadDialog;
