import { BaseModel } from "sjs-base-model";

export default class ClaimSubscriptionPostModel extends BaseModel {
  status = "";
  message = "";

  constructor(data2) {
    super();

    this.update(data2);
  }
}
