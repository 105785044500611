import {
  Tooltip,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik, getIn } from "formik";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiButton from "../../components/muiButton/MuiButton";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useParams } from "react-router";
import TimeZoneAction from "../../stores/timezone/TimeZoneAction";
import { theme } from "../App";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserProfileAction.REQUEST_USERPROFILE,
    UserProfileAction.REQUEST_MAILSUBSCIPTION,
    UserProfileAction.REQUEST_NOTIFICATION_SUBSCIPTION,
  ]),
  userprofile: state.userprofile.userprofile || [],
});

const BankInformation = (props) => {
  const [bankLoading, setBankLoading] = useState(false);

  function fetchUserprofile() {
    let Id = localStorage.getItem("userId");

    let params = {
      current_page: "profile",
      user_id: Id,
    };
    props.dispatch(UserProfileAction.requestUserprofile(params));
  }

  useEffect(() => {
    fetchUserprofile();
  }, []);

  let initial = {};
  let formProps = {};
  const { _id, _source } = props?.userprofile;

  if (props?.userprofile) {
    Object.assign(
      initial,
      { account_holder_name: _source?.bank_details?.account_holder_name },
      { account_number: _source?.bank_details?.account_number },
      { bank_name: _source?.bank_details?.bank_name },
      { bank_address: _source?.bank_details?.bank_address },
      { branch_name: _source?.bank_details?.branch_name },
      { gst_no: _source?.bank_details?.gst_no },
      { bank_gst_no_checkbox: _source?.bank_details?.bank_gst_no_checkbox },
      { pan_no: _source?.bank_details?.pan_no },
      { ifsc: _source?.bank_details?.ifsc }
    );
  }

  if (initial) {
    formProps = {
      account_holder_name: initial.account_holder_name,
      account_number: initial.account_number,
      bank_name: initial.bank_name,
      bank_address: initial.bank_address,
      branch_name: initial.branch_name,
      gst_no: initial.gst_no,
      bank_gst_no_checkbox: initial.bank_gst_no_checkbox,
      pan_no: initial.pan_no,
      ifsc: initial.ifsc,
    };
  } else {
    formProps = {
      account_holder_name: "",
      account_number: "",
      bank_name: "",
      bank_address: "",
      branch_name: "",
      gst_no: "",
      bank_gst_no_checkbox: true,
      pan_no: "",
      ifsc: "",
    };
  }

  initial = {
    account_holder_name: initial.account_holder_name,
    account_number: initial.account_number,
    bank_name: initial.bank_name,
    bank_address: initial.bank_address,
    branch_name: initial.branch_name,
    gst_no: initial.gst_no,
    bank_gst_no_checkbox: initial.bank_gst_no_checkbox,
    pan_no: initial.pan_no,

    ifsc: initial.ifsc,
  };

  const formRef = useRef(null);

  function capitalizeWords(input) {
    return input.toUpperCase();
  }

  return (
    <Box>
      <LoadingIndicator isActive={props.isRequesting}>
        {
          <>
            <Formik
              initialValues={initial}
              enableReinitialize
              onSubmit={(values) => {
                setBankLoading(true);
                let Obj = {};
                let bank_details_object = {};

                Object.assign(
                  bank_details_object,
                  values.account_holder_name
                    ? {
                        account_holder_name: values.account_holder_name,
                      }
                    : { account_holder_name: null },

                  values.account_number
                    ? {
                        account_number: values.account_number,
                      }
                    : { account_number: null },

                  values.bank_name
                    ? {
                        bank_name: values.bank_name,
                      }
                    : { bank_name: null },

                  values.bank_address
                    ? {
                        bank_address: values.bank_address,
                      }
                    : { bank_address: null },

                  values.branch_name
                    ? {
                        branch_name: values.branch_name,
                      }
                    : { branch_name: null },

                  values.bank_gst_no_checkbox
                    ? {
                        gst_no: values.gst_no,
                      }
                    : { gst_no: null },

                  {
                    bank_gst_no_checkbox: values.bank_gst_no_checkbox,
                  },

                  !values.bank_gst_no_checkbox
                    ? {
                        pan_no: values.pan_no,
                      }
                    : { pan_no: null },

                  values.ifsc
                    ? {
                        ifsc: values.ifsc,
                      }
                    : { ifsc: null }
                );
                Object.assign(Obj, { bank_details: bank_details_object });

                props
                  .dispatch(
                    UserProfileAction.updateBankDetails(JSON.stringify(Obj))
                  )
                  .then(() => {
                    setBankLoading(false);
                  });
              }}
              innerRef={formRef}
            >
              {({
                errors,
                touched,
                handleChange,
                setFieldValue,
                values,
              }) => (
                <Form
                  style={{
                    width: "100%",
                    background: "black",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    className="mx-4 my-4"
                    width="50%"
                    sx={{
                      "&:hover": {
                        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    <Box className="m-0 p-1">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "30px",
                        }}
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="m-1 p-1"
                        style={{
                          backgroundColor: ` ${theme.palette.background.tab}`,
                        }}
                      >
                        <AccountBalanceIcon style={{ marginLeft: "3px" }} />
                        <Typography
                          style={{
                            fontWeight: "bold",
                            paddingTop: "3px",
                            paddingLeft: "10px",
                          }}
                        >
                          Bank Details
                        </Typography>
                      </Box>
                      <Box className="m-0 px-4 pt-4">
                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item lg={4} md={4} sm={4} mt={1}>
                              Account Holder Name :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Tooltip
                                title="Account Holder Name"
                                arrow
                                placement="right-end"
                              >
                                <Field
                                  as={MuiTextField}
                                  variant="outlined"
                                  placeholder="Account Holder Name"
                                  // disabled={props.edit ? true : false}
                                  id="account_holder_name"
                                  size="small"
                                  name="account_holder_name"
                                  error={
                                    Boolean(errors.account_holder_name) &&
                                    Boolean(touched.account_holder_name)
                                  }
                                  helperText={
                                    Boolean(touched.account_holder_name) &&
                                    errors.account_holder_name
                                  }
                                  className="col-10"
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const cursorPosition =
                                      e.target.selectionStart;
                                    const words = inputValue.split(" ");
                                    const formattedWords = words.map(
                                      (word, index) => {
                                        if (index === words.length - 1) {
                                          // The last word
                                          return (
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                          );
                                        } else {
                                          return (
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                          );
                                        }
                                      }
                                    );
                                    const beautifiedValue =
                                      formattedWords.join(" ");
                                    setFieldValue(
                                      "account_holder_name",
                                      beautifiedValue
                                    );
                                    // Restore cursor position
                                    e.target.setSelectionRange(
                                      cursorPosition,
                                      cursorPosition
                                    );
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            justifyContent={`${"space-between"}`}
                          >
                            <Grid item lg={4} md={4} sm={4} mt={1}>
                              Account Number :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Field
                                as={MuiTextField}
                                className="col-10"
                                variant="outlined"
                                placeholder={"Account Number"}
                                id={"account_number"}
                                size="small"
                                name={"account_number"}
                                inputMode={"numeric"}
                                // maxLength={13}
                                handleKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                // className="mt-1"
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item lg={4} md={4} sm={4} mt={1}>
                              Bank Name :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Tooltip
                                title="Bank Name"
                                arrow
                                placement="right-end"
                              >
                                <Field
                                  as={MuiTextField}
                                  variant="outlined"
                                  placeholder="Bank Name"
                                  // disabled={props.edit ? true : false}
                                  id="bank_name"
                                  size="small"
                                  name="bank_name"
                                  error={
                                    Boolean(errors.bank_name) &&
                                    Boolean(touched.bank_name)
                                  }
                                  helperText={
                                    Boolean(touched.bank_name) &&
                                    errors.bank_name
                                  }
                                  className="col-10"
                                  onChange={(e) => {
                                    const newValue = capitalizeWords(
                                      e.target.value
                                    );
                                    setFieldValue("bank_name", newValue);
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item lg={4} md={4} sm={4} mt={1}>
                              Bank Address :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Tooltip
                                title="Bank Address"
                                arrow
                                placement="right-end"
                              >
                                <Field
                                  as={MuiTextField}
                                  variant="outlined"
                                  placeholder="Bank Address"
                                  // disabled={props.edit ? true : false}
                                  id="bank_address"
                                  size="small"
                                  name="bank_address"
                                  error={
                                    Boolean(errors.bank_address) &&
                                    Boolean(touched.bank_address)
                                  }
                                  helperText={
                                    Boolean(touched.bank_address) &&
                                    errors.bank_address
                                  }
                                  className="col-10"
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setFieldValue("bank_address", newValue);
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item lg={4} md={4} sm={4} mt={1}>
                              Branch Name :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Tooltip
                                title="Branch Name"
                                arrow
                                placement="right-end"
                              >
                                <Field
                                  as={MuiTextField}
                                  variant="outlined"
                                  placeholder="Branch Name"
                                  // disabled={props.edit ? true : false}
                                  id="branch_name"
                                  size="small"
                                  name="branch_name"
                                  error={
                                    Boolean(errors.branch_name) &&
                                    Boolean(touched.bank_name)
                                  }
                                  helperText={
                                    Boolean(touched.branch_name) &&
                                    errors.branch_name
                                  }
                                  className="col-10"
                                  onChange={(e) => {
                                    const newValue = capitalizeWords(
                                      e.target.value
                                    );
                                    setFieldValue("branch_name", newValue);
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item lg={4} md={4} sm={4} mt={1}>
                              IFSC :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Tooltip title="IFSC" arrow placement="right-end">
                                <Field
                                  as={MuiTextField}
                                  variant="outlined"
                                  placeholder="IFSC"
                                  id="ifsc"
                                  size="small"
                                  name="ifsc"
                                  error={
                                    Boolean(errors.ifsc) &&
                                    Boolean(touched.ifsc)
                                  }
                                  helperText={
                                    Boolean(touched.ifsc) && errors.ifsc
                                  }
                                  className="col-10"
                                  onChange={(e) => {
                                    const newValue = capitalizeWords(
                                      e.target.value
                                    );
                                    const isAllowed = /^[A-Z0-9]{0,11}$/.test(
                                      newValue
                                    );
                                    if (isAllowed) {
                                      setFieldValue("ifsc", newValue);
                                    }
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item lg={4} md={4} sm={4} mt={1}>
                              GST / PAN
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Tooltip
                                title="GST / PAN"
                                arrow
                                placement="right-end"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      sx={{
                                        color: "white",
                                        fontSize: "25px",
                                        "&.Mui-checked": {
                                          color: theme.palette.primary.main,
                                        },
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(255, 255, 255, 0.1)",
                                        },
                                      }}
                                      checked={values.bank_gst_no_checkbox}
                                    />
                                  }
                                  name="bank_gst_no_checkbox"
                                  value={values.bank_gst_no_checkbox}
                                  onChange={(event, value) => {
                                    setFieldValue("gst_no", "");
                                    setFieldValue("pan_no", "");
                                    setFieldValue(
                                      "bank_gst_no_checkbox",
                                      value
                                    );
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>

                        {values.bank_gst_no_checkbox ? (
                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                GST :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title="GST"
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="GST"
                                    id="gst_no"
                                    size="small"
                                    name="gst_no"
                                    error={
                                      Boolean(errors.gst_no) &&
                                      Boolean(touched.gst_no)
                                    }
                                    helperText={
                                      Boolean(touched.gst_no) && errors.gst_no
                                    }
                                    className="col-10"
                                    onChange={(e) => {
                                      const newValue = capitalizeWords(
                                        e.target.value
                                      );
                                      const isAllowed = /^[A-Z0-9]{0,11}$/.test(
                                        newValue
                                      );
                                      if (isAllowed) {
                                        setFieldValue("gst_no", newValue);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                PAN :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title="PAN"
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="PAN"
                                    id="pan_no"
                                    size="small"
                                    name="pan_no"
                                    error={
                                      Boolean(errors.pan_no) &&
                                      Boolean(touched.pan_no)
                                    }
                                    helperText={
                                      Boolean(touched.pan_no) && errors.pan_no
                                    }
                                    className="col-10"
                                    onChange={(e) => {
                                      const newValue = capitalizeWords(
                                        e.target.value
                                      );
                                      const isAllowed = /^[A-Z0-9]{0,11}$/.test(
                                        newValue
                                      );
                                      if (isAllowed) {
                                        setFieldValue("pan_no", newValue);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          display="flex"
                          justifyContent="center"
                          gap={2}
                          mt={4}
                        >
                          <Grid item sm={3} xs={12}>
                            <MuiButton
                              type="submit"
                              name="Submit"
                              loading={bankLoading}
                              width="100%"
                            />
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <MuiButton
                              name={"Reset"}
                              cancel
                              width="100%"
                              onClick={() => {
                                setFieldValue("account_holder_name", "");
                                setFieldValue("account_number", "");
                                setFieldValue('bank_address', '');
                                setFieldValue("bank_name", "");
                                setFieldValue("ifsc", "");
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        }
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(BankInformation);
