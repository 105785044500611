import React, { useEffect, useState } from "react";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import environment from "environment";
import ReactPlayer from "react-player";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import { theme } from "../App";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const RetriveAssetDialog = (props) => {
  const { RetriveData, releaseId } = props;

  const [assetUrl, setAssetUrl] = useState("");
  const [imageLoader, setImageLoader] = useState(false);

  const onClosefunction = () => {
    // stopGetRequest(props.onClose);
    props.onClose();
  };

  let access_tocken = localStorage.getItem("Auth")
    ? JSON.parse(localStorage.getItem("Auth")).access_token
    : "";

  return (
    <Box>
      <Box>
        <Grid
          item
          container
          xl={12}
          alignItems="center"
          justifyContent={"center"}
        >
          {props?.RetriveData?.asset_url ? (
            <ReactPlayer
              url={`${environment.api.retriveTrackAsset}?path=${props?.RetriveData?.asset_url}&release_id=${releaseId}&client_id=${RetriveData.client_id.id}&token=${access_tocken}&current_page=upload`}
              controls={true}
              width="100%"
              height="20px"
              maxWidth="100%"
              className=" p-0 m-0"
              style={{
                padding: 0,
                margin: 0,
                display: "none",
              }}
              config={{
                file: {
                  forceAudio: true, // Ensures that ReactPlayer uses audio controls
                  attributes: {
                    controlsList: "nodownload", // Optional: Hide download button
                  },
                },
              }}
            />
          ) : (
            <NotStartedIcon />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default connect(mapDispatchToProps)(RetriveAssetDialog);
