import React, { useEffect, useRef, useState } from "react";
import "../mainnav/main-nav.css";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardMedia,
  ClickAwayListener,
  Collapse,
  CssBaseline,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../Images/A3Tunes.webp";
import routes from "../Route/routecoll";
import AvatarIcon from "./components/AvatarIcon";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MyRoute from "../Route/routes";
import { connect } from "react-redux";
import MyAuthRoute from "../Route/Authroute";
import AuthAction from "../../stores/Auth/AuthAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import NewReleaseDialog from "../../views/release/NewReleaseDialog";
import ProfilePopper from "./components/ProfilePopper";
import NotificationAction from "../../stores/notifications/NotificationAction";
import NotificationDialog from "../../views/notification/NotificationDialog";
import NotificationBox from "../notificationPopOver/NotificationBox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { theme } from "../../views/App";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import BasicRichTreeView from "./NavBarTree";
import environment from "environment";
import { jwtDecode } from "jwt-decode";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import useMediaQuery from "@mui/material/useMediaQuery";
const drawerWidth = 220;
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AuthAction.REQUEST_LOGIN,
    NotificationAction.REQUEST_FILTER_NOTIFICATIONS,
    UserProfileAction.REQUEST_USERPROFILE,
    ReleasesAction.REQUEST_DRAFT_RELEASES,
  ]),
  draftRelease: state.draftRelease.draftRelease || [],
  auth: state.auth,
  NotificationsFilter: state.NotificationsFilter.NotificationsFilter || [],
  userprofile: state.userprofile.userprofile || [],
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `100%`,
  zIndex: theme.zIndex.drawer + 1,
  // boxShadow: theme.shadows,
  borderBottom: `11px solid ${theme.palette.background.main}`,
  backgroundImage: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // margin Left: drawerWidth,
    width: `100%`,
    // boxShadow: theme.shadows,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MenuNavLink = (props) => {
  return <Link exact="true" {...props} style={{ textDecoration: "none" }} />;
};

const MainNav = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));
  const isLG = useMediaQuery(theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("laptop"));

  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [notificationsData, setNotificationData] = useState({});
  const [openNotificationDialog, setOpenNotificationsDialog] = useState(false);
  const topHeaderRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const onlineAlertRef = useRef(null);
  const [alertBoxHeight, setAlertBoxHeight] = useState(0);
  const [open, setOpen] = React.useState(true);
  const io = require("socket.io-client");
  const [IsUserLogin, setIsUserLogin] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [notificationReceived, setNotificationReceived] = useState(null);
  const socketRef = useRef(null);
  const getToolbarStyles = () => {
    if (isXL) {
      return { padding: "25px 100px", height: "114px" };
    } else {
      return { padding: "15px 54px", height: "66px" };
    }
  };
  function fetchNotifications() {
    let params = {
      page_limit: "none",
    };

    // the below callback When the user login for the first time, the latest unread notification need to open
    let callback = (Response) => {
      Response?.hits &&
        Response.hits.length > 0 &&
        setNotificationData({
          id: Response.hits[0]._id,
          created_by: Response.hits[0]?._source.created_by,
          created_on: Response.hits[0]?._source.created_on,
          modified_by: Response.hits[0]?._source.modified_by,
          modified_on: Response.hits[0]?._source.modified_on,
          notification_image_url:
            Response.hits[0]?._source.notification_image_url,
          notification_msg: Response.hits[0]?._source.notification_msg,
          notification_msg_read_status:
            Response.hits[0]?._source.notification_msg_read_status,
          notification_sent: Response.hits[0]?._source.notification_sent,
          notification_title: Response.hits[0]?._source.notification_title,
          notification_type: Response.hits[0]?._source.notification_type,
        });

      Response?.hits &&
        Response.hits.length > 0 &&
        setOpenNotificationsDialog(true);
    };

    props.dispatch(
      NotificationAction.requestFilterNotifications(params, callback)
    );
  }
  const { isRequesting } = props;

  // Socket service for notifications

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Disconnect the socket when the page is about to unload
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Cleanup function: Disconnect the socket when the component is unmounted
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
      }
    };
  }, [socketConnected]);
  function fetchReleases1(params) {
    props.dispatch(ReleasesAction.requesDraftReleases(params));
  }

  useEffect(() => {
    if (IsUserLogin) {
      const filterparam = {
        filters: `[["status.keyword","must","","match","1"]]`,
      };
      fetchReleases1(filterparam);
    }
  }, [IsUserLogin]);
  let draftcount = props.draftRelease?.hits?.hits?.filter(
    (item) => item?._source?.status?.id === "1"
  ).length;

  useEffect(() => {
    draftcount === 0 && setOpenReleaseDialog(true);
  }, [draftcount]);

  useEffect(() => {
    if (IsUserLogin) fetchNotifications();

    if (IsUserLogin && socketConnected === false) {
      // alert("CONNECTING TO SOCKET");
      socketRef.current = io(`wss://${environment.api.socketApi}`, {
        transportOptions: {
          polling: {
            extraHeaders: {
              "user-id": jwtDecode(localStorage.getItem("Auth")).sub,
            },
          },
        },
        logger: false,
      });

      socketRef.current.on("connect", () => {
        // alert("Connected to Socket");
        setSocketConnected(true);
        localStorage.setItem("socket_id", socketRef.current.id);

        socketRef.current.on("message", (data) => {
          alert("Received message:", data);
        });
      });

      return () => {
        // Disconnect the socket when the component is re-rendered
        if (socketRef.current && socketConnected) {
          socketRef.current.disconnect();
          setSocketConnected(false);
        }
      };
    }
  }, [IsUserLogin]);

  useEffect(() => {
    if (socketRef.current !== null) {
      socketRef.current.on("connect", () => {
        // alert("Connected to Socket");
        setSocketConnected(true);

        socketRef.current.on("message", (data) => {
          alert("Received message:", data);
        });

        socketRef.current.on("disconnect", () => {});
      });
    }
  }, [socketRef.current]);

  useEffect(() => {
    if (socketConnected) {
      socketRef.current.on("send_notification", (data) => {
        setNotificationReceived(data);
      });

      socketRef.current.on("disconnect", () => {
        // alert("disconnected_from_server");
        setSocketConnected(false);
      });
    }
  }, [socketConnected]);

  if (!IsUserLogin) {
    if (localStorage.getItem("Auth") != null) {
      setIsUserLogin(true);
    } else {
      // Cleanup the socket when the user is not logged in
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
        setSocketConnected(false);
      }
    }
  }

  useEffect(() => {
    if (notificationReceived !== null) {
      setNotificationData({
        id: null,
        created_by: notificationReceived.created_by,
        created_on: notificationReceived.created_on,
        modified_by: notificationReceived.modified_by,
        modified_on: notificationReceived.modified_on,
        notification_image_url: notificationReceived.notification_image_url,
        notification_msg: notificationReceived.notification_msg,
        notification_msg_read_status:
          notificationReceived.notification_msg_read_status,
        notification_sent: notificationReceived.notification_sent,
        notification_title: notificationReceived.notification_title,
        notification_type: notificationReceived.notification_type,
      });

      setOpenNotificationsDialog(true);

      // alert(
      //   `${
      //     notificationReceived.notification_title &&
      //     notificationReceived.notification_title
      //   }\nDescription : ${
      //     notificationReceived.notification_msg &&
      //     notificationReceived.notification_msg
      //   }\n`
      // );
      setNotificationReceived((prev) => null);

      setNewNotificationsCount((prev) => {
        return prev + 1;
      });
    }
  }, [notificationReceived]);

  useEffect(() => {
    if (!isRequesting) {
      setNewNotificationsCount(
        props.NotificationsFilter.hits?.hits?.filter(
          (item) => item._source.notification_msg_read_status === false
        ).length
      );
    }
  }, [props.NotificationsFilter]);

  if (!IsUserLogin) {
    if (localStorage.getItem("Auth") != null) {
      setIsUserLogin(true);
    } else {
      socketRef.current && socketRef.current.close();
    }
  }

  useEffect(() => {
    const topHeaderHeight = topHeaderRef?.current?.offsetHeight;
    setHeaderHeight(topHeaderHeight);

    const alertHeight = onlineAlertRef?.current?.offsetHeight;
    setAlertBoxHeight(alertHeight);
  });

  const [openReleases, setOpenReleases] = React.useState(false);

  const handleReleasesSettings = () => {
    setOpenReleases(!openReleases);
  };

  useEffect((event) => {
    if (localStorage.getItem("Auth") === null) {
      navigate("/", { state: { from: location.pathname } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoClick = (item) => {
    navigate("/dashboard");
  };

  let pathname = window.location.pathname;

  const [openReleaseDialog, setOpenReleaseDialog] = useState(false);
  const [format, setformat] = useState(null);

  const [openMenu, setOpenMenu] = React.useState(false);
  const menuPopperRef = React.useRef(null);

  const handleMenuPopper = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (menuPopperRef?.current?.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  let selectedRow = null;
  if (routes) {
    routes.data
      .filter((item) => item.path === pathname)
      .map((value) => (selectedRow = value.id));
  }

  let releases_menu_array = [];
  let releases_menu_array_main = [];

  if (routes) {
    {
      releases_menu_array = routes.data.filter(
        (col) => col.category === "Release"
      );
      releases_menu_array[0] &&
        releases_menu_array_main.push(releases_menu_array[0]);
    }
  }

  let access_tocken = localStorage.getItem("Auth")
    ? JSON.parse(localStorage.getItem("Auth")).access_token
    : "";

  const useStyles = makeStyles((theme) => ({
    root: {
      height: 48,

      fontSize: 13,
    },
    toolbar: {
      minHeight: 48,
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: "8%",
      paddingRight: "8%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: "space-around",
      },
    },
    menu: {
      color: "white",
      backgroundColor: "#242424",
    },
    menuItem: {
      paddingLeft: "16px",
      paddingTop: "13px",
      paddingRight: "93px",
      paddingBottom: "13px",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.21px",
      textAlign: "left",
      color: "#adadad",
      backgroundColor: "#242424",
      [theme.breakpoints.down("xl")]: {
        fontSize: "9px", // For laptop screens
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "14px", // For laptop screens
      },
    },
    listStyles: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      position: "relative",
    },
    listItemStyle: {
      fontFamily: "Inter",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#e3e3e3",
    },
  }));

  const classes = useStyles();

  const accountSettingsMenuList = [
    {
      primaryText: "SINGLE",
      icon: "Home",
      category: "CREATE RELEASE",
    },

    {
      primaryText: "ALBUM",
      icon: "Home",
      category: "CREATE RELEASE",
    },

    // {
    //   primaryText: "RELEASE",
    //   icon: "Home",
    //   category: "LIBRARY",
    // },

    {
      primaryText: "RELEASED",
      icon: "Home",
      category: "LIBRARY",
    },

    {
      primaryText: "SUBMITTED",
      icon: "Home",
      category: "LIBRARY",
    },
    {
      primaryText: "DRAFTS",
      icon: "Home",
      category: "LIBRARY",
    },
    {
      primaryText: "REJECTED",
      icon: "Home",
      category: "LIBRARY",
    },
    {
      primaryText: "DAILY TRENDS",
      icon: "Home",
      category: "INSIGHTS",
    },

    {
      primaryText: "STREAM ANALYTICS",
      icon: "Home",
      category: "INSIGHTS",
    },

    {
      primaryText: "REVENUE ANALYTICS",
      icon: "Home",
      category: "ROYALITIES",
    },
    {
      primaryText: "DOWNLOAD REPORT",
      icon: "Home",
      category: "ROYALITIES",
    },

    {
      primaryText: "PAYMENT",
      icon: "Home",
      category: "ROYALITIES",
    },
    {
      primaryText: "MONTHWISE REVENUE",
      icon: "Home",
      category: "ROYALITIES",
    },
    // {
    //   primaryText: "CUMULATIVE TRACKS",
    //   icon: "Home",
    //   category: "ROYALITIES",
    // },
    // {
    //   primaryText: "CURRENT PLAN",
    //   icon: "Home",
    //   category: "ACCOUNT SETTINGS",
    // },

    {
      primaryText: "SUBSCRIPTION PLANS",
      icon: "Home",
      category: "ACCOUNT SETTINGS",
    },

    // {
    //   primaryText: "UPGRADE PLAN",
    //   icon: "Home",
    //   category: "ACCOUNT SETTINGS",
    // },

    {
      primaryText: "MY SUBSCRIPTIONS",
      icon: "Home",
      category: "ACCOUNT SETTINGS",
    },
    {
      primaryText: "MY ORDERS",
      icon: "Home",
      category: "ACCOUNT SETTINGS",
    },
    {
      primaryText: "REQUESTS",
      icon: "Home",
      category: "ACCOUNT SETTINGS",
    },

    {
      primaryText: "BANK INFORMATION",
      icon: "Home",
      category: "ACCOUNT SETTINGS",
    },

    {
      primaryText: "PROFILE",
      icon: "Home",
      category: "ACCOUNT SETTINGS",
    },
  ];

  if (props.userprofile?._source?.subscription_plan_id) {
    accountSettingsMenuList.push({
      primaryText: "CURRENT PLAN",
      icon: "Home",
      category: "ACCOUNT SETTINGS",
    });
  }

  if (props.userprofile?._source?.subscription_plan_id !== 2) {
    accountSettingsMenuList.push({
      primaryText: "UPGRADE PLAN",
      icon: "Home",
      category: "ACCOUNT SETTINGS",
    });
  }

  const [filteredMenuList, setfilteredMenuList] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    setfilteredMenuList(
      accountSettingsMenuList?.filter(
        (menu) =>
          menu?.category?.toLowerCase() ===
          event?.currentTarget?.innerText?.toLowerCase()
      )
    );
  };

  const handleDirectNavigation = (event) => {
    // Destructure the relevant properties for better readability
    const { innerText } = event.currentTarget;

    // Find the navigation path based on the title
    let navigationPath = routes?.data?.find(
      (route) => route?.title?.toLowerCase() === innerText.toLowerCase()
    )?.path;

    const releaseArray = ["/single", "/album"];
    const libraryArray = [
      "/delivered",
      "/release",
      "/submitted",
      "/drafts",
      "/rejected",
    ];

    // Perform navigation if a path is found
    if (navigationPath) {
      if (releaseArray?.includes(navigationPath)) {
        if (navigationPath === "/single") {
          setformat("single");
          localStorage.setItem("pathIndex", "newRelease");
          setOpenReleaseDialog(true);
        } else if (navigationPath === "/album") {
          localStorage.setItem("pathIndex", "newRelease");
          setOpenReleaseDialog(true);
          setformat("album");
        }
      } else if (libraryArray?.includes(navigationPath)) {
        navigate(navigationPath, {
          state: {
            status:
              innerText?.toLowerCase() === "release"
                ? null
                : innerText?.toLowerCase() === "submitted"
                ? { id: 2, status_name: "Submitted" }
                : innerText?.toLowerCase() === "drafts"
                ? { id: 1, status_name: "Draft" }
                : innerText?.toLowerCase() === "rejected"
                ? { id: 9, status_name: "Rejected" }
                : null,
          },
        });
      } else {
        navigate(navigationPath);
      }
    } else {
      console.error("No matching route found.");
    }
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };

  const navList = [
    {
      primaryText: "CREATE RELEASE",
      icon: <ExpandMore />,
      isIconLeftSide: false,
      subItems: true,
      cta: handleClick,
    },
    {
      primaryText: "LIBRARY",
      icon: <ExpandMore />,
      isIconLeftSide: false,
      subItems: true,
      cta: handleClick,
    },
    {
      primaryText: "INSIGHTS",
      icon: <ExpandMore />,
      isIconLeftSide: false,
      subItems: true,
      cta: handleClick,
    },

    {
      primaryText: "ROYALITIES",
      icon: <ExpandMore />,
      isIconLeftSide: false,
      subItems: true,
      cta: handleClick,
    },

    {
      primaryText: "ACCOUNT SETTINGS",
      icon: <ExpandMore />,
      isIconLeftSide: false,
      subItems: true,
      cta: handleClick,
    },
    {
      primaryText: "NEWS & UPDATE",
      // icon: <ExpandMore />,
      isIconLeftSide: false,
      subItems: false,
      cta: (e) => {
        handleDirectNavigation(e);
      },
    },

    {
      primaryText: "Faq",
      // icon: <ExpandMore />,
      isIconLeftSide: false,
      subItems: false,
      cta: (e) => {
        handleDirectNavigation(e);
      },
    },
  ];

  return (
    <React.Fragment>
      <Box>
        <CssBaseline />
        {access_tocken === "" || access_tocken === undefined ? (
          // props.auth.access_token === "" ||
          //   props.auth.access_token === undefined
          <>
            <MyAuthRoute />
          </>
        ) : (
          <>
            <AppBar
              open={open}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: theme.palette.background.main,
                scrollbarWidth: "none",
                height: { laptop: "66px", lg: "66px", xl: "114px" },
              }}
              ref={topHeaderRef}
            >
              <NotificationDialog
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
                open={openNotificationDialog}
                onClose={() => {
                  setOpenNotificationsDialog(false);
                }}
                notificationsData={notificationsData}
              />
              <Toolbar style={getToolbarStyles()}>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    xl={2}
                    laptop={1}
                    lg={1}
                    md={5.5}
                    sm={7}
                    xs={6}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xl={12}
                      container
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid>
                        <CardMedia
                          component="img"
                          sx={{
                            cursor: "pointer",
                            maxWidth: {
                              xs: "70px",
                              sm: "80px",
                              md: "120px",
                              laptop: "60px",
                              lg: "60px",
                              xl: "113px",
                            },
                            maxHeight: {
                              xs: "40px",
                              sm: "50px",
                              md: "55px",
                              lg: "37px",
                              laptop: "37px",
                              xl: "69px",
                            },
                            height: "auto",
                          }}
                          image={logo}
                          onClick={() => {
                            logoClick();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xl={10}
                    laptop={11}
                    lg={11}
                    md={6.5}
                    sm={5}
                    xs={6}
                  >
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      laptop={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        container
                        item
                        lg={12}
                        laptop={12}
                        xl={12}
                        sm={12}
                        sx={{
                          display: {
                            lg: "flex",
                            xl: "flex",
                            laptop: "flex",
                            sm: "none",
                            xs: "none",
                            md: "none",
                          },
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* dummy */}

                        {navList.map(
                          (
                            {
                              primaryText,
                              icon,
                              subItems,
                              cta,
                              isIconLeftSide,
                            },
                            index
                          ) => (
                            <Grid key={index}>
                              <ListItem
                                className={classes.listItemStyle}
                                onClick={cta}
                                sx={{
                                  cursor: "pointer",
                                  fontSize: {
                                    xl: 18,
                                    lg: 10,
                                    laptop: 10,
                                    md: 12,
                                    sm: 10,
                                    xs: 9,
                                  },
                                  paddingX: {
                                    lg: "6px",
                                    laptop: "6px",
                                    xl: "8px",
                                  },
                                }}
                              >
                                {isIconLeftSide ? icon : null}
                                {primaryText?.toUpperCase()}
                                {!isIconLeftSide ? icon : null}
                              </ListItem>
                            </Grid>
                          )
                        )}
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose2}
                          // PopoverClasses={{
                          //   paper: { backgroundColor: "red" },
                          // }}
                          sx={{
                            "& .MuiList-root": {
                              padding: 0,
                            },
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          // anchorPosition={{ left: 10, top: 10 }}
                          getContentAnchorEl={null}
                        >
                          {filteredMenuList?.map((item, index) => (
                            <MenuItem
                              key={index}
                              onClick={(e) => {
                                handleDirectNavigation(e);
                                setAnchorEl(null);
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#000",
                                },

                                // paddingLeft: "16px",
                                // paddingTop: "13px",
                                // paddingRight: {
                                //   xl: "46px",
                                //   laptop: "16px",
                                //   lg: "16px",
                                // },
                                // paddingBottom: "13px",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "0.21px",
                                textAlign: "left",
                                color: "#adadad",
                                backgroundColor: "#242424",
                                [theme.breakpoints.down("xl")]: {
                                  fontSize: "9px", // For laptop screens
                                  paddingRight: "16px",
                                  paddingLeft: "8px",
                                  paddingTop: "8px",
                                  paddingBottom: "8px",
                                },
                                [theme.breakpoints.up("xl")]: {
                                  fontSize: "14px", // For laptop screens
                                  paddingRight: "46px",
                                  paddingLeft: "16px",
                                  paddingTop: "13px",
                                  paddingBottom: "13px",
                                },
                              }}
                              className={classes.menuItem}
                            >
                              {item.primaryText}
                            </MenuItem>
                          ))}
                        </Menu>
                        {/* dummy */}

                        <Grid
                          paddingRight={0}
                          paddingLeft={0}
                          display="flex"
                          justifyContent="center"
                          alignSelf="center"
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                          }}
                        >
                          {/* <Box
                            sx={{
                              padding: {
                                lg: "8px 6px",
                                laptop: "8px 6px",
                                xl: "8px 8px",
                              },
                            }}
                          >
                            <AddShoppingCartIcon
                              sx={{
                                fontSize: { xl: 30, laptop: 16, lg: 16 },
                              }}
                            />
                          </Box> */}
                        </Grid>

                        <Grid
                          mr={0}
                          component="button"
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              laptop: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                            backgroundColor: "#030303",
                            textAlign: "inherit",
                            border: "none",
                            color: "white",
                          }}
                        >
                          <NotificationBox
                            count={newNotificationsCount}
                            setNewNotificationsCount={setNewNotificationsCount}
                            fetchNotifications={fetchNotifications}
                            sx={{
                              width: theme.typography.appIcon.width,
                              height: theme.typography.appIcon.height,
                            }}
                          />
                        </Grid>

                        <Grid
                          paddingRight={1}
                          paddingLeft={0}
                          display="flex"
                          justifyContent="center"
                          alignSelf="center"
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              laptop: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                          }}
                        >
                          <ProfilePopper>
                            <Box sx={{ paddingY: "8px", paddingLeft: "6px" }}>
                              <AvatarIcon />
                            </Box>
                          </ProfilePopper>
                        </Grid>
                      </Grid>
                      {/* upto here */}
                      <Grid
                        container
                        item
                        lg={12}
                        xl={12}
                        sm={12}
                        sx={{
                          display: {
                            lg: "none",
                            xl: "none",
                            laptop: "none",
                            sm: "flex",
                          },
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography
                          component={"div"}
                          ref={menuPopperRef}
                          id="composition-button"
                          aria-controls={
                            openMenu ? "composition-menu" : undefined
                          }
                          aria-expanded={openMenu ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleMenuPopper}
                          style={{ backgroundColor: "none" }}
                        >
                          <IconButton>
                            <MenuIcon />
                          </IconButton>
                        </Typography>

                        <Popper
                          open={openMenu}
                          anchorEl={menuPopperRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                          sx={{
                            zIndex: 100,
                            width: "100%",
                          }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                              }}
                            >
                              <Paper
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ClickAwayListener onClickAway={handleClose}>
                                  <div
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                  >
                                    <Grid
                                      container
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      p={2}
                                      sx={{ boxShadow: `${theme.shadows}` }}
                                    >
                                      <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        paddingX={2}
                                      >
                                        <Typography
                                          sx={{
                                            width: "100%",
                                          }}
                                          component="div"
                                          fontFamily={
                                            theme.typography.fontFamily
                                          }
                                          fontSize={"22px"}
                                          fontWeight={
                                            theme.typography.fontWeight.regular
                                          }
                                          color={theme.palette.text.white}
                                        >
                                          <BasicRichTreeView
                                            routes={routes}
                                            onClose={() => {
                                              setOpenMenu(false);
                                            }}
                                            setformat={setformat}
                                            setOpenReleaseDialog={
                                              setOpenReleaseDialog
                                            }
                                            openReleaseDialog={
                                              openReleaseDialog
                                            }
                                          />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>

                        <Grid
                          paddingRight={0}
                          paddingLeft={0}
                          display="flex"
                          justifyContent="center"
                          alignSelf="center"
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                          }}
                        ></Grid>

                        <Grid
                          mr={0}
                          component="button"
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                            backgroundColor: "#030303",
                            textAlign: "inherit",
                            border: "none",
                            color: "white",
                          }}
                        >
                          <NotificationBox
                            count={newNotificationsCount}
                            setNewNotificationsCount={setNewNotificationsCount}
                            fetchNotifications={fetchNotifications}
                            sx={{
                              width: theme.typography.appIcon.width,
                              height: theme.typography.appIcon.height,
                            }}
                          />
                        </Grid>

                        <Grid
                          paddingRight={1}
                          paddingLeft={0}
                          display="flex"
                          justifyContent="center"
                          alignSelf="center"
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                          }}
                        >
                          <ProfilePopper>
                            <Box>
                              <AvatarIcon />
                            </Box>
                          </ProfilePopper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>

            <Box
              component="main"
              sx={{
                flexGrow: 1,
                pl: 0,
              }}
            >
              <Box sx={{ height: { laptop: "66px", lg: "66px", xl: "114px" } }}>
                <DrawerHeader />
              </Box>
              <Box
                sx={{
                  display: navigator.onLine ? "none" : "block",
                }}
              >
                <Alert
                  severity="error"
                  hidden={navigator.onLine}
                  ref={onlineAlertRef}
                >
                  <AlertTitle>
                    <strong>No Internet!</strong>
                  </AlertTitle>
                  Please make sure you are connected to an internet.
                </Alert>

                <NewReleaseDialog
                  open={openReleaseDialog}
                  onClose={() => {
                    setOpenReleaseDialog(false);
                  }}
                  format={format}
                  setformat={setformat}
                />
              </Box>

              <Box height={`calc(100vh - ${headerHeight + alertBoxHeight}px )`}>
                <MyRoute />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(MainNav);
MainNav.propTypes = {
  userprofile: PropTypes.shape({
    _source: PropTypes.object, // Adjust this according to your expected shape
  }).isRequired,
};
