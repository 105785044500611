import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TrackModel from "./model/TrackModel";
import TrackPutModel from "./model/TrackPutModel";
import TrackPostModel from "./model/TrackPostModel";
import TrackByIdModel from "./model/TrackByIdModel";
import SyncPlaylinkModel from "./model/SyncPlaylinkModel";
import TrackBulkPutModel from "./model/TrackBulkPutModel";
import TrackStatusCountGet from "./model/TrackStatusCountGet";

// TrackEffect
export default class TrackEffect {
  // calling an API according to the track model
  // Get method for the
  static async requestTrack(params, callBack) {
    const endpoint = environment.api.track;
    let response = EffectUtility.getToModel(TrackModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestSyncTrackPlaylink(id, params) {
    const endpoint = environment.api.syncplaylink + "/" + id;
    return EffectUtility.getToModel(SyncPlaylinkModel, endpoint, params);
  }

  static async requestSyncReleaseStores(id, params) {
    const endpoint = environment.api.syncreleasesstores + "/" + id;
    return EffectUtility.getToModel(SyncPlaylinkModel, endpoint, params);
  }

  static async requestTrackByIdClear() {
    return {};
  }

  static async requestTrackById(id, params) {
    const endpoint = environment.api.track + "/" + id;
    return EffectUtility.getToModel(TrackByIdModel, endpoint, params);
  }

 
  // Get method for the track filter
  static async requestTrackFilter(params) {
    const endpoint = environment.api.track;
    return EffectUtility.getToModel(TrackModel, endpoint, params);
  }

  // update method for the playlink
  static async requestUpdatePlayLink(data, id, params) {
    const endpoint = environment.api.updateplaylink + "/" + id;
    return EffectUtility.putToModel(TrackPutModel, endpoint, data, params);
  }

  // update method for the album playlink
  static async requestAlbumPlayLink(data, id, params) {
    const endpoint = environment.api.albumPlayLink + "/" + id;
    return EffectUtility.putToModel(TrackPutModel, endpoint, data, params);
  }

  // put method for the track
  static async requestPutTrack(data, id, params) {
    const endpoint = environment.api.track + "/" + id;
    return EffectUtility.putToModel(TrackPutModel, endpoint, data, params);
  }

  // post method for the track
  static async requestPostTrack(data, params) {
    const endpoint = environment.api.track + "/add";
    return EffectUtility.postToModel(TrackPostModel, endpoint, data, params);
  }

  static async requestDeleteTrack(id, callback) {
    const endpoint = environment.api.track + "/" + id;
    let response = EffectUtility.deleteToModel(TrackPostModel, endpoint);

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestExistingTracks(params) {
    const endpoint = environment.api.track;
    let response = EffectUtility.getToModel(TrackModel, endpoint, params);
    return response;
  }

  static async requestExistingTracksClear() {
    return {};
  }

  static async requestPutTrackBulkUpdate(data) {
    const endpoint = environment.api.trackBulk;
    return EffectUtility.putToModel(TrackBulkPutModel, endpoint, data);
  }

  static async requestTrackStatusCount(params) {
    const endpoint = environment.api.trackStatusCount;
    return EffectUtility.getToModel(TrackStatusCountGet, endpoint, params);
  }
}
