import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useCallback, useEffect, useState } from "react";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import {
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Paper,
  Popper,
  FormGroup,
  Snackbar,
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import MuiButton from "../../components/muiButton/MuiButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
// Autocomplete
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../components/datePicker/DateRangePicker";
import { useLocation } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TicketsAction from "../../stores/tickets/TicketsAction";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import copy from "copy-to-clipboard";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import RaiseTicketPopper from "../../components/poppers/RaiseTicketPopper";
import AddTaskIcon from "@mui/icons-material/AddTask";
import RaiseIssueDialog from "../release/RaiseIssueDialog";
import environment from "environment";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TicketsAction.REQUEST_TICKETS,
    ReleasesAction.REQUEST_RELEASE_FILTER,
  ]),
  tickets: state.tickets.tickets || [],
  pagination: state.tickets || [],
});

// Ticket list
function Issues(props) {
  const theme = useTheme();

  //using useLocation for props from  dashboard page
  let { state } = useLocation();
  let navigate = useNavigate();

  let location = useLocation();
  //initial props state assigning with useeffect
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [copied, setCopied] = useState(false);

  const [selected_release, set_selected_release] = useState(null);
  const [createdDatelistSelected, setCreatedDatelistSelected] = useState(null);
  const [createdDate, setDate] = useState([null, null]);
  const [selected_status, set_selected_status] = useState(null);

  useEffect(() => {
    function fetchFunction() {
      let params = {
        page_limit: `${page_limit}`,
        order_by: '[["modified_on", "desc"]]',
        current_page: "requests",
      };
      fetchTickets(params);

      const filterparam = {
        page_limit: "none",
        order_by: `[["created_on","desc"]]`,
        current_page: "requests",
      };
    }
    fetchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchTickets(params) {
    props.dispatch(TicketsAction.requestTickets(params));
  }

  // Pagination
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {
      page_number: `${value}` - 1,
      page_limit: `${page_limit}`,
      order_by: '[["modified_on", "desc"]]',
      current_page: "requests",
    };

    if (value - 1 !== props.pagination.tickets.current_page_no) {
      fetchTickets(params);
    }
  };

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const issueTypes = ["Change Requests"];
  const [openIssueRaiseDialog, setOpenIssueRaiseDialog] = useState(false);
  const [releaseDataIssue, setReleaseDataIssue] = useState(null);

  const columns = [
    {
      field: "ticket_id",
      headerName: "SL NO",
      width: 100,
      flex: 0.5,
      renderCell: (params, index) => {
        return (
          <>
            <Typography sx={{ width: 25 }}>
              {params.row ? params.row.ticket_id.slno : ""}
            </Typography>

            <IconButton
              title="Ticket Id"
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                copyToClipboard(params.id);
              }}
            >
              <ContentCopyRoundedIcon fontSize="small" />
            </IconButton>
            <Snackbar
              message="Ticket Id copied to clipboard"
              sx={{ height: "70%" }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={500}
              onClose={() => setCopied(false)}
              open={copied}
            />
          </>
        );
      },
    },
    {
      field: "ticket_name",
      flex: 3.5,
      headerName: "Ticket Name",
      width: 250,
    },
    {
      field: "description",
      flex: 3.5,
      headerName: "Description",
      width: 250,
      renderCell: (params) => <ReturnDescription params={params.row} />,
    },
    { field: "created_on", headerName: "Created On", width: 200, flex: 1.5 },

    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      width: 200,
      // hide: selected && selected && selected.includes("Status") ? false : true,
    },

    // {
    //   width: 50,
    //   field: "issues",
    //   type: "actions",
    //   headerName: (
    //     <div title="Add Task">
    //       <AddTaskIcon
    //         style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
    //       />
    //     </div>
    //   ),
    //   //
    //   // width: 160,
    //   cellClassName: "actions",
    //   renderCell: (params) => (
    //     <RaiseTicketPopper
    //       issueTypes={issueTypes}
    //       onClick={(issueType) => {
    //         setOpenIssueRaiseDialog(true);
    //         setReleaseDataIssue({ releaseData: params.row, type: issueType });
    //       }}
    //     >
    //       <div title="Add Task">
    //         <AddTaskIcon
    //           style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
    //         />
    //       </div>
    //     </RaiseTicketPopper>
    //   ),
    // },
  ];

  const ReturnDescription = (desc) => {
    return <span className="text-wrap">{desc.params.description[0]}</span>;
  };

  const returnTrimmedTicketDesc = (ticketDesc) => {
    let maxLen = 100;
    let ticket_desc = ticketDesc;
    if (ticket_desc.length > maxLen) {
      ticket_desc =
        ticket_desc
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&")
          .substring(0, maxLen - 3) + "...";
    }
    return <p className="text-left">{ticket_desc}</p>;
  };

  const rows = [];
  if (props.tickets.hits) {
    props.tickets.hits.hits.map((list, index) => {
      return rows.push({
        id: list._id,
        ticket_id: {
          id: list._id,
          slno: list._id
            ? props.pagination.tickets.current_page_no * page_limit + ++index
            : "",
        },
        ticket_name: list._source.ticket_name,
        description: [
          list._source.ticket_desc &&
            returnTrimmedTicketDesc(list._source.ticket_desc),
        ],
        created_on: (function createdDate() {
          let created = list._source.created_on;
          let dateConversion = new Date(created);
          dateConversion =
            dateConversion.toDateString() +
            " " +
            dateConversion.toLocaleTimeString();

          return dateConversion;
        })(),
        priority: Object.getOwnPropertySymbols(list._source.priority)
          ? list._source.priority.priority_name
          : null,

        // customer_name: Object.getOwnPropertySymbols(list._source.customer)
        //   ? list._source.customer.customer_name
        //   : null,
        customer_name:
          list._source.customer_id && list._source.customer_id.customer_name,
        status: list._source.status ? list._source.status.status_name : null,
        assigned_to: list._source.assigned_to.user_id
          ? list._source.assigned_to.user_id
          : "",
        platform: list._source.platform && list._source.platform.platform_name,
        activities:
          list._source.activities && list._source.activities.activities_name,
        sentiment_value:
          list._source.sentiment_value !== null
            ? `${
                list._source.sentiment_value === 0.0
                  ? "0.0"
                  : list._source.sentiment_value
              }`
            : null,
        tags:
          list._source.tags && list._source.tags.map((item) => item.tag_name),
        category: list._source.category && list._source.category.category_name,
        release_id: list._source.release_id ? list._source.release_id : "",
      });
    });
  }
  console.log("dlsajl", rows);
  // fetch function
  const { isRequesting } = props;

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  function releaseHandleChange(e, value) {
    set_selected_release(value);
  }

  function createdDateHandle(event, createdDate) {
    setCreatedDatelistSelected(createdDate);
  }

  function dateValueHandleChange(e, v) {
    setDate([e[0].$d, e[1].$d]);
  }

  function statushandleChange(event, status) {
    set_selected_status(status);
  }

  const handleRowClick = (event, row) => {
    // const param = {
    //   current_page: "issues",
    // };

    // const callback = (res) => {
    //   console.log("res===>", res);
    // };

    // props.dispatch(
    //   ReleasesAction.requestFinalReleaseById(
    //     event.row.release_id,
    //     param,
    //     callback
    //   )
    // );

    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    event.row.release_id &&
      fetch(`${environment.api.finalReleases}/${event.row.release_id}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            if (
              response.found === true &&
              response?._source?.status?.id &&
              (response?._source?.status?.id === "7" ||
                response?._source?.status?.id === "8")
            ) {
              navigate("/deliveredRelease", {
                state: {
                  releaseId: event.row.release_id,
                },
              });
            } else {
              localStorage.setItem("id", event.row.release_id);
              navigate("/releaseInfo");
            }
          }
        });
  };

  return (
    <Box>
      <Grid container>
        <Typography
          component={"span"}
          sx={{ fontSize: theme.typography.h2.fontSize }}
        >
          {formatName("My Requests")}
        </Typography>
      </Grid>
      <Grid
        display="flex"
        justifyContent="center"
        container
        item
        lg={12}
        md={12}
        sm={12}
      >
        {/* loading icon */}
        <Grid item lg={12} md={12} sm={12} pr={1}>
          <LoadingIndicator isActive={isRequesting}>
            {isRequesting ? (
              <Box>
                <LoadingBgImage />
              </Box>
            ) : (
              <DataGridFunction
                curser="pointer"
                columns={columns}
                rows={rows}
                pagelength={props.pagination.tickets.total_page_no}
                page={props.pagination.tickets.current_page_no}
                handleChange={handleChange}
                onRowClick={handleRowClick}
                checkboxSelection={false}
              />
            )}
          </LoadingIndicator>

          <RaiseIssueDialog
            open={openIssueRaiseDialog}
            onClose={() => {
              setOpenIssueRaiseDialog(false);
            }}
            releaseInfo={releaseDataIssue}
            current_page={"requests"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
export default connect(mapStateToProps)(Issues);
