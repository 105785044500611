import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import Rettrive from "../../release/Rettrive";

const LatestReleasesBox = ({ latestReleaseList }) => {
  let navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-between",
        whiteSpace: "nowrap",
        wordWrap: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingY: "99px",
      }}
    >
      {latestReleaseList &&
        latestReleaseList.map((item) => {
          return (
            <Card
              sx={{
                width: "143px",
                height: "177px",
                cursor: "pointer",
                objectFit: "contain ",
                borderRadius: 0,
              }}
              onClick={() => {
                navigate("/deliveredRelease", {
                  state: {
                    releaseId: item._id,
                  },
                });
              }}
            >
              <CardContent sx={{ padding: 0 }}>
                <Box
                  sx={{
                    height: "143px", // Image height
                    width: "143px", // Image width
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  <Rettrive
                    thumbnail_url={item?._source?.thumbnail_url}
                    release_id={item._id}
                    current_page={"dashboard"}
                  />
                </Box>
                <Box
                  sx={{
                    height: "34px", // Remaining height for title
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingY: 1,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {item?._source?.title}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          );
        })}
    </Box>
  );
};

export default LatestReleasesBox;
