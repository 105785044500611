import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { theme } from "../App";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { useNavigate } from "react-router";
import { Field, Form, Formik } from "formik";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiButton from "../../components/muiButton/MuiButton";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import TerritoriesAction from "../../stores/territories/TerritoriesAction";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TerritoriesAction.REQUEST_TERRITORIES,
  ]),
  territories: state.territories.territories || {},
});

const NewReleaseDialog = (props) => {
  const navigate = useNavigate();

  let initial = {
    title: "",
    is_new_release: false,
    format:props.format,
  };
  const [loading, setLoading] = useState(false);
  function fetchTerritoriesData() {
    let params = {
      page_limit: "none",
      current_page: "releases",
    };
    props.dispatch(TerritoriesAction.requestTerritories(params));
  }

  useEffect(() => {
    props.open === true && fetchTerritoriesData();
  }, [props.open]);

  let Africa = [];
  let Antartica = [];
  let Australia = [];
  let Europe = [];
  let Northamerica = [];
  let southamerica = [];
  let Asia = [];

  if (props.territories && props.territories.territory_data) {
    Africa = props.territories.territory_data.AF;
    Antartica = props.territories.territory_data.AN;
    Australia = props.territories.territory_data.OC;
    Europe = props.territories.territory_data.EU;
    Northamerica = props.territories.territory_data.NA;
    southamerica = props.territories.territory_data.SA;
    Asia = props.territories.territory_data.AS;
  }

  let AsiaIdArray = [];

  Asia &&
    Asia.map((item) => {
      AsiaIdArray.push(item.country_code);
    });

  let AfricaIdArray = [];

  Africa &&
    Africa.map((item) => {
      AfricaIdArray.push(item.country_code);
    });

  let AntarticaIdArray = [];

  Antartica &&
    Antartica.map((item) => {
      AntarticaIdArray.push(item.country_code);
    });

  let AustraliaIdArray = [];

  Australia &&
    Australia.map((item) => {
      AustraliaIdArray.push(item.country_code);
    });

  let EuropeIdArray = [];

  Europe &&
    Europe.map((item) => {
      EuropeIdArray.push(item.country_code);
    });

  let NorthamericaIdArray = [];

  Northamerica &&
    Northamerica.map((item) => {
      NorthamericaIdArray.push(item.country_code);
    });

  let southamericaIdArray = [];

  southamerica &&
    southamerica.map((item) => {
      southamericaIdArray.push(item.country_code);
    });

  let allCountriesArray = [
    ...AfricaIdArray,
    ...AntarticaIdArray,
    ...AustraliaIdArray,
    ...EuropeIdArray,
    ...NorthamericaIdArray,
    ...southamericaIdArray,
    ...AsiaIdArray,
  ];

  const csvLink = useRef();
  const [exportData, setExportData] = useState([]);
  const handleClick = (event) => {
    props.setformat(event.target.value);
  };

  const handleExport = () => {
    let Export = [];

    Export.push({
      release_title: "",
      song_title: "",
      type: "",
      primary_artist: "",
      composer: "",
      genre: "",
      sub_genre: "",
      label_name: "",
      meta_language: "",
      format: "",
      plan: "",
      original_release_date: "",
      production_year: "",
      copyright_year: "",
      phonographic_copyright_year: "",
      copyright_to: "",
      published_by: "",
      p_catalouge_number: "",
      lyric_language: "",
      track_language: "",
      preview: "",
      publisher: "",
      main_release_date: "",
      content_type: "",
      track_type: "",
      ISRC: "",
      instrumental: "",
      author: "",
      lyrics: "",
    });

    setExportData(Export);

    setTimeout(() => {
      csvLink.current.link.click();
    }, 2000);
  };

  const handleClose = () => {
    props.onClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <Formik
        initialValues={initial}
        validate={(values) => {
          let errors = {};
          if (!values.title) {
            errors.title = "Title is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let callBack = (res) => {
            if (res.status === "success") {
              localStorage.setItem("id", res.id);
              navigate(`/releaseInfo`);
            }
          };

          let postObj = {
            title: values.title,
            territory:
              allCountriesArray && allCountriesArray.length !== 0
                ? allCountriesArray
                : [],

            is_new_release: values.is_new_release,
            format: props.format,
            type_of_release: "audio",
          };

          Object.assign(postObj);

          let current_page = "?current_page=releases";

          props
            .dispatch(
              ReleasesAction.requestPostRelease(
                JSON.stringify(postObj),
                callBack,
                current_page
              )
            )
            .then((res) => {
              let params = {
                page_limit: 15,
                order_by: '[["created_on", "desc"]]',
                current_page: "releases",
              };
              props.dispatch(ReleasesAction.requesReleases(params));
              props.onClose();
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              props.onClose();
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <Box>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                px={3}
                py={1}
                sx={{
                  borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
                }}
              >
                <Grid item sx={{ color: theme.palette.text.gray }}></Grid>
                <Grid item sx={{ color: theme.palette.text.gray }}>
                  <RadioGroup
                    row
                    aria-label="music-type"
                    name="music-type"
                    value={props.format ? props.format : "single"}
                    onChange={handleClick}
                  >
                    <FormControlLabel
                      value="single"
                      control={<Radio />}
                      label="Single"
                    />
                    <FormControlLabel
                      value="album"
                      control={<Radio />}
                      label="Album"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item sx={{ color: theme.palette.text.gray }}>
                  New Release
                </Grid>
              </Grid>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.gray }}
              />
              <Box p={5}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: theme.typography.fontSize.small,
                      sm: theme.typography.fontSize.normal,
                      lg: theme.typography.fontSize.medium,
                    },
                  }}
                >
                  {formatName("What is the Title of Your New Release ?")}
                </Typography>
                <Grid item lg={12} md={12} sm={12} container pt={5}>
                  <Field
                    as={MuiTextField}
                    variant="outlined"
                    placeholder="Fill in your release title-song/album"
                    id="title"
                    size="small"
                    name="title"
                    error={Boolean(errors.title) && Boolean(touched.title)}
                    helperText={Boolean(touched.title) && errors.title}
                    className="col-12"
                  />
                  {/* <CheckboxField
                    label="Please select only if this release is an album and Contains atleast one track already uploaded earlier"
                    name="is_new_release"
                    value={values.is_new_release}
                    onChange={handleChange}
                  /> */}
                </Grid>
              </Box>

              <DialogActions>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent="space-between"
                  alignItems={"center"}
                  // className="bg-warning"
                  mx={4}
                >
                  <Grid item lg={2} md={2} sm={2}>
                    {/* <Grid item xs={4}>
                      <IconButton
                        onClick={() => {
                          handleExport();
                        }}
                        title={"Download Release Template"}
                      >
                        <DownloadForOfflineIcon
                          style={{
                            fontSize: "20px",
                            color: theme.palette.primary.main,
                          }}
                        />
                      </IconButton>

                      <CSVLink
                        data={exportData}
                        filename="BulkUploadTemplate.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />
                    </Grid> */}
                  </Grid>

                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    container
                    alignItems={"center"}
                    justifyContent="flex-end"
                    gap={2}
                    // className="bg-info"
                  >
                    <Grid item xs={4}>
                      <MuiButton
                        loading={loading}
                        name={"Create"}
                        type="submit"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MuiButton
                        name={"Cancel"}
                        // loading={loading}
                        width="100%"
                        cancel
                        onClick={() => {
                          props.onClose();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default connect(mapStateToProps)(NewReleaseDialog);

const CheckboxField = ({ label, name, value, onChange }) => {
  return (
    <Grid item lg={12} md={12} sm={12} container mt={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            style={{ marginTop: "-18px" }}
            onChange={onChange}
          />
        }
        label={label}
        name={name}
        value={value}
        // label={<Typography style={{ marginTop: "17px" }}>{label}</Typography>}
      />
    </Grid>
  );
};
