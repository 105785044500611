import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ClientBulkPutModel from "./model/ClientBulkPutModel";
import ClientModel from "./model/ClientModel";
import ClientPostModel from "./model/ClientPostModel";
import ClientPutModel from "./model/ClientPutModel";
import ClientDeviceResetModel from "./model/ClientDeviceResetModel";

// StatusEffect
export default class ClientEffect {
  // calling an API according to the status model
  // Get method for the
  static async requestClient(params) {
    const endpoint = environment.api.client;
    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  static async requestClientClear() {
    return {};
  }

  static async requestClientFilterClear() {
    return {};
  }

  // Get method for the client filter
  static async requestClientFilter(params) {
    const endpoint = environment.api.client;
    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  // put method for the client
  static async requestPutClient(data, id) {
    const endpoint = environment.api.client + "/" + id;
    return EffectUtility.putToModel(ClientPutModel, endpoint, data);
  }

  // post method for the client
  static async requestPostClient(data, callBack) {
    const endpoint = environment.api.client;
    let response = EffectUtility.postToModel(ClientPostModel, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // post method for the client
  static async requestPutClientBulkUpdate(data) {
    const endpoint = environment.api.clientUpdate;
    return EffectUtility.putToModel(ClientBulkPutModel, endpoint, data);
  }

  // Delete method
  static async requestDeviceReset(id) {
    const endpoint = environment.api.clientResetDevice + "/" + id;
    let response = EffectUtility.deleteToModel(
      ClientDeviceResetModel,
      endpoint
    );
    return response;
  }
}
