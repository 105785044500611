import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { theme } from "../App";
import { ErrorMessage, FastField, Field, Form, Formik } from "formik";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import MuiButton from "../../components/muiButton/MuiButton";
import RevenueClaimAction from "../../stores/revenueClaim/RevenueClaimAction";
import { keyframes } from "@emotion/react";

const scroller = keyframes`
0% {
  transform: translateX(100%);
}
100% {
  transform: translateX(-100%);
}
}
`;

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const RevenueClaimRequest = (props) => {
  let initial = {
    claim_ids: [],
    description: "",
  };
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.onClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
    >
      <Formik
        initialValues={initial}
        validate={(values) => {
          var errors = {};
          if (!values.description) {
            errors.description = "Description is Required";
          }

          return errors;
        }}
        onSubmit={async (values, formikHelpers) => {
          setLoading(true);

          let postObj = {
            description: values.description,
            claim_ids: props?.claimIds ? props.claimIds : [],
          };

          await props
            .dispatch(RevenueClaimAction.requestRevenueClaimPost(postObj))
            .then(() => {
              props.onSubmit();
              props.onClose();
            });

          setLoading(false);
        }}
      >
        {({ errors, touched, dirty, handleChange, values }) => (
          <Form>
            <Box>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                px={3}
                py={1}
                sx={{
                  borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
                }}
              >
                <Grid item sx={{ color: theme.palette.text.gray }}></Grid>
                <Grid item sx={{ color: theme.palette.text.gray }}>
                  Claim Revenue
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ overflow: "hidden" }} pt={1} pb={2}>
                <Typography
                  sx={{
                    color: theme.palette.secondary.main,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontWeight: "bold",
                    animation: `${scroller} 11s linear infinite`,
                  }}
                >
                  ** Payments claimed after the 5th will be processed and
                  disbursed between the 1st and 15th of the following month. **
                </Typography>
              </Grid>
              <Box px={5}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Typography>Description * :</Typography>
                  <FastField
                    className={
                      touched.description && errors.description
                        ? "ticket_desc_test_area_error scrollable"
                        : "ticket_desc_test_area scrollable"
                    }
                    name="description"
                    component="textarea"
                    value={values.description}
                    onChange={handleChange}
                    type="text"
                    maxRows={3}
                    minRows={3}
                    style={{
                      width: "100%",
                      height: 150,
                      padding: "10px",
                      boxSizing: "border-box",
                      color: `${theme.palette.text.primary}`,
                    }}
                    aria-label="maximum height"
                    placeholder="Type here..."
                    label="description"
                    error={
                      Boolean(errors.description) &&
                      Boolean(touched.description)
                    }
                    helperText={
                      Boolean(touched.description) && errors.description
                    }
                  />
                  <Box px={1}>
                    <ErrorMessage name="description" />
                  </Box>
                </Grid>
              </Box>

              <DialogActions>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent="flex-end"
                  alignItems={"center"}
                  mx={4}
                  pt={2}
                  pb={1}
                >
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    container
                    alignItems={"center"}
                    justifyContent="flex-end"
                    gap={2}
                  >
                    <Grid item xs={4}>
                      <MuiButton
                        loading={loading}
                        name={"Claim"}
                        type="submit"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MuiButton
                        name={"Cancel"}
                        width="100%"
                        cancel
                        onClick={() => {
                          props.onClose();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default connect(mapStateToProps)(RevenueClaimRequest);
