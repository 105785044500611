import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { fieldToTextField } from "formik-material-ui";
import dayjs from "dayjs";
import { format, isValid } from "date-fns";

const MuiDateField = React.forwardRef(
  ({ value, disablePast, onChange, maxDate, dateformat, ...props }, ref) => {
    const {
      form: { setTouched, setFieldValue, touched },
    } = props;
    const { error, helperText, ...field } = fieldToTextField(props);
    const { name } = field;

    const [isFocused, setIsFocused] = useState(false);
    const borderColor =
      error && touched[name] && !isFocused ? "0.2px solid red" : null;

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DemoContainer
          components={["DateField"]}
          sx={{
            margin: 0,
            padding: 0,
          }}
        > */}
        <DateField
          disablePast
          // maxDate={maxDate}
          {...props}
          {...field}
          format={dateformat}
          value={value ? dayjs(value) : null}
          onChange={(value) => {
            value
              ? isValid(value.$d) && setFieldValue(name)
              : setFieldValue(name, null);
          }}
          onBlur={() => setTouched({ ...touched, [name]: true })}
          InputProps={{
            style: {
              border: borderColor,
            },
            onFocus: () => setIsFocused(true),
            onBlur: () => setIsFocused(false),
          }}
          ref={ref}
        />
        {/* </DemoContainer> */}
      </LocalizationProvider>
    );
  }
);

export default MuiDateField;
