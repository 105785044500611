import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import { theme } from "../../../views/App";
import { useLocation, useNavigate } from "react-router";
import routes from "../../Route/routecoll";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function ProfilePopper(props) {
  const [open, setOpen] = React.useState(false);
  const errorLogPopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const navigate = useNavigate();
  const location = useLocation();

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen?.current === true && open === false) {
      errorLogPopperRef?.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (errorLogPopperRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const returnMainMenu = (routes) => {
    let filtered_route_main = [];

    routes.data.forEach((col) => {
      if (col.category === "Main") {
        filtered_route_main.push(col);
      }
    });

    let dropdownList = [
      // "myPlans",
      // "profile",
      "logout",
      // "subscriptions", "orders"
    ];

    filtered_route_main = filtered_route_main.filter((obj) =>
      dropdownList.includes(obj.id)
    );

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <ListItemButton
              key={`${text}-${index}`}
              title={text.title}
              onClick={() => {
                navigate(`${text.path}`, {
                  state: {
                    from: location.pathname,
                  },
                });
                setOpen(false);
              }}
            >
              <ListItemIcon
                sx={{
                  color: theme.palette.text.main,
                  minWidth: "36px",
                }}
              >
                <span
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  {text.icon}
                </span>
              </ListItemIcon>
              <ListItemText
                primary={text.title}
                sx={{
                  color: theme.palette.text.main,
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          );
        })
      : null;
  };

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={errorLogPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={errorLogPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              style={{
                width: "auto",
                minWidth: 150,
                height: "auto",
                padding: 0,
                boxShadow: theme.shadows,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {returnMainMenu(routes)}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(ProfilePopper);
