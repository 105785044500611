import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import MuiButton from "../../components/muiButton/MuiButton";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import { theme } from "../App";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const NotificationSubscription = (props) => {
  let initial = {};

  if (props.edit) {
    initial = {
      notification_subscribed:
        props.notificationSubscription &&
        props.notificationSubscription.notification_subscribed,
    };
  } else {
    initial = {
      notification_subscribed: true,
    };
  }

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  useEffect(() => {
    setShowAlert(false);
  }, [props.open]);

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
        {/* Scope of Formik starts here */}
        <Formik
          initialValues={initial}
          onSubmit={(values, formikHelpers) => {
            let page_params = {
              current_page: "profile",
            };
            let client_id = localStorage.getItem("userId");

            setLoading(true);
            if (props.edit) {
              let post_obj = {};

              Object.assign(post_obj, {
                notification_subscribed: values.notification_subscribed,
                client_id: client_id,
              });

              props
                .dispatch(
                  UserProfileAction.requestPutNotificationSubsciption(post_obj)
                )
                .then(() => {
                  setLoading(false);
                  let params = {
                    current_page: "profile",
                  };

                  props.dispatch(
                    UserProfileAction.requestNotificationSubsciption(params)
                  );
                  props.onClose();
                });
            } else {
              let post_obj = {};

              Object.assign(post_obj, {
                notification_subscribed: values.notification_subscribed,
                client_id: client_id,
              });

              props
                .dispatch(
                  UserProfileAction.requestPostNotificationSubsciption(post_obj)
                )
                .then(() => {
                  setLoading(false);
                  let params = {
                    current_page: "profile",
                  };
                  props.dispatch(
                    UserProfileAction.requestNotificationSubsciption(params)
                  );
                  props.onClose();
                });
            }
          }}
          validateOnBlur={true}
          validateOnChange
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            // Form Tag from Formik
            <Form>
              {/* Content of the dialog Box  */}
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* text Content inside DialogBox  */}
                <DialogContentText sx={{ width: "100%" }}>
                  <div className="text-center pb-2">
                    <div className="row p-3">
                      <div className="col-10 ">
                        <h3
                          className="dailogtext"
                          style={{ color: theme.palette.text.primary }}
                        >
                          <b>
                            <span
                              style={{ color: theme.palette.text.first_letter }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;M
                            </span>
                            ail &nbsp;
                            <span
                              style={{ color: theme.palette.text.first_letter }}
                            >
                              S
                            </span>
                            ubscription &nbsp;
                          </b>
                        </h3>
                      </div>
                      <div className="col-2 closebtn">
                        <IconButton
                          title="Close"
                          onClick={() => {
                            props.onClose();
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
                {/* Form Filed inside Dialog Box  */}
                <Grid
                  item
                  container
                  //   flexDirection={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                  //   className="bg-warning"
                >
                  <Grid
                    item
                    xl={6}
                    md={6}
                    lg={6}
                    sm={6}
                    // className="bg-success"
                    alignItems={"flex-start"}
                    justifyContent="flex-start"
                  >
                    <CheckboxField
                      label="Notification Subscription"
                      name="notification_subscribed"
                      value={values.notification_subscribed}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              {/* Actions performed from form action buttons  */}
              <DialogActions className="m-1 my-4">
                {/* Calling ButtonComponent to perform form actions  */}
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  display="flex"
                  justifyContent="space-evenly"
                >
                  <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                    <MuiButton
                      name={"Submit"}
                      type="submit"
                      loading={loading}
                      size="large"
                      width="100%"
                      //color="primary"
                      sx={{ backgroundColor: "blue", color: "white" }}
                    />
                  </Grid>
                  <Grid item lg={5} md={5} sm={5} minWidth={200}>
                    <MuiButton
                      name={"Cancel"}
                      type="reset"
                      size="large"
                      width="100%"
                      cancel
                      //color="secondary"
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default connect(mapDispatchToProps)(NotificationSubscription);

const CheckboxField = ({ label, name, value, onChange }) => {
  return (
    <Grid item lg={12} md={12} sm={12} container>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            style={{ marginTop: "-1px" }}
            onChange={onChange}
          />
        }
        label={label}
        name={name}
        value={value}
        // label={<Typography style={{ marginTop: "17px" }}>{label}</Typography>}
      />
    </Grid>
  );
};
