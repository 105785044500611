import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Zoom,
  Collapse,
  Alert,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import TrackRevenueAction from "../../stores/trackRevenue/TrackRevenueAction";
import { connect } from "react-redux";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../views/App";
import NoDataFoundReports from "../reports/NoDataFoundReports";
import MuiButton from "../../components/muiButton/MuiButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { format } from "date-fns";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import Snackbar from "@mui/material/Snackbar";
import MuiMonthPicker from "../../components/datePicker/MuiMonthPicker";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import { keyframes } from "@mui/material";
import ReactEcharts from "echarts-for-react";

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
  `;

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackRevenueAction.REQUEST_TRACKWISE_REVENUE_SUMMARY,
  ]),
  trackwiseRevenueSummary:
    state.trackwiseRevenueSummary.trackwiseRevenueSummary || [],
});

function TrackwiseRevenueSummary(props) {
  const boxRef = useRef();
  const { isRequesting, trackwiseRevenueSummary } = props;

  const [timeRange, setTimeRange] = useState("All Data");
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [fromMonth, setFromMonth] = useState(null);
  const [toMonth, setToMonth] = useState(null);
  const [customApplyClicked, setCustomApplyClicked] = useState(false);
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);

  const [trackSelected, setTrackSelected] = useState({});

  useEffect(() => {
    if (props.trackID && props.trackName) {
      setTrackSelected({
        track_id: props.trackID,
        track_name: props.trackName,
      });
    } else {
      setTrackSelected(null);
    }
  }, [props.trackID, props.trackName]);

  function fetchTrackwiseRevenueSummary(params) {
    props
      .dispatch(TrackRevenueAction.requestTrackwiseRevenueSummary(params))
      .then(() => {
        setClearLoader(false);
      });
  }

  function customDateFormatter(dateObj) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${months[dateObj.$M]} ${dateObj.$y}`;
  }

  function fromMonthHandleChange(fromMonth) {
    setFromMonth(fromMonth);
  }
  function toMonthHandleChange(toMonth) {
    setToMonth(toMonth);
  }

  // Helper function to calculate the from date based on the given number of months
  const calculateFromDate = (months) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - months);
    currentDate.setDate(1); // Set the day to the 1st
    return currentDate.toISOString().split("T")[0];
  };

  // Helper function to calculate the to date based on the from date and number of months
  const calculateToDate = (fromDate, months) => {
    const toDate = new Date(fromDate);
    toDate.setMonth(toDate.getMonth() + months);
    toDate.setDate(toDate.getDate() - 1); // Set the day to the last day of the previous month
    return toDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (
      (isDatePickerOpen && !customApplyClicked) ||
      (customApplyClicked && !fromMonth && !toMonth)
    ) {
      setCustomApplyClicked(false);
      return;
    }

    if (customApplyClicked && new Date(fromMonth.$d) > new Date(toMonth.$d)) {
      setCustomApplyClicked(false);
      setAlertPopUp(true);
      return;
    }

    const today = new Date();
    let fromDate;
    let toDate;

    let params = {
      current_page: "raw_report",
    };

    switch (timeRange) {
      case "All Data":
        fromDate = null;
        toDate = null;
        break;
      case "Past 3 months":
        fromDate = calculateFromDate(3);
        toDate = calculateToDate(fromDate, 3);
        break;
      case "Past 6 months":
        fromDate = calculateFromDate(6);
        toDate = calculateToDate(fromDate, 6);
        break;
      case "Past 12 months":
        fromDate = calculateFromDate(12);
        toDate = calculateToDate(fromDate, 12);
        break;
      default:
        fromDate = "2020-01-01";
        toDate = format(today, "yyyy-MM-dd");
        break;
    }

    const filter = [];

    trackSelected &&
      filter.push(`["track_id","must","","match","${trackSelected.track_id}"]`);

    if (timeRange === "Custom") {
      let toDateValue = new Date(toMonth);
      let toYearValue = toDateValue.getFullYear();
      let toMonthvalue = toDateValue.getMonth() + 1;

      fromDate =
        fromMonth && format(new Date(fromMonth).setDate(1), "yyyy-MM-dd");
      toDate =
        toMonth &&
        `${toYearValue}-${toMonthvalue}-${new Date(
          toYearValue,
          toMonthvalue,
          0
        ).getDate()}`;

      if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
        return setAlertPopUp(true);
      }

      setCustomApplyClicked(false);
    }

    fromDate &&
      Object.assign(params, {
        from: format(new Date(fromDate), "yyyy-MM-dd"),
      });

    toDate &&
      Object.assign(params, {
        to: format(new Date(toDate), "yyyy-MM-dd"),
      });

    filter.length &&
      Object.assign(params, {
        filters: `[${filter}]`,
      });

    fetchTrackwiseRevenueSummary(params);
  }, [timeRange, customApplyClicked, trackSelected]);

  useEffect(() => {
    if (
      customApplyClicked &&
      fromMonth &&
      toMonth &&
      new Date(fromMonth.$d) < new Date(toMonth.$d)
    ) {
      setTimeRange("Custom");
    }
  }, [isDatePickerOpen]);

  // Month on month revenue data construction
  const momChartData = trackwiseRevenueSummary?.revenue_by_month?.map(
    (item) => ({
      key: item.key,
      [item.key]: item.value,
    })
  );

  const momChartkeysArray = trackwiseRevenueSummary?.revenue_by_month?.map(
    (item) => item.key
  );

  // Storewise Revenue data construction
  const storewiseRevenueData = trackwiseRevenueSummary?.store_wise_revenue?.map(
    (item) => ({
      id: item.key,
      label: item.key,
      value: item.value,
    })
  );

  // Storewise streams data construction
  const storesWiseStreamsData =
    trackwiseRevenueSummary?.store_wise_streaming?.map((item, index) => ({
      id: item.key,
      label: item.key,
      value: item.value,
    }));

  // Territory wise Revenue data construction
  const territorywiseChartData =
    trackwiseRevenueSummary?.territory_wise_count?.map((item, index) => ({
      key: item.key,
      [item.key]: item.value,
    }));
  const territorywiseChartkeysArray =
    trackwiseRevenueSummary?.territory_wise_count?.map((item) => item.key);

  const clearFunction = () => {
    if (timeRange == "All Data") {
      setClearLoader(false);
      return;
    }
    setCustomApplyClicked(false);
    setDatePickerOpen(false);
    setTimeRange("All Data");
    setFromMonth(null);
    setToMonth(null);
  };
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  //Dynamic Bar Chart Height
  const barChartHeightFactor = 4;
  let barChartHeight = 50;
  if (territorywiseChartData?.length > 40) {
    barChartHeight = Math.max(
      50,
      territorywiseChartData?.length * barChartHeightFactor
    );
  }

  let territoryLegendOffset,
    maxYAxisLength = 0;
  if (territorywiseChartData?.length > 0) {
    maxYAxisLength =
      territorywiseChartData &&
      Math.max(
        ...territorywiseChartData?.map((item) => {
          return String(item.key?.length);
        })
      );
    if (maxYAxisLength > 10)
      territoryLegendOffset = -85 * (maxYAxisLength / 10);
    else territoryLegendOffset = -85;
  }
  const customColors = [
    `${theme.pieChart.color1}`,
    `${theme.pieChart.color2}`,
    `${theme.pieChart.color3}`,
    `${theme.pieChart.color4}`,
    `${theme.pieChart.color5}`,
    `${theme.pieChart.color6}`,
  ];
  const getOptionsforTrackSummaryStoresWiseStreamsPie = () => {
    const seriesData = storesWiseStreamsData.map((item, index) => ({
      name: item.label,
      value: item.value,
      itemStyle: {
        color: customColors[index],
      },
    }));

    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: true,
        type: "scroll",
        orient: "vertical",
        icon: "circle",
        right: 0,
      },

      grid: {
        top: "5%",
        left: "5%",
        right: "5%",
        bottom: "5%",
      },

      dataZoom: [
        {
          type: "inside",
        },
      ],
      confine: true,
      series: [
        {
          type: "pie",
          data: seriesData,
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            focus: "series",
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
          },
          selectedMode: "series",
          padAngle: 0.7,
        },
      ],
    };
  };

  const getOptionsforTrackSummarystorewiseRevenuePie = () => {
    const seriesData = storewiseRevenueData.map((item, index) => ({
      name: item.label,
      value: item.value,
      itemStyle: {
        color: customColors[index],
      },
    }));

    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: true,
        type: "scroll",
        orient: "vertical",
        icon: "circle",
        right: 0,
      },

      grid: {
        top: "5%",
        left: "5%",
        right: "5%",
        bottom: "5%",
      },

      dataZoom: [
        {
          type: "inside",
        },
      ],
      confine: true,
      series: [
        {
          type: "pie",
          radius: "70%",
          data: seriesData,

          label: {
            show: true,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
          labelLine: {
            show: true,
            smooth: true,
            lineStyle: {
              type: "solid",
            },
          },
          emphasis: {
            focus: "series",
          },
          selectedMode: "series",
          padAngle: 0.7,
        },
      ],
    };
  };
  const getOptionsforTrackSummaryBar = () => {
    const seriesData = momChartData?.map((item) => ({
      name: item.key,
      value: item[item.key],
      itemStyle: {
        color: getRandomColor(),
      },
    }));
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };

    return {
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          let val = params[0].value;
          let formattedvalue = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params[0].color};border-radius:50%;"></span>`;
          return `${colorSpan}${params[0].name}: ${formattedvalue} `;
        },
      },

      xAxis: {
        type: "category",
        data: momChartData?.map((item) => item.key),

        axisLabel: {
          hideOverlap: true,
          rich: {
            width: 100,
            overflow: "breakAll",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          hideOverlap: true,
        },
      },
      grid: {
        left: "18%",
        right: "5%",
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      legend: {
        show: true,
        orient: "vertical",
        icon: "circle", // Set the orientation of the legend=        icon: "circle",
        right: 0,
        top: "10%",
        type: "scroll",
        itemGap: 20,
        itemStyle: {
          borderColor: "#6c757d",
          borderWidth: 1,
        },
        itemHeight: 17,
      },

      series: [
        {
          type: "bar",
          data: seriesData,
          label: {
            show: false,
          },
        },
      ],
    };
  };
  const getOptionsforTrackSummaryTerritoryBased = () => {
    const seriesData = trackwiseRevenueSummary?.territory_wise_count?.map(
      (item) => ({
        name: item.key,
        value: item.value,
      })
    );
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return `${colorSpan}${params.name}: ${formattedvalue} )`;
        },
      },

      yAxis: {
        type: "category",
        data: trackwiseRevenueSummary?.territory_wise_count?.map(
          (item) => item.key
        ),

        axisLabel: {
          hideOverlap: true,
          rich: {
            width: 100,
            overflow: "breakAll",
          },
        },
      },
      xAxis: {
        type: "value",
        axisLabel: {
          hideOverlap: true,
        },
      },
      grid: {
        left: "18%",
        right: "5%",
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      legend: {
        show: true,
        orient: "vertical",
        icon: "circle", // Set the orientation of the legend=        icon: "circle",
        right: 0,
        top: "10%",
        type: "scroll",
        itemGap: 20,
        itemStyle: {
          borderColor: "#6c757d",
          borderWidth: 1,
        },
        itemHeight: 17,
      },

      series: [
        {
          type: "bar",
          data: seriesData,
          label: {
            formatter: (params) => {
              let val = params.value;
              let formattedvalue = formatValue(val);
              return formattedvalue;
            },
            show: true,
            position: "right",
          },
        },
      ],
    };
  };

  return (
    <>
      {" "}
      <style
        dangerouslySetInnerHTML={{
          __html: `
      @keyframes zoomInFade {
        0% {
          opacity: 0;
          transform: scale(0.5);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
    `,
        }}
      />
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        fullWidth
        maxWidth="xl"
        TransitionComponent={Zoom}
        transitionDuration={400}
      >
        <Box ref={boxRef}>
          <LoadingIndicator isActive={props.isRequesting}>
            <Grid
              container
              lg={12}
              sm={12}
              md={12}
              display={"flex"}
              style={{
                position: "sticky",
                top: `${boxRef?.current?.offsetTop}px`,
                backgroundColor: theme.palette.background.default,
                zIndex: 10,
              }}
              pl={4.5}
              sx={{ gap: 2 }}
            >
              <Grid
                container
                item
                xs={12}
                px={2}
                py={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={6}>
                  {trackSelected && (
                    <Typography
                      component={"span"}
                      sx={{ fontSize: 20, fontWeight: 600 }}
                    >
                      Track :{" "}
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                        component={"span"}
                      >
                        {" "}
                        {trackSelected?.track_name}
                      </Typography>
                    </Typography>
                  )}
                </Grid>
                <Grid
                  container
                  xs={6}
                  sx={{
                    justifyContent: "flex-end",
                  }}
                >
                  <Grid item mr={2}>
                    <AutoCompleteComponent
                      disableClearable={true}
                      value={timeRange}
                      list={[
                        "All Data",
                        "Past 3 months",
                        "Past 6 months",
                        "Past 12 months",
                        "Custom",
                      ]}
                      textFieldProps={{
                        placeholder: "Select time range",
                      }}
                      handleChange={(e, v) => {
                        if (v == "Custom") setDatePickerOpen(true);
                        else {
                          setTimeRange(v);
                          setFromMonth(null);
                          setToMonth(null);
                        }
                      }}
                      size="small"
                    />
                  </Grid>
                  {timeRange == "Custom" && fromMonth && toMonth && (
                    <Box
                      mr={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "white",
                        height: "37px",
                        borderRadius: "8px",
                        paddingX: "15px",
                        border: `1px solid ${theme.palette.text.gray}`,
                      }}
                    >
                      {`${customDateFormatter(
                        fromMonth
                      )} - ${customDateFormatter(toMonth)}`}
                      <Tooltip title="Edit Calendar" arrow placement="bottom">
                        <EditCalendarTwoToneIcon
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => setDatePickerOpen(true)}
                        />
                      </Tooltip>
                    </Box>
                  )}
                  <Grid item>
                    <MuiButton
                      cancel
                      width="100%"
                      height="37px"
                      loading={clearLoader}
                      noTextOnLoading
                      name={"Clear"}
                      onClick={() => {
                        setClearLoader(true);
                        clearFunction();
                      }}
                    />
                  </Grid>
                  <Tooltip title="Close" arrow placement="bottom">
                    <Box
                      ml={2}
                      sx={{
                        marginTop: "7px",
                      }}
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <HighlightOffIcon
                        sx={{
                          "&:hover": {
                            transition: "transform 0.5s, color 0.2s",
                            transform: "scale(1.2)",
                            color: theme.palette.secondary.main,
                          },
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            {isRequesting ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              ></Box>
            ) : (
              <Grid
                container
                mb={3}
                sx={{
                  width: "100%",
                  height: "100%",
                  paddingX: 2,
                  paddingY: 1,
                }}
              >
                <Grid
                  container
                  sm={12}
                  gap={2}
                  sx={{
                    boxShadow: theme.shadows,
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    animation: `${slideIn} 0.5s ease-in-out`,
                  }}
                >
                  <Grid
                    item
                    sm={12}
                    md={12}
                    sx={{
                      height: 400,
                      marginBottom: 10,
                      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 20,
                        paddingY: 1,
                        animation: `${slideIn} 0.5s ease-in-out`,
                      }}
                    >
                      {formatName("Month on Month Revenue")}
                    </Typography>

                    {momChartData?.length ? (
                      <ReactEcharts
                        style={{
                          height: "40vh",
                          width: "100%",
                        }}
                        option={getOptionsforTrackSummaryBar()}
                      />
                    ) : (
                      <NoDataFoundReports />
                    )}
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    sx={{
                      height: 400,
                      marginBottom: 10,
                      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.2)",
                      animation: "zoomInFade 0.3s ease-in-out",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 20,
                        paddingY: 1,
                        animation: `${slideIn} 0.5s ease-in-out`,
                      }}
                    >
                      {formatName("Storewise Revenue")}
                    </Typography>

                    {storewiseRevenueData?.length ? (
                      <ReactEcharts
                        style={{
                          height: "40vh",
                          width: "100%",
                        }}
                        option={getOptionsforTrackSummarystorewiseRevenuePie()}
                      />
                    ) : (
                      <NoDataFoundReports />
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  sm={12}
                  sx={{
                    boxShadow: theme.shadows,
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                  }}
                  gap={2}
                >
                  <Grid
                    item
                    sm={12}
                    md={12}
                    sx={{
                      height: 400,
                      marginBottom: 10,
                      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.2)",
                      animation: "zoomInFade 0.3s ease-in-out",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 20,
                        paddingY: 1,
                        animation: `${slideIn} 0.5s ease-in-out`,
                      }}
                    >
                      {formatName("Storewise Streams")}
                    </Typography>

                    {storesWiseStreamsData?.length ? (
                      <ReactEcharts
                        style={{
                          height: "40vh",
                          width: "100%",
                        }}
                        option={getOptionsforTrackSummaryStoresWiseStreamsPie()}
                      />
                    ) : (
                      <NoDataFoundReports />
                    )}
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    sx={{
                      height: `${barChartHeight}vh`,
                      marginBottom: 10,
                      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.2)",
                      animation: "zoomInFade 0.6s ease-in-out",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 20,
                        paddingY: 1,
                        animation: `${slideIn} 0.5s ease-in-out`,
                      }}
                    >
                      {formatName("Territorywise Revenue")}
                    </Typography>

                    {territorywiseChartData?.length ? (
                      <ReactEcharts
                        style={{
                          height: "43vh",
                          width: "100%",
                        }}
                        option={getOptionsforTrackSummaryTerritoryBased()}
                      />
                    ) : (
                      <NoDataFoundReports />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </LoadingIndicator>
        </Box>
        <Dialog
          open={isDatePickerOpen}
          onClose={() => {
            setDatePickerOpen(false);
          }}
          TransitionComponent={Zoom}
          transitionDuration={400}
          fullWidth
        >
          <DialogTitle sx={{ paddingBottom: "25px" }}>
            Select a Month Range{" "}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 10,
                cursor: "pointer",
                padding: 1,
              }}
              onClick={() => {
                setDatePickerOpen(false);
              }}
            >
              <HighlightOffIcon
                sx={{
                  "&:hover": {
                    transition: "transform 0.5s, color 0.2s",
                    transform: "scale(1.2)",
                    color: theme.palette.secondary.main,
                  },
                }}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <div style={{ width: "48%", float: "left" }}>
              <MuiMonthPicker
                value={fromMonth}
                onChange={fromMonthHandleChange}
                disableFuture
              />
            </div>
            <div style={{ width: "48%", float: "right" }}>
              <MuiMonthPicker
                value={toMonth}
                onChange={toMonthHandleChange}
                disableFuture
              />
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px", marginTop: "15px" }}>
            <MuiButton
              width="40%"
              name={"Apply"}
              onClick={() => {
                if (!fromMonth && !toMonth) {
                  setAlertPopUp(true);
                  return;
                }
                setCustomApplyClicked(true);
                setDatePickerOpen(false);
              }}
            />
            <MuiButton
              cancel
              width="40%"
              name={"Clear"}
              onClick={() => {
                clearFunction();
              }}
            />
          </DialogActions>
        </Dialog>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={alertPopUp}
        autoHideDuration={1500}
        onClose={() => setAlertPopUp(false)}
        TransitionComponent={Collapse}
      >
        <Alert
          onClose={() => setAlertPopUp(false)}
          severity="warning"
          sx={{
            width: "100%",
            borderRadius: "5px",
            backgroundColor: theme.palette.secondary.main,
            color: "white",
          }}
        >
          Incorrect Date format !!
        </Alert>
      </Snackbar>
    </>
  );
}

export default connect(mapStateToProps)(TrackwiseRevenueSummary);
