import { Box } from "@mui/material";
import React from "react";
import BasicDashboard from "./components/BasicDashboard";
import LatestActivities from "./components/LatestActivities";
import DynamicReport_2 from "./DynamicReport_2";

const NewDashboard = () => {
  return (
    <Box>
      <BasicDashboard />
      <DynamicReport_2 />
      <LatestActivities />
    </Box>
  );
};

export default NewDashboard;
