import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";

import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import React, { useEffect } from "react";
import { theme } from "../App";
import Rettrive from "../release/Rettrive";
import { useNavigate } from "react-router";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import PageNotFound from "../../components/PageNotFound";
const mapStateToProps = (state) => ({
  draftReleaseRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_DRAFT_RELEASES,
  ]),

  draftRelease: state.draftRelease.draftRelease || [],
});

function LatestDraftsBox(props) {
  function fetchReleases(params) {
    props.dispatch(ReleasesAction.requesDraftReleases(params));
  }
  useEffect(() => {
    const filterparam = {
      page_limit: "6",
      order_by: `[["modified_on","desc"]]`,
      current_page: "dashboard",
      filters: `[["status.keyword","must","","match","1"]]`,
    };

    fetchReleases(filterparam);
  }, []);

  let navigate = useNavigate();
  let latestDraftsList = [];
  props.draftRelease?.hits?.hits
    ?.filter((item) => item?._source?.status?.id === "1")
    .map((item) => {
      latestDraftsList.push(item);
    });
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-between",
        whiteSpace: "nowrap",
        wordWrap: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingTop: { xl: "84px", lg: "28px", laptop: "28px" },
        paddingBottom: { xl: "116px", lg: "60px", laptop: "60px" },
      }}
    >
      {latestDraftsList && latestDraftsList.length > 0 ? (
        <>
          {latestDraftsList?.map((item) => (
            <Card
              sx={{
                width: { xl: "143px", lg: "83px", laptop: "83px" },
                height: { xl: "177px", lg: "110px", laptop: "110px" },
                cursor: "pointer",
                backgroundColor: "transparent",
                objectFit: "contain ",
                borderRadius: 0,
              }}
              key={item._id}
              onClick={() => {
                localStorage.setItem("id", item._id);
                navigate("/releaseInfo");
              }}
            >
              <CardContent sx={{ padding: 0 }}>
                <Box
                  sx={{
                    height: { xl: "143px", lg: "83px", laptop: "83px" },
                    width: { xl: "143px", lg: "83px", laptop: "83px" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  <Rettrive
                    thumbnail_url={item?._source?.thumbnail_url}
                    release_id={item._id}
                    current_page={"dashboard"}
                  />
                </Box>
                <Box
                  sx={{
                    height: "34px", // Remaining height for title
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingY: 1,

                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      wordWrap: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: {
                        xl: "16px",
                        lg: "9px",
                        laptop: "9px",
                      },
                    }}
                  >
                    {item?._source?.title}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        </>
      ) : (
        !props.draftReleaseRequesting && <PageNotFound page="Reports" />
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(LatestDraftsBox);
