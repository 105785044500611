import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

//SHADOW CONTAINER WITH WHITE BACKGROUND
export const ShadowContainer = styled(Paper)((props) => ({
  backgroundColor: props.theme.palette.mode === "dark" ? "#1A2027" : "white",
  ...props.theme.typography.body2,
  padding: props.theme.spacing(2),
  textAlign: "center",
  color: props.theme.palette.primary.main,
  boxShadow: props.theme.shadows,
  borderRadius: props.borderRadius,
}));

//WHITE BACKGROUND LAYOUT WITH BORDER
export const BorderContainer = styled(Paper)((props) => {
  return {
    backgroundColor: props.theme.palette.mode === "dark" ? "#1A2027" : "white",
    ...props.theme.typography.body2,
    padding: props.theme.spacing(1),
    textAlign: "center",
    border: props.theme.border,
    boxShadow: "none",
    borderRadius: props.borderRadius,
    color: props.theme.palette.primary.main,
    fontSize: "14px",
    h2: {
      fontSize: "26px",
    },
  };
});

//GREEN BACKGROUND CONTAINER
export const BackGroundContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.grey,
  borderRadius: "3px",
  textAlign: "center",
  padding: "3px 15px",
  color: theme.palette.primary.main,
  fontSize: "14px",
  h2: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  border: `1px solid ${theme.palette.background.grey}`,
}));

//MODEL SCREENS CONTAINER FOR POLICY AND PERMISSIONS
export const ModelScreenContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "white",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.primary.main,
  border: "solid 1px #d6ebf0",
  boxShadow: theme.shadows,
}));

//HEADINGS LAYOUT
export const HeadingBox = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.primary.main,
  boxShadow: "none",
  fontSize: "26px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
}));

//DIALOG CONTAINER FOR POST AND PUT METHODS
export const DialogBox = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "6px",
  boxShadow: theme.shadows,
  color: theme.palette.primary.main,
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
}));

//Create Ticket page Field Container
export const FieldBoxContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "white",
  ...theme.typography.body2,
  padding: 0,
  height: "max-content",
  textAlign: "left",
  border: theme.border,
  boxShadow: "none",
  color: theme.palette.primary.main,
  fontSize: "14px",
  h2: {
    fontSize: "26px",
  },
}));

//Dashboard Tickets Container layout
export const DashboardTicketsBox = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "white",
  ...theme.typography.subtitle1,

  textAlign: "center",
  border: `solid 2px ${theme.palette.background.default}`,
  color: theme.palette.primary.main,
  borderRadius: "9px",
}));
