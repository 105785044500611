import React, { forwardRef, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { theme } from "../../views/App";
import environment from "environment";
import WhiteImage from "../../Images/WhiteImage.webp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Custom component with scrollbar
const CustomListbox = forwardRef((props, ref) => (
  <Paper
    {...props}
    ref={ref}
    sx={{
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#b0d2da",
        borderRadius: "3px",
        "&:hover": {
          backgroundColor: "#85bac6",
        },
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
    }}
  />
));

// Autocomplete component function
const AutoCompleteFlagDisplay = forwardRef(
  (
    {
      renderOption,
      disableCloseOnSelect,
      getOptionLabel,
      list,
      getOptionDisabled,
      name,
      textFieldProps,
      handleChange,
      error,
      clear,
      size,
      width,
      disabled,
      helperText,
      value,
      disableClearable,
      multiple,
      filterOptions,
      defaultValue,
      isOptionEqualToValue,
      limitTags,
      renderTags,
      inputValue,
      onKeyDown,
      height,
    },
    ref
  ) => {
    const [phoneCodes, setPhoneCodes] = useState([]);
    const [selectedFlag, setSelectedFlag] = useState("");

    useEffect(() => {
      setSelectedFlag(value ? value?.code?.toLowerCase() : "");
    }, [value]);

    const useStyles = makeStyles({
      root: {
        border: `1px solid ${theme.palette.primary.main}`,
        marginTop: 2,
        marginBottom: 2,
        zIndex: 1,
        overflow: "hidden",
      },
    });

    const classes = useStyles();

    useEffect(() => {
      fetchPhoneCodes();
    }, []);

    const fetchPhoneCodes = () => {
      let access_token = localStorage.getItem("Auth")
        ? JSON.parse(localStorage.getItem("Auth")).access_token
        : "";
      fetch(`${environment.api.baseApi}/phone_codes`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            response.country_codes && setPhoneCodes(response.country_codes);
          }
        });
    };
    const imageWrapperStyle = {
      position: "relative",
      display: "inline-block",
      width: "56px", // Set width according to the white image size
      height: "46px", // Set height according to the white image size
    };

    const whiteImageStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)", // This will center the flag image
      // zIndex: 1,
      width: "56px", // Bigger size for the white image
      height: "46px",
      objectFit: "contain",
    };

    const flagImageStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)", // This will center the flag image
      // zIndex: 2,
      width: "40px", // Smaller size for the flag image
      height: "40px",
      objectFit: "contain",
    };

    return (
      <Box sx={{ width: width || 150 }}>
        <Autocomplete
          // classes={{
          //   paper: classes.root,
          // }}
          popupIcon={<ExpandMoreIcon sx={{ color: "#707070", fontSize: "35px" }} />}
          style={{ zIndex: 1 }}
          disableCloseOnSelect={disableCloseOnSelect}
          defaultValue={defaultValue}
          getOptionLabel={(option) => option.name}
          value={value}
          onKeyDown={onKeyDown}
          inputValue=""
          ListboxComponent={CustomListbox}
          limitTags={limitTags || 10}
          disableClearable={disableClearable}
          isOptionEqualToValue={isOptionEqualToValue}
          multiple={multiple}
          disabled={disabled}
          clearOnBlur={true}
          ref={ref}
          options={list || phoneCodes}
          getOptionDisabled={getOptionDisabled}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
            
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
            
              <span style={{ fontSize: "12px" }}>
                {" "}
                ({option.code}) {option.dial_code} {option.name}
              </span>
            </Box>
          )}
          onChange={(e, value) => {
            // if (value) {
            //   setSelectedFlag(value.code.toLowerCase());
              // if (value.dial_code !== "+91") {
              //   window.alert("We are not accepting any code other than +91.");
              //   return;
              // }
              handleChange(e, value);
            // }
          }}
          renderTags={renderTags}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              helperText={helperText}
              size={size}
              error={error}
              name={name}
              sx={{
                height: height || "100%",
                backgroundColor: textFieldProps.backgroundColor || "#808080",
                padding: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
                svg: { display: "block", padding: 0 },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: selectedFlag && (
                  <InputAdornment position="start">
                    <div style={imageWrapperStyle}>
                      <img
                        src={WhiteImage}
                        alt="WhiteImage"
                        style={whiteImageStyle}
                      />
                      <img
                        src={`https://flagcdn.com/w20/${selectedFlag}.png`}
                        alt="flag"
                        style={flagImageStyle}
                      />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
    );
  }
);

export default AutoCompleteFlagDisplay;
