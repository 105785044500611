import React, { forwardRef, useRef, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  DialogTitle,
  styled,
  OutlinedInput,
  Select,
  Typography,
  Box,
  Tab,
  IconButton,
  Tabs,
  Radio,
  RadioGroup,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {  Close } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  faApple,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  Form,
  Formik,
  Field,
  FastField,
  ErrorMessage,
  FieldArray,
} from "formik";
import { connect } from "react-redux";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import { theme } from "../App";
import TrackAction from "../../stores/track/TrackAction";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import {
  inputProps,
  menuProps,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import environment from "environment";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import ArtistAction from "../../stores/artist/ArtistAction";
import ActorAction from "../../stores/actor/ActorAction";
import ActressAction from "../../stores/actress/ActressAction";
import DirectorAction from "../../stores/director/DirectorAction";
import AutocompleteDialoge from "../../components/autoCompleteComponent/AutocompleteDialoge";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const CustomTab = styled(Tab)(({ theme, selected }) => ({
  fontSize: "18px",
  fontWeight: selected ? "bold" : "normal",
  color: selected ? theme.palette.text.primary : "#b1b1b1",
  textTransform: "capitalize",
}));

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ paddingY: "47px" }}>{children}</Box>}
  </Typography>
);

const useStyles = makeStyles(() => ({
  scrollbar: {
    overflow: "auto",
    overflowX: "hidden",
    borderRadius: "6px",
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#b0d2da",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#85bac6",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
}));

const TrackPost = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const formRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (formRef?.current) {
      formRef?.current?.handleSubmit();
    }
  };

  function idsWithEditPermission() {
    return ["1", "4", "10", "9"].includes(props._source?.status?.id);
  }

  let { releasedata } = props;

  let editProps = props.projectEditData && props.projectEditData;

  // Project post and Put method
  let initial = {};

  let yesNoArray = ["Yes", "No"];
  let InstrumentalArray = ["Yes", "No"];
  let AskToGenerateIsrc = ["Yes", "No"];

  let contentTypeArray = ["Audio", "Video", "Apple Video", "Dolby Audio"];
  // Based on the type of release in the release index, the Content type array is assigned
  props?.releasedata?.type_of_release === "audio"
    ? (contentTypeArray = ["Audio", "Dolby Audio"])
    : (contentTypeArray = ["Video", "Apple Video"]);
  let primaryTrackTypeeArray = ["Audio", "Video"];
  let secondaryTrackTypes = ["Original", "Karaoke", "Cover", "Medley"];
  let IPRSandPPL = ["IPRS", "PPL"];

  // Inside your component
  const classes = useStyles();

  const currentYear = new Date().getFullYear();
  const startYear = 0;
  const yearArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  let featuring = [];

  editProps?.featuring?.map((item) => {
    featuring.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let featuring_initial = [];

  releasedata?.featuring_artists?.map((item) => {
    featuring_initial.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_id?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let releaseInfoPrimaryArtist = [];

  props?.primary_artist?.map((item) => {
    releaseInfoPrimaryArtist.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_id?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let releaseInfoFeaturingArtist = [];

  props?.featuring_artists?.map((item) => {
    releaseInfoFeaturingArtist.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_name,
    });
  });

  let remixer = [];

  editProps?.remixer?.map((item) => {
    remixer.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_name,
    });
  });

  let primary_actor = [];
  editProps?.primary_actor?.map((item) => {
    primary_actor.push({
      actor_id: item?.actor_id?.id,
      actor_name: item?.actor_name,
    });
  });

  let primary_actress = [];
  editProps?.primary_actress?.map((item) => {
    primary_actress.push({
      actress_id: item?.actress_id?.id,
      actress_name: item?.actress_name,
    });
  });

  let director = [];
  editProps?.director?.map((item) => {
    director.push({
      director_id: item?.director_id?.id,
      director_name: item?.director_name,
    });
  });
  let primary_artists = [];

  editProps?.primary_artists?.map((item) => {
    primary_artists.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let primary_artists_initial = [];

  releasedata?.artists?.map((item) => {
    primary_artists_initial.push({
      artist_id: item?.artist_id?.id,
      artist_name: item?.artist_id?.artist_name,
      stream_platforms: item?.artist_id?.stream_platforms,
    });
  });

  let composer = [];
  props?.artistArray?.map((item) => {
    composer.push(item?.artist_name);
  });

  let languages_list = [];
  props?.nameslist?.map((item) => {
    languages_list.push(item);
  });

  function capitalizeFirstLetter(str) {
    // Split the string into words
    let words = str.split("_");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] =
        words[i][0].toUpperCase() + words[i].substring(1).toLowerCase();
    }

    // Join the words back into a single string
    let capitalizedString = words.join(" ");

    return capitalizedString;
  }

  const updateTrackErrorInState = (newValue) => {
    if (window.history && window.history.replaceState) {
      const currentState = window.history.state || {};
      const newState = {
        ...currentState,
        usr: {
          ...currentState.usr,
          trackError: newValue,
        },
      };
      window.history.replaceState(newState, "")
      location.state = newState;
    }
  };

  let genre_initial = [];

  releasedata?.genre_type &&
    genre_initial.push({
      id: releasedata?.genre_type.id,
      name: releasedata?.genre_type.genre_name,
    });

  let sub_genre_initial = [];

  releasedata?.sub_genre_type &&
    sub_genre_initial.push({
      id: releasedata?.sub_genre_type.id,
      name: releasedata?.sub_genre_type.genre_name,
    });

  if (props.edit) {
    if (props.isTrackError) {
      handleSubmit();
    }
    initial = {
      title: editProps.title,
      arranger:
        editProps.arranger && editProps.arranger.length > 0
          ? editProps.arranger
          : [""],
      ask_to_generate_an_isrc: editProps.ask_to_generate_an_isrc ? "Yes" : "No",
      author:
        editProps.author && editProps.author.length > 0
          ? editProps.author
          : [""],
      client_id: editProps.client_id,
      composer:
        editProps.composer && editProps.composer.length > 0
          ? editProps.composer
          : [""],

      content_type: editProps.content_type
        ? capitalizeFirstLetter(editProps.content_type)
        : "",
      featuring: featuring?.length !== 0 ? featuring : [""],
      genre: editProps.genre?.id ?? "",
      instrumental: editProps.instrumental ? "Yes" : "No",
      isrc: editProps.isrc,
      lyrics: editProps.lyrics ?? "",
      lyrics_language: editProps.lyrics_language
        ? {
            id: editProps.lyrics_language?.lyrics_language,
            name: editProps?.lyrics_language?.lyrics_language_name,
          }
        : "",
      parental_advisory: editProps.parental_advisory
        ? capitalizeFirstLetter(editProps.parental_advisory)
        : "",
      plan_id: editProps.plan_id?.id ?? "",
      preview_start: editProps.preview_start ?? "",
      primary_artists: primary_artists.length !== 0 ? primary_artists : [""],
      primary_track_type: editProps.primary_track_type
        ? capitalizeFirstLetter(editProps.primary_track_type)
        : releasedata?.type_of_release
        ? capitalizeFirstLetter(releasedata.type_of_release)
        : "",
      producer:
        editProps.producer && editProps.producer.length > 0
          ? editProps.producer
          : [""],
      producer_catalouge_number: editProps.producer_catalouge_number,
      production_year: editProps.production_year ?? "",
      published_by: editProps.published_by,
      publisher: !editProps.publisher
        ? ["", "IPRS"]
        : editProps.publisher?.split("-"),
      remixer: remixer,
      primary_actor: primary_actor,
      primary_actress: primary_actress,
      director: director,
      secondary_genre: editProps.secondary_genre,
      secondary_track_type: editProps.secondary_track_type
        ? capitalizeFirstLetter(editProps.secondary_track_type)
        : "",
      seconday_sub_genre: editProps.seconday_sub_genre,
      release_id: props.release_id,
      stream_id: editProps.stream_id,
      sub_genre_name: editProps.sub_genre_name?.id ?? "",
      track_title_language: editProps.track_title_language
        ? {
            id: editProps.track_title_language?.track_title_language,
            name: editProps?.track_title_language?.track_title_language_name,
          }
        : null,
      version_subtitle: editProps.version_subtitle,
      views: editProps.views ?? "",
      phonographic_copyright_year: editProps.phonographic_copyright_year ?? "",
      copyright_year: editProps.copyright_year ?? "",
      copyrighted_to: editProps.copyrighted_to ?? "",
      track_number: editProps.track_number,
    };
  } else {
    initial = {
      title: "",
      arranger: [""],
      ask_to_generate_an_isrc: "Yes",
      author: [{ name: "", category: "" }],
      client_id: "",
      composer: [""],
      content_type: "",
      featuring:
        releasedata && releasedata.featuring_artists.length !== 0
          ? featuring_initial && featuring_initial.length !== 0
            ? featuring_initial
            : [""]
          : [""],
      genre: releasedata && releasedata.genre_type ? genre_initial[0].id : "",
      instrumental: "No",
      isrc: "",
      lyrics: "",
      lyrics_language:
        releasedata && releasedata.meta_language
          ? {
              id: releasedata.meta_language.meta_language,
              name: releasedata.meta_language.meta_language_name,
            }
          : null,
      parental_advisory: "No",
      plan_id:
        releasedata && releasedata.plan_id
          ? releasedata.plan_id.id
            ? releasedata.plan_id.id
            : ""
          : "",
      preview_start: "",
      primary_artists:
        releasedata && releasedata.artists.length !== 0
          ? primary_artists_initial && primary_artists_initial.length !== 0
            ? primary_artists_initial
            : [""]
          : [""],
      primary_track_type: releasedata?.type_of_release
        ? capitalizeFirstLetter(releasedata.type_of_release)
        : "",
      producer: [""],
      producer_catalouge_number:
        releasedata && releasedata.producer_catalogue_number
          ? releasedata.producer_catalogue_number
          : "",
      production_year:
        releasedata && releasedata.production_year
          ? releasedata.production_year
          : "",
      published_by:
        releasedata && releasedata.published_by ? releasedata.published_by : "",

      publisher: ["", "IPRS"],
      remixer: [],
      primary_actor: [],
      primary_actress: [],
      director: [],
      release_id: props.release_id,
      secondary_genre: "",
      secondary_track_type: "",
      seconday_sub_genre: "",
      stream_id: "",
      sub_genre_name:
        releasedata && releasedata.sub_genre_type
          ? sub_genre_initial[0].id
          : "",
      track_title_language:
        releasedata && releasedata.meta_language
          ? {
              id: releasedata.meta_language.meta_language,
              name: releasedata.meta_language.meta_language_name,
            }
          : null,
      version_subtitle:
        releasedata && releasedata["music/album"]
          ? releasedata["music/album"]
          : "",
      views: "",
      phonographic_copyright_year:
        releasedata && releasedata.phonographic_copyright_year
          ? releasedata.phonographic_copyright_year
          : "",
      copyright_year:
        releasedata && releasedata.copyright_year
          ? releasedata.copyright_year
          : "",
      copyrighted_to:
        releasedata && releasedata.copyrighted_to
          ? releasedata.copyrighted_to
          : "",
    };
  }

  // Formik

  function Required() {
    return (
      <span style={{ color: "red", fontSize: "14px" }}>
        <span>Required</span> <span>*</span>
      </span>
    );
  }

  function RequiredText() {
    return (
      <span
        style={{
          color: "red",
          fontSize: "14px",
          marginTop: "-8px",
          marginLeft: "-10px",
        }}
      >
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </span>
    );
  }
  const handlePhoneKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode < 48 || keyCode > 57) {
      // Prevent input of non-numeric characters
      event.preventDefault();
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const key = String.fromCharCode(keyCode);
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (!alphanumericRegex.test(key)) {
      event.preventDefault();
    }
  };

  function getAssetId(trackId) {
    let AssetID = "";

    AssetID = props?.AssetAndTrackList.filter(
      (item) => item.track_id === trackId
    ).map((value) => {
      return value.assets_id;
    });

    return AssetID[0]?.id ?? "";
  }

  function fetchArtistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }

  function convertToSnakeCase(str) {
    let lowercaseStr = str.toLowerCase();
    let words = lowercaseStr.split(" ");
    if (words.length > 1) {
      return words.join("_");
    }
    return lowercaseStr;
  }

  const [selectedArtists, setSelectedArtists] = useState([]);
  const [inputvalueforactor, setinputvalueforactor] = useState("");
  const [inputvalueforactress, setinputvalueforactress] = useState("");
  const [inputvalueforDirector, setinputvalueforDirector] = useState("");

  const inputRef = useRef("");
  const postRef = useRef("");
  const actorFieldRef = useRef("");
  const artistFieldRef = useRef("");

  const primaryArtistFieldRef = useRef(null);
  const featuringArtistFieldRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [youtubeData, setYoutubeData] = useState(null);
  const [appleMusicData, setAppleMusicData] = useState(null);
  const [spotifyData, setSpotifyData] = useState(null);

  const ArtistSelect = forwardRef(
    (
      {
        name,
        label,
        placeholder,
        options,
        values,
        touched,
        errors,
        setFieldValue,
        required,
        openProp,
      },
      ref
    ) => {
      return (
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            textAlign="right"
            px={1}
            style={{ direction: "ltr", color: theme.palette.info.main }}
          >
            <Typography>
              {label} {required && "*"}
            </Typography>
          </Grid>

          <Grid item lg={7} md={7} sm={7} textAlign="left" px={1}>
            <Grid item lg={12} md={12} sm={12} className="d-flex">
              <Field
                name={name}
                size="small"
                // Calling AutoCompleteMultiSelect Component
                component={AutoCompleteMultiSelect}
                options={options ? options : []}
                textFieldProps={{
                  variant: "outlined",
                  placeholder: placeholder,
                }}
                openProp={openProp}
                multiple
                filterOptions={(options, { inputValue }) => {
                  let minCharsToShowDropdown = 2;
                  if (inputValue.length < minCharsToShowDropdown) {
                    return []; // If less than minCharsToShowDropdown, hide the dropdown
                  }

                  const filteredOptions = options.filter(
                    (option) =>
                      option.artist_name &&
                      option.artist_name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  );

                  return filteredOptions.sort((a, b) =>
                    a.artist_name.localeCompare(b.artist_name)
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option.artist_name === value.artist_name
                }
                ref={ref}
                freeSolo={true}
                getOptionLabel={(option) => option.artist_name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.artist_id}>
                      <CustomOption option={option} />
                    </li>
                  );
                }}
                onInputChange={(e, value) => {
                  inputRef.current = value;
                  artistFieldRef.current = name;
                }}
                sx={{
                  width: "-webkit-fill-available",
                }}
                value={values[name]}
                // error={errors[name]}
                error={Boolean(errors[name]) && Boolean(touched[name])}
                helperText={Boolean(touched[name]) && errors[name]}
              />

              <IconButton
                disabled={values[name] && values[name].length === 3}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (inputRef.current) {
                    const trimmedInput = inputRef.current.trim();

                    if (trimmedInput !== "") {
                      setIsLoading(["Youtube", "Apple Music", "Spotify"]);

                      setYoutubeData(null);
                      setAppleMusicData(null);
                      setSpotifyData(null);
                      setSelectedArtists([]);

                      fetchPlatformArtists("YouTube", inputRef.current);
                      fetchPlatformArtists("Apple Music", inputRef.current);
                      fetchPlatformArtists("Spotify", inputRef.current);
                    }
                  }
                }}
              >
                <SearchIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <span className="px-1 ">
                <span>
                  <ReportGmailerrorredIcon
                    style={{ marginRight: 2, fontSize: 16 }}
                  />
                </span>
                <span>Max 3 members can be added</span>
              </span>
              {name === artistFieldRef.current && (
                <>
                  <RenderArtistsSearchList
                    platformData={youtubeData}
                    platformName={"Youtube"}
                    isLoading={isLoading}
                    selectedArtistsArray={selectedArtists}
                  />
                  <RenderArtistsSearchList
                    platformData={appleMusicData}
                    platformName={"Apple Music"}
                    isLoading={isLoading}
                    selectedArtistsArray={selectedArtists}
                  />
                  <RenderArtistsSearchList
                    platformData={spotifyData}
                    platformName={"Spotify"}
                    isLoading={isLoading}
                    selectedArtistsArray={selectedArtists}
                  />
                  {(youtubeData || spotifyData || appleMusicData) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingY: "3px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setYoutubeData(null);
                          setAppleMusicData(null);
                          setSpotifyData(null);
                          setIsLoading(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                          ml: 1,
                        }}
                        onClick={() => {
                          let post_stream_platforms = [];

                          [
                            { platform_id: "1", platform_name: "spotify" },
                            { platform_id: "2", platform_name: "apple_music" },
                            {
                              platform_id: "3",
                              platform_name: "youtube_music",
                            },
                          ].map((platform) => {
                            if (selectedArtists.length > 0) {
                              if (
                                !selectedArtists.some(
                                  (artist) =>
                                    artist.stream_platform_id ===
                                    platform.platform_id
                                )
                              ) {
                                post_stream_platforms.push({
                                  stream_platform_artist_id: "",
                                  stream_platform_artist_name: "",
                                  stream_platform_artist_profile_url: "",
                                  stream_platform_id: platform.platform_id,
                                  stream_platform_name: platform.platform_name,
                                  generate: true,
                                });
                              } else {
                                post_stream_platforms.push(
                                  selectedArtists.filter(
                                    (artist) =>
                                      artist.stream_platform_id ===
                                      platform.platform_id
                                  )[0]
                                );
                              }
                            } else {
                              post_stream_platforms.push({
                                stream_platform_artist_id: "",
                                stream_platform_artist_name: "",
                                stream_platform_artist_profile_url: "",
                                stream_platform_id: platform.platform_id,
                                stream_platform_name: platform.platform_name,
                                generate: true,
                              });
                            }
                          });

                          let postData = {
                            artist_name: inputRef.current,
                            stream_platforms: post_stream_platforms,
                          };

                          let access_token = localStorage.getItem("Auth")
                            ? JSON.parse(localStorage.getItem("Auth"))
                                .access_token
                            : "";

                          fetch(
                            `${environment.api.artist}/add?current_page=tracks`,
                            {
                              method: "POST",
                              headers: {
                                Authorization: "Bearer " + access_token,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify(postData),
                            }
                          )
                            .then((res) => res.json())
                            .then((response) => {
                              setFieldValue(name, [
                                ...values[name],
                                {
                                  artist_id: response.id,
                                  artist_name: inputRef.current,
                                },
                              ]);

                              setYoutubeData(null);
                              setAppleMusicData(null);
                              setSpotifyData(null);
                              setSelectedArtists([]);
                            });
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  );

  const ArtistSelectDailoge = forwardRef(
    (
      {
        name,
        label,
        placeholder,
        options,
        values,
        touched,
        errors,
        setFieldValue,
        required,
        openProp,
        disabled,
        title,
        width,
      },
      ref
    ) => {
      return (
        <FieldArray name={name}>
          {({ insert, remove, push }) => (
            <Box sx={{ width: "100%" }}>
              {Array.isArray(values[name]) &&
                values[name].map((item, index) => (
                  <Grid key={index} container alignItems="center">
                    <Grid
                      item
                      xs={3}
                      sx={{
                        textAlign: "right",
                        fontSize: "16px",
                        lineHeight: 1.56,
                        color: "#b1b1b1",
                        paddingRight: "30px",
                      }}
                    >
                      {index === 0 && (
                        <Typography>
                          {label} {required && "*"}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={9} sx={{ marginBottom: "10px" }}>
                      <Grid container alignItems="center">
                        <Grid item xs={10.7}>
                          <Tooltip title={title} arrow placement="right-end">
                            <div>
                              <Field
                                width="100%"
                                style={{
                                  sx: "100%",
                                }}
                                disabled={disabled}
                                name={`${name}[${index}]`}
                                size="small"
                                component={AutocompleteDialoge}
                                remove={() => {
                                  values[name].length !== 1 && remove(index);
                                }}
                                fetchartistFilter={fetchArtistFilter}
                                innerref={ref}
                                values={values}
                                options={options ? options : []}
                                textFieldProps={{
                                  variant: "outlined",
                                  placeholder: placeholder,
                                }}
                                renderOption={(props, option) => {
                                  if (option && option.artist_name) {
                                    return (
                                      <li {...props} key={option.artist_id}>
                                        <CustomOption option={option} />
                                      </li>
                                    );
                                  }
                                }}
                                filterOptions={(options, { inputValue }) => {
                                  ref.current = inputValue;
                                  let minCharsToShowDropdown = 2;
                                  let filteredOptions;

                                  if (
                                    inputValue.length < minCharsToShowDropdown
                                  ) {
                                    return []; // If less than minCharsToShowDropdown, hide the dropdown
                                  } else {
                                    filteredOptions = options.filter(
                                      (option) =>
                                        option.artist_name &&
                                        option.artist_name
                                          .toLowerCase()
                                          .includes(inputValue.toLowerCase())
                                    );

                                    filteredOptions = filteredOptions.sort(
                                      (a, b) =>
                                        a.artist_name.localeCompare(
                                          b.artist_name
                                        )
                                    );

                                    filteredOptions = [
                                      ...filteredOptions,
                                      {
                                        artist_name: `Add "${inputValue}"`,
                                      },
                                    ];
                                  }

                                  return filteredOptions;
                                }}
                                sx={{
                                  width: width || "100%",
                                  height: "30px",
                                  borderRadius: "3px",
                                  backgroundColor: "#b4b3b3",
                                  "& .MuiOutlinedInput-root": {
                                    height: "30px",
                                  },
                                }}
                                getOptionLabel={(option) => {
                                  return option && option.artist_name;
                                }}
                                value={values[name][index]}
                                isOptionEqualToValue={(option, value) => {
                                  return (
                                    option.artist_name === value.artist_name
                                  );
                                }}
                              />
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid>
                          {(values[name][index] || values[name][index - 1]) && (
                            <IconButton
                              sx={{
                                width: "29px",
                                height: "28px",
                                backgroundColor: "#343434",
                                borderRadius: "3px",
                                color: "#898989",
                                fontSize: "25px",
                              }}
                              onClick={() => {
                                remove(index);
                                values[name].length === 1 && push("");
                              }}
                            >
                              <Close />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {values[name] &&
                values[name][values[name].length - 1] &&
                values[name][values[name].length - 1].length !== 0 && (
                  <Grid
                    container
                    item
                    lg={11.5}
                    md={11.5}
                    sm={11.5}
                    mt={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    px={1}
                  >
                    <Grid item>
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => push("")}
                      >
                        <AddCircleIcon
                          sx={{
                            marginRight: "5px",
                            color: "#7c7c7c",
                            lineHeight: 2.08,
                            fontSize: "14px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#7c7c7c",
                            lineHeight: 2.08,
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          Add Artist
                        </Typography>
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
            </Box>
          )}
        </FieldArray>
      );
    }
  );

  function UpdateSelectedArtists(previousArray, updatedObject, checked) {
    let idToUpdate = updatedObject.stream_platform_id;
    let updatedArray;

    if (checked) {
      if (
        previousArray.some((item) => item.stream_platform_id === idToUpdate)
      ) {
        updatedArray = previousArray.map((item) =>
          item.stream_platform_id === idToUpdate ? updatedObject : item
        );
      } else {
        updatedArray = [...previousArray, updatedObject];
      }
    } else {
      updatedArray = previousArray.filter(
        (item) => item.stream_platform_id !== idToUpdate
      );
    }

    return updatedArray;
  }

  function fetchPlatformArtists(platform, query) {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let API = "";
    if (platform === "YouTube")
      API = `${environment.api.baseApi}/search_musiclabels_artist_youtube?q=${query}&current_page=tracks`;
    if (platform === "Apple Music")
      API = `${environment.api.baseApi}/applemusic_artist?q=${query}&current_page=tracks`;
    if (platform === "Spotify")
      API = `${environment.api.baseApi}/spotify_artist?q=${query}&current_page=tracks`;

    fetch(API, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (platform === "Apple Music") {
          if (response) {
            if (response.status && response.status === "Failed") {
              setAppleMusicData([]);
            } else {
              let artist_data = response.apple_music_artist_details;
              setAppleMusicData(artist_data);
            }

            setIsLoading((prev) => {
              let platforms = prev;
              const indexToRemove = platforms.indexOf("Apple Music");
              if (indexToRemove !== -1) {
                platforms.splice(indexToRemove, 1);
              }

              return platforms;
            });
          }
        }
        if (platform === "YouTube") {
          if (response) {
            if (response.status && response.status === "Failed") {
              setYoutubeData([]);
            } else {
              let artist_data = response.youtube_music_artist_details;
              setYoutubeData(artist_data);
            }

            setIsLoading((prev) => {
              let platforms = prev;
              const indexToRemove = platforms.indexOf("Youtube");
              if (indexToRemove !== -1) {
                platforms.splice(indexToRemove, 1);
              }

              return platforms;
            });
          }
        }
        if (platform === "Spotify") {
          if (response) {
            if (response.status && response.status === "Failed") {
              setSpotifyData([]);
            } else {
              let artist_data = response.spotify_artist_details;
              setSpotifyData(artist_data);
            }
            setIsLoading((prev) => {
              let platforms = prev;
              const indexToRemove = platforms.indexOf("Spotify");
              if (indexToRemove !== -1) {
                platforms.splice(indexToRemove, 1);
              }

              return platforms;
            });
          }
        }
      })
      .catch((err) => {});
  }

  const CustomOption = ({ option }) => (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      <Typography component={"p"}>{option.artist_name}</Typography>
      <Typography component={"div"} className="d-flex">
        {option.stream_platforms?.map((item) => (
          <>
            <Typography
              sx={{
                textDecoration: "none",
                color: theme.palette.primary.main,
                marginX: 1,
              }}
            >
              {item.stream_platform_artist_id &&
              item.stream_platform_id === "1" ? (
                <FontAwesomeIcon icon={faSpotify}></FontAwesomeIcon>
              ) : item.stream_platform_artist_id &&
                item.stream_platform_id === "2" ? (
                <FontAwesomeIcon icon={faApple}></FontAwesomeIcon>
              ) : item.stream_platform_artist_id &&
                item.stream_platform_id === "3" ? (
                <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
              ) : null}
            </Typography>
          </>
        ))}
      </Typography>
    </Box>
  );

  const RenderArtistsSearchList = ({
    platformData,
    platformName,
    isLoading,
    selectedArtistsArray,
  }) => {
    return (
      <Box>
        {(platformData || (isLoading && Boolean(isLoading.length))) && (
          <Typography
            sx={{
              backgroundColor: `${theme.palette.background.light}`,
              height: "30px",
              borderBottom: `1px solid #bebec0`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingX: "10px",
            }}
          >
            <span
              style={{ flex: 1, display: "flex", justifyContent: "center" }}
            >
              {platformName}{" "}
            </span>
            {!platformData && isLoading.includes(platformName) ? (
              <CircularProgress size={20}></CircularProgress>
            ) : null}
          </Typography>
        )}

        {platformData &&
          (platformData.length > 0 ? (
            <Box
              className="scrollable"
              style={{
                height: "200px",
                border: "1px solid grey",
                backgroundColor: "white",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              <List>
                {platformData?.map((item, index) => (
                  <ListItem key={index} button>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedArtists.some(
                            (artist) =>
                              artist.stream_platform_artist_id ===
                              item.artist_id
                          )}
                        />
                      }
                      label=""
                      name="selected_artist"
                      onChange={(e, checked) => {
                        let post_obj = {};

                        if (platformName === "Youtube") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_image_url,
                            stream_platform_id: "3",
                            stream_platform_name: platformName,
                            generate: false,
                          });

                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                        if (platformName === "Spotify") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_images_url[0],
                            stream_platform_id: "1",
                            stream_platform_name: platformName,
                            generate: false,
                          });
                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                        if (platformName === "Apple Music") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_image_url,
                            stream_platform_id: "2",
                            stream_platform_name: platformName,
                            generate: false,
                          });
                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                      }}
                    />
                    <ListItemAvatar>
                      <Avatar
                        alt={item.artist_name}
                        src={
                          platformName === "Spotify"
                            ? item.artist_images_url[0]
                            : platformName === "Youtube"
                            ? item.artist_image_url
                            : platformName === "Apple Music"
                            ? item.artist_image_url
                            : ""
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText primary={item.artist_name} />
                    <Typography
                      component={"a"}
                      href={
                        platformName === "Spotify"
                          ? item.profile_url
                          : platformName === "Youtube"
                          ? item.profile_url
                          : platformName === "Apple Music"
                          ? item.profile_url
                          : null
                      }
                      target="_blank"
                    >
                      <OpenInNewIcon />
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Box
              style={{
                border: "1px solid grey",
                backgroundColor: "white",
                overflowY: "auto",
                padding: 2,
              }}
            >
              <Typography component={"p"} textAlign={"center"}>
                No Artists found!
              </Typography>
            </Box>
          ))}
      </Box>
    );
  };

  const PostFunction = (value, setFieldValue, values, name) => {
    if (name === "primary_actor") {
      let obj = {
        actor_name: value,
      };

      let params = {
        current_page: "tracks",
      };

      const callBack = async (res) => {
        await props.onGet(name);

        setFieldValue(name, [
          ...values[name],
          {
            actor_id: res?.id,
            actor_name: res?.data?.actor_name,
          },
        ]);

        postRef.current = "";
      };

      props
        .dispatch(ActorAction.requestPostActor(obj, params, callBack))
        .then(() => {});
    } else if (name === "primary_actress") {
      let obj = {
        actress_name: value,
      };

      let params = {
        current_page: "tracks",
      };

      const callBack = (res) => {
        setFieldValue(name, [
          ...values[name],
          {
            actress_id: res.id,
            actress_name: res?.data?.actress_name,
          },
        ]);
        postRef.current = "";
      };

      props
        .dispatch(ActressAction.requestPostActress(obj, params, callBack))
        .then(() => {
          props.onGet(name);
        });
    } else if (name === "director") {
      let obj = {
        director_name: value,
      };

      let params = {
        current_page: "tracks",
      };

      const callBack = (res) => {
        setFieldValue(name, [
          ...values[name],
          {
            director_id: res?.id,
            director_name: res?.data?.director_name,
          },
        ]);
        postRef.current = "";
      };

      props
        .dispatch(DirectorAction.requestPostDirector(obj, params, callBack))
        .then(() => {
          props.onGet(name);
        });
    }
  };

  const returnAddIconForActorField = (options, input) => {
    let boolean = false;

    let filteredActorArray = [];

    if (options && options.length && options.length !== 0) {
      filteredActorArray = options.filter((item) => {
        return (
          item.actor_name &&
          item.actor_name.toLowerCase().includes(input.toLowerCase())
        );
      });
    }

    if (filteredActorArray && filteredActorArray.length === 0) {
      boolean = true;
    }

    return boolean;
  };

  const returnAddIconDirectorForField = (options, input) => {
    let boolean = false;

    let filteredDirectorArray = [];

    if (options && options.length && options.length !== 0) {
      filteredDirectorArray = options.filter((item) => {
        return (
          item.director_name &&
          item.director_name.toLowerCase().includes(input.toLowerCase())
        );
      });
    }

    if (filteredDirectorArray && filteredDirectorArray.length === 0) {
      boolean = true;
    }

    return boolean;
  };

  const returnAddIconForActressField = (options, input) => {
    let boolean = false;

    let filteredActressArray = [];

    if (options && options.length && options.length !== 0) {
      filteredActressArray = options.filter((item) => {
        return (
          item.actress_name &&
          item.actress_name.toLowerCase().includes(input.toLowerCase())
        );
      });
    }

    if (filteredActressArray && filteredActressArray.length === 0) {
      boolean = true;
    }

    return boolean;
  };

  // Only for producer, arranger, composer & author
  function spaceRemover(inputArray) {
    return inputArray.some((item) => item.trim() !== "")
      ? {
          updatedArray: inputArray
            .filter((item) => item.trim() !== "")
            .map((item) => item.trim()),
        }
      : { updatedArray: [] };
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth="md"
      className="scrollable"
      PaperProps={{
        sx: {
          backgroundColor: "#000000",
          color: "#ffffff",
          borderRadius: "3px",
        },
      }}
      // onClose={null}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
        }}
        id="customized-dialog-title"
      >
        <Typography variant="h6" sx={{ fontSize: "22px" }}>
          Edit Track 2 &gt; {initial.title}
        </Typography>
        <IconButton
          onClick={props?.onClose}
          aria-label="close"
          sx={{ color: "#b1b1b1", fontSize: "24px" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
        >
          <CustomTab label="Track Info" selected={tabValue === 0} />
          <CustomTab
            label="Additional Contribution"
            selected={tabValue === 1}
          />
          <CustomTab label="Clips" selected={tabValue === 2} />
        </Tabs>
      </Box>
      <DialogContent dividers className={classes.scrollbar}>
        <Formik
          enableReinitialize
          initialValues={initial}
          onSubmit={(values, formikHelpers) => {
            let Obj = {};

            {
              props.subgenrelist?.length > 0 &&
              props.subgenrelist.some(
                (item) => item?.id == values.sub_genre_name
              )
                ? (values.sub_genre_name = values.sub_genre_name)
                : (values.sub_genre_name = null);
            }

            Object.assign(
              Obj,
              values.title ? { title: values.title.trim() } : { title: null },
              values.ask_to_generate_an_isrc && {
                ask_to_generate_an_isrc:
                  values.ask_to_generate_an_isrc === "No" ? false : true,
              },
              {
                isrc:
                  values.ask_to_generate_an_isrc === "Yes" ? "" : values.isrc,
              },

              values.content_type && {
                content_type: convertToSnakeCase(values.content_type),
              },

              values.featuring.some((item) => item !== "")
                ? { featuring: values.featuring }
                : { featuring: [] },
              values.instrumental && {
                instrumental: values.instrumental === "No" ? false : true,
              },
              values.lyrics ? { lyrics: values.lyrics } : { lyrics: "" },
              values.lyrics_language && {
                lyrics_language:
                  values.instrumental === "Yes"
                    ? "zxx"
                    : values.lyrics_language.id,
              },
              // : {
              //     lyrics_language: null,
              //   },
              values.parental_advisory && {
                parental_advisory: values.parental_advisory.toLowerCase(),
              },
              values.preview_start
                ? {
                    preview_start: values.preview_start,
                  }
                : {
                    preview_start: null,
                  },

              values.plan_id
                ? {
                    plan_id: values.plan_id,
                  }
                : {
                    plan_id: values.plan_id,
                  },
              values.primary_artists.some((item) => item !== "")
                ? {
                    primary_artists: values.primary_artists,
                  }
                : {
                    primary_artists: [],
                  },

              values.director
                ? {
                    director: values.director,
                  }
                : {
                    director: [],
                  },
              values.primary_actor
                ? {
                    primary_actor: values.primary_actor,
                  }
                : {
                    primary_actor: [],
                  },
              values.primary_actress
                ? {
                    primary_actress: values.primary_actress,
                  }
                : {
                    primary_actress: [],
                  },
              values.primary_track_type && {
                primary_track_type: values.primary_track_type.toLowerCase(),
              },
              { author: spaceRemover(values.author).updatedArray },
              { composer: spaceRemover(values.composer).updatedArray },
              { arranger: spaceRemover(values.arranger).updatedArray },
              { producer: spaceRemover(values.producer).updatedArray },
              values.producer_catalouge_number
                ? {
                    producer_catalouge_number: values.producer_catalouge_number,
                  }
                : {
                    producer_catalouge_number: null,
                  },
              values.production_year
                ? {
                    production_year: values.production_year,
                  }
                : {
                    production_year: null,
                  },
              values.published_by
                ? {
                    published_by: values.published_by,
                  }
                : {
                    published_by: null,
                  },
              values.publisher && {
                publisher: values.publisher.join("-"),
              },
              values.remixer
                ? {
                    remixer: values.remixer,
                  }
                : {
                    remixer: [],
                  },
              values.release_id && {
                release_id: values.release_id,
              },
              // values.secondary_genre && {
              //   secondary_genre: values.secondary_genre,
              // },
              values.secondary_track_type && {
                secondary_track_type: values.secondary_track_type.toLowerCase(),
              },
              // values.seconday_sub_genre && {
              //   seconday_sub_genre: values.seconday_sub_genre,
              // },
              values.stream_id && {
                stream_id: values.stream_id,
              },
              values.genre
                ? {
                    genre: values.genre,
                  }
                : {
                    genre: null,
                  },
              values.sub_genre_name
                ? {
                    sub_genre_name: values.sub_genre_name,
                  }
                : {
                    sub_genre_name: null,
                  },
              values.track_title_language && {
                track_title_language:
                  values.instrumental === "Yes"
                    ? "zxx"
                    : values.track_title_language.id,
              },
              // : {
              //     track_title_language: null,
              //   },
              values.version_subtitle
                ? {
                    ["music/album"]: values.version_subtitle,
                  }
                : {
                    ["music/album"]: null,
                  },
              values.views
                ? {
                    views: Number(values.views),
                  }
                : {
                    views: null,
                  },
              values.phonographic_copyright_year && {
                phonographic_copyright_year:
                  values.phonographic_copyright_year.toString(),
              },
              values.copyright_year && {
                copyright_year: `${values.copyright_year}`,
              },
              values.copyrighted_to
                ? {
                    copyrighted_to: values.copyrighted_to,
                  }
                : {
                    copyrighted_to: null,
                  },
              !values.track_number
                ? { track_number: props?.currentTracksLength + 1 }
                : { track_number: values.track_number }
            );
            setLoading(true);
            // parameter for showing list based on the modified_by
            const params = {
              // order_by: `[["track_number","asc"]]`,
              page_limit: 15,
              filters: `[["release_id.keyword","must","","match","${props.release_id}"]]`,
              current_page: "tracks",
            };
            // const param = {
            //   order_by: `[["created_on","desc"]]`,
            //   page_limit: "none",
            //   current_page: "tracks",
            // };
            if (props.edit) {
              props.setIsTrackError(false);
              let page_params = {
                current_page: "tracks",
              };
              props
                .dispatch(
                  TrackAction.requestPutTrack(
                    JSON.stringify(Obj),
                    props.projectEditData.id,
                    page_params
                  )
                )
                .then(() => {
                  // props.dispatch(TrackAction.requestTrack(params));
                  // props.dispatch(TrackAction.requestTrackFilter(param));
                  props.onSubmit();
                  if (props.isTrackError || props.submissionRedirect)
                    navigate("/submission");
                  props.onClose();
                  setLoading(false);
                  updateTrackErrorInState(false);
                });
            } else {
              const callBack = (res) => {
                const tracks = [];
                res?.hits?.hits?.length > 0 &&
                  res.hits.hits.map((track) => {
                    tracks.push({
                      assets_id: getAssetId(track._id),
                      track_id: track._id,
                    });
                  });

                let put_obj = {
                  tracks: tracks,
                };

                if (res?.hits?.hits?.length > 0) {
                  let page_params = {
                    current_page: "tracks",
                  };
                  props
                    .dispatch(
                      ReleasesAction.requestPutRelease(
                        JSON.stringify(put_obj),
                        props.release_id,
                        page_params
                      )
                    )
                    .then(() => {
                      let page_params = {
                        current_page: "tracks",
                      };
                      props.dispatch(
                        ReleasesAction.requestReleaseById(
                          props.release_id,
                          page_params
                        )
                      );
                    });
                }
              };

              let page_params = {
                current_page: "tracks",
              };

              props
                .dispatch(TrackAction.requestPostTrack(Obj, page_params))
                .then(() => {
                  props.dispatch(TrackAction.requestTrack(params, callBack));
                  // props.onSubmit();
                  props.onClose();
                  //navigate("/releaseDate");

                  setLoading(false);
                });
            }

            // formikHelpers.resetForm();
          }}
          validate={(values) => {
            let errors = {};

            function isAlphanumericWithBoth(values) {
              const containsLetters = /[a-zA-Z]/.test(values);
              const containsNumbers = /[0-9]/.test(values);
              const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

              if (containsLetters && containsNumbers && !containsSpecialChars) {
                // The string contains both letters and numbers (alphanumeric) and no special characters.
                return true;
              } else {
                // The string does not meet the criteria.
                return false;
              }
            }
            if (!values.content_type) {
              errors.content_type = <Required />;
            }
            if (!values.primary_track_type) {
              errors.primary_track_type = <Required />;
            }
            if (!values.secondary_track_type) {
              errors.secondary_track_type = <Required />;
            }

            if (!values.title) {
              errors.title = <RequiredText />;
            }

            if (values.primary_artists) {
              let trueValue = values.primary_artists.some(
                (item) => item !== ""
              );
              !trueValue && (errors.primary_artists = []);
              !trueValue && (errors.primary_artists[0] = <RequiredText />);
            }

            if (values.author && values.instrumental === "No") {
              let trueValue = values.author.some((item) => item !== "");
              !trueValue && (errors.author = []);
              !trueValue && (errors.author[0] = <Required />);
            }
            if (values.composer) {
              let trueValue = values.composer.some((item) => item !== "");
              !trueValue && (errors.composer = []);
              !trueValue && (errors.composer[0] = <Required />);
            }

            if (!values.genre) {
              errors.genre = <Required />;
            }
            if (!values.sub_genre_name) {
              errors.sub_genre_name = <Required />;
            }
            // if (!values.plan_id) {
            //   errors.plan_id = <Required />;
            // }
            if (values.instrumental === "No") {
              if (!values.track_title_language) {
                errors.track_title_language = <Required />;
              }
            }
            if (values.instrumental === "No") {
              if (!values.lyrics_language) {
                errors.lyrics_language = <Required />;
              }
            }
            if (!values.copyright_year) {
              errors.copyright_year = <Required />;
            }

            if (!values.production_year) {
              errors.production_year = <Required />;
            }
            if (!values.preview_start) {
              errors.preview_start = <RequiredText />;
            }

            if (values.publisher.length > 0) {
              (!values.publisher[0] || !values.publisher[1]) &&
                (errors.publisher = []);

              if (values.publisher.length === 1 && !values.publisher[1]) {
                errors.publisher[1] = <Required />;
              }
              if (values.publisher.length === 2) {
                !values.publisher[0] && (errors.publisher[0] = <Required />);
                !values.publisher[1] && (errors.publisher[1] = <Required />);
              }
            } else {
              (!values.publisher[0] || !values.publisher[1]) &&
                (errors.publisher = []);
              !values.publisher[0] && (errors.publisher[0] = <Required />);
              !values.publisher[1] && (errors.publisher[1] = <Required />);
            }

            if (values.ask_to_generate_an_isrc === "No") {
              if (!values.isrc) {
                errors.isrc = <Required />;
              }
            }

            if (!values.copyrighted_to) {
              errors.copyrighted_to = <RequiredText />;
            }

            if (!values.published_by) {
              errors.published_by = <RequiredText />;
            }

            if (!values.phonographic_copyright_year) {
              errors.phonographic_copyright_year = <Required />;
            }

            if (!values.producer_catalouge_number) {
              errors.producer_catalouge_number = <RequiredText />;
            }

            if (values.producer_catalouge_number) {
              if (!isAlphanumericWithBoth(values.producer_catalouge_number)) {
                errors.producer_catalouge_number =
                  "should only contain alphanumeric characters";
              }
            }

            return props.isTrackError && errors;
          }}
          innerRef={formRef}
        >
          {({
            errors,
            isValid,
            helperText,
            touched,
            dirty,
            handleChange,
            setFieldValue,
            values,
          }) => (
            <Box>
              <Form>
                {/* ********************************************* Tag Name */}
                <TabPanel value={tabValue} index={0}>
                  <Box>
                    <LoadingIndicator isActive={props.isRequesting}>
                      {!props.isRequesting && (
                        <div>
                          <Box>
                            <Grid
                              //lg={12}
                              item
                              container
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Grid
                                item
                                container
                                xl={11}
                                lg={10}
                                md={10}
                                sm={8}
                                xs={8}
                                sx={{
                                  display: "flex",
                                  flexDirection: "flex-column",
                                }}
                                gap={3}
                              >
                                <FormField
                                  label="Title"
                                  placeholder="Song Title"
                                  id="title"
                                  name="title"
                                  errors={errors}
                                  required={true}
                                  touched={touched}
                                  title="Enter the Track name/Song Title"
                                  width="545px"
                                />
                                <FormField
                                  label="Version (Optional)"
                                  placeholder="Version"
                                  id="version"
                                  name="version"
                                  errors={errors}
                                  touched={touched}
                                  title="Enter the version"
                                  width="545px"
                                />
                             
                               
                               
                               
                               
                                <Grid item xs={12}>
                                  <ArtistSelectDailoge
                                    ref={primaryArtistFieldRef}
                                    name="primary_artists"
                                    artistFieldRef={artistFieldRef}
                                    label="Artist(S)"
                                    placeholder="Artist's Name"
                                    options={
                                      props.artistArray ? props.artistArray : []
                                    }
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    required={true}
                                    openProp={
                                      values && values.primary_artists
                                        ? values.primary_artists
                                        : []
                                    }
                                    setFieldValue={setFieldValue}
                                    title="Enter a new artist or select an existing catalog artist as an additional main artist or other artist role.Each field should contain only one artist name unless the artists are generally listed together as a group.Spelling should be consistent across all artist's content"
                                    width="515px"
                                  />
                                </Grid>
                                <FormField
                                  label="Display Artist"
                                  placeholder="artist"
                                  id="artist"
                                  name="artist"
                                  errors={errors}
                                  touched={touched}
                                  title="Enter the artist"
                                  width="545px"
                                />
                               
                               
                                <TextandDropdownComponent
                                  name="author"
                                  label={"Writer / Composer"}
                                  values={values}
                                  required={
                                    values.instrumental === "Yes" ? false : true
                                  }
                                  disabled={
                                    values.instrumental === "Yes" ? true : false
                                  }
                                  errors={errors}
                                  touched={touched}
                                  title="Enter the author name"
                                  placeholder="Writer / Composer"
                                  setFieldValue={setFieldValue}
                                />
                              
                               
                                <Grid item xs={12}>
                                  <FieldArrayComponent
                                    name="producer"
                                    label="Producers"
                                    values={values}
                                    title="Enter the producer name"
                                  />
                                </Grid>
                               
                               
                               
                               
                                <Grid item xs={12}>
                                  <Grid container alignItems={"center"}>
                                    <Grid
                                      item
                                      xs={3}
                                      sx={{
                                        textAlign: "right",
                                        fontSize: "16px",
                                        lineHeight: 1.56,
                                        color: "#b1b1b1",
                                        paddingRight: "30px",
                                      }}
                                    >
                                      <Typography>Genre *</Typography>
                                    </Grid>

                                    <Grid item xs={9}>
                                      <Grid container alignItems="center">
                                        <Grid item xs={10}>
                                          <Tooltip
                                            title="Choose the product's genre. Storefront genre categorization often occurs at the discretion of the music service and may differ slightly from the chosen genre."
                                            arrow
                                            placement="right-end"
                                          >
                                            <Field
                                              name="genre"
                                              component={
                                                AutoCompleteMultiSelect
                                              }
                                              disableClearable
                                              disabled={
                                                props._source
                                                  ? props._source.status &&
                                                    idsWithEditPermission()
                                                    ? false
                                                    : true
                                                  : false
                                              }
                                              options={props.genreList || []}
                                              isOptionEqualToValue={(
                                                option,
                                                value
                                              ) => option.id === value.id}
                                              getOptionLabel={(option) =>
                                                option.name || ""
                                              }
                                              textFieldProps={{
                                                variant: "outlined",
                                                placeholder: "Select an option",
                                              }}
                                              onChange={(event, value) => {
                                                setFieldValue(
                                                  "genre",
                                                  value?.id || ""
                                                );
                                                setFieldValue(
                                                  "sub_genre_name",
                                                  null
                                                );
                                              }}
                                              value={
                                                props.genreList.find(
                                                  (item) =>
                                                    item.id === values.genre
                                                ) || null
                                              }
                                              sx={{
                                                width: "545px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                backgroundColor: "#b4b3b3",
                                              }}
                                            />
                                          </Tooltip>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                               
                                <Grid item xs={12}>
                                  <AutoCompleteFormLyrics
                                    name="lyrics_language"
                                    label="Audio Language"
                                    placeholder="Lyrics Language"
                                    options={props.nameslist}
                                    errors={errors}
                                    size="small"
                                    touched={touched}
                                    required={true}
                                    values={values}
                                    disabled={values.instrumental === "Yes"}
                                    title="Choose the language in which the vocals on the track are sung.If there are no vocals or the vocal content does not contain words in an intelligable language,choose 'No Linguistic Content'"
                                    width="545px"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <SelectFieldObject
                                    label="Plan Tier"
                                    name="plan_id"
                                    options={props.planList ?? []}
                                    size="small"
                                    placeholder="Select an option"
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    title="Enter the plan name"
                                    width={"545px"}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <ISRCSection
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleKeyPress={(e) => {
                                      // Handle key press if necessary
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TwoSelectField
                                    label="C Line"
                                    name="phonographic_copyright_year"
                                    options={yearArray.sort((a, b) => b - a)}
                                    size="small"
                                    placeholder="Select an option"
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    title="Enter phonographic copyright text.Enter the year date on which the sound recording was produced and the name of the copyright owner of the sound recording"
                                    width="196px"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TwoSelectField
                                    label="P Line"
                                    name="production_year"
                                    options={yearArray.sort((a, b) => b - a)}
                                    size="small"
                                    placeholder="Select an option"
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    title="Enter the production Year"
                                    width="196px"
                                  />
                                </Grid>
                                <PublisherSection
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  IPRSandPPL={IPRSandPPL}
                                />
                             
                               
                                <Grid item xs={12}></Grid>
                                <Grid container alignItems="center">
                                  <Grid
                                    item
                                    xs={3}
                                    sx={{
                                      textAlign: "right",
                                      fontSize: "16px",
                                      lineHeight: 1.56,
                                      color: "#b1b1b1",
                                      paddingRight: "30px",
                                    }}
                                  >
                                    <Typography>Explicit Lyrics</Typography>
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Grid container alignItems="center">
                                      <Grid item xs={11}>
                                        <Field name="parental_advisory">
                                          {({ field }) => (
                                            <RadioGroup
                                              {...field}
                                              row
                                              onChange={(event) => {
                                                setFieldValue(
                                                  "parental_advisory",
                                                  event.target.value
                                                );
                                              }}
                                            >
                                              {yesNoArray.map((option) => (
                                                <FormControlLabel
                                                  key={option}
                                                  className="p-0 m-0 mr-5"
                                                  value={option}
                                                  control={<CustomRadio />}
                                                  label={
                                                    <span
                                                      style={{
                                                        fontSize: 16,
                                                        marginLeft: 8,
                                                      }}
                                                    >
                                                      {option}
                                                    </span>
                                                  }
                                                  sx={{ fontSize: 12 }}
                                                />
                                              ))}
                                            </RadioGroup>
                                          )}
                                        </Field>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                               
                                <br />
                              
                                
                               
                                
                               
                                <Grid item xs={12}>
                                  <TextareaField
                                    label="Lyrics"
                                    placeholder="Maximum 20000 Characters"
                                    id="lyrics"
                                    name="lyrics"
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    title="You have indicated an audio language but have not entered any lyrics.Music services strongly recommend including complete lyrics on all tracks with vocal content to assist in discovery via lyric and voice searches.Some music services may also display lyrics on their platform"
                                  />
                                </Grid>
                               
                              
                               
                                
                               
                                
                              </Grid>
                            </Grid>
                          </Box>
                          <DialogActions className="m-1 my-4">
                            <Grid
                              container
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between", 
                                color: theme.palette.text.primary,
                                marginX: "10px",
                              }}
                            >
                             
                              <Grid
                                item
                                container
                                xs="auto"
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid item>
                                  <IconButton aria-label="backward">
                                    <ArrowBackIosIcon
                                      sx={{
                                        color: "#adadad",
                                        fontSize: "16px",
                                      }}
                                    />
                                  </IconButton>
                                </Grid>

                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      color: "#b1b1b1",
                                      lineHeightStep: 1.56,
                                    }}
                                  >
                                    {initial.title}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    aria-label="forward"
                                  >
                                    <ArrowForwardIosIcon
                                      sx={{
                                        color: "#adadad",
                                        fontSize: "16px",
                                      }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>

                              {/* Right Side: Cancel and Save Buttons */}
                              <Grid item container xs="auto" spacing={2}>
                                <Grid item>
                                  {!props.edit ? (
                                    <MuiButton
                                      name={"Cancel"}
                                      width="110px"
                                      height="37px"
                                      borderRadius="18px"
                                      background="#505152"
                                      fontSize="16px"
                                      fontWeight="bold"
                                      color="white"
                                      onClick={() => {
                                        props.onClose();
                                      }}
                                      cancel
                                    />
                                  ) : (
                                    <MuiButton
                                      type="reset"
                                      name="Reset"
                                      onClick={Formik.resetForm}
                                      width="110px"
                                      height="37px"
                                      borderRadius="18px"
                                      background="#505152"
                                      fontSize="16px"
                                      fontWeight="bold"
                                      color="white"
                                      cancel
                                    />
                                  )}
                                </Grid>
                                <Grid item>
                                  {props.edit ? (
                                    <MuiButton
                                      loading={loading}
                                      type="submit"
                                      name="Submit"
                                      save
                                      width="110px"
                                      height="37px"
                                      borderRadius="18px"
                                      background="#2a5e6c"
                                      fontSize="16px"
                                      fontWeight="bold"
                                      color="white"
                                    />
                                  ) : (
                                    <MuiButton
                                      loading={loading}
                                      type="submit"
                                      save
                                      name="Add Tracks"
                                      width="110px"
                                      height="37px"
                                      borderRadius="18px"
                                      background="#2a5e6c"
                                      fontSize="16px"
                                      fontWeight="bold"
                                      color="white"
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </DialogActions>
                        </div>
                      )}
                    </LoadingIndicator>
                  </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  Content for Additional Contribution
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  Content for Clips
                </TabPanel>
              </Form>
            </Box>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(TrackPost);

const FormField = ({
  label,
  placeholder,
  id,
  name,
  errors,
  touched,
  handlekeypress,
  disabled,
  required,
  title,
  width,
  showDeleteIcon,
  nameslist,
}) => (
  <Grid container alignItems="center">
    {label && (
      <Grid
        item
        xs={3}
        sx={{
          textAlign: "right",
          fontSize: "16px",
          lineHeight: 1.56,
          color: "#b1b1b1",
          paddingRight: "30px",
        }}
      >
        <Typography>
          {label} {required && "*"}
        </Typography>
      </Grid>
    )}

    <Grid item xs={9}>
      <Grid container alignItems="center">
        <Grid item xs={11.2}>
          <Tooltip title={title} arrow placement="right-end">
            <FastField
              as={CustomTextField}
              variant="outlined"
              placeholder={placeholder}
              id={id}
              onKeyPress={handlekeypress}
              size="small"
              name={name}
              error={Boolean(errors[name]) && Boolean(touched[name])}
              helperText={Boolean(touched[name]) && errors[name]}
              disabled={disabled}
              width={width}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={0.5} container justifyContent="flex-end">
          {showDeleteIcon && (
            <IconButton
              sx={{
                width: "29px",
                height: "28px",
                backgroundColor: "#343434",
                borderRadius: "3px",
                color: "#898989",
                fontSize: "25px",
              }}
            >
              <Close />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
const CheckboxField = ({ label, name, value, onChange }) => {
  return (
    <Grid item lg={12} md={12} sm={12} container>
      <Grid item lg={3} md={3} sm={3} ml={10} mt={1}></Grid>
      <Grid item lg={6} md={6} sm={6}>
        <FormControlLabel
          control={<Checkbox checked={value} onChange={onChange} />}
          label={label}
          name={name}
          value={value}
        />
      </Grid>
    </Grid>
  );
};

const SelectField = ({
  label,
  name,
  options,
  size,
  placeholder,
  errors,
  touched,
  required,
  title,
  width,
}) => {
  return (
    <Grid container alignItems={"center"}>
      {label && (
        <Grid
          item
          xs={3}
          sx={{
            textAlign: "right",
            fontSize: "16px",
            lineHeight: 1.56,
            color: "#b1b1b1",
            paddingRight: "30px",
          }}
        >
          {" "}
          <Typography>
            {label} {required && "*"}
          </Typography>
        </Grid>
      )}
      <Grid item xs={9}>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <FastField style={{ width: "100%" }} name={name}>
              {({ field, meta }) => (
                <Tooltip title={title} arrow placement="right-end">
                  <div>
                    <Select
                      input={
                        <OutlinedInput
                          {...inputProps}
                          // sx={{
                          //   height: "30px",
                          // }}
                        />
                      }
                      MenuProps={menuProps}
                      {...field}
                      displayEmpty
                      size={size}
                      style={{
                        width: width || "100%",
                        height: "30px",
                        borderRadius: "3px",
                        backgroundColor: "#b4b3b3",
                        color: "#000",
                      }}
                      error={meta.touched && meta.error ? true : false}
                    >
                      {placeholder && (
                        <MenuItem value="" disabled>
                          <span
                            style={{
                              color: "#b7c4c2",
                              opacity: 1,
                              textShadow:
                                "0.3px 0.3px 0.3px rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            {placeholder}
                          </span>
                        </MenuItem>
                      )}
                      {options.map((item) => (
                        <MenuItem
                          style={{
                            maxWidth: 250,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            color: theme.palette.text.secondary,
                          }}
                          key={item}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Tooltip>
              )}
            </FastField>
            &nbsp;
            <ErrorMessage name={name} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TwoSelectField = ({
  label,
  name,
  options,
  size,
  placeholder,
  errors,
  touched,
  required,
  title,
  values,
  index,
  disabled, 
  setFieldValue, 
}) => {
  return (
    <Grid container alignItems={"center"}>
      {label && (
        <Grid
          item
          xs={3}
          sx={{
            textAlign: "right",
            fontSize: "16px",
            lineHeight: 1.56,
            color: "#b1b1b1",
            paddingRight: "30px",
          }}
        >
          <Typography>
            {label} {required && "*"}
          </Typography>
        </Grid>
      )}
      <Grid item xs={9}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={4}>
            <FastField name={name}>
              {({ field, meta }) => (
                <Tooltip title={title} arrow placement="right-end">
                  <div>
                    <Select
                      input={<OutlinedInput {...field} />}
                      displayEmpty
                      size={size}
                      style={{
                        width: "196px",
                        height: "30px",
                        borderRadius: "3px",
                        backgroundColor: "#b4b3b3",
                        color: "#000",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "250px",
                            width: 196,
                          },
                        },
                      }}
                      error={meta.touched && meta.error ? true : false}
                    >
                      {placeholder && (
                        <MenuItem value="" disabled>
                          <span
                            style={{
                              color: "#b7c4c2",
                              opacity: 1,
                              textShadow:
                                "0.3px 0.3px 0.3px rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            {placeholder}
                          </span>
                        </MenuItem>
                      )}
                      {options.map((item) => (
                        <MenuItem
                          style={{
                            maxWidth: 250,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            color: "#000", 
                          }}
                          key={item}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Tooltip>
              )}
            </FastField>
            <ErrorMessage name={name} />
          </Grid>
          <Grid item xs={7}>
            <Tooltip title={title} arrow placement="right-end">
              <Field
                as={CustomTextField}
                variant="outlined"
                placeholder={
                  name === "author" && values.instrumental === "Yes"
                    ? "Disabled"
                    : "Enter here"
                }
                id={`${name}[${index}]`}
                size="small"
                name={`name.${index}.name`}
                disabled={disabled}
                onChange={(e) => {
                  setFieldValue(`${name}.${index}.name`, e.target.value);
                }}
                sx={{
                  width: "343px",
                  height: "30px",
                  borderRadius: "3px",
                  backgroundColor: "#b4b3b3",
                  "& .MuiOutlinedInput-root": {
                    height: "30px",
                  },
                }}
                error={Boolean(errors && errors.hasOwnProperty(name))}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// Custom filter function
const customFilterOptionsartist = (options, { inputValue }) => {
  let minCharsToShowDropdown = 2;
  if (inputValue.length < minCharsToShowDropdown) {
    return []; 
  }

  const filteredOptions = options.filter((option) =>
    option.artist_name?.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Sort the filtered options in ascending order
  return filteredOptions.sort((a, b) =>
    a.artist_name.localeCompare(b.artist_name)
  );
};

const customFilterOptionslyrics = (options, { inputValue }) => {
  let minCharsToShowDropdown = 1;
  if (inputValue.length < minCharsToShowDropdown) {
    return options; // Show all options if input is empty or too short
  }

  // Filter options based on the entered value
  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Sort the filtered options in ascending order
  return filteredOptions.sort((a, b) => a.name.localeCompare(b.name));
};



const TextareaField = ({
  label,
  placeholder,
  id,
  name,
  errors,
  touched,
  handlekeypress,
  disabled,
  required,
  handleChange,
  values,
  title,
}) => {
  return (
    <Grid container alignItems="center">
      <Grid
        item
        xs={3}
        sx={{
          textAlign: "right",
          fontSize: "16px",
          lineHeight: 1.56,
          color: "#b1b1b1",
          paddingRight: "30px",
        }}
      >
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}
        </Typography>
      </Grid>
      <Grid item xs={9}>
       
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Tooltip title={title} arrow placement="right-end">
              <div>
                <FastField
                  className={
                    touched[name] && errors[name]
                      ? "ticket_desc_test_area_error scrollable textarea-placeholder"
                      : "ticket_desc_test_area scrollable textarea-placeholder"
                  }
                  name={name}
                  component="textarea"
                  // value={values.name}
                  onChange={handleChange}
                  defaultValue={values.name}
                  type="text"
                  maxrows={5}
                  minrows={5}
                  style={{
                    width: "544px",
                    height: "100px",
                    padding: "10px",
                    borderRadius: "3px",
                    boxSizing: "border-box",
                    outline: "none",
                    border: "none",
                    // color: `${theme.palette.text.primary}`,
                    backgroundColor: "#b4b3b3",
                    "::placeholder": {
                      color: "#7c7c7c",
                    },
                  }}
                  aria-label="maximum height"
                  placeholder={placeholder}
                />
              </div>
            </Tooltip>
            <Box px={1}>
              <ErrorMessage name={name} />
            </Box>
          </Grid>
        </Grid>
        {/* <ErrorMessage name={name} /> */}
      </Grid>
    </Grid>
  );
};

const AutoCompleteFormLyrics = (props) => {
  const {
    name,
    label,
    placeholder,
    options,
    errors,
    touched,
    required,
    values,
    disabled,
    title,
    width,
  } = props;

  return (
    <Grid container alignItems={"center"}>
      <Grid
        item
        xs={3}
        sx={{
          textAlign: "right",
          fontSize: "16px",
          lineHeight: 1.56,
          color: "#b1b1b1",
          paddingRight: "30px",
        }}
      >
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}{" "}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Grid item xs={11}>
          <Tooltip title={title} arrow placement="right-end">
            <div>
              <Field
                name={name}
                size="small"
                component={AutoCompleteMultiSelect}
                disableClearable
                fieldheight="30px"
                options={options ? options : []}
                filterOptions={customFilterOptionslyrics}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                margin={0}
                getOptionLabel={(option) => option.name}
                textFieldProps={{
                  margin: "normal",
                  variant: "outlined",
                  placeholder: placeholder,
                }}
                disabled={disabled}
                sx={{
                  width: width || "100%",
                  height: "30px",
                  borderRadius: "3px",
                  backgroundColor: "#b4b3b3",
                  "& .MuiOutlinedInput-root": {
                    height: "30px",
                  },
                }}
                // error={Boolean(errors[name]) && Boolean(touched[name])}
                // helperText={Boolean(touched[name]) && errors[name]}
              />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const SelectFieldObject = ({
  label,
  name,
  options,
  size,
  placeholder,
  errors,
  touched,
  required,
  title,
  width,
}) => {
  return (
    <Grid container alignItems={"center"}>
      <Grid
        item
        xs={3}
        sx={{
          textAlign: "right",
          fontSize: "16px",
          lineHeight: 1.56,
          color: "#b1b1b1",
          paddingRight: "30px",
        }}
      >
        <Typography color={theme.palette.text.form}>
          {label} {required && "*"}{" "}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Grid item xs={11}>
          <Field name={name}>
            {({ field, meta }) => (
              <Tooltip title={title} arrow placement="right-end">
                <div>
                  <Select
                    input={<OutlinedInput {...inputProps} />}
                    MenuProps={menuProps}
                    value={field.value ? field.value.id : ""}
                    onChange={(event) => {
                      const selectedOption = options.find(
                        (option) => option.id === event.target.value
                      );
                      field.onChange(selectedOption);
                    }}
                    {...field}
                    displayEmpty
                    size={size}
                    style={{
                      width: width || "100%",
                      height: "30px",
                      // color: theme.palette.text.primary,
                      borderRadius: "3px",
                      backgroundColor: "#b4b3b3",
                    }}
                    error={meta.touched && meta.error ? true : false}
                  >
                    {placeholder && (
                      <MenuItem value="" disabled style={{ overflow: "none" }}>
                        <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
                      </MenuItem>
                    )}
                    {options &&
                      options.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          style={{
                            maxWidth: 250,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            color: theme.palette.background.secondary,
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </Tooltip>
            )}
          </Field>
          &nbsp;
          <ErrorMessage name={name} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const FieldArrayComponent = ({
  name,
  label,
  required,
  values,
  errors,
  touched,
  title,
  disabled,
}) => {
  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <Box sx={{ width: "100%" }}>
          {Array.isArray(values[name]) &&
            values[name].map((item, index) => (
              <Grid key={index} container alignItems="center" spacing={2}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    textAlign: "right",
                    fontSize: "16px",
                    lineHeight: 1.56,
                    color: "#b1b1b1",
                    paddingRight: "30px",
                  }}
                >
                  {index === 0 && (
                    <Typography>
                      {label} {required && "*"}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={9}>
                  <Grid container alignItems="center">
                    <Grid item xs={10.3}>
                      <Tooltip title={title} arrow placement="right-end">
                        <div>
                          <Field
                            as={CustomTextField}
                            variant="outlined"
                            placeholder={
                              name === "author" && values.instrumental === "Yes"
                                ? "Disabled"
                                : "Enter here"
                            }
                            id={`${name}[${index}]`}
                            size="small"
                            name={`${name}[${index}]`}
                            disabled={disabled}
                            sx={{
                              width: "500px",
                              height: "30px",
                              borderRadius: "3px",
                              backgroundColor: "#b4b3b3",
                              "& .MuiOutlinedInput-root": {
                                height: "30px",
                              },
                            }}
                            error={
                              errors && Boolean(errors.hasOwnProperty(name))
                            }
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={0.8} sx={{ textAlign: "right" }}>
                      {values[name].length > 1 && (
                        <IconButton
                          sx={{
                            width: "29px",
                            height: "28px",
                            backgroundColor: "#343434",
                            borderRadius: "3px",
                            color: "#898989",
                            fontSize: "25px",
                          }}
                          onClick={() => remove(index)}
                        >
                          <Close />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ErrorMessage name={`${name}[${index}]`} />
                </Grid>
              </Grid>
            ))}
          {values[name] && values[name].length > 0 && (
            <Grid
              container
              item
              lg={11.5}
              md={11.5}
              sm={11.5}
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              px={1}
            >
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => push("")}
              >
                <AddCircleIcon
                  sx={{
                    marginRight: "5px",
                    color: "#7c7c7c",
                    lineHeight: 2.08,
                    fontSize: "14px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#7c7c7c",
                    lineHeight: 2.08,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Add {label}
                </Typography>
              </IconButton>
            </Grid>
          )}
        </Box>
      )}
    </FieldArray>
  );
};

const CustomTextField = styled(MuiTextField)(({ theme, width }) => ({
  width: width || "100%",
  height: "30px",
  borderRadius: "3px",
  backgroundColor: "#b4b3b3",
  "& .MuiOutlinedInput-root": {
    height: "30px",
  },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
  width: "27px",
  height: "27px",
  borderRadius: "4px",
  border: "solid 1px #707070",
  backgroundColor: "white",
  padding: 0,
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& .MuiSvgIcon-root": {
    display: "none",
  },

  "&.Mui-checked": {
    backgroundColor: "white",
    borderColor: "black",

    "&:after": {
      content: '""',
      position: "absolute",
      width: "16px",
      height: "16px",
      borderRadius: "2px",
      backgroundColor: "black",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      border: "none",
    },
  },
  "&:hover": {
    backgroundColor: "white",
    borderColor: "black",

    "&:after": {
      content: '""',
      position: "absolute",
      width: "16px",
      height: "16px",
      borderRadius: "2px",
      backgroundColor: "black",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      border: "none",
    },
  },
}));

const PublisherSection = ({
  values,
  errors,
  touched,
  setFieldValue,
  IPRSandPPL, // Assuming you have this options array for the dropdown
}) => (
  <FieldArray name="publisher">
    {({ remove, push }) => (
      <Box sx={{ width: "100%" }}>
        {Array.isArray(values.publisher) &&
          values.publisher.map((item, index) => (
            <Grid key={index} container alignItems="center" spacing={2}>
              {/* Publisher Label */}
              <Grid
                item
                xs={3}
                sx={{
                  textAlign: "right",
                  fontSize: "16px",
                  lineHeight: 1.56,
                  color: "#b1b1b1",
                  paddingRight: "30px",
                }}
              >
                {index === 0 && <Typography>Publisher *</Typography>}
              </Grid>

              {/* Publisher Name and Performing Rights */}
              <Grid item xs={9}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Publisher Name Field */}
                  <Grid item xs={4}>
                    <Tooltip
                      title="Enter the publisher name"
                      arrow
                      placement="right-end"
                    >
                      <FastField
                        as={CustomTextField}
                        variant="outlined"
                        placeholder="Publisher Name"
                        id={`publisher[${index}].name`}
                        size="small"
                        name={`publisher[${index}].name`}
                        error={
                          Boolean(
                            errors.publisher && errors.publisher[index]?.name
                          ) &&
                          Boolean(
                            touched.publisher && touched.publisher[index]?.name
                          )
                        }
                        sx={{
                          width: "196px",
                          height: "30px",
                          borderRadius: "3px",
                          backgroundColor: "#b4b3b3",
                          "& .MuiOutlinedInput-root": {
                            height: "30px",
                          },
                        }}
                      />
                    </Tooltip>
                    <ErrorMessage name={`publisher[${index}].name`} />
                  </Grid>

                  {/* Performing Rights Dropdown */}
                  <Grid item xs={7}>
                    <Tooltip
                      title="Enter performing rights organization"
                      arrow
                      placement="right-end"
                    >
                      <SelectField
                        name={`publisher[${index}].rights`}
                        options={IPRSandPPL}
                        size="small"
                        placeholder="Performing Rights"
                        title="Select performing rights organization"
                        width="343px"
                        sx={{
                          width: "343px",
                          height: "30px",
                          borderRadius: "3px",
                          backgroundColor: "#b4b3b3",
                        }}
                      />
                    </Tooltip>
                    <ErrorMessage name={`publisher[${index}].rights`} />
                  </Grid>

                  {/* Remove Button */}
                  <Grid item xs={1}>
                    {values.publisher.length > 1 && (
                      <IconButton
                        sx={{
                          width: "29px",
                          height: "28px",
                          backgroundColor: "#343434",
                          borderRadius: "3px",
                          color: "#898989",
                          fontSize: "25px",
                        }}
                        onClick={() => remove(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ErrorMessage name={`publisher[${index}]`} />
              </Grid>
            </Grid>
          ))}
        {/* Add Publisher Button */}
        <Grid
          container
          item
          xs={12}
          mt={1}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => push({ name: "", rights: "" })}
          >
            <AddCircleIcon
              sx={{
                marginRight: "5px",
                color: "#7c7c7c",
                lineHeight: 2.08,
                fontSize: "14px",
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                color: "#7c7c7c",
                lineHeight: 2.08,
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Add Publisher
            </Typography>
          </IconButton>
        </Grid>
      </Box>
    )}
  </FieldArray>
);

const options = [
  { id: "composer", name: "Composer" },
  { id: "writer", name: "Writer" },
];

const TextandDropdownComponent = ({
  name,
  label,
  required,
  placeholder,
  values,
  errors,
  touched,
  title,
  disabled,
  setFieldValue,
}) => (
  <FieldArray name={name}>
    {({ remove, push }) => (
      <Box sx={{ width: "100%" }}>
        {Array.isArray(values[name]) &&
          values[name].map((item, index) => (
            <Grid key={index} container alignItems="center" spacing={2}>
              <Grid
                item
                xs={3}
                sx={{
                  textAlign: "right",
                  fontSize: "16px",
                  lineHeight: 1.56,
                  color: "#b1b1b1",
                  paddingRight: "30px",
                }}
              >
                {index === 0 && <Typography>{label}</Typography>}
              </Grid>
              <Grid item xs={9}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={7}>
                    <Tooltip title={title} arrow placement="right-end">
                      <Field
                        as={CustomTextField}
                        variant="outlined"
                        placeholder={
                          name === "author" && values.instrumental === "Yes"
                            ? "Disabled"
                            : "Enter here"
                        }
                        id={`${name}[${index}]`}
                        size="small"
                        name={`name.${index}.name`}
                        disabled={disabled}
                        onChange={(e) => {
                          setFieldValue(
                            `${name}.${index}.name`,
                            e.target.value
                          );
                        }}
                        sx={{
                          width: "333px",
                          height: "30px",
                          borderRadius: "3px",
                          backgroundColor: "#b4b3b3",
                          "& .MuiOutlinedInput-root": {
                            height: "30px",
                          },
                        }}
                        error={Boolean(errors && errors.hasOwnProperty(name))}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Tooltip title={title} arrow placement="right-end">
                      <Field
                        // name={`${name}[${index}]${["category"]}`}
                        name={`name.${index}.category`}
                        size="small"
                        component={AutoCompleteMultiSelect}
                        disableClearable
                        fieldheight="30px"
                        options={options}
                        filterOptions={customFilterOptionslyrics}
                        // isOptionEqualToValue={(option, value) =>
                        //   option.id === value.id
                        // }
                        margin={0}
                        onChange={(e, value) => {
                          setFieldValue(
                            `${name}.${index}.category`,
                            value?.id || ""
                          ); // Set category
                        }}
                        getOptionLabel={(option) => option.name}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder,
                        }}
                        disabled={disabled}
                        sx={{
                          width: "186px",
                          height: "30px",
                          borderRadius: "3px",
                          backgroundColor: "#b4b3b3",
                          "& .MuiOutlinedInput-root": {
                            height: "30px",
                          },
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={0.5} sx={{ textAlign: "right" }}>
                    {values[name].length > 1 && (
                      <IconButton
                        sx={{
                          width: "29px",
                          height: "28px",
                          backgroundColor: "#343434",
                          borderRadius: "3px",
                          color: "#898989",
                          fontSize: "25px",
                        }}
                        onClick={() => remove(index)}
                      >
                        <Close />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ErrorMessage name={`${name}[${index}]`} />
              </Grid>
            </Grid>
          ))}
        {values[name]?.length > 0 && (
          <Grid
            container
            item
            lg={11.5}
            md={11.5}
            sm={11.5}
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            px={1}
          >
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => push("")}
            >
              <AddCircleIcon
                sx={{
                  marginRight: "5px",
                  color: "#7c7c7c",
                  lineHeight: 2.08,
                  fontSize: "14px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#7c7c7c",
                  lineHeight: 2.08,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Add {label}
              </Typography>
            </IconButton>
          </Grid>
        )}
      </Box>
    )}
  </FieldArray>
);

const ISRCOptions = [
  { value: "No", label: "Enter an ISRC" },
  { value: "Yes", label: "Generate an ISRC" },
];

const ISRCSection = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleKeyPress,
}) => (
  <Grid container alignItems="center">
    <Grid item xs={12}>
      <Grid container alignItems={"center"}>
        <Grid
          item
          xs={3}
          sx={{
            textAlign: "right",
            fontSize: "16px",
            lineHeight: 1.56,
            color: "#b1b1b1",
            paddingRight: "30px",
          }}
        >
          <Typography>ISRC</Typography>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <FastField name="ask_to_generate_an_isrc">
                {({ field, meta }) => (
                  <Tooltip title="Choose an option" arrow placement="right-end">
                    <div>
                      <Select
                        input={<OutlinedInput {...field} />}
                        displayEmpty
                        size="small"
                        style={{
                          width: "196px",
                          height: "30px",
                          borderRadius: "3px",
                          backgroundColor: "#b4b3b3",
                          color: "#000",
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: "250px",
                              width: 196,
                            },
                          },
                        }}
                        error={meta.touched && meta.error ? true : false}
                        onChange={(event) => {
                          setFieldValue(
                            "ask_to_generate_an_isrc",
                            event.target.value
                          );
                          if (event.target.value === "generate") {
                            setFieldValue("isrc", ""); // Clear ISRC field if generating
                          }
                        }}
                      >
                        {ISRCOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            style={{
                              maxWidth: 250,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              color: "#000", // Same style as in TwoSelectField
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Tooltip>
                )}
              </FastField>
            </Grid>
            <Grid item xs={7}>
              <Tooltip
                title={
                  values.ask_to_generate_an_isrc === "generate"
                    ? "ISRC field is disabled as the system will generate it"
                    : "Enter valid ISRC (International Standard Recording Code)"
                }
                arrow
                placement="right-end"
              >
                <div>
                  <FastField
                    as={CustomTextField}
                    variant="outlined"
                    placeholder="ISRC"
                    id="isrc"
                    size="small"
                    name="isrc"
                    disabled={values.ask_to_generate_an_isrc === "generate"}
                    className="col-12"
                    onKeyPress={handleKeyPress}
                    sx={{
                      height: "30px",
                      borderRadius: "3px",
                      backgroundColor: "#b4b3b3",
                      "& .MuiOutlinedInput-root": {
                        height: "30px",
                      },
                    }}
                  />
                </div>
              </Tooltip>
              &nbsp;
              <ErrorMessage name="isrc" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
