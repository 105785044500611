import AccountVerifiedEffect from "./AccountVerifiedEffect";
import ActionUtility from "../../utilities/ActionUtility";
import AccountVerifiedModel from "./models/AccountVerifiedModel";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";

export default class AccountVerifiedAction {
  static REQUEST_ACCOUNTVERIFIED = "AuthAction.REQUEST_ACCOUNTVERIFIED";
  static REQUEST_ACCOUNTVERIFIED_FINISHED =
    "AuthAction.REQUEST_ACCOUNTVERIFIED_FINISHED";

  static requestaccountverified(obj, callBackFunction, params, headers) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AccountVerifiedAction.REQUEST_ACCOUNTVERIFIED,
        AccountVerifiedEffect.requestaccountverified,
        obj,
        callBackFunction,
        params,
        headers
      );
    };
  }

  static InitAccountVerified() {
    return async (dispatch) => {
      if (localStorage.getItem("Auth") != null) {
        let token = localStorage.getItem("Auth");

        const model = new AccountVerifiedModel(JSON.parse(token));
        const isError = model instanceof HttpErrorResponseModel;
        dispatch(
          ActionUtility.createAction(
            `${AccountVerifiedAction.REQUEST_ACCOUNTVERIFIED}_FINISHED`,
            model,
            isError
          )
        );

        return model;
      }
    };
  }
}
