import React from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, IconButton, Typography } from "@mui/material";

const ReportContainer = ({
  name,
  id,
  setDetailReportId,
  setDetailReportOpen,
  children,
  overflowY = false,
  className,
}) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", // Align the name to the left and the IconButton to the right
          alignItems: "center",
          paddingTop: { xl: "23px", lg: "12px", laptop: "12px" },
          paddingRight: { xl: "28px", lg: "13px", laptop: "13px" },
        }}
      >
        <Typography
          sx={{
            lineHeight: { xl: "36px", laptop: "20px", lg: "20px" },
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 2,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#e2e2e2",
            fontSize: {
              xl: "18px", // for extra large screens
              laptop: "10px", // for laptop screens
              lg: "10px", // for large screens
            },
          }}
        >
          {name}
        </Typography>

        <IconButton
          onClick={() => {
            setDetailReportOpen(true);
            setDetailReportId(id);
          }}
          title="Maximize"
        >
          {/* <FullscreenIcon fontSize="medium" /> */}
          <FullscreenIcon
            sx={{ fontSize: { lg: "10px", laptop: "10px", xl: "18px" } }}
          />
        </IconButton>
      </Box>
      <Box
        className={className}
        sx={{
          flex: 1,
          overflowY: overflowY ? "auto" : null,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ReportContainer;
