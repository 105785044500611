import environment from "./base";

const backendDomain = "https://a3diyapi.clikbite.com";
const apiVersion = "/api/v1";
const baseApi = backendDomain + apiVersion;
const socketApi = "a3labelsocket.clikbite.com";
const a3TunesApi = "https://stagingtunes.clikbite.com";

const env = environment({ baseApi , socketApi, a3TunesApi });


const productionEnv = {
  ...env,
  // override anything that gets added from base.
  api: {
    ...env.api,
    // error200: `${baseApi}/api/v1/error-200`,
    // error500: `${baseApi}/api/v1/error-500`,
  },
  isProduction: false,
  isDevelopment: true,
};
export default productionEnv;
