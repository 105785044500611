import ActionUtility from "../../utilities/ActionUtility";
import LabelEffect from "./LabelEffect";

export default class LabelAction {
  // LabelAction action with an function declaration
  static REQUEST_LABELS = "LabelAction.REQUEST_LABELS";
  static REQUEST_LABELS_FINISHED = "LabelAction.REQUEST_LABELS_FINISHED";

  static REQUEST_LABELS_FILTER = "LabelAction.REQUEST_LABELS_FILTER";
  static REQUEST_LABELS_FILTER_FINISHED =
    "LabelAction.REQUEST_LABELS_FILTER_FINISHED";

  static REQUEST_PUT_LABELS = "LabelAction.REQUEST_PUT_LABELS";
  static REQUEST_PUT_LABELS_FINISHED =
    "LabelAction.REQUEST_PUT_LABELS_FINISHED";

  static REQUEST_POST_LABELS = "LabelAction.REQUEST_POST_LABELS";
  static REQUEST_POST_LABELS_FINISHED =
    "LabelAction.REQUEST_POST_LABELS_FINISHED";

  static REQUEST_LABELS_CLEAR = "LabelAction.REQUEST_LABELS_CLEAR";
  static REQUEST_LABELS_CLEAR_FINISHED =
    "LabelAction.REQUEST_LABELS_CLEAR_FINISHED";

  static REQUEST_LABELS_FILTER_CLEAR =
    "LabelAction.REQUEST_LABELS_FILTER_CLEAR";
  static REQUEST_LABELS_FILTER_CLEAR_FINISHED =
    "LabelAction.REQUEST_LABELS_FILTER_CLEAR_FINISHED";

  // METHODS
  // labels GET function
  static requestLabel(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LabelAction.REQUEST_LABELS,
        LabelEffect.requestLabel,
        params
      );
    };
  }

  static requestLabelClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LabelAction.REQUEST_LABELS_CLEAR,
        LabelEffect.requestLabelClear
      );
    };
  }

  static requestLabelFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LabelAction.REQUEST_LABELS_FILTER_CLEAR,
        LabelEffect.requestLabelFilterClear
      );
    };
  }

  static requestLabelFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LabelAction.REQUEST_LABELS_FILTER,
        LabelEffect.requestLabelFilter,
        params
      );
    };
  }
  static requestPutLabel(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LabelAction.REQUEST_PUT_LABELS,
        LabelEffect.requestPutLabel,
        data,
        id,
        params
      );
    };
  }

  static requestPostLabel(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LabelAction.REQUEST_POST_LABELS,
        LabelEffect.requestPostLabel,
        data,
        params,
        callback
      );
    };
  }
}
