import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import RequestingReducer from "./requesting/RequestingReducer";
import ErrorReducer from "./error/ErrorReducer";
import ToastsReducer from "./toasts/ToastsReducer";
import ArtistFilterReducer from "../stores/artist/ArtistFilterReduser";
import ReleasesShadowTableReduser from "./releasesShadowTable/ReleasesShadowTableReduser";
import ActorReducer from "./actor/ActorReducer";
import ActorFilterReducer from "./actor/ActorFilterReducer";
import ActressReducer from "./actress/ActressReducer";
import ActressFilterReducer from "./actress/ActressFilterReducer";
import DirectorReducer from "./director/DirectorReducer";
import DirectorFilterReducer from "./director/DirectorFilterReducer";
import ArtistReduser from "../stores/artist/ArtistReduser";
import ArtistFilterReduser from "../stores/artist/ArtistFilterReduser";
import ClientFilterReduser from "./client/ClientFilterReduser";
import GenreReduser from "./genre/GenreReduser";
import GenreFilterReduser from "./genre/GenreFilterReduser";
import PlanReduser from "./plan/PlanReduser";
import PlanFilterReduser from "./plan/PlanFilterReduser";
import ReleaseByIdReducer from "./releases/ReleaseByIdReducer";
import ExistingTracksReducer from "./releases/ExistingTracksReducer.js";
import SubGenreReducer from "./subgenre/SubGenreReducer";
import SubGenreFilterReducer from "./subgenre/SubGenreFilterReducer";
import LanguageReduser from "./language/LanguageReduser";
import TrackReduser from "./track/TrackReduser";
import TrackFilterReduser from "./track/TrackFilterReduser";
import ReleasesReducer from "./releases/ReleasesReducer.js";
import ReleasesFilterReducer from "./releases/ReleasesFilterReducer.js";
import StatusReduser from "./status/StatusReduser.js";
import StatusFilterReduser from "./status/StatusFilterReduser.js";
import LabelReuser from "./label/LabelReuser.js";
import LabelFilterReduser from "./label/LabelFilterReduser.js";
import AssetsFilterReduser from "./assets/AssetsFilterReduser";
import AssetsReduser from "./assets/AssetsReduser";
import ValidationByIdReducer from "./validation/ValidationByIdReducer";
import DraftReleaseReduser from "./releases/DraftReleaseReduser.js";
import FinalReleasesReducer from "./releases/FinalReleasesReducer.js";
import FinalReleaseByIdReducer from "./releases/FinalReleaseByIdReducer.js";
import AuthReducer from "./Auth/AuthReducer.js";
import StreamPlatformReduser from "./streamPlatform/StreamPlatformReduser.js";
import StreamPlatformFilterReduser from "./streamPlatform/StreamPlatformFilterReduser.js";
import TerritoriesReduser from "./territories/TerritoriesReduser.js";
import SubscriptionPlansReduser from "./subscriptionPlans/SubscriptionPlansReduser.js";
import SubscriptionReducer from "./subscriptions/SubscriptionReducer.js";
import SubscriptionOrderReducer from "./subscriptionOrders/SubscriptionOrderReducer.js";
import UserProfileReducer from "./userprofile/UserProfileReducer.js";
import MailSubscriptionReduser from "./userprofile/MailSubscriptionReduser.js";
import NotificationSubscriptionReduser from "./userprofile/NotificationSubscriptionReduser.js";
import NotificationReducer from "./notifications/NotificationReducer";
import NotificationsByIdReducer from "./notifications/NotificationsByIdReducer";
import NotificationFilterReducer from "./notifications/NotificationFilterReducer";
import TopStoreReduser from "./topStoresReport/TopStoreReduser.js";
import TopLabelReducer from "./topLabelReport/TopLabelReducer.js";
import TopLabelFilterReducer from "./topLabelReport/TopLabelFilterReducer.js";
import TopTerritoriesReducer from "./TopTerritories/TopTerritoriesReducer.js";
import TopTerritoryReduser from "./TopTerritories/TopTerritoryReduser.js";
import AudioStreamsReduser from "./topStoresReport/AudioStreamsReduser.js";
import TopDevicesReduser from "./topDevicesRport/TopDevicesReduser.js";
import TopTrackReduser from "./topTrackReport/TopTrackReduser.js";
import TopTrackWithDateFilterReducer from "./topTrackReport/TopTrackWithDateFilterReducer.js";
import SubscriptionBasePlansReduser from "./subscriptionPlans/SubscriptionBasePlansReduser.js";
import TopAlbumReducer from "./topAlbum/TopAlbumReducer";
import TopAlbumFilterReducer from "./topAlbum/TopAlbumFilterReducer";
import TopLyricistReducer from "./topLyricist/TopLyricistReducer";
import TopLyricistFilterReducer from "./topLyricist/TopLyricistFilterReducer";
import TopReleaseReduser from "./topReleaseReport/TopReleaseReduser";
import TopArtistReducer from "./topArtistReport/TopArtistReducer";
import TopArtistFilterReducer from "./topArtistReport/TopArtistFilterReducer";
import TopLanguagesReduser from "./topLanguages/TopLanguagesReduser";
import TopLanguagesFilterReduser from "./topLanguages/TopLanguagesFilterReduser";
import TopComposerReducer from "./topComposer/TopComposerReducer";
import TopComposerFilterReducer from "./topComposer/TopComposerFilterReducer";
import TicketsReduser from "./tickets/TicketsReducer.js";
import ReleasesShadowTableIdReduser from "./releasesShadowTable/ReleasesShadowTableIdReduser";
import ExistingTrackReduser from "./track/ExistingTrackReduser.js";
import TrackByIdReduser from "./track/TrackByIdReduser";
import TopRetailerReduser from "./topStoresReport/TopRetailerReduser";
import TopFiveDeliveredReleasesReduser from "./releases/TopFiveDeliveredReleasesReduser.js";
import A3ReportsStorewiseReducer from "./reportsA3/A3ReportsStorewiseReducer.js";
import TrackwiseRevenueReduser from "./netRevenueReportByMonth/TrackwiseRevenueReduser.js";
import A3ReportsCountrywiseReducer from "./reportsA3/A3ReportsCountrywiseReducer.js";
import A3LabelRevenueReportReducer from "./reportsA3/A3LabelRevenueReportReducer.js";
import ReleasewiseRevenueReduser from "./netRevenueReportByMonth/ReleasewiseRevenueReduser.js";
import ArtistwiseRevenueReduser from "./netRevenueReportByMonth/ArtistwiseRevenueReduser.js";
import RevenueReportReducer from "./revenueReport/RevenueReportReducer.js";
import RevenueReportFilterReducer from "./revenueReport/RevenueReportFilterReducer.js";
import FilterRevenueReportReducer from "./revenueReport/FilterRevenueReportReducer.js";
import RevenueReportExportReducer from "./revenueReport/RevenueReportExportReducer.js";
import SubscriptionBasePlansFilterReduser from "./subscriptionPlans/SubscriptionBasePlansFilterReduser.js";
import TrackwiseRevenueSummaryReducer from "./trackRevenue/TrackwiseRevenueSummaryReducer.js";
import RevenueClaimFilterReducer from "./revenueClaim/RevenueClaimFilterReducer.js";
import ClientRevenueBalanceReducer from "./revenueClaim/ClientRevenueBalanceReducer.js";
import RevenueClaimReducer from "./revenueClaim/RevenueClaimReducer.js";
import NetRevenueByMonthReducer from "./netRevenueReportByMonth/NetRevenueByMonthReducer.js";
import NetRevenueByMonthFilterReducer from "./netRevenueReportByMonth/NetRevenueByMonthFilterReducer.js";
import UserProfilePercentageReducer from "./userprofile/UserProfilePercentageReducer.js";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) => {
  const reducerMap = {
    error: ErrorReducer.reducer,
    requesting: RequestingReducer.reducer,
    router: connectRouter(history),

    auth: new AuthReducer().reducer,

    //actor
    actor: new ActorReducer().reducer,
    actorFilter: new ActorFilterReducer().reducer,

    //actress
    actress: new ActressReducer().reducer,
    actressFilter: new ActressFilterReducer().reducer,

    //director
    director: new DirectorReducer().reducer,
    directorFilter: new DirectorFilterReducer().reducer,

    artist: new ArtistReduser().reducer,
    artistFilter: new ArtistFilterReduser().reducer,
    client: new ClientFilterReduser().reducer,

    revenueClaim: new RevenueClaimReducer().reducer,
    netRevenueByMonth: new NetRevenueByMonthReducer().reducer,

    clientFilter: new ClientFilterReduser().reducer,
    revenueClaimFilter: new RevenueClaimFilterReducer().reducer,
    clientRevenueBalance: new ClientRevenueBalanceReducer().reducer,

    // assets
    assets: new AssetsReduser().reducer,
    assetsFilter: new AssetsFilterReduser().reducer,

    //genre
    genre: new GenreReduser().reducer,
    genreFilter: new GenreFilterReduser().reducer,

    storewiseRevenueReport: new A3ReportsStorewiseReducer().reducer,
    trackwiseRevenue: new TrackwiseRevenueReduser().reducer,
    countrywiseRevenueReport: new A3ReportsCountrywiseReducer().reducer,
    labelRevenueReport: new A3LabelRevenueReportReducer().reducer,
    releasewiseRevenue: new ReleasewiseRevenueReduser().reducer,
    artistwiseRevenue: new ArtistwiseRevenueReduser().reducer,

    netRevenueByMonthFilter: new NetRevenueByMonthFilterReducer().reducer,

    //plan
    plan: new PlanReduser().reducer,
    planFilter: new PlanFilterReduser().reducer,

    //stream_platform
    stream_platform: new StreamPlatformReduser().reducer,
    stream_platform_filter: new StreamPlatformFilterReduser().reducer,

    //subgenre
    subgenre: new SubGenreReducer().reducer,
    subgenreFilter: new SubGenreFilterReducer().reducer,

    language: new LanguageReduser().reducer,

    toasts: new ToastsReducer().reducer,

    release: new ReleasesReducer().reducer,
    draftRelease: new DraftReleaseReduser().reducer,
    releaseFilter: new ReleasesFilterReducer().reducer,
    releaseById: new ReleaseByIdReducer().reducer,
    releaseById2: new ExistingTracksReducer().reducer,
    finalReleases: new FinalReleasesReducer().reducer,
    finalReleaseById: new FinalReleaseByIdReducer().reducer,
    validateReleaseById: new ValidationByIdReducer().reducer,

    revenueReport: new RevenueReportReducer().reducer,
    revenueReportFilter: new RevenueReportFilterReducer().reducer,
    filterRevenueReport: new FilterRevenueReportReducer().reducer,
    revenueReportExport: new RevenueReportExportReducer().reducer,
    trackwiseRevenueSummary: new TrackwiseRevenueSummaryReducer().reducer,

    //status
    status: new StatusReduser().reducer,
    statusFilter: new StatusFilterReduser().reducer,

    //ORM notifications
    Notifications: new NotificationReducer().reducer,
    NotificationsById: new NotificationsByIdReducer().reducer,
    NotificationsFilter: new NotificationFilterReducer().reducer,

    //label
    label: new LabelReuser().reducer,
    labelFilter: new LabelFilterReduser().reducer,
    //top retailer
    topRetailer: new TopRetailerReduser().reducer,

    territories: new TerritoriesReduser().reducer,
    subscriptionPlan: new SubscriptionPlansReduser().reducer,
    subscriptionBasePlan: new SubscriptionBasePlansReduser().reducer,
    subscriptionBasePlanFilter: new SubscriptionBasePlansFilterReduser()
      .reducer,

    subscription: new SubscriptionReducer().reducer,
    subscriptionOrder: new SubscriptionOrderReducer().reducer,
    userprofile: new UserProfileReducer().reducer,
    uprofile_percentage: new UserProfilePercentageReducer().reducer,

    mail_subsription: new MailSubscriptionReduser().reducer,
    notificationSubscription: new NotificationSubscriptionReduser().reducer,
    tickets: new TicketsReduser().reducer,

    topStore: new TopStoreReduser().reducer,

    topLabel: new TopLabelReducer().reducer,
    topLabelFilter: new TopLabelFilterReducer().reducer,

    top_territories_report: new TopTerritoriesReducer().reducer,
    top_territory: new TopTerritoryReduser().reducer,
    audioStreams: new AudioStreamsReduser().reducer,

    top_device_type_report: new TopDevicesReduser().reducer,
    //releasesShadowTable
    releasesShadowTable: new ReleasesShadowTableReduser().reducer,
    releasesShadowTableById: new ReleasesShadowTableIdReduser().reducer,
    //top album
    top_albums_report: new TopAlbumReducer().reducer,
    top_albums_report_filter: new TopAlbumFilterReducer().reducer,
    //Top Track reports
    topTrack: new TopTrackReduser().reducer,
    topTrack_with_date_filter: new TopTrackWithDateFilterReducer().reducer,

    //top lyricist
    top_lyricists_report: new TopLyricistReducer().reducer,
    top_lyricists_report_filter: new TopLyricistFilterReducer().reducer,

    //Top Releases reports
    topRelease: new TopReleaseReduser().reducer,
    //TopArtistReport
    topArtist: new TopArtistReducer().reducer,
    topArtistFilter: new TopArtistFilterReducer().reducer,

    // top languges
    top_languages_report: new TopLanguagesReduser().reducer,
    top_territories_report_filter: new TopLanguagesFilterReduser().reducer,
    //top composer
    top_composers_report: new TopComposerReducer().reducer,
    top_composer_report_filter: new TopComposerFilterReducer().reducer,

    //tracks
    track: new TrackReduser().reducer,
    trackFilter: new TrackFilterReduser().reducer,
    trackById: new TrackByIdReduser().reducer,
    existingTracks: new ExistingTrackReduser().reducer,
    top5Delivered: new TopFiveDeliveredReleasesReduser().reducer,

    // trackRevenue: new TrackRevenueReducer().reducer,
  };

  return combineReducers(reducerMap);
};
