import BaseReducer from "../../utilities/BaseReducer";
import StreamPlatformAction from "./StreamPlatformAction";

export default class StreamPlatformFilterReduser extends BaseReducer {
  //initial state of StreamPlatformFilterReduser
  initialState = {
    stream_platform_filter: [],
  };
  //StreamPlatformFilterReduser request action finish
  [StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      stream_platform_filter: action.payload,
    };
  }

  [StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      stream_platform_filter: [],
    };
  }
}
