import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import backgroundLogo from "../../Images/A3Tunes.webp";

function LoadingBgImage() {
  const [opacity, setOpacity] = useState(0.2);
  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity((prevOpacity) => (prevOpacity === 0.2 ? 0.5 : 0.2));
    }, 500); // Adjust the duration of the animation (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 30,
      }}
    >
      <img
        src={backgroundLogo}
        alt=""
        style={{
          width: 350,
          opacity: opacity,
          transition: "opacity 0.5s ease-in-out",
        }}
      />
    </Box>
  );
}

export default LoadingBgImage;
