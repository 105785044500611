import React, { useEffect, useRef, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  BackGroundContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../componentLayout/ComponentLayout";
import { makeStyles } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import NotificationAction from "../../stores/notifications/NotificationAction";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import CircularLoadingIndicator from "../loading-indicator/CircularLoadingIndicator";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router";
import NotificationDialog from "../../views/notification/NotificationDialog";
import { theme } from "../../views/App";

const useStyles = makeStyles({
  gridContainer: {
    height: 250, // set the height of the grid container
    overflow: "auto", // add scrollbars when necessary
    "&::-webkit-scrollbar": {
      display: "none", // hide the scrollbar for webkit-based browsers (e.g. Chrome, Safari)
    },
    "-ms-overflow-style": "none", // hide the scrollbar for Internet Explorer and Edge
    "scrollbar-width": "none", // hide the scrollbar for Firefox and other browsers that support the new scrollbar style
  },
});

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    NotificationAction.REQUEST_FILTER_NOTIFICATIONS,
    NotificationAction.REQUEST_NOTIFICATIONS_BY_ID,
  ]),
  NotificationsFilter: state.NotificationsFilter.NotificationsFilter || [],
});

function NotificationBox(props) {
  const [open, setOpen] = React.useState(false);
  const notificationPopperRef = React.useRef(null);
  const [notificationsUnread, setNotifictionsUnread] = useState(
    props.count ? props.count : 0
  );

  const scrollRef = useRef(null);
  const prevOpen = React.useRef(open);

  const classes = useStyles();
  const navigate = useNavigate();

  let { isRequesting } = props;

  const [notificationsData, setNotificationData] = useState({});
  const [openNotificationDialog, setOpenNotificationsDialog] = useState(false);

  useEffect(() => {
    if (open) fetchNotifications();
  }, [open]);

  function fetchNotifications() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(NotificationAction.requestFilterNotifications(params));
  }

  const handleNotificationPopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    setNotifictionsUnread(props.count);
  }, [props.count]);

  function UpdateNotificationCount() {
    props.setNewNotificationsCount(props.count - 1);
  }

  const handleClose = (event) => {
    if (notificationPopperRef?.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen?.current === true && open === false) {
      notificationPopperRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const container = scrollRef.current;
    container?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function returnLocalTime(time) {
    let timeConversion = new Date(time);
    timeConversion =
      timeConversion.toDateString() +
      ", " +
      timeConversion.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

    return timeConversion;
  }

  return (
    <Grid item container xs={6}>
      <NotificationDialog
        open={openNotificationDialog}
        onClose={() => {
          setOpenNotificationsDialog(false);
        }}
        notificationsData={notificationsData}
      />
      <Typography
        component={"div"}
        ref={notificationPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleNotificationPopper}
        style={{ backgroundColor: "none" }}
      >
        <Badge
          sx={{
            "& .MuiBadge-badge": {
              minWidth: "0px",
              width: { xl: 19, lg: 10, laptop: 10 }, // badge size
              height: { xl: 19, lg: 10, laptop: 10 }, // badge size
              fontSize: { xl: "16px", lg: "6px", laptop: "6px" }, // control font size
              backgroundColor: "#f0142f",
            },
          }}
          badgeContent={props.count}
        >
          <NotificationsIcon
            sx={{
              width: { xl: 27, lg: 16, laptop: 16 },
              height: { xl: 27, lg: 16, laptop: 16 },
            }}
          />
        </Badge>
      </Typography>

      <Popper
        open={open}
        anchorEl={notificationPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper style={{ width: 350 }}>
              {props.NotificationsFilter &&
              props.NotificationsFilter.hits?.hits &&
              props.NotificationsFilter.hits.hits.length ? (
                <ShadowContainer>
                  {!isRequesting ? (
                    <ClickAwayListener onClickAway={handleClose}>
                      <div
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                      >
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          justifyContent="flex-end"
                          sx={{ position: "sticky", top: 0, mb: 2 }}
                        >
                          <Typography
                            component={"span"}
                            onClick={() => {
                              navigate("/notifications");
                              setOpen(false);
                            }}
                            sx={{
                              color: theme.palette.text.primary,
                              "&:hover": {
                                textDecoration: "underline",
                                fontWeight: "bold",
                              },
                            }}
                          >
                            View All
                          </Typography>
                        </Grid>
                        {props?.NotificationsFilter?.hits?.hits?.filter(
                          (item) => {
                            return (
                              item?._source?.notification_msg_read_status ===
                              false
                            );
                          }
                        ).length !== 0 ? (
                          <Grid
                            container
                            xs={12}
                            gap={1}
                            className={`${classes.gridContainer} `}
                            ref={scrollRef}
                          >
                            {props?.NotificationsFilter?.hits?.hits
                              ?.filter((item) => {
                                return (
                                  item?._source
                                    ?.notification_msg_read_status === false
                                );
                              })
                              .map((notification, ind) => (
                                <Grid item key={ind} xs={12}>
                                  <div
                                    onClick={() => {
                                      setNotificationData({
                                        id: notification._id,
                                        created_by:
                                          notification?._source.created_by,
                                        created_on:
                                          notification?._source.created_on,
                                        modified_by:
                                          notification?._source.modified_by,
                                        modified_on:
                                          notification?._source.modified_on,
                                        notification_image_url:
                                          notification?._source
                                            .notification_image_url,
                                        notification_msg:
                                          notification?._source
                                            .notification_msg,
                                        notification_msg_read_status:
                                          notification?._source
                                            .notification_msg_read_status,
                                        notification_sent:
                                          notification?._source
                                            .notification_sent,
                                        notification_title:
                                          notification?._source
                                            .notification_title,
                                        notification_type:
                                          notification?._source
                                            .notification_type,
                                      });
                                      setOpenNotificationsDialog(true);
                                      let post_data = {
                                        notification_msg_read_status: true,
                                      };
                                      notification?._source
                                        .notification_msg_read_status ===
                                        false &&
                                        props
                                          .dispatch(
                                            NotificationAction.requestPutNotifications(
                                              JSON.stringify(post_data),
                                              notification._id
                                            )
                                          )
                                          .then(() => {
                                            UpdateNotificationCount();
                                          });
                                    }}
                                  >
                                    {notification?._source
                                      .notification_msg_read_status ? (
                                      <ModelScreenContainer>
                                        <p>
                                          Title:{" "}
                                          {
                                            notification._source
                                              .notification_title
                                          }
                                        </p>
                                        <p>
                                          Message:{" "}
                                          {
                                            notification._source
                                              .notification_msg
                                          }
                                        </p>
                                        <p>
                                          {returnLocalTime(
                                            notification._source.created_on
                                          )}
                                        </p>
                                      </ModelScreenContainer>
                                    ) : (
                                      <BackGroundContainer>
                                        <p>
                                          <span style={{ fontWeight: "bold" }}>
                                            Title:{" "}
                                          </span>
                                          {
                                            notification._source
                                              .notification_title
                                          }
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "bold" }}>
                                            Message:{" "}
                                          </span>
                                          {
                                            notification._source
                                              .notification_msg
                                          }
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "bold" }}>
                                            {returnLocalTime(
                                              notification._source.created_on
                                            )}
                                          </span>
                                        </p>
                                      </BackGroundContainer>
                                    )}
                                  </div>
                                </Grid>
                              ))}
                          </Grid>
                        ) : (
                          <Typography
                            component={"span"}
                            sx={{ fontSize: "18px" }}
                          >
                            <hr
                              className="p-0 mb-4"
                              style={{
                                color: theme.palette.primary.main,
                                marginBottom: theme.spacing(2),
                              }}
                            />
                            No Notifications
                          </Typography>
                        )}
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <CircularLoadingIndicator isActive={isRequesting} />
                  )}
                </ShadowContainer>
              ) : !isRequesting ? (
                <ShadowContainer sx={{ width: "100%" }}>
                  <Typography component={"span"} sx={{ color: "white" }}>
                    No Notifications
                  </Typography>
                </ShadowContainer>
              ) : (
                <CircularLoadingIndicator isActive={isRequesting} />
              )}
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapStateToProps)(NotificationBox);
