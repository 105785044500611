import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { theme } from "../../App";
import { makeStyles } from "@material-ui/core";
import MuiButton from "../../../components/muiButton/MuiButton";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import UserProfileAction from "../../../stores/userprofile/UserProfileAction";
import SubscriptionPlansAction from "../../../stores/subscriptionPlans/SubscriptionPlansAction";
import { useNavigate } from "react-router";
import ReleasedDetails from "./ReleasedDetails";
import { Warning } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS,
    SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS_FILTER,
    UserProfileAction.REQUEST_USERPROFILE,
  ]),
  subscriptionBasePlan: state.subscriptionBasePlan.subscriptionBasePlan || [],
  subscriptionBasePlanFilter:
    state.subscriptionBasePlanFilter.subscriptionBasePlanFilter || [],
  userprofile: state.userprofile.userprofile || [],
});

const BasicDashboard = (props) => {
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));
  const getToolbarStyles = () => {
    if (isXL) {
      return { paddingLeft: "100px", textTransform: "uppercase" };
    } else {
      return { paddingLeft: "54px", textTransform: "uppercase" };
    }
  };
  console.log("props", props);
  const useStyles = makeStyles(() => ({
    cardContainer: {
      width: 64,
      height: 64,
    },
    cardWrapper: {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      backgroundColor: "transparent",
      border: ` solid 1px #676869`,
      objectFit: "contain",
      backgroundPosition: "contain",
      backgroundRepeat: "no-repeat",
      display: "flex",
      backgroundSize: "cover",
    },
    dCardTitle: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#efefef",
      textTransform: "uppercase",
      marginBottom: "5px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "9px", // For laptop screens
      },
    },

    dCardMoney: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      color: "var(--white)",
      textTransform: "uppercase",
      [theme.breakpoints.down("xl")]: {
        fontSize: "10px", // For laptop screens
      },
    },
    buttonStyle: {
      backgroundColor: "#a09153",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#121212",
      paddingX: 2,
      paddingY: 1,
      borderRadius: 18.5,
    },
    textSize: {
      fontSize: "15.8px",
    },
  }));

  const navigate = useNavigate();

  const classes = useStyles();

  const fetchRequestUserprofile = () => {
    props.dispatch(UserProfileAction.requestUserprofile());
  };

  const fetchSubscriptionPlans = () => {
    const subscription_plan_id =
      props?.userprofile?._source?.subscription_plan_id;

    const filter = [];

    filter.push(`["order_number","must","","term","${subscription_plan_id}"]`);

    const param = {
      filters: `[${filter}]`,
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
    };

    props.dispatch(SubscriptionPlansAction.requestSubscriptionBasePlans(param));
  };

  const fetchSubscriptionPlansFilter = () => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
    };

    props.dispatch(
      SubscriptionPlansAction.requestSubscriptionBasePlansFilter(param)
    );
  };

  useEffect(() => {
    fetchSubscriptionPlans();
    fetchSubscriptionPlansFilter();

    fetchRequestUserprofile();
  }, []);

  const base_plans = [];
  if (props?.subscriptionBasePlanFilter?.hits?.hits) {
    props?.subscriptionBasePlanFilter?.hits?.hits?.map((plan) => {
      if (plan?._source?.order_number) {
        base_plans.push(plan);
      }
    });
  }

  const upgrade_plans = [];

  if (base_plans?.length !== 0 && props?.userprofile?._source) {
    base_plans?.map((plan) => {
      if (
        plan?._source?.order_number !==
        props?.userprofile?._source?.subscription_plan_id
      )
        upgrade_plans?.push(plan);
    });
  }
  // console.log("subscriptionBasePlan", props.subscriptionBasePlan?.hits?.hits);
  console.log("subscriptionBasePlan", props);

  return (
    <Box sx={{ display: "flex", padding: 0 }}>
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={6}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.header,
              height: { laptop: "20px", lg: "20px", xl: "38px" },

              paddingY: { xl: "6px", laptop: "3px", lg: "3px" },
            }}
          >
            <p className="dashboard-title" style={getToolbarStyles()}>
              Dashboard
            </p>
          </Box>

          <Grid
            item
            sx={{ paddingRight: { xl: "17px", laptop: "10px", lg: "10px" } }}
          >
            <Box
              sx={{
                paddingLeft: { lg: "54px", laptop: "54px", xl: "100px" },
                paddingTop: { lg: "10px", laptop: "10px", xl: "18px" },
                paddingBottom: { lg: "24px", laptop: "24px", xl: "36px" },
                borderRight: "1px solid #2b2a2a",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                lg={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <Grid item height={"100%"} lg={3} xs={3} width={"100%"}>
                  <Grid
                    item
                    container
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-around"
                    height="100%"
                  >
                    <Grid
                      item
                      sx={{
                        paddingBottom: { lg: "8px", laptop: "8px", xl: "22px" },
                      }}
                    >
                      <Box
                        sx={{
                          height: { lg: "64px", laptop: "64px", xl: "118px" },
                          width: { lg: "64px", laptop: "64px", xl: "118px" },
                        }}
                      >
                        <Box className={classes.cardWrapper}>
                          <Grid className="my_royality_balance" />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        paddingBottom: { lg: "8px", laptop: "8px", xl: "10px" },
                      }}
                    >
                      <p className={classes.dCardTitle}>My Royalty Balance</p>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        paddingBottom: {
                          lg: "11px",
                          laptop: "11px",
                          xl: "34px",
                        },
                      }}
                      className={classes.dCardMoney}
                    >
                      $810.78
                    </Grid>

                    <Grid item>
                      <Button
                        sx={{
                          height: { laptop: "20px", lg: "20px", xl: "37px" },
                          backgroundColor: "#a09153",
                          width: { laptop: "59px", lg: "59px", xl: "110px" },
                          fontFamily: "Inter",
                          fontSize: { laptop: "9px", lg: "9px", xl: "14px" },
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#121212",
                          padding: 0,
                          borderRadius: 18.5,
                          "&:hover": {
                            backgroundColor: "#121212",
                            color: "#a09153",
                          },
                        }}
                      >
                        Withdraw
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {props.subscriptionBasePlan?.hits?.hits[0] && (
                  <Grid item lg={3} xs={3} height={"100%"} width={"100%"}>
                    <Grid
                      item
                      container
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"space-around"}
                      height={"100%"}
                    >
                      <Grid
                        item
                        sx={{
                          paddingBottom: {
                            lg: "8px",
                            laptop: "8px",
                            xl: "22px",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            height: { lg: "64px", laptop: "64px", xl: "118px" },
                            width: { lg: "64px", laptop: "64px", xl: "118px" },
                          }}
                        >
                          <Box className={classes.cardWrapper}>
                            <Grid className="my_current_plan" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          paddingBottom: {
                            lg: "8px",
                            laptop: "8px",
                            xl: "10px",
                          },
                        }}
                      >
                        <p className={classes.dCardTitle}>My Current Plan</p>
                      </Grid>

                      <Grid
                        item
                        className={classes.dCardMoney}
                        sx={{
                          paddingBottom: {
                            lg: "11px",
                            laptop: "11px",
                            xl: "34px",
                          },
                          color: "#e3e3e3",
                          textAlign: "center",
                        }}
                      >
                        {
                          props.subscriptionBasePlan?.hits?.hits[0]?._source
                            ?.plan_name
                        }
                      </Grid>

                      <Grid item>
                        <Button
                          sx={{
                            height: { laptop: "20px", lg: "20px", xl: "37px" },
                            backgroundColor: "#a09153",
                            width: { laptop: "59px", lg: "59px", xl: "110px" },
                            fontFamily: "Inter",
                            fontSize: { laptop: "9px", lg: "9px", xl: "12px" },
                            fontWeight: "bold",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#121212",
                            padding: 0,
                            borderRadius: 18.5,
                            "&:hover": {
                              backgroundColor: "#121212",
                              color: "#a09153",
                            },
                          }}
                        >
                          Add Track
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {
                  <Grid item lg={3} xs={3} height={"100%"} width={"100%"}>
                    <Grid
                      item
                      container
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"space-around"}
                      height={"100%"}
                    >
                      <Grid
                        item
                        sx={{
                          paddingBottom: {
                            lg: "8px",
                            laptop: "8px",
                            xl: "22px",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            height: { lg: "64px", laptop: "64px", xl: "118px" },
                            width: { lg: "64px", laptop: "64px", xl: "118px" },
                          }}
                        >
                          <Box className={classes.cardWrapper}>
                            <Grid className="upgrade_your_plan" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          paddingBottom: {
                            lg: "8px",
                            laptop: "8px",
                            xl: "10px",
                          },
                        }}
                      >
                        <p className={classes.dCardTitle}>UpGRADE YOUR Plan</p>
                      </Grid>
                      <Grid
                        item
                        className={classes.dCardMoney}
                        sx={{
                          paddingBottom: {
                            lg: "11px",
                            laptop: "11px",
                            xl: "34px",
                          },
                          color: "#e3e3e3",
                          textAlign: "center",
                          textDecoration:
                            props?.userprofile?._source
                              ?.subscription_plan_id === 2
                              ? "line-through"
                              : "none",
                        }}
                      >
                        {upgrade_plans[0]?._source?.plan_name}
                      </Grid>

                      <Grid item>
                        <Button
                          disabled={
                            props?.userprofile?._source
                              ?.subscription_plan_id === 2
                          }
                          sx={{
                            height: { laptop: "20px", lg: "20px", xl: "37px" },
                            width: { laptop: "59px", lg: "59px", xl: "110px" },
                            backgroundColor: "#a09153",
                            fontFamily: "Inter",
                            fontSize: { laptop: "9px", lg: "9px", xl: "12px" },
                            fontWeight: "bold",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",

                            color: "#121212",
                            padding: 0,
                            borderRadius: 18.5,
                            textDecoration:
                              props?.userprofile?._source
                                ?.subscription_plan_id === 2
                                ? "line-through"
                                : "none",
                            "&:hover": {
                              backgroundColor: "#121212",
                              color: "#a09153",
                            },
                          }}
                          onClick={() => {
                            navigate("/upgradePlans");
                          }}
                        >
                          Upgrade
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
          {/* Title Section */}
          <Box
            className="dashboard-title"
            sx={{
              backgroundColor: theme.palette.background.header,
              paddingLeft: "20px",
              height: { laptop: "20px", lg: "20px", xl: "38px" },
              paddingY: { xl: "6px", laptop: "3px", lg: "3px" },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "#a09153", fontSize: "inherit" }}>M</span>
              y&nbsp;
              <span style={{ color: "#a09153", fontSize: "inherit" }}>L</span>
              atest&nbsp;
              <span style={{ color: "#a09153", fontSize: "inherit" }}>R</span>
              elease{" "}
            </Box>
          </Box>
          {/* ReleasedDetails Section */}
          <Grid
            item
            sx={{
              flexGrow: 1, // This ensures the ReleasedDetails component takes up the remaining height
              overflow: "auto",
              paddingLeft: "20px",
              paddingRight: { lg: "54px", laptop: "54px", xl: "100px" },
              paddingTop: { lg: "18px", laptop: "18px", xl: "32px" },
              paddingBottom: { lg: "18px", laptop: "18px", xl: "36px" },
              borderLeft: "1px solid #2b2a2a",
              alignContent: "center",
            }}
          >
            <ReleasedDetails />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(mapStateToProps)(BasicDashboard);
