import BaseReducer from "../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// Releases Reduser
export default class ReleasesFilterReducer extends BaseReducer {
  initialState = {
    releaseFilter: [],
  };
  [ReleasesAction.REQUEST_RELEASE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      releaseFilter: action.payload,
    };
  }

  [ReleasesAction.REQUEST_RELEASE_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      releaseFilter:[],
    };
  }
}
