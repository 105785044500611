import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import MuiButton from "../../components/muiButton/MuiButton";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import StreamPlatformAction from "../../stores/streamPlatform/StreamPlatformAction";
import TrackAction from "../../stores/track/TrackAction";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Switch from "@mui/material/Switch";
import { theme } from "../App";
import Grow from "@mui/material/Grow";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import ReleasesAction from "../../stores/releases/ReleasesAction";

const useStyles = makeStyles(() => ({
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#88abb4",
    },
    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.light,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#9dc0c9",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#9dc0c9",
    },
  },
}));

const ExampleTextField = styled(TextField)({
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK_BY_ID,
    StreamPlatformAction.REQUEST_STREAM_PLATFORM,
  ]),
  trackById: state.trackById.trackById || {},
  stream_platform: state.stream_platform.stream_platform || {},
});

const AlbumPlayLinks = (props) => {
  const [openPopperDialog, setPopperDialog] = useState(false);
  const [editRows, setEditRows] = useState(null);
  const classes = useStyles();

  function requestFinalReleaseById(id, page_params) {
    props.dispatch(ReleasesAction.requestFinalReleaseById(id, page_params));
  }

  function fetchStreamPlatforms() {
    let params = {
      page_limit: "none",
      current_page: "delivered_release_details",
    };
    props.dispatch(StreamPlatformAction.requesPlatform(params));
  }

  useEffect(() => {
    fetchStreamPlatforms();
  }, [props.trackId]);

  const [rows, setRows] = useState(null);

  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  useEffect(() => {
    let platform_list = [];

    if (
      props &&
      props.stream_platform &&
      props.stream_platform.hits &&
      props.stream_platform.hits.hits &&
      props.stream_platform.hits.hits.length !== 0
    ) {
      props.stream_platform.hits.hits.map((item, index) => {
        platform_list.push({
          id: item._id,
          stream_platform_name: item._source.stream_platform_name
            ? convertToTitleCase(item._source.stream_platform_name)
            : null,
          stream_platform_id: item._id,
        });
      });
    }
    if (
      props.releaseData &&
      props.releaseData._source &&
      props.releaseData._source.release_play_link &&
      props.releaseData._source.release_play_link.length &&
      platform_list &&
      platform_list.length !== 0
    ) {
      let play_links = props.releaseData._source.release_play_link;
      for (let i = 0; i < play_links.length; i++) {
        for (let j = 0; j < platform_list.length; j++) {
          if (play_links[i].streaming_platform_id === platform_list[j].id) {
            platform_list[j].playLinkUrl = {
              play_link: play_links[i].play_link,
            };
            break;
          }
        }
      }
    }
    let platformListWithUrl = [];
    platformListWithUrl =
      platform_list &&
      platform_list.length !== 0 &&
      platform_list.filter((val, index) => {
        return val.playLinkUrl;
      });

    const playLinks = props?.releaseData?._source?.release_play_link;

    let playLinksWithOrderNumber = [];
    platformListWithUrl?.length > 0 &&
      platformListWithUrl.forEach((platform, i) => {
        playLinks.forEach((playLink) => {
          if (playLink.streaming_platform_id === platform.stream_platform_id) {
            platform.order_number = playLink.order_number;
            platform.enable = playLink.enable;
          }
        });
        playLinksWithOrderNumber.push(platform);
      });

    playLinksWithOrderNumber?.sort((a, b) => a.order_number - b.order_number);

    const uniqueSlNos = [];
    let nextSlNo = 1;
    for (let i = 0; i < playLinksWithOrderNumber.length; i++) {
      const currentSlNo = playLinksWithOrderNumber[i].order_number;
      if (!uniqueSlNos.includes(currentSlNo)) {
        playLinksWithOrderNumber[i].order_number = nextSlNo;
        uniqueSlNos.push(nextSlNo);
        nextSlNo++;
      } else {
        while (uniqueSlNos.includes(nextSlNo)) {
          nextSlNo++;
        }
        playLinksWithOrderNumber[i].order_number = nextSlNo;
        uniqueSlNos.push(nextSlNo);
        nextSlNo++;
      }
    }

    setRows(playLinksWithOrderNumber);
  }, [props.stream_platform.hits, props.trackById._source]);

  let platform_list = [];

  if (
    props &&
    props.stream_platform &&
    props.stream_platform.hits &&
    props.stream_platform.hits.hits &&
    props.stream_platform.hits.hits.length !== 0
  ) {
    props.stream_platform.hits.hits.map((item) => {
      platform_list.push({
        streaming_platform_id: item._id,
        streaming_plfatform_name: item._source.stream_platform_name
          ? convertToTitleCase(item._source.stream_platform_name)
          : null,
      });
    });
  }

  platform_list = platform_list.filter(
    (platform) =>
      rows &&
      !rows.some(
        (row) => row.stream_platform_id === platform.streaming_platform_id
      )
  );

  let [platformlistvalue, setplatformlistvalue] = useState(null);

  const platform_listHandleChange = (event, valueofevent) => {
    setplatformlistvalue(valueofevent);
    if (valueofevent) {
      setRows([
        ...rows,
        {
          enable: valueofevent.enable,
          order_number: rows.length + 1,
          id: valueofevent.streaming_platform_id,
          stream_platform_name: valueofevent.streaming_plfatform_name
            ? convertToTitleCase(valueofevent.streaming_plfatform_name)
            : null,
          stream_platform_id: valueofevent.streaming_platform_id,
          playLinkUrl: {
            play_link: "",
          },
        },
      ]);
    }
  };

  let columns = [
    {
      field: "order_number",
      headerName: "SL NO",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "stream_platform_id",
      headerName: "Stream Platform Id",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 3,
    },

    {
      field: "enable",
      headerName: "Enable",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Switch
            className={classes.switch}
            checked={params.row.enable === undefined ? true : params.row.enable} //{params.row.enable}
            onChange={(event) => handleSwitchChange(event, params.row.id)}
          />
        );
      },
    },

    {
      field: "delete",
      headerName: "Delete",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <DeleteTwoToneIcon
            style={{ fontSize: "28px" }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                transition: "transform 0.5s, color 0.2s",
                transform: "scale(1.1)",
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => {
              setEditRows(params);
              setPopperDialog(true);
            }}
          />
        );
      },
    },

    {
      field: "stream_platform_name",
      headerName: "Stream Platform Name",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 5,
    },

    {
      field: "play_link",
      headerName: "Play Link",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 5,
      renderCell: (params) => {
        return (
          <>
            <ExampleTextField
              defaultValue={
                params.row.playLinkUrl ? params.row.playLinkUrl.play_link : ""
              }
              fullWidth
              style={{ marginRight: "5px" }}
              onChange={(e, val) => {
                let findobject = rows.filter((item) => {
                  return item.id == params.id;
                })[0];
                let indexofparam = rows.indexOf(findobject);

                const updatedRows = [...rows];

                const updatedObject = {
                  ...updatedRows[indexofparam], // Copy the object you want to update
                  id: params.row.stream_platform_id,
                  stream_platform_name: params.row.stream_platform_name
                    ? convertToTitleCase(params.row.stream_platform_name)
                    : null,
                  stream_platform_id: params.row.stream_platform_id,
                  playLinkUrl: {
                    play_link: e.target.value,
                  },
                };

                updatedRows[indexofparam] = updatedObject;

                setRows(updatedRows);
              }}
              InputProps={{
                style: {
                  height: "30px",
                  borderRadius: "50px",
                },
              }}
            />
            {params.row.playLinkUrl && params.row.playLinkUrl.play_link && (
              <IconButton edge="end">
                <OpenInNewIcon
                  style={{
                    color: theme.palette.primary.light,
                  }}
                  onClick={() => {
                    if (params.row.playLinkUrl) {
                      params.row.playLinkUrl.play_link &&
                        window.open(params.row.playLinkUrl.play_link);
                    }
                  }}
                />
              </IconButton>
            )}
            <div style={{ margin: "5px" }}></div>
          </>
        );
      },
    },
  ];

  function convertToSnakeCase(str) {
    let lowercaseStr = str.toLowerCase();
    let words = lowercaseStr.split(" ");

    if (words.length > 1) {
      return words.join("_");
    }

    return lowercaseStr;
  }

  const updatePlayLinkFunction = (argRows = rows) => {
    let Obj = {};

    Object.assign(
      Obj,
      argRows && argRows.length !== 0
        ? {
            release_play_link:
              argRows &&
              argRows.length !== 0 &&
              argRows.map((item, index) => {
                return {
                  enable: item.enable,
                  order_number: item.order_number,
                  play_link: item.playLinkUrl.play_link,
                  streaming_platform_id: item.id,
                  streaming_plfatform_name: item.stream_platform_name
                    ? convertToSnakeCase(item.stream_platform_name)
                    : null,
                };
              }),
          }
        : { release_play_link: [] }
    );
    let page_params = {
      current_page: "delivered_release_details",
    };

    console.log("AlbumPlayLinks", Obj);
    props
      .dispatch(
        TrackAction.requestAlbumPlayLink(
          JSON.stringify(Obj),
          props.releaseId,
          page_params
        )
      )
      .then(() => {
        requestFinalReleaseById(props.releaseId);
      });
  };

  const rowOrderHandleChange = async (params) => {
    const newRows = [...rows];
    const selectedItem = newRows.splice(params.oldIndex, 1)[0];
    newRows.splice(params.targetIndex, 0, selectedItem);
    for (let i = 0; i < newRows.length; i++) {
      newRows[i].order_number = i + 1;
    }
    setRows(newRows);
  };

  const handleSwitchChange = (event, rowId) => {
    const updatedRows = rows.map((row) =>
      row.id === rowId ? { ...row, enable: event.target.checked } : row
    );
    setRows(updatedRows);
  };

  function deletePlayLink() {
    const filteredArray = rows.filter((item) => item.id != editRows.id);
    setRows(filteredArray);
    updatePlayLinkFunction(filteredArray);
    setPopperDialog(false);
  }

  return (
    <Box>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        alignItems="center"
        justifyContent={"space-between"}
        mt={2}
      >
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent="flex-start"
          gap={2}
          xl={10}
          lg={10}
          md={10}
          sm={10}
          xs={10}
        >
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item mr={2}>
              <Typography>Add PlayLinks :</Typography>
            </Grid>

            <Grid item>
              <AutoCompleteComponent
                list={platform_list ? platform_list : []}
                textFieldProps={{
                  placeholder: "Add Platform",
                }}
                value={platformlistvalue}
                getOptionLabel={(option) => option.streaming_plfatform_name}
                isOptionEqualToValue={(option, value) =>
                  option &&
                  option.streaming_platform_id === value &&
                  value.streaming_platform_id
                }
                handleChange={platform_listHandleChange}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container>
            <Typography component={"span"}>
              {props?.trackById?._source?.play_link?.length
                ? formatName(
                    `${
                      props &&
                      props.trackById &&
                      props.trackById._source &&
                      props.trackById._source.play_link &&
                      props.trackById._source.play_link.length
                    }`
                  )
                : 0}{" "}
              Results found
            </Typography>
          </Grid>
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} mb={2}>
          <Button
            variant="contained"
            style={{
              border: "1px solid #dce6e8",
              borderRadius: "4px",
              background: `linear-gradient(to bottom, ${theme.palette.background.button} 10%, white 30%)`,
              color: theme.palette.text.primary,
              padding: "6px 26px 5px 25px",
            }}
            sx={{
              transition: "box-shadow 0.3s, font-weight 0.1s ease",
              "&:hover": {
                boxShadow: `0px 4px 8px ${theme.palette.primary.main}75`, // Add box-shadow on hover
                fontWeight: "bold",
                padding: "6px 15px 5px 15px",
              },
            }}
            onClick={() => {
              updatePlayLinkFunction();
            }}
          >
              Update Play Links
          </Button>
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12}>
        <Grid item lg={12} md={12} sm={12}>
          <Box>
            <LoadingIndicator isActive={props.isRequesting}>
              {!props.isRequesting && (
                <DataGridFunction
                  columns={columns}
                  rows={rows ? rows : []}
                  rowHeight={80}
                  rowReordering
                  onRowOrderChange={rowOrderHandleChange}
                  height="35vh"
                />
              )}
            </LoadingIndicator>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openPopperDialog}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Grow}
        transitionDuration={400}
        onClose={() => {
          setPopperDialog(false);
        }}
      >
        <DialogContent>
          <Box m={1} fontSize={"18px"}>
            Are you sure you want to delete the Play Link?
          </Box>
        </DialogContent>
        <DialogActions>
          <MuiButton
            width="20%"
            name={"Yes"}
            onClick={() => {
              deletePlayLink();
            }}
          />
          <MuiButton
            width="20%"
            name={"No"}
            cancel
            onClick={() => {
              setPopperDialog(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default connect(mapStateToProps)(AlbumPlayLinks);
