import { BaseModel } from "sjs-base-model";

export default class SubscriptionPostModel extends BaseModel {
  status = "";
  message = "";
  order_id = "";

  constructor(data2) {
    super();

    this.update(data2);
  }
}
