import BaseReducer from "../../utilities/BaseReducer";
import SubscriptionAction from "./SubscriptionAction";

// StatusReduser
export default class SubscriptionReducer extends BaseReducer {
  initialState = {
    subscription: [],
  };
  [SubscriptionAction.REQUEST_SUBSCRIPTION_FINISHED](state, action) {
    return {
      ...state,
      subscription: action.payload,
    };
  }

  [SubscriptionAction.REQUEST_SUBSCRIPTION_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      subscription: [],
    };
  }
}
