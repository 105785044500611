import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { theme } from "../../views/App";
import { fieldToTextField } from "formik-material-ui";
import { Box, TextField } from "@mui/material";

import PropTypes from "prop-types";
import LabelAction from "../../stores/label/LabelAction";
import { useDispatch } from "react-redux";

export default function FreeSoloCreateOptionDialog({
  disabled,
  readOnly,
  disableCloseOnSelect,
  diasbleSvg,
  disableClearable,
  textFieldProps,
  size,
  limitTags,
  fieldValue,
  renderOption,
  freeSolo,
  filterOptions,
  onChange,
  margin,
  padding,
  openProp,
  onInputChange,
  getOptionLabel,
  values,
  inputValue,
  fetchartistFilter,
  isOptionEqualToValue,
  value,
  InputProps,
  renderTags,
  remove,
  push,
  //   artistFieldRef,
  ...props
}) {
  const useStyles = makeStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      marginTop: 2,
      marginBottom: 2,
      zIndex: 1,
      overflow: "hidden",
    },
  });

  const dispatch = useDispatch();

  const {
    form: { setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();

  return (
    <Box>
      <Autocomplete
        classes={{
          paper: classes.root,
        }}
        disableCloseOnSelect={disableCloseOnSelect}
        disableClearable={true}
        readOnly={readOnly ? readOnly : false}
        {...props}
        {...field}
        onChange={
          onChange
            ? onChange
            : (_, value) => {
                if (
                  value &&
                  typeof value === "object" &&
                  Object.keys(value).length === 1 &&
                  value.hasOwnProperty("name")
                ) {
                  const LabelNameValue = value["name"];
                  if (
                    typeof LabelNameValue === "string" &&
                    LabelNameValue.startsWith("Add ")
                  ) {
                    let extractedName;
                    const inputString = value.name;
                    const prefix = 'Add "';
                    const suffix = '"';

                    const startIndex =
                      inputString.indexOf(prefix) + prefix.length;
                    const endIndex = inputString.lastIndexOf(suffix);

                    if (
                      startIndex >= 0 &&
                      endIndex >= 0 &&
                      endIndex > startIndex
                    ) {
                      extractedName = inputString.substring(
                        startIndex,
                        endIndex
                      );
                    }

                    let body = {
                      label_name: extractedName,
                    };
                    const label_param = {
                      page_limit: "none",
                      order_by: `[["created_on","desc"]]`,
                      current_page: "releaseInfo",
                    };

                    const callback = (res) => {
                      console.log("RRR", res);

                      if (res.status === "success") {
                        setFieldValue(name, {
                          name: extractedName,
                          id: res.id,
                        });
                      } else {
                        setFieldValue(name, "");
                      }
                      dispatch(LabelAction.requestLabelFilter(label_param));
                    };
                    dispatch(LabelAction.requestPostLabel(body, {}, callback));
                  }
                } else {
                  setFieldValue(name, value);
                }
              }
        }
        onInputChange={onInputChange}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        freeSolo={true}
        renderInput={(props) => (
          <TextField
            InputLabelProps={{ shrink: true }}
            {...props}
            {...textFieldProps}
            helperText={helperText}
            error={error}
            size={size}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              backgroundColor:"#808080",
              //   svg: { display: diasbleSvg ? diasbleSvg : "block" },
              margin: margin,
              padding: padding,
              width: "100%",
            }}
          />
        )}
      />
    </Box>
  );
}
FreeSoloCreateOptionDialog.propTypes = {
  innerref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};
