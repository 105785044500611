import React, { useState } from "react";
import MuiButton from "../../components/muiButton/MuiButton";
import { connect, useDispatch, useStore } from "react-redux";
import SubscriptionAction from "../../stores/subscriptions/SubscriptionAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import { Box } from "@mui/material";
import environment from "environment";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserProfileAction.REQUEST_USERPROFILE,
  ]),
  userprofile: state.userprofile.userprofile || [],
});

function RazorPayPayment(props) {
  const dispatch = useDispatch();
  const { selectedPlan, superSaverSelected, superSaverQuantity } = props;

  // handlePayment Function
  const handlePayment = async (method) => {
    let order_id;
    try {
      const callback = (res) => {
        res.then((res) => {
          if (res.status === "success") {
            if (method === "PayPal") {
              window.open(
                `https://www.sandbox.paypal.com/checkoutnow?token=${res?.order_id}`,
                "_self"
              );
            } else {
              order_id = res?.order_id;

              let paymentData = {
                order_id: order_id,
                amount: selectedPlan?._source.plan_price * 100,
                userName:
                  props.userprofile._source.first_name +
                  props.userprofile._source.last_name,
                userEmail: props.userprofile._source.main_email_address,
                userPhoneNumber: props.userprofile._source.phone_number,
              };

              //192.168.137.220:5033/api/v1/subscription_order_callback

              // const res = await fetch(`http://192.168.137.188:4000/api/payment/order`, {
              //   method: "POST",
              //   headers: {
              //     "content-type": "application/json",
              //   },
              //   body: JSON.stringify({
              //     amount,
              //   }),
              // });

              // const data = await res.json();
              // console.log("data", data);

              handlePaymentVerify(paymentData);
            }
          }
        });
      };

      if (superSaverSelected) {
        let postSuperSaverBody = {
          release_type: selectedPlan._source.release_type,
          quantity: Number(superSaverQuantity),
        };

        if (method === "PayPal") {
          Object.assign(postSuperSaverBody, { payment_service: "paypal" });
        }

        dispatch(
          SubscriptionAction.requestBuySuperSaverSubscription(
            JSON.stringify(postSuperSaverBody),
            callback
          )
        );
      } else {
        let postSubscriptionBody = {
          plan_id: selectedPlan?._id,
        };

        if (method === "PayPal") {
          Object.assign(postSubscriptionBody, { payment_service: "paypal" });
        }

        dispatch(
          SubscriptionAction.requestPostSubscription(
            JSON.stringify(postSubscriptionBody),
            callback
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handlePaymentVerify Function
  const handlePaymentVerify = async (data) => {
    // const options = {
    //   key: process.env.REACT_APP_RAZORPAY_KEY_ID,
    //   amount: data.amount,
    //   currency: "INR",
    //   name: "A3 TUNES",
    //   description: "Test Mode",
    //   order_id: data.id,
    //   // callback_url: "https://rockyfrontend.clikbite.com",
    //   // handler: async (response) => {
    //   //   console.log("response", response);
    //   //   try {
    //   //     const res = await fetch(
    //   //       `http://192.168.137.188:4000/api/payment/verify`,
    //   //       {
    //   //         method: "POST",
    //   //         headers: {
    //   //           "content-type": "application/json",
    //   //         },
    //   //         body: JSON.stringify({
    //   //           razorpay_order_id: response.razorpay_order_id,
    //   //           razorpay_payment_id: response.razorpay_payment_id,
    //   //           razorpay_signature: response.razorpay_signature,
    //   //         }),
    //   //       }
    //   //     );

    //   //     const verifyData = await res.json();

    //   //     console.log("verifyData", verifyData);
    //   //   } catch (error) {
    //   //     console.log(error);
    //   //   }
    //   // },
    //   prefill: {
    //     //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
    //     name: "Tester", //your customer's name
    //     email: "tester@example.com",
    //     contact: "9000090000", //Provide the customer's phone number for better conversion rates
    //   },
    //   theme: {
    //     color: "#5f63b8",
    //   },
    // };

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: data.amount,
      currency: "INR",
      name: "A3 TUNES",
      description: "Test Mode",
      order_id: data.order_id,
      callback_url: environment.api.subscription_order_callback,
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        name: data.userName, //your customer's name
        email: data.userEmail,
        contact: data.userPhoneNumber, //Provide the customer's phone number for better conversion rates
      },
      theme: {
        color: "#5f63b8",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      <Box>
        <MuiButton
          name={"RazozPay "}
          onClick={() => {
            handlePayment();
          }}
          disabled={props.selectedPlan ? false : true}
          width={200}
        />
      </Box>
      <Box sx={{ marginLeft: 1 }}>
        <MuiButton
          name={"PayPal"}
          onClick={() => {
            handlePayment("PayPal");
          }}
          disabled={props.selectedPlan ? false : true}
          width={200}
        />
      </Box>
    </Box>
  );
}

export default connect(mapStateToProps)(RazorPayPayment);
