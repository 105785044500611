import BaseReducer from "../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// Releases Reducer
export default class ReleasesReducer extends BaseReducer {
  initialState = {
    release: [],
  };
  [ReleasesAction.REQUEST_RELEASES_FINISHED](state, action) {
    return {
      ...state,
      release: action.payload,
    };
  }

  [ReleasesAction.REQUEST_RELEASE_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      release: [],
    };
  }
}
