import { Alert, Slide, Snackbar } from "@mui/material";
import React from "react";
import { theme } from "../../views/App";

function MuiSnackbarAlert({
  open,
  onClose,
  message,
  autoHideDuration,
  backgroundColor,
  color,
  snackbarstaus,
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={autoHideDuration ? autoHideDuration : 1500}
      onClose={onClose}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={onClose}
        severity={snackbarstaus ? snackbarstaus : "warning"}
        sx={{
          width: "100%",
          borderRadius: "5px",
          backgroundColor:
            snackbarstaus === "success"
              ? "green"
              : backgroundColor
              ? backgroundColor
              : theme.palette.secondary.main,

          color: color ? color : "white",
        }}
      >
        {message ? message : ""}
      </Alert>
    </Snackbar>
  );
}

export default MuiSnackbarAlert;
