import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import SubscriptionModel from "./models/SubscriptionModel";
import SubscriptionPostModel from "./models/SubscriptionPostModel";
import ClaimSubscriptionPostModel from "./models/ClaimSubscriptionPostModel";

// SubscriptionEffect
export default class SubscriptionEffect {
  // calling an API according to the subscription model
  // Get method for the
  static async requestSubscription(params) {
    const endpoint = environment.api.subscription;
    return EffectUtility.getToModel(SubscriptionModel, endpoint, params);
  }

  static async requestSubscriptionClear() {
    return {};
  }

  static async requestSubscriptionFilterClear() {
    return {};
  }

  // Get method for the subscription filtear
  static async requestSubscriptionFilter(params, callBack) {
    const endpoint = environment.api.subscription;
    let response = EffectUtility.getToModel(
      SubscriptionModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (callBack) {
        callBack(resp);
      }
    });

    return response;
  }

  static async requestBuySuperSaverSubscription(data, callback) {
    const endpoint = environment.api.buySuperSaver;
    let response = EffectUtility.postToModel(
      SubscriptionPostModel,
      endpoint,
      data
    );

    if (callback) {
      callback(response);
    }

    return response;
  }

  static async requestClaimSubscription(data, callback) {
    const endpoint = environment.api.claimSubscription;
    let response = EffectUtility.postToModel(
      ClaimSubscriptionPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (callback) {
        callback(resp);
      }
    });
    return response;
  }

  // post method for the subscription
  static async requestPostSubscription(data, callback) {
    const endpoint = environment.api.subscription;
    const response = EffectUtility.postToModel(
      SubscriptionPostModel,
      endpoint,
      data
    );

    if (callback) {
      callback(response);
    }

    return response;
  }
}
