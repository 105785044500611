import React, { useEffect } from "react";
import { Box, Grid, ListItem, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import { theme } from "../../views/App";
import { ShadowContainer } from "../componentLayout/ComponentLayout";

const mapDispatchToProps = (dispatch) => {
  return {};
};

function UploadPopper({ format, children, type, disabled }) {
  const [open, setOpen] = React.useState(false);
  const morePopperRef = React.useRef(null);

  const handlePopper = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleClosepopper = (event) => {
    if (morePopperRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseDetails = () => {
    setOpen(false);
  };

  return (
    <Grid container>
      <Typography
        component={"div"}
        ref={morePopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopper}
        onMouseLeave={handleCloseDetails}
        style={{
          backgroundColor: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",

          fontSize: theme.typography.fontSize.normal,
        }}
      >
        {children ? children : "Format"}
      </Typography>
      <Popper
        open={open}
        anchorEl={morePopperRef.current}
        role={undefined}
        placement="bottom"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
              transform: open ? "translateX(116px)" : "none",
            }}
          >
            <Paper
              style={{
                width: "280px",
                maxWidth: "none",
                padding: 15,
                backgroundColor: theme.palette.background.tab,
                boxShadow: theme.shadows,
              }}
            >
              <div
                onMouseLeave={handleClosepopper}
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                style={{
                  height: "50%",
                  scrollbarWidth: "none",
                  transition: "all 0.1s ease",
                  // padding: 10,
                }}
              >
                <Typography
                  style={{
                    wordWrap: "break-word",
                    width: "100%",
                    whiteSpace: "normal",
                  }}
                >
                  <strong
                    style={{ fontWeight: theme.typography.fontWeight.bold }}
                  >
                    {type}{" "}
                  </strong>
                  {format}
                </Typography>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(UploadPopper);
