import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import SubscriptionOrderPostModel from "./models/SubscriptionOrderPostModel";
import SubscriptionOrderModel from "./models/SubscriptionOrderModel";

// SubscriptionOrderEffect
export default class SubscriptionOrderEffect {
  // calling an API according to the subscription model
  // Get method for the
  static async requestSubscriptionOrder(params) {
    const endpoint = environment.api.subscriptionOrder;
    return EffectUtility.getToModel(SubscriptionOrderModel, endpoint, params);
  }

  static async requestSubscriptionOrderClear() {
    return {};
  }

  static async requestSubscriptionOrderFilterClear() {
    return {};
  }

  // Get method for the subscription filtear
  static async requestSubscriptionOrderFilter(params) {
    const endpoint = environment.api.subscriptionOrder;
    return EffectUtility.getToModel(SubscriptionOrderModel, endpoint, params);
  }

  //   // post method for the subscription
  //   static async requestPostSubscriptionOrder(data) {
  //     const endpoint = environment.api.subscriptionOrder + "/add";
  //     return EffectUtility.postToModel(
  //       SubscriptionOrderPostModel,
  //       endpoint,
  //       data
  //     );
  //   }
}
