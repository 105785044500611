import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fieldToTextField } from "formik-material-ui";
import dayjs from "dayjs";
import MuiTextField from "../muiTextField/MuiTextField";
import { theme } from "../../views/App";

export default function MuiDatePicker({
  disableClearable,
  textFieldProps,
  size,
  width,
  fieldValue,
  onChange,
  format,
  views,
  value,
  disablePast,
  disabled,

  ...props
}) {
  const {
    form: { setFieldValue, touched },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  const [isFocused, setIsFocused] = useState(false);
  const borderColor =
    error && touched[name] && !isFocused ? "0.2px solid red" : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        value={value ? dayjs(value) : null}
        onChange={(value) => setFieldValue(name, value)}
        slots={{
          textField: MuiTextField,
        }}
        disablePast={disablePast}
        format={format}
        views={views}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: "outlined",
            size: "small",
            label: "",
            // style: {
            //   outline: `1px ${theme.palette.borderColor.textField}`,
            //   backgroundColor: theme.palette.background.paper,
            //   borderRadius: "8px",
            // },
            InputProps: {
              style: {
                border: borderColor,
                backgroundColor: theme.palette.background.textfield,
                borderRadius: "8px",
                color: "#ffffff",
              },
              onFocus: () => setIsFocused(true),
              onBlur: () => setIsFocused(false),
            },
          },
        }}
        label="Basic date picker"
      />
    </LocalizationProvider>
  );
}
