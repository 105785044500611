import React, { useEffect } from "react";
import { Box, Grid, ListItem, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import { theme } from "../../views/App";
import { ShadowContainer } from "../componentLayout/ComponentLayout";
import CircleLoader from "../circularLoder/CircleLoader";
import { useNavigate } from "react-router";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function ReleasesMorePopper(props) {
  const [open, setOpen] = React.useState(false);
  const morePopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const navigate = useNavigate();

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      morePopperRef?.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (morePopperRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container>
      <Typography
        component={"div"}
        ref={morePopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={morePopperRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
              transform: open ? "translateX(-160px)" : "none",
            }}
          >
            <ShadowContainer borderRadius={5}>
              <Paper
                style={{
                  width: "auto",
                  minWidth: 150,
                  height: "auto",
                  padding: 2,
                  //   boxShadow: theme.shadows,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    style={{
                      maxHeight: 250,
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                    }}
                    className="scrollable"
                  >
                    {/* {props.status === "Draft" && (
                      <ListItem
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.palette.background.default,
                          },
                        }}
                        onClick={(event) => {
                          event.ignore = true;
                          props.onClick(event);
                        }}
                      >
                        Delete Draft &nbsp;&nbsp;
                        {props.deleteloading ? (
                          <CircleLoader width={"20px"} height={"20px"} />
                        ) : null}
                      </ListItem>
                    )} */}

                    <ListItem
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: theme.palette.background.default,
                        },
                      }}
                      onClick={(event) => {
                        event.ignore = true;
                        localStorage.setItem(
                          "release_details_id",
                          props.releaseId
                        );
                        navigate("/release_details", {
                          state: {
                            releaseId: props.releaseId,
                            status: props.status,
                          },
                        });
                      }}
                    >
                      View Details &nbsp;&nbsp;
                    </ListItem>
                  </div>
                </ClickAwayListener>
              </Paper>
            </ShadowContainer>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(ReleasesMorePopper);
