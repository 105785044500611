import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import MuiButton from "../../components/muiButton/MuiButton";
import PostPlusButton from "../../components/postPlusButton/PostPlusButton";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import AssetsAction from "../../stores/assets/AssetsAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import AssetsPost from "./AssetsPost";
import AssetsEditName from "./AssetsEditName";
import { faCheck, faTrash, faCancel } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../App";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import RetriveAssetDialog from "./RetriveAssetDialog";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AssetsAction.REQUEST_ASSETS,
    AssetsAction.REQUEST_ASSETS_FILTER,
  ]),
  assets: state.assets.assets.hits || [],
  assetsFilter: state.assetsFilter.assetsFilter || [],
});

const Assets = (props) => {
  const { isRequesting } = props;
  const [assets, setAssets] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [page_limit] = useState(15);
  const [open, setOpen] = useState(false);
  const [retriveAssetOpen, setretriveAssetOpen] = useState(false);
  const [editNameOpen, setEditNameOpen] = useState(false);
  const [editingRow, setEditingRow] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [initialStart, setInitialStart] = useState(true);

  const [editRowsModel, setEditRowsModel] = useState({});
  const [FilterClicked, setFilterClicked] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isNameChange, setIsNameChange] = useState(false);

  function fetchAssetsFilter(param) {
    props.dispatch(AssetsAction.requestAssetsFilter(param));
  }

  function fetchAssets(param) {
    props.dispatch(AssetsAction.requestAssets(param)).then((event) => {
      setInitialStart(false);
    });
  }

  useEffect(() => {
    // const filterparam = {
    //   page_limit: "none",
    //   order_by: `[["assets_name.keyword", "asc"]]`,
    // };

    // fetchAssetsFilter(filterparam);

    // const params = {
    //   order_by: `[["assets_name.keyword", "asc"]]`,
    //   page_limit: `${page_limit}`,
    // };

    let params = {
      current_page: "upload",
      page_limit: "none",
      filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
    };

    fetchAssets(params);
  }, []);

  useEffect(() => {
    if (isNameChange && !editNameOpen) {
      let params = {
        current_page: "upload",
        page_limit: "none",
        filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
      };
      fetchAssets(params);
      setIsNameChange(false);
    }
  }, [editNameOpen]);

  function returnProgressIndicator(assetID) {
    let percentUploaded = 0;

    if (localStorage.getItem("upload_progress")) {
      let UploadProgressArray = JSON.parse(
        localStorage.getItem("upload_progress")
      );

      let filteredAssetUpload = UploadProgressArray?.filter(
        (obj) => obj.asset_id === assetID
      );

      percentUploaded =
        filteredAssetUpload?.length > 0
          ? filteredAssetUpload[0].percentUploaded
          : 0;
    }

    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size={35}
          variant="determinate"
          value={percentUploaded}
          sx={{ color: theme.palette.primary.main }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.primary"
            fontSize={12}
          >
            {`${Math.round(percentUploaded)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    // Dont remove this useEffect because it is used to render the percentage of file uploading based on the progress value.
  }, [props.progress]);

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "id",
    //   headerName: "ID",
    //   hide: true,
    // },
    {
      field: "assets_name",
      renderHeader: () => (
        <div>
          <span>Asset name</span>
          <span style={{ marginLeft: "95px" }}>Edit</span>
        </div>
      ),
      width: 150,
      flex: 2,
      renderCell: (params) => (
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography>{params?.row?.assets_name}</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Tooltip title="Edit File Name" arrow placement="top">
              <IconButton
                onClick={() => {
                  setEditNameOpen(true);
                  setEditingRow(params.row);
                }}
                disabled={
                  props._source
                    ? props._source.status &&
                      (props._source.status.id === "1" ||
                        props._source.status.id === "4" ||
                        props._source.status.id === "10" ||
                        props._source.status.id == "9")
                      ? false
                      : true
                    : false
                }
              >
                <EditIcon
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    color:
                      props?._source?.status?.id === "1" ||
                      props?._source?.status?.id === "4" ||
                      props?._source?.status?.id === "10" ||
                      props?._source?.status?.id == "9"
                        ? theme.palette.text.primary
                        : theme.palette.text.gray,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "file_name",
      headerName: "File name",
      width: 150,
      flex: 2,
    },

    {
      field: "upload",
      headerName: "Upload",
      align: "center",
      headerAlign: "center",

      width: 250,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.upload === "checked" ? (
            <IconButton>
              <FontAwesomeIcon
                icon={faCheck}
                style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
              />
            </IconButton>
          ) : params?.row?.upload === "loading" ? (
            <>
              {props.uploadStarted ? (
                returnProgressIndicator(params?.row?.id)
              ) : (
                <CircleLoader width={"20px"} height={"20px"} />
              )}
            </>
          ) : (
            <IconButton
              title="Re Upload"
              onClick={() => {
                props.handleReupload(params?.row?.id);
              }}
            >
              <FontAwesomeIcon
                icon={faCancel}
                style={{ height: "20px", width: "20px", color: "red" }}
              />
            </IconButton>
          )}
        </>
      ),
    },
    // {
    //   field: "check",
    //   headerName: "Check",
    //   width: 250,

    //   flex: 1.5,

    //   renderCell: (params) => (
    //     <FontAwesomeIcon
    //       icon={faCheck}
    //       style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
    //     ></FontAwesomeIcon>
    //   ),
    // },
    // {
    //   field: "encoding",
    //   headerName: "Encoding",
    //   width: 250,

    //   flex: 1.5,

    //   renderCell: (params) => (
    //     <FontAwesomeIcon
    //       icon={faCheck}
    //       style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
    //     ></FontAwesomeIcon>
    //   ),
    // },
    // {
    //   field: "client_id",
    //   headerName: "Client ID",
    //   width: 250,
    //   flex: 3,
    // },
    // {
    //   field: "stream_id",
    //   headerName: "Stream ID",
    //   width: 250,
    //   flex: 3,
    // },
    // {
    //   field: "track_id",
    //   headerName: "Track ID",
    //   width: 250,
    //   flex: 3,
    // },
    // {
    //   field: "created_by",
    //   headerName: "Created By",
    //   width: 250,
    //   flex: 3,
    // },

    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Edit",
    //   width: 160,
    //   hide: false,
    //   flex: 2,
    //   cellClassName: "actions",
    //   renderCell: (params) => (
    //     <IconButton
    //       title="Edit"
    //       onClick={(event) => {
    //         event.ignore = true;
    //         setEdit(true);
    //         setOpen(true);
    //         setEditRowsModel(params.row);
    //       }}
    //       sx={{ border: "0" }}
    //     >
    //       <FontAwesomeIcon
    //         icon={faPenToSquare}
    //         style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
    //         title="Edit"
    //       ></FontAwesomeIcon>
    //     </IconButton>
    //   ),
    // },

    {
      field: "actions",
      type: "actions",
      headerName: "Play",
      width: 160,
      flex: 1,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Play"
          onClick={(event) => {
            event.ignore = true;
            // setEdit(true);
            setretriveAssetOpen(true);
            setEditRowsModel({
              ...params.row,
              typeofrelease: props.releaseById._source.type_of_release,
            });
          }}
          sx={{ border: "0" }}
        >
          {/* <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
            title="Edit"
          ></FontAwesomeIcon> */}

          <PlayCircleIcon
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          />
        </IconButton>
      ),
    },

    {
      field: "delete",
      type: "actions",
      renderHeader: () => (
        <FontAwesomeIcon
          icon={faTrash}
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        />
      ),
      width: 160,
      hide: false,
      flex: 1.5,
      cellClassName: "actions",
      hide: props._source && props._source.status.status_name === "submited",
      renderCell: (params) => (
        <Typography
          component={"div"}
          onClick={(event) => {
            setConfirmDialog(true);
            event.ignore = true;
            setEditRowsModel(params.row);
          }}
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            padding: "4px 8px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Delete file
        </Typography>
      ),
    },
  ];

  let assetList = [];
  if (props.assetsFilter.hits) {
    props.assetsFilter.hits.hits.forEach((value) => {
      assetList.push(value._source.assets_name);
    });
  }

  let clientIDList = [];
  if (props.assetsFilter.hits) {
    props.assetsFilter.hits.hits.forEach((value) => {
      clientIDList.push(value._source.client_id);
    });
  }

  let streamIDList = [];
  if (props.assetsFilter.hits) {
    props.assetsFilter.hits.hits.forEach((value) => {
      streamIDList.push(value._source.stream_id);
    });
  }

  let trackIDList = [];
  if (props.assetsFilter.hits) {
    props.assetsFilter.hits.hits.forEach((value) => {
      trackIDList.push(value._source.track_id);
    });
  }

  const assetsListHandleChange = (event, assets) => {
    if (assets != null) {
      setAssets(assets);
    } else {
      setAssets("");
    }
  };

  const uniqueList = [...new Set(assetList)];

  const handleFilter = () => {
    const filter = [];

    if (assets !== "") {
      filter.push(`["assets_name.keyword","must","","term","${assets}"]`);
    }

    if (assets !== "") {
      const params = {
        filters: `[${filter}]`,
        // order_by: `[["order_number","asc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetchAssets(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    if (assets !== "") {
      filter.push(`["assets_name.keyword","must","","term","${assets}"]`);
    }
    if (assets !== "" && FilterClicked) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["assets_name.keyword", "asc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["assets_name.keyword", "asc"]]`,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchAssets(params);
    }
  };

  const ClearState = () => {
    // Clear the state
    if (assets !== "") {
      setClearLoader(true);
      setAssets("");
    }
    setFilterClicked(false);
    const params = {
      order_by: `[["assets_name.keyword", "asc"]]`,
      page_limit: 15,
    };
    FilterClicked && fetchAssets(params);
  };

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  function deleteFile() {
    let page_params = {
      current_page: "upload",
    };

    const callBack = (res) => {
      if (props?.releaseById?._source?.tracks) {
        const newTracks = props?.releaseById?._source?.tracks?.map((track) => {
          if (track?.assets_id?.id === res._id.toString()) {
            return {
              track_id: track?.track_id?.id ?? "",
              assets_id: "",
            }; // Set the entire dictionary to an empty object
          } else {
            return {
              track_id: track?.track_id?.id ?? "",
              assets_id: track?.assets_id?.id ?? "",
            }; // Return the original dictionary
          }
        });
        let put_obj = {
          tracks: newTracks,
        };
        let params = { current_page: "upload" };
        props
          .dispatch(
            ReleasesAction.requestPutRelease(
              JSON.stringify(put_obj),
              localStorage.getItem("id"),
              params
            )
          )
          .then(() => {
            props.dispatch(
              ReleasesAction.requestReleaseById(
                localStorage.getItem("id"),
                params
              )
            );
          });
      }
    };
    props
      .dispatch(
        AssetsAction.requestAssetsDelete(
          editRowsModel.id,
          page_params,
          callBack
        )
      )
      .then(() => {
        setConfirmDialog(false);
        let params = {
          page_limit: "none",
          filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
          current_page: "upload",
        };
        fetchAssets(params);
      });
    setEditRowsModel({});
  }

  return (
    <Box sx={{ width: "100%" }}>
      <AssetsPost
        open={open}
        onSubmit={() => {
          // const params = {
          //   order_by: `[["assets_name.keyword", "asc"]]`,
          //   page_limit: 15,
          // };
          let params = {
            page_limit: "none",
            filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
            current_page: "upload",
          };

          props.dispatch(AssetsAction.requestAssets(params));
          // props.dispatch(AssetsAction.requestAssetsFilter(param));
        }}
        onClose={() => setOpen(false)}
        editData={editRowsModel}
        edit={edit}
      />

      <RetriveAssetDialog
        open={retriveAssetOpen}
        onClose={() => setretriveAssetOpen(false)}
        RetriveData={editRowsModel}
        releaseId={localStorage.getItem("id")}
        // edit={edit}
      />

      <AssetsEditName
        //rows={props.rows}
        rows={editingRow}
        open={editNameOpen}
        onClose={(param) => {
          setIsNameChange(param);
          setEditNameOpen(false);
        }}
      />

      <Dialog open={Boolean(confirmDialog)} fullWidth maxWidth="sm">
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Are you sure you want to delete the file ?</h5>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setConfirmDialog(false);
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              deleteFile();
            }}
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>

      {/*************************************************** Tag Filter */}

      {/* <Grid container>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={2} md={3} sm={3.5} mt={1}>
              <AutoCompleteComponent
                value={assets}
                list={uniqueList}
                textFieldProps={{
                  placeholder: "Assets Name",
                }}
                handleChange={assetsListHandleChange}
                size="small"
                width="100%"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={5}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  lg={8}
                  sm={8}
                  md={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Filter"}
                      width="100%"
                      onClick={handleFilter}
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  
                  <PostPlusButton
                    open={open}
                    openCloseModal={openCloseModal}
                    editMode={editMode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}

      {/* data grid */}
      {navigator.onLine ? (
        <Box>
          <Grid
            display="flex"
            justifyContent="center"
            container
            item
            lg={12}
            md={12}
            sm={12}
          >
            <Grid item xl={8} lg={10} md={10} sm={12} xs={12}>
              <LoadingIndicator isActive={isRequesting}>
                {props.assets && props.assets.hits && (
                  <DataGridFunction
                    columns={columns}
                    rows={!initialStart ? props.rows : []}
                    rowHeight={60}
                    height={props.rows.length * 60 + 100}
                    isRequesting={initialStart}
                    // checkboxSelection={true}
                    // height={"100%"}
                    // pagelength={props.pagination.total_page_no}
                    // page={props.pagination.current_page_no}
                    // handleChange={handleChange}
                  />
                )}
              </LoadingIndicator>
            </Grid>
          </Grid>
        </Box>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
    </Box>
  );
};

export default connect(mapStateToProps)(Assets);
