import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { LicenseInfo } from "@mui/x-license";
import PaginationUI from "../pagination/Pagination";
import { Box } from "@mui/material";
import { theme } from "../../views/App";
import { makeStyles } from "@material-ui/styles";
import NoDataFound from "../nodatafound/NoDataFound";

LicenseInfo.setLicenseKey(
  "958fef58ed50f3faf70194d92e62f97eTz03NTIwMixFPTE3MjY5ODIwMTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function DataGridFunction({
  columns,
  rows,
  rowReordering,
  pagination,
  rowHeight,
  getRowHeight,
  onCellClick,
  checkboxSelection,
  cursor,
  getRowId,
  rowBorder,
  rowColor,
  headerColor,
  onSelectionModelChange,
  apiRef,
  onRowClick,
  pagelength,
  onRowOrderChange,
  page,
  handleChange,
  headerHeight,
  height,
  selectionModel,
  getRowClassName,
  isRequesting,
  initialState,
  getDetailPanelHeight,
  getDetailPanelContent,
  columnGroupingModel,
  disableRowSelectionOnClick,
}) {
  const useStyles = makeStyles({
    root: {
      "& .MuiDataGrid-columnSeparator": {
        visibility: "visible !important",
        opacity: "1 !important",
      },

      // header
      "& .MuiDataGrid-columnHeader": {
        padding: "0px !important",
        height: "40px",
      },
      "& .MuiDataGrid-cell": {
        padding: "0px !important", // Adjust as needed
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
        padding: "0px !important",
        lineHeight: "40px",
      },

      "& .MuiDataGrid-row": {
        padding: "0px !important",

        "&:hover": {
          backgroundColor: !getRowClassName && theme.palette.primary.datgrid,
          cursor: cursor === "pointer" ? "pointer" : "default",
        },
      },
      "& .MuiDataGrid-scrollbar": {
        "&::-webkit-scrollbar": {
          width: "6px",
          height: "6px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#b0d2da",
          borderRadius: "3px",
          "&:hover": {
            backgroundColor: "#85bac6",
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        "&::-webkit-scrollbar": {
          width: "6px",
          height: "6px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#b0d2da",
          borderRadius: "3px",
          "&:hover": {
            backgroundColor: "#85bac6",
          },
        },
      },
    },
  });
  const classes = useStyles();

  const CustomPagination = () => {
    return (
      <PaginationUI
        pagelength={pagelength}
        page={page}
        handleChange={handleChange}
      />
    );
  };

  return (
    <Box
      sx={{
        height: rows.length === 0 ? "15vh" : height ? height : "80vh",
        width: "100%",
        "& .MuiDataGrid-root": {
          border: "none", // Remove the outer border of the DataGrid
        },

        "& .MuiDataGrid-columnHeader": {
          backgroundColor: "#212121",
          height: "40px !important",
        },

        "& .MuiDataGrid-cell": {
          height: "52px !important",
          // paddingLeft: "15px !important",
          fontFamily: "BookAntiqua",
          fontSize: "14px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          // textAlign: "left",
          alignContent: "center",
          color: "#adadad", // Set height for data rows
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontFamily: "BookAntiqua",
          fontSize: "16px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          textAlign: "left",
          color: "#adadad",
        },
      }}
    >
      <DataGridPro
        sx={{
          "& .MuiDataGrid-cell": {
            borderTop: "0.5px solid #2b2a2a", // Remove the top border for each cell
          },

          "--DataGrid-rowBorderColor": "transparent", // Removes the row border color
        }}
        slots={{
          // noRowsOverlay:
          //   !isRequesting && rows.length === 0 ? noDataUI : EmptyFooter,
          footer: pagelength ? CustomPagination : () => <></>,
        }}
        className={classes.root}
        rows={rows}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        columnHeaderHeight={headerHeight ? headerHeight : 55}
        hideFooterPagination={true}
        onSelectionModelChange={onSelectionModelChange}
        apiRef={apiRef}
        initialState={initialState}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        columnGroupingModel={columnGroupingModel}
        onRowOrderChange={onRowOrderChange}
        rowReordering={rowReordering}
        density="compact"
        rowHeight={rowHeight}
        getRowHeight={getRowHeight}
        onCellClick={onCellClick}
        getRowId={getRowId ? getRowId : (rows) => rows.id}
        disableVirtualization
        selectionModel={selectionModel}
        disableSelectionOnClick
        onRowClick={onRowClick}
        getRowClassName={
          getRowClassName
            ? getRowClassName
            : (params) => {
                return rowColor ? (params.id % 2 === 0 ? "oddRow" : "") : "";
              }
        }
      />
    </Box>
  );
}

export default DataGridFunction;
