import ActionUtility from "../../utilities/ActionUtility";
import SubscriptionOrderEffect from "./SubscriptionOrderEffect";

export default class SubscriptionOrderAction {
  // SubscriptionOrderAction action with an function declaration
  static REQUEST_SUBSCRIPTION_ORDER =
    "SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER";
  static REQUEST_SUBSCRIPTION_ORDER_FINISHED =
    "SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_FINISHED";

  static REQUEST_SUBSCRIPTION_ORDER_FILTER =
    "SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_FILTER";
  static REQUEST_SUBSCRIPTION_ORDER_FILTER_FINISHED =
    "SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_FILTER_FINISHED";

  //   static REQUEST_POST_SUBSCRIPTION_ORDER =
  //     "SubscriptionOrderAction.REQUEST_POST_SUBSCRIPTION_ORDER";
  //   static REQUEST_POST_SUBSCRIPTION_ORDER_FINISHED =
  //     "SubscriptionOrderAction.REQUEST_POST_SUBSCRIPTION_ORDER_FINISHED";

  static REQUEST_SUBSCRIPTION_ORDER_CLEAR =
    "SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_CLEAR";
  static REQUEST_SUBSCRIPTION_ORDER_CLEAR_FINISHED =
    "SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_CLEAR_FINISHED";

  static REQUEST_SUBSCRIPTION_ORDER_FILTER_CLEAR =
    "SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_FILTER_CLEAR";
  static REQUEST_SUBSCRIPTION_ORDER_FILTER_CLEAR_FINISHED =
    "SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_FILTER_CLEAR_FINISHED";

  // METHODS
  // Organization GET function
  static requestSubscriptionOrder(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER,
        SubscriptionOrderEffect.requestSubscriptionOrder,
        params
      );
    };
  }

  static requestSubscriptionOrderClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_CLEAR,
        SubscriptionOrderEffect.requestSubscriptionOrderClear
      );
    };
  }

  static requestSubscriptionOrderFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_FILTER_CLEAR,
        SubscriptionOrderEffect.requestSubscriptionOrderFilterClear
      );
    };
  }

  static requestSubscriptionOrderFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_FILTER,
        SubscriptionOrderEffect.requestSubscriptionOrderFilter,
        params
      );
    };
  }

  //   static requestPostSubscriptionOrder(data) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         SubscriptionOrderAction.REQUEST_POST_SUBSCRIPTION_ORDER,
  //         SubscriptionOrderEffect.requestPostSubscriptionOrder,
  //         data
  //       );
  //     };
  //   }
}
