import React from "react";
import Pagination from "@mui/material/Pagination";
import { PaginationItem } from "@mui/material";

export default function PaginationUI(props) {
  return (
    <Pagination
      shape="rounded"
      count={props.pagelength}
      page={props.page + 1}
      onChange={props.handleChange}
      renderItem={(page) => (
        <PaginationItem
          {...page}
          disableRipple
          sx={{
            margin: 0,
            padding: 1,
            "&.Mui-selected": {
              borderBottom: "3px solid white",
              fontWeight: "bold",
            },
          }}
        />
      )}
    />
  );
}
