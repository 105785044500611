import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import PostPlusButton from "../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../components/muiButton/MuiButton";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
// import StatusPost from "./StatusPost";
import StatusAction from "../../stores/status/StatusAction";
// import StatusPost from "../status/StatusPost";
import LabelAction from "../../stores/label/LabelAction";
import LabelPost from "./LabelPost";
import ClientAction from "../../stores/client/ClientAction";
import BlockIcon from "@mui/icons-material/Block";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
// import ActionGenerator from "../../stores/boilerPlate/BoilerPlateAction";
// mapstatetoprops function

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    LabelAction.REQUEST_LABELS,
    LabelAction.REQUEST_LABELS_FILTER,
    ClientAction.REQUEST_CLIENT_FILTER,
  ]),
  pagination: state.label.label || [],
  label: state.label.label || [],
  labelFilter: state.labelFilter.labelFilter || [],
  clientFilter: state.clientFilter.clientFilter || [],
});

function LabelGridView(props) {
  //******************************************************** SetState
  const [searchLabel, setSearchLabel] = useState(null);
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // ***************************************************** Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "id",
    //   headerName: "ID",
    //   hide: true,
    // },
    {
      field: "label_name",
      headerName: "Label Name",
      width: 250,
      flex: 3,
    },

    {
      field: "client_id",
      headerName: "Client Name",
      width: 250,
      flex: 3,
      renderCell: (params) => {
        return params?.row?.client_id?.client_name;
      },
    },

    // {
    //   field: "label_status",
    //   headerName: "Label Status",
    //   flex: 3,
    // },

    // {
    //   field: "client_id_object",
    //   headerName: "Client Name",
    //   width: 250,
    //   flex: 3,
    //   hide: true,
    // },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 160,
      hide: false,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => {
        console.log("params", params);
        return (
          <IconButton
            title={
              params.row.label_status && params.row.label_status === "Activated"
                ? "Updation Blocked"
                : "Edit"
            }
            onClick={(event) => {
              if (
                params.row.label_status &&
                params.row.label_status === "Activated"
              ) {
              } else {
                event.ignore = true;
                setEdit(true);
                setOpen(true);
                setEditRowsModel(params.row);
              }
            }}
            sx={{ border: "0" }}
          >
            {params.row.label_status &&
            params.row.label_status === "Activated" ? (
              <BlockIcon
                style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
                title="Edit"
              ></FontAwesomeIcon>
            )}
          </IconButton>
        );
      },
    },
  ];

  const { isRequesting } = props;

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }

    // console.log("ActionGenerator", ActionGenerator.generateActions("LABEL"));
  }, [isRequesting]);
  // *********************************************  fetch methods

  function fetchLabels(params) {
    props.dispatch(LabelAction.requestLabel(params));
  }

  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }

  function fetchClientFilter(param) {
    props.dispatch(ClientAction.requestClientFilter(param));
  }

  // **************************************************  UseEffect
  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "label",
    };

    fetchLabelFilter(param);
    // fetchClientFilter(param);

    const filter = [];
    let params = {};

    if (searchLabel !== null) {
      filter.push(`["label_name.keyword","must","","term","${searchLabel}"]`);
    }

    if (searchLabel !== null) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        current_page: "label",
      };
    } else {
      params = {
        page_number: 0,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
        current_page: "label",
      };
    }
    fetchLabels(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //********************************************************* pagination
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    if (searchLabel !== null) {
      filter.push(`["label_name.keyword","must","","term","${searchLabel}"]`);
    }
    if (searchLabel !== null && FilterClicked) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchLabels(params);
    }
  };

  // *******************************************************  handle Filter
  const handleFilter = () => {
    const filter = [];

    if (searchLabel !== null) {
      filter.push(`["label_name.keyword","must","","term","${searchLabel}"]`);
    }

    if (searchLabel !== null) {
      const params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetchLabels(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  // ********************************************************* DataGrid  rows
  const rows = [];

  let client_name_array = [];

  if (props.clientFilter.hits) {
    props.clientFilter.hits.hits.length !== 0 &&
      props.clientFilter.hits.hits.map((item) => {
        client_name_array.push({
          client_id: item._id,
          client_name: item._source.client_name,
        });
      });
  }

  const getclientnamefunction = (client_id) => {
    let client_name = [];

    let client =
      client_name_array &&
      client_name_array.length !== 0 &&
      client_name_array.filter((val) => {
        return val.client_id === client_id;
      });

    client &&
      client.length !== 0 &&
      client.map((item) => {
        return client_name.push(item.client_name);
      });

    return client_name && client_name.length !== 0 ? client_name[0] : "";
  };

  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const getclientObjectfunction = (client_id) => {
    let client_name = [];

    let client =
      client_name_array &&
      client_name_array.length !== 0 &&
      client_name_array.filter((val) => {
        return val.client_id === client_id;
      });

    client &&
      client.length !== 0 &&
      client.map((item) => {
        return client_name.push({
          client_id: item.client_id,
          client_name: item.client_name,
        });
      });

    return client_name && client_name.length !== 0 ? client_name[0] : "";
  };

  if (props.label.hits) {
    props.label.hits.hitslength !== 0 &&
      props.label.hits.hits.map((item, index) => {
        return rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          _index: item._index,
          _score: item._score,
          label_name: item._source.label_name,
          created_by: item._source.created_by?.user_name,
          client_id: item?._source?.client_id,
          client_id_object: getclientObjectfunction(item._source.client_id),
          order_number: item._source.order_number,
          label_status: item._source.status
            ? convertToTitleCase(item._source.status)
            : "",
        });
      });
  }

  // ****************************************** tag name handle change

  const labelListHandleChange = (event, searchLabel) => {
    if (searchLabel != null) {
      setSearchLabel(searchLabel);
    } else {
      setSearchLabel(null);
    }
  };

  // ********************************************* Tag Filter List
  let labelList = [];
  if (props.labelFilter.hits) {
    props.labelFilter.hits.hits.forEach((value) => {
      labelList.push(value._source.label_name);
    });
  }
  labelList.sort((a, b) => a.localeCompare(b));
  const uniqueList = [...new Set(labelList)];

  //***************************************** */ project nama list

  // ************************************************* clear function

  const ClearState = () => {
    // Clear the state

    if (searchLabel !== null) {
      setClearLoader(true);
      setSearchLabel(null);
    }
    setFilterClicked(false);
    const params = {
      order_by: `[["created_on","desc"]]`,
      page_limit: 15,
    };
    FilterClicked && fetchLabels(params);
  };

  return (
    <React.Fragment>
      {/* Tag post modal screen */}
      <Box>
        <LabelPost
          open={open}
          onSubmit={() => {}}
          onClose={() => setOpen(false)}
          projectEditData={editRowsModel}
          edit={edit}
          client_name_array={client_name_array && client_name_array}
        />

        {/*************************************************** Tag Filter */}

        <Grid container>
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={2} md={3} sm={3.5} mt={1}>
              <AutoCompleteComponent
                value={searchLabel}
                list={uniqueList}
                textFieldProps={{
                  placeholder: "Label Name",
                }}
                handleChange={labelListHandleChange}
                size="small"
                width="100%"
              />
            </Grid>
            <Grid item lg={8} md={8} sm={7}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  item
                  lg={8}
                  sm={8}
                  md={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid item lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Filter"}
                      width="100%"
                      onClick={handleFilter}
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={3}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                  <Grid>
                    {/* post button component*/}
                    <PostPlusButton
                      open={open}
                      openCloseModal={openCloseModal}
                      editMode={editMode}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* data grid */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid item lg={10} md={12} sm={12} xs={12}>
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {isRequesting ? (
                    <Box>
                      <LoadingBgImage />
                    </Box>
                  ) : (
                    <DataGridFunction
                      columns={columns}
                      rows={rows}
                      rowHeight={60}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                    />
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(LabelGridView);
