import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Tooltip,
} from "@mui/material";
import { ErrorMessage, FastField, Field } from "formik";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useState } from "react";

export function Star() {
  return <span style={{ color: "red" }}>*</span>;
}

export function QuestionIcon() {
  return <HelpIcon sx={{ color: "#399af9", fontSize: "16px" }} />;
}

export const menuProps = {
  PaperProps: {
    style: {
      border: `1px solid #707070`,
      borderRadius: 2,
      marginTop: 2,
      maxHeight: "200px",
      maxWidth: "200px",
    },
  },
};

export const HighlightOff = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Typography
      component={"span"}
      sx={{ justifySelf: "end", marginLeft: 3, cursor: "pointer" }}
      onClick={() => props.close()}
    >
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isHovered ? (
          <HighlightOffTwoToneIcon sx={{ fontSize: 20 }} />
        ) : (
          <HighlightOffIcon sx={{ fontSize: 20 }} />
        )}
      </div>
    </Typography>
  );
};

export const inputProps = {
  sx: {
    padding: 0,
    "& legend": { display: "none" },
    "& fieldset": {
      top: 0,
    },
    backgroundColor: "white",
    outline: "none",
    maxHeight: "33px",
    borderRadius: "5px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
    },
  },
};

export const SelectFieldObject = ({
  label,
  name,
  options,
  size,
  placeholder,
  errors,
  touched,
  required,
  disabled,
  title,
}) => {
  return (
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      container
      gap={3}
      display="flex"
      justifyContent="center"
      //alignItems={"center"}
    >
      <Grid
        item
        lg={3}
        md={3}
        sm={3}
        textAlign="right"
        className="form_label"
        style={{ direction: "ltr" }}
        mt={0.5}
      >
        <Typography>
          {label} {required && "*"}
        </Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={8}>
        <Field name={name}>
          {({ field, meta }) => (
            <Tooltip title={title} arrow placement="right-end">
              <Select
                input={<OutlinedInput {...inputProps} disabled={disabled} />}
                MenuProps={menuProps}
                value={field.value ? field.value.id : ""}
                onChange={(event) => {
                  const selectedOption = options.find(
                    (option) => option.id === event.target.value
                  );
                  field.onChange(selectedOption); // Update the hidden field with the selected object
                }}
                {...field}
                displayEmpty
                size={size}
                sx={{
                  width: "100%",
                  height: "30px",
                  backgroundColor: "gray",
                  color: "#ffffff",
                  "& .MuiSelect-select": {
                    textAlign: "left",
                    alignItems: "flex-start",
                  },
                }}
                error={meta.touched && meta.error ? true : false}
              >
                {placeholder && (
                  <MenuItem value="" disabled>
                    <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
                  </MenuItem>
                )}
                {options?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "normal",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          )}
        </Field>
        &nbsp;
        <ErrorMessage name={name} />
      </Grid>
    </Grid>
  );
};
