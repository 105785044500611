import {
  Box,
  Button,
  IconButton,
  ListItem,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import Rettrive from "./Rettrive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReleasesMorePopper from "../../components/poppers/ReleasesMorePopper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { theme } from "../App";
import { useNavigate } from "react-router";
import RaiseTicketPopper from "../../components/poppers/RaiseTicketPopper";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { connect } from "react-redux";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import RaiseIssueDialog from "./RaiseIssueDialog";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DataGridFunction from "../../components/dataGridComponent/CustomDataGrid";

// import "./Rettrive.css";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const ReleaseTable = (props) => {
  const navigate = useNavigate();
  // const [openTerritoryDialog, setOpenTerritoryyDialog] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [openIssueRaiseDialog, setOpenIssueRaiseDialog] = useState(false);
  const [releaseDataIssue, setReleaseDataIssue] = useState(null);
  const [withdrawRequestConfirm, setwithdrawRequestConfirm] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteReleaseId, setDeleteReleaseId] = useState(null);

  const rows = [];

  const issueTypes = [
    "Label Request",
    "Take down request",
    "YT Claim release",
    "Promotion Request",
    "Metadata Request",
  ];

  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  if (props.props.release && props.props.release.hits) {
    props.props.release.hits.hits &&
      props.props.release.hits.hits.length > 0 &&
      props.props.release.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.props.pagination.current_page_no * props.page_limit +
              ++index
            : "",
          id: item._id,
          type_of_release:
            item._source.type_of_release && item._source.type_of_release
              ? item._source.type_of_release.charAt(0).toUpperCase() +
                item._source.type_of_release.slice(1)
              : "",
          status:
            item._source.status && item._source.status.status_name
              ? convertToTitleCase(item._source.status.status_name)
              : "",
          status_obj: item._source.status && item._source.status,
          thumbnail_url: item._source.thumbnail_url,
          title: item._source.title,
          artists: item._source.artists,
          label: item._source.label,
          digital_release_date: item._source.digital_release_date,
          no_of_tracks: item._source.tracks?.length,
          UPC_EAN: item._source.UPC_EAN,
          copyrighted_to: item._source.copyrighted_to,
          featuring_artists: item._source.featuring_artists,
          main_release_date: item._source.main_release_date,
          modified_on: item._source.modified_on,
          pre_order_release_date: item._source.pre_order_release_date,
          production_year: item._source.production_year,
          created_on: item._source.created_on,
          published_by: item._source.published_by,
          producer_catalogue_number: item._source.producer_catalogue_number,
          genre_type: item._source.genre_type,
          sub_genre_type: item._source.sub_genre_type,
          format: item._source.format,
          territory: item._source.territory,
          thumbnail_url: item._source.thumbnail_url,
        });
      });
  }

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  function WithdrawReleaseSubmit(releaseID) {
    let param = {
      current_page: "releases",
    };

    props
      .dispatch(ReleasesAction.RequestWithdrawReleaseFromReview(releaseID))
      .then(() => {
        // let params = {
        //   page_limit: "15",
        //   order_by: '[["modified_on", "desc"]]',
        //   current_page: "releases",
        // };
        // props.dispatch(ReleasesAction.requesReleases(params));

        props.fetchReleasesWithdraw();
      });
    setwithdrawRequestConfirm(null);
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL.NO",
      resizable: false,
      align: "left",
      headerAlign: "left",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu
      width: 90,
      padding: "0px",
      renderCell: (params) => {
        return <Box sx={{ paddingLeft: "15px" }}>{params.value}</Box>;
      },
    },
    {
      width: 187,

      field: "type_of_release",
      sortable: false, // Disable sorting
      headerAlign: "left",
      align: "left",
      disableColumnMenu: true, // Disable the 3 dots menu
      // headerName: "Release  Type",
      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "36.4px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          RELEASE TYPE
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box
            sx={{
              paddingLeft: "36.4px",
              paddingRight: "45px",
              textTransform: "uppercase",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.type_of_release ? params.row.type_of_release : ""}
          </Box>
        );
      },
    },

    {
      width: 187,
      field: "title",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu
      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "36.4px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            // width: "129px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          RELEASE TITLE
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box
            sx={{
              paddingLeft: "36.4px",
              paddingRight: "45px",
              textTransform: "uppercase",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.title}
          </Box>
        );
      },
    },

    {
      width: 126,
      // flex: 1,
      field: "artists",
      hide: true,
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu
      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "26.4px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            // width: "129px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          ARTISTS
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box
            sx={{
              paddingLeft: "26.4px",
              paddingRight: "42px",
              textTransform: "uppercase",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.artists &&
              params.row.artists.length > 0 &&
              params.row.artists.map((artist, index) => (
                <span>{artist.artist_id.artist_name}</span>
              ))}
          </Box>
        );
      },
    },

    {
      width: 131,
      field: "no_of_tracks",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu
      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "27.4px",
            // paddingRight: "9.6px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            // width: "129px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          tracks
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box
            sx={{
              paddingLeft: "53.4px",
              paddingRight: "40px",
              textTransform: "uppercase",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.no_of_tracks}
          </Box>
        );
      },
    },

    {
      width: 149,
      field: "producer_catalogue_number",
      // headerName: "Label",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu
      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "25.4px",
            // paddingRight: "9.6px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            // width: "129px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          Catalog #
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box
            sx={{
              paddingLeft: "29.4px",
              paddingRight: "38px",
              textTransform: "uppercase",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.producer_catalogue_number}
          </Box>
        );
      },
    },

    {
      width: 183,
      // flex: 1,
      field: "main_release_date",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu
      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "31.4px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            // width: "129px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          Release Date
        </Box>
      ),
      renderCell: (params) => {
        let relDate = formatDate(params.row.main_release_date);
        return (
          <Box
            sx={{
              paddingLeft: "31.4px",
              paddingRight: "36px",
              textTransform: "uppercase",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {relDate}
          </Box>
        );
      },
    },

    {
      width: 183,
      // flex: 1,
      field: "modified_on",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu
      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "29.4px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          Last Modified
        </Box>
      ),
      renderCell: (params) => {
        let lastMod = formatDate(params.row.modified_on);
        return (
          <Box
            sx={{
              paddingLeft: "29.4px",
              paddingRight: "31px",
              textTransform: "uppercase",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {lastMod}
          </Box>
        );
      },
    },

    {
      width: 127,
      // flex: 1,
      field: "status",
      cellClassName: "status",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu

      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "24.4px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          status
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box
            sx={{
              fontFamily: "BookAntiqua",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAlign: "left",
              color: "#367989",
              paddingLeft: "24.4px",
              textTransform: "uppercase",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.status}
          </Box>
        );
      },
    },
    {
      width: 161,
      field: "quick_link",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu

      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "29.4px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          Quick Link
        </Box>
      ),
    },
    {
      width: 200,
      field: "plan_exp_date",
      sortable: false, // Disable sorting
      disableColumnMenu: true, // Disable the 3 dots menu

      renderHeader: () => (
        <Box
          sx={{
            paddingLeft: "29.4px",
            fontFamily: "BookAntiqua",
            fontSize: "16px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#adadad",
            textTransform: "uppercase",
          }}
        >
          Plan Expiry Date
        </Box>
      ),
    },
    // {
    //   width: 130,
    //   field: "label",
    //   headerName: "Label",
    //   renderCell: (params) => {
    //     return params.row.label && params.row.label.label_name
    //       ? params.row.label.label_name
    //       : "";
    //   },
    // },

    // {
    //   width: 125,
    //   // flex: 1,
    //   field: "created_on",
    //   headerName: "Created On",
    //   renderCell: (params) => {
    //     return formatDate(params.row.created_on);
    //   },
    // },

    // { width: 130, field: "UPC_EAN", hide: true, headerName: "UPC" },

    {
      width: 50,
      field: "actions",
      type: "actions",
      renderHeader: () => (
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{
            color: theme.palette.background.icon,
            height: "20px",
            width: "20px",
          }}
        />
      ),
      renderCell: (params) => {
        return (
          <IconButton
            title={
              params.row.status_obj?.id === "2"
                ? "Request Withdraw from Release"
                : params.row.status_obj?.id === "1" ||
                  params.row.status_obj?.id === "4" ||
                  params.row.status_obj?.id === "10" ||
                  params.row.status_obj?.id === "9"
                ? "Edit"
                : ""
            }
            sx={{ border: "0" }}
            onClick={(event) => {
              if (
                params.row.status_obj?.id === "1" ||
                params.row.status_obj?.id === "4" ||
                params.row.status_obj?.id === "10" ||
                params.row.status_obj?.id === "9"
              ) {
                event.ignore = true;
                setEdit(true);
                setEditRowsModel(params.row);
                localStorage.setItem("releaseFormOpen", false);
                localStorage.setItem("id", params.id);

                navigate(`/releaseInfo`);
              }

              if (params.row.status_obj?.id === "2") {
                setwithdrawRequestConfirm(params.id);
              }
            }}
          >
            {params.row.status_obj?.id === "1" ||
            params.row.status_obj?.id === "4" ||
            params.row.status_obj?.id === "10" ||
            params.row.status_obj?.id === "9" ? (
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{
                  color: theme.palette.background.icon,
                  height: "20px",
                  width: "20px",
                }}
              ></FontAwesomeIcon>
            ) : (
              params.row.status_obj?.id === "2" && (
                <KeyboardReturnIcon
                  style={{
                    color: theme.palette.background.icon,
                    height: "20px",
                    width: "20px",
                  }}
                />
              )
            )}
          </IconButton>
        );
      },
    },
    // {
    //   width: 50,
    //   field: "delete",
    //   type: "actions",
    //   headerName: (
    //     <FontAwesomeIcon
    //       icon={faTrash}
    //       style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
    //     ></FontAwesomeIcon>
    //   ),
    //   //
    //   // width: 160,
    //   cellClassName: "actions",
    //   renderCell: (params) => (
    //     <IconButton
    //       title="Delete"
    //       sx={{ border: "0" }}
    //       onClick={(event) => {
    //         event.ignore = true;
    //         if (params?.row?.id) {
    //           props
    //             .dispatch(ReleasesAction.requestDeleteRelease(params.row.id))
    //             .then(() => {
    //               const params = {
    //                 order_by: '[["created_on", "desc"]]',
    //                 page_limit: page_limit,
    //               };
    //               fetchReleases(params);
    //               const filterparam = {
    //                 page_limit: "none",
    //                 order_by: `[["created_on","desc"]]`,
    //               };

    //               fetchReleasesFilter(filterparam);
    //             });
    //         }
    //       }}
    //     >
    //       <FontAwesomeIcon
    //         icon={faTrash}
    //         style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
    //       ></FontAwesomeIcon>
    //     </IconButton>
    //   ),
    // },

    {
      width: 50,
      field: "issues",
      type: "actions",
      renderHeader: () => (
        <div title=" Change Request">
          <AddTaskIcon
            style={{
              color: theme.palette.background.icon,
              height: "20px",
              width: "20px",
            }}
          />
        </div>
      ),

      // width: 160,
      cellClassName: "actions",
      renderCell: (params) => (
        <RaiseTicketPopper
          issueTypes={issueTypes}
          onClick={(issueType) => {
            setOpenIssueRaiseDialog(true);
            setReleaseDataIssue({ releaseData: params.row, type: issueType });
          }}
        >
          <div title=" Change Request">
            <AddTaskIcon
              style={{
                color: theme.palette.background.icon,
                height: "20px",
                width: "20px",
              }}
            />
          </div>
        </RaiseTicketPopper>
      ),
    },

    {
      width: 50,
      field: "more",
      type: "actions",
      renderHeader: () => (
        <MoreVertIcon
          style={{
            color: theme.palette.background.icon,
            height: "20px",
            width: "20px",
          }}
        />
      ),
      //
      // width: 160,
      cellClassName: "actions",
      renderCell: (params) => (
        <ReleasesMorePopper
          onClick={(event) => {
            if (params?.row?.id) {
              setDeleteReleaseId(params.row.id);
            }
            setConfirmDialog(true);
          }}
          releaseId={params.row.id}
          deleteloading={props.deleteloading}
          status={params.row.status_obj?.status_name}
        >
          <MoreVertIcon
            style={{
              color: theme.palette.background.icon,
              height: "20px",
              width: "20px",
              cursor: "pointer",
            }}
          />
        </ReleasesMorePopper>
      ),
    },
  ];

  // const territories = useMemo(
  //   () => (
  //     <TerritoryDailog
  //       list={territorySelected}
  //       open={openTerritoryDialog}
  //       onClose={() => {
  //         setOpenTerritoryyDialog(false);
  //       }}
  //     />
  //   ),
  //   [openTerritoryDialog]
  // );

  return (
    <>
      {/* Withdraw confirmation dialog Box */}
      <Dialog open={Boolean(withdrawRequestConfirm)} fullWidth maxWidth="sm">
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Would you like to withdraw your Submit request ?</h5>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setwithdrawRequestConfirm(null);
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              WithdrawReleaseSubmit(withdrawRequestConfirm);
            }}
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Release Confirmation Dialog Box */}
      <Dialog open={Boolean(confirmDialog)} fullWidth maxWidth="sm">
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Are you sure you want to delete the Release ?</h5>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setConfirmDialog(false);
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              props.deleteRelease(deleteReleaseId);
              setConfirmDialog(false);
            }}
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>

      <DataGridFunction
        columns={columns}
        rows={rows}
        isRequesting={props.isRequesting}
        rowHeight={80}
        pagelength={props.props?.pagination?.total_page_no}
        page={props.props?.pagination?.current_page_no}
        handleChange={props.handleChange}
        height={"75vh"}
      />
      <RaiseIssueDialog
        open={openIssueRaiseDialog}
        onClose={() => {
          setOpenIssueRaiseDialog(false);
        }}
        releaseInfo={releaseDataIssue}
        current_page={"releases"}
      />
    </>
  );
};

export default connect(mapDispatchToProps)(ReleaseTable);
