import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import AuthModel from "./models/AuthModel";
import { jwtDecode } from "jwt-decode";

export default class AuthEffect {
  static async requestLogin(
    username,
    password,
    callBackFunction,
    callBackFunctiontwo,
    params,
    callBackFunctionthree
  ) {
    const endpoint = environment.api.login;
    const response = await HttpUtility.post(
      endpoint,
      {
        username: username,
        password: password,
      },
      params
    );

    if (response?.status === 400) {
      console.log("logcheck", response?.raw?.data?.message);
      if (response?.raw?.data?.message === "mobile number is not verified") {
        callBackFunctionthree(response);
      }
    }

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    localStorage.setItem("Auth", JSON.stringify(response.data));
    localStorage.setItem("isFirstLogin",false)
    const token = response.data.access_token;
    const decoded = jwtDecode(token);
    // localstorage set
    localStorage.setItem("roles", JSON.stringify(decoded.realm_access.roles));
    localStorage.getItem("userId") === null &&
      localStorage.setItem("userId", decoded.sub);
    if (response.status === 200) {
      if (localStorage.getItem("userId") !== null) {
        if (localStorage.getItem("userId") === decoded.sub) {
          callBackFunction();
        } else {
          localStorage.clear();
          localStorage.setItem("Auth", JSON.stringify(response.data));
          localStorage.setItem("userId", decoded.sub);

          callBackFunctiontwo();
        }
      } else {
        localStorage.setItem("userId", decoded.sub);
      }
    }
    return new AuthModel(response.data);
  }
}
