import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import { connect } from "react-redux";
import routes from "./routecoll";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
  auth: state.auth,
});
const MyRoute = (props) => {
  return (
    <Suspense fallback={<LoadingIndicator isActive={true} />}>
      <Routes>
        {routes.data
          .filter((col) => col.category === "Main")
          .map((item) => {
            return (
              <Route
                exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}
        {routes.data
          .filter((col) => col.category === "Section_1")
          .map((item) => {
            return (
              <Route
                exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}

        {routes.data
          .filter((col) => col.category === "Release")
          .map((item) => {
            return (
              <Route
                exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}
        {routes.data
          .filter((col) => col.category === "Section_2")
          .map((item) => {
            return (
              <Route
                exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}
      </Routes>
    </Suspense>
  );
};
export default connect(mapStateToProps)(MyRoute);
