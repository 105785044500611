import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ValidationPutModel from "./models/ValidationPutModel";
import ValidationGetByIdModel from "./models/ValidationGetModel";

// ValidationEffect
export default class ValidationEffect {
  static async requestValidationById(id) {
    const endpoint = environment.api.validationRelease + "/" + id;
    return EffectUtility.getToModel(ValidationGetByIdModel, endpoint);
  }

  static async requestPutValidation(data, id) {
    const endpoint = environment.api.validationRelease + "/" + id;
    return EffectUtility.putToModel(ValidationPutModel, endpoint, data);
  }

  // post method for the label
  static async requestPostValidation(data) {
    const endpoint = environment.api.validationRelease + "/add";
    return EffectUtility.postToModel(ValidationPutModel, endpoint, data);
  }
}
