import React, { useEffect } from "react";
import * as echarts from "echarts";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Chip, Grid } from "@mui/material";

export default function ProfileChart({ profileData }) {
  useEffect(() => {
    const chartDom = document.getElementById("profile-completion-chart");
    const myChart = echarts.init(chartDom);

    // Data for the chart
    const completed = profileData.profile_percentage;
    const missing = 100 - completed;

    // ECharts option
    const option = {
      title: {
        // text: `Profile Completion`,
        // subtext: `${completed}% completed`,
        left: "center",
        // top: "10%",
        textStyle: {
          fontSize: 16,
        },
        subtextStyle: {
          fontSize: 14,
          color: "#fff",
        },
      },
      //   tooltip: {
      //     trigger: "item",
      //     formatter: "{a} <br/>{b}: {c} ({d}%)",
      //   },
      series: [
        {
          //   name: "Profile Completion",
          type: "pie",
          radius: ["70%", "80%"],
          avoidLabelOverlap: false,
          label: {
            // show: true,
            position: "center",
            formatter: "{b}: {c}%",
            color: "#fff",
            fontSize: 14,
          },
          emphasis: {
            label: {
              //   show: true,
              fontSize: 18,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          color: ["green", "red"],

          data: [
            { value: completed, name: "Completed" },
            { value: missing, name: "Incomplete" },
          ],
        },
      ],
    };

    // Render the chart
    myChart.setOption(option);

    // Resize chart on window resize
    const resizeChart = () => {
      myChart.resize();
    };
    window.addEventListener("resize", resizeChart);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", resizeChart);
      myChart.dispose();
    };
  }, [profileData]);

  function formatString(input) {
    return input
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words with a space
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        id="profile-completion-chart"
        style={{ width: "100%", height: "200px" }}
      />
      <Box mt={4} width={"100%"}>
        <Typography variant="h6">Missing Fields</Typography>
        {profileData.missing_fields?.length > 0 ? (
          <Grid item container gap={1}>
            {profileData.missing_fields.map((field, index) => (
              <Chip
                label={
                  field ? (
                    <Typography fontSize={{ xl: 12, lg: 10, md: 8, sm: 6 }}>
                      {formatString(field)}
                    </Typography>
                  ) : null
                }
                sx={{
                  border: `0.5px solid red`,
                }}
              />
            ))}
          </Grid>
        ) : (
          <Typography variant="body1">All fields are completed.</Typography>
        )}
      </Box>
    </Box>
  );
}
