import { Box, Grid } from "@mui/material";
import React from "react";
import { theme } from "../../views/App";
import ReactEcharts from "echarts-for-react";

const StreamsComponent = (props) => {
  let totalCount = 0;

  if (props.audioStreamsReport) {
    for (const item of props.audioStreamsReport) {
      totalCount += item.count;
    }
  }

  const seriesData = props.audioStreamsReport?.map((item) => ({
    count: item.count,
    key: item.key,
  }));
  const getOption = {
    tooltip: {
      trigger: "axis",
      formatter: (params) => {
        const key = params[0].axisValue; // The x-axis label
        const count = params[0].data; // The y-axis value

        return `${key}: ${count}`;
      },
      confine: true, // Ensure the tooltip is confined within the chart's bounding box
    },
    grid: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    xAxis: {
      type: "category",
      data: seriesData?.map((item) => item.key),
      axisLabel: {
        rotate: 45, // Optional: Rotate labels for better readability
        show: false, // Hide axis labels
      },
      axisLine: {
        show: false, // Hide the axis line
      },
      axisTick: {
        show: false, // Hide the axis ticks
      },
      splitLine: {
        show: false, // Hide split lines
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: false, // Hide axis labels
      },
      axisLine: {
        show: false, // Hide the axis line
      },
      axisTick: {
        show: false, // Hide the axis ticks
      },
      splitLine: {
        show: false, // Hide split lines
      },
    },
    series: [
      {
        data: seriesData?.map((item) => item.count),
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#367989", // Symbol color
          borderColor: "#367989", // Border color of the symbol
          borderWidth: 1, // Border width of the symbol
        },
        lineStyle: {
          color: "#367989", // Set the line color
        },
        symbol: "circle",
        symbolSize: 6,
      },
    ],
  };
  return (
    <Box
      width={"100%"}
      sx={{
        background: theme.palette.background.grey,
        borderRadius: "12px",
      }}
    >
      <Box paddingY={2} paddingX={2} width="100%">
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          flexDirection={"column"}
          alignItems="flex-start"
          justifyContent={"center"}
        >
          <Grid
            item
            container
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            alignItems="center"
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Box>
              <h6
                style={{
                  color: theme.palette.text.primary,
                  fontWeight: "bold",
                }}
              >
                STREAMS
              </h6>
            </Box>
          </Grid>
          <Grid
            item
            container
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={4}
            alignItems="center"
            justifyContent={"flex-start"}
          >
            <Box height={"100%"}>
              <ReactEcharts
                style={{
                  height: "100%",
                  width: "170%",
                }}
                option={getOption}
              />
            </Box>
          </Grid>{" "}
          <Grid
            item
            container
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            alignItems="center"
            justifyContent={"flex-start"}
            mt={4}
          >
            <Box sx={{ lineHeight: 1.2 }}>
              <div
                style={{
                  color: theme.palette.text.primary,
                  fontWeight: "bold",
                }}
              >
                CURRENT
              </div>
              <div
                style={{
                  fontSize: 32,
                }}
              >
                {totalCount ? totalCount : 0}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StreamsComponent;
