import React, { useEffect, useState } from "react";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { Box, Grid } from "@mui/material";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import DataGridFunction from "../../components/dataGridComponent/CustomDataGrid";
import SubscriptionOrderAction from "../../stores/subscriptionOrders/SubscriptionOrderAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER,
  ]),
  pagination: state.subscriptionOrder.subscriptionOrder || [],
  subscriptionOrder: state.subscriptionOrder.subscriptionOrder || [],
});

function SubscriptionOrders(props) {
  const [page_limit] = useState(15);

  const { isRequesting } = props;

  useEffect(() => {
    const params = {
      page_limit: page_limit,
      order_by: `[["created_on","desc"]]`,
    };
    fetchSubscriptionOrders(params);
  }, []);

  function fetchSubscriptionOrders(params) {
    props.dispatch(SubscriptionOrderAction.requestSubscriptionOrder(params));
  }

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {
      page_number: `${value}` - 1,
      order_by: `[["created_on","desc"]]`,
      page_limit: `${page_limit}`,
    };

    if (value - 1 !== props.pagination.current_page_no) {
      fetchSubscriptionOrders(params);
    }
  };

  console.log("Subscriptions", props);

  const rows = [];

  if (props.subscriptionOrder.hits) {
    props.subscriptionOrder.hits.hits?.length !== 0 &&
      props.subscriptionOrder.hits.hits.map((item, index) => {
        return rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          order_id: item._source.order_id,
          payment_id: item._source.payment_id,
          payment_status: item._source.payment_status,
          signature: item._source.plan_info?.signature,
          subscription_ids: item._source.subscription_ids,
          amount: item._source.amount,
          discount: item._source.bill_info.discount_deduct["super saver"],
          gross_total: item._source.bill_info.gross_total,
          net_total: item._source.bill_info.net_total,
          price: item._source.bill_info.price,
          rounded_net_total: item._source.bill_info.rounded_net_total,
          quantity: item._source.bill_info.qunatity,
        });
      });
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      // flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "order_id",
      headerName: "Order ID",
      width: 150,
      // flex: 3,
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      width: 150,
      // flex: 3,
    },
    {
      field: "payment_status",
      headerName: "Payment status",
      width: 150,
      // flex: 3,
    },

    {
      field: "subscription_ids",
      headerName: "Subscription IDs",
      width: 150,
      // flex: 3,
    },

    {
      field: "price",
      headerName: "Price",
      width: 150,
      // flex: 3,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      // flex: 3,
    },

    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      // flex: 3,
    },

    {
      field: "gross_total",
      headerName: "Gross Total",
      width: 150,
      // flex: 3,
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 150,
      // flex: 3,
    },

    {
      field: "rounded_net_total",
      headerName: "Rounded Net Total",
      width: 150,
      // flex: 3,
    },
  ];

  return (
    <React.Fragment>
      <Box>
        {navigator.onLine ? (
          <Box>
            <Grid
              container
              item
              lg={12}
              xl={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                height: "-webkit-fill-available",
                px: 2,
              }}
            >
              <LoadingIndicator isActive={isRequesting}>
                {isRequesting ? (
                  <Box>
                    <LoadingBgImage />
                  </Box>
                ) : (
                  <DataGridFunction
                    columns={columns}
                    rows={rows}
                    rowHeight={60}
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChange}
                  />
                )}
              </LoadingIndicator>
            </Grid>
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(SubscriptionOrders);
