import BaseReducer from "../../utilities/BaseReducer";
import SubscriptionOrderAction from "./SubscriptionOrderAction";

// StatusReduser
export default class SubscriptionOrderReducer extends BaseReducer {
  initialState = {
    subscription: [],
  };
  [SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_FINISHED](state, action) {
    return {
      ...state,
      subscriptionOrder: action.payload,
    };
  }

  [SubscriptionOrderAction.REQUEST_SUBSCRIPTION_ORDER_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      subscriptionOrder: [],
    };
  }
}
