import React from "react";
import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// loading
function LoadingIndicator(props) {
  const { children, isActive } = props;

  return (
    <Box width="100%">
      {isActive && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {children}
    </Box>
  );
}

export default LoadingIndicator;
