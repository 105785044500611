import { BaseModel } from "sjs-base-model";

// LabelModel
export default class UserProfilePercentageModel extends BaseModel {
  // response getting from the api mentioned end point
  allow = "";
  base_plan = "";
  _primary_term = "";
  email_verified = "";
  missing_fields = [];
  mobile_verified = "";
  profile_percentage = "";
  constructor(data1) {
    super();

    this.update(data1);
  }
}
