import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../../stores/releases/ReleasesAction";
import { connect } from "react-redux";
import Rettrive from "../../release/Rettrive";
import { styled } from "@mui/system";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_FINAL_RELEASES,
  ]),
  finalReleases: state.finalReleases.finalReleases || [],
});

const StyledTypography = styled(Typography)({
  fontSize: {
    xl: "14px",
    lg: "8px",
  },
});
const HeadingTypography = styled(Typography)({
  fontSize: {
    xl: "18px",
    lg: "10px",
  },
});

const ReleasedDetails = (props) => {
  const [latestData, setLatestData] = useState("");

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (props.finalReleases.hits) {
      setLatestData(props.finalReleases.hits.hits);
    }
  }, [props.finalReleases.hits]);

  useEffect(() => {
    latestRelease();
  }, []);

  function latestRelease() {
    const params = {
      filter: `[["status.keyword", "must", "", "match", "8"]]`,
      order_by: `[["main_release_date","desc"]]`,
      page_limit: "1",
    };
    props.dispatch(ReleasesAction.requestFinalReleases(params));
  }

  return (
    <>
      <Grid
        item
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "stretch", // Makes both grids take the same height
          // paddingY: { xl: "32px", lg: "18px" },
        }}
      >
        {latestData.length > 0 ? (
          <>
            <Grid
              item
              xs={12}
              md={12}
              xl={5}
              sm={12}
              lg={4}
              laptop={4}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginRight: { lg: "25px", laptop: "25px", xl: "45px" }, // Add margin to the right of the first grid
              }}
            >
              <Box
                sx={{
                  width: {
                    xl: "240px",
                    md: "150px",
                    laptop: "129px",
                    sm: "100px",
                    lg: "129px",
                  },
                  height: {
                    xl: "240px",
                    md: "150px",
                    sm: "100px",
                    lg: "129px",
                    laptop: "129px",
                  },
                }}
              >
                <Rettrive
                  thumbnail_url={latestData[0]._source.thumbnail_url}
                  release_id={latestData[0]._id}
                  current_page={"dashboard"}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column", // Ensures the content stacks properly
              }}
            >
              <HeadingTypography
                sx={{
                  fontFamily: "Inter",
                  fontSize: { xl: 18, laptop: 9, lg: 9, sm: 14 }, // Responsive font sizes
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: { xl: "23px", lg: "13px", laptop: "13px" },
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#e2e2e2",
                  textTransform: "uppercase",
                  paddingBottom: { lg: "4px", laptop: "4px", xl: "15px" },
                }}
              >
                {latestData[0]._source.title}
              </HeadingTypography>

              <Grid
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  // lineHeight: { lg: 2.13, laptop: 2.13, xl: 2.21 },
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "var(--white)",
                  display: "flex",
                  fontSize: { xl: 14, laptop: 8, lg: 8, sm: 14 },
                }}
              >
                <Grid container>
                  <Grid item xs={5}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Version
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : 24
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={5}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Label
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : {latestData[0]._source.label.label_name}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={5}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Parent Label
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : Qwertyfy
                    </StyledTypography>
                  </Grid>

                  {/* <Grid item xs={5}>
                    <StyledTypography>UPC</StyledTypography>
                  </Grid> */}
                  {/* <Grid item xs={7}>
                    <StyledTypography>
                      : {latestData[0]._source.UPC_EAN}
                    </StyledTypography>
                  </Grid> */}

                  <Grid item xs={5}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Catalog No
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : {latestData[0]._source.producer_catalogue_number}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={5}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Genre
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : {latestData[0]._source.genre_type.genre_name}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={5}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Compilation
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : Nil
                    </StyledTypography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Release Date
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : {formatDate(latestData[0]._source.main_release_date)}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Original Re-Date
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      :{" "}
                      {formatDate(latestData[0]._source.physical_release_date)}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Pre-Order Date
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : {latestData[0]._source.pre_order_release_date}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      Price Tier
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : {latestData[0]._source.plan_id}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      C Line
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : {latestData[0]._source.copyrighted_to}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      P Line
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : {latestData[0]._source.published_by}
                    </StyledTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      PDF Booklet
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTypography
                      sx={{
                        lineHeight: { lg: "17px", laptop: "17px", xl: "31px" },
                      }}
                    >
                      : XYZ
                    </StyledTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          "no data found"
        )}
      </Grid>
    </>
  );
};

export default connect(mapStateToProps)(ReleasedDetails);
