import BaseReducer from "../../utilities/BaseReducer";
import ReleasesShadowTableAction from "./ReleasesShadowTableAction";

// ReleasesShadowTableReduser
export default class ReleasesShadowTableReduser extends BaseReducer {
  initialState = {
    label: [],
  };
  [ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      releasesShadowTable: action.payload,
    };
  }

  [ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      releasesShadowTable: [],
    };
  }
}
