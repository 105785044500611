import BaseReducer from "../../utilities/BaseReducer";
import StatusAction from "./StatusAction";

// StatusReduser
export default class StatusReduser extends BaseReducer {
  initialState = {
    status: [],
  };
  [StatusAction.REQUEST_STATUS_FINISHED](state, action) {
    return {
      ...state,
      status: action.payload,
    };
  }

  [StatusAction.REQUEST_STATUS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      status: [],
    };
  }
}
