import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { theme } from "../../views/App.js";

const PaymentFailed = () => {
  return (
    <Card
      sx={{
        maxWidth: { xs: 200, sm: 300, md: 400 },
        height: { xs: 200, sm: 300, md: 400 },
        mx: "auto",
        mt: 5,
        textAlign: "center",
        boxShadow: 3,
        background: theme.palette.background.grey,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <CancelOutlinedIcon
            sx={{
              fontSize: { xs: 30, sm: 50, md: 70, },
              color: "red",
            }}
          />
        </Box>
        <Typography variant="h5" gutterBottom>
          Payment Failed
        </Typography>
        <Typography variant="body1" gutterBottom>
          Unfortunately, your payment could not be processed. <br />
          Please try again or contact customer support.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PaymentFailed;
