import React from "react";
import { useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function MuiButton({
  name,
  type,
  onClick,
  width,
  padding,
  title,
  disabled,
  loading,
  noTextOnLoading,
  minWidth,
  fontSize,
  size,
  height,
  backgroundColor,
  radius,
  ref,
  background,
  borderRadius,
  textTransform,
  letterSpacing,
  color,
  fontweight,

}) {
  const theme = useTheme();

  return (
    <LoadingButton
      ref={ref}
      disabled={disabled}
      variant="contained"
      type={type}
      loading={loading}
      loadingPosition={noTextOnLoading ? "" : "end"}
      endIcon={noTextOnLoading && loading ? null : name}
      title={title}
      sx={{
        background:
          background === "secondary"
            ? theme.palette.secondary.main
            : background
            ? background
            : theme.palette.primary.main,

        letterSpacing: letterSpacing ? letterSpacing : "none",
        textTransform: textTransform ? textTransform : "none",
        borderRadius: borderRadius
          ? borderRadius
          : theme.palette.buttonComponent.borderRadius.small,
        color: color ? color : theme.palette.text.primary,
        fontSize: fontSize ? fontSize : "14px",
        // marginRight: "10px",
        // width: 200,
        "& .MuiButton-endIcon": {
          margin: 0,
        },
        height: height ? height : "38px",
        width: width ? width : "auto",
        cursor: disabled ? "not-allowed" : "default",
        padding: padding ? padding : "0px",
        minWidth: minWidth,
        fontWeight: fontweight ? fontweight : "normal",
        "&:hover": {
          backgroundColor:
            background === "secondary"
              ? theme.palette.secondary.hover
              : theme.palette.secondary.primary,
          color: backgroundColor ? "white" : "#d6ebf0",
        },
      }}
      onClick={onClick}
    ></LoadingButton>
  );
}
