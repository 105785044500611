import BaseReducer from "../../utilities/BaseReducer";
import ValidationAction from "./ValidationAction";

export default class ValidationByIdReducer extends BaseReducer {
  initialState = {
    validateReleaseById: [],
  };
  [ValidationAction.REQUEST_VALIDATION_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      validateReleaseById: action.payload,
    };
  }
}
