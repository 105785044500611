import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import AlbumIcon from "@mui/icons-material/Album";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import FaceIcon from "@mui/icons-material/Face";
import PeopleIcon from "@mui/icons-material/People";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TopTracks from "./components/TopTracks";
import TopReleases from "./components/TopReleases";
import TopArtists from "./components/TopArtists";
import TopLabels from "./components/TopLabels";
import TopStores from "./components/TopStores";
import TopTerritories from "./components/TopTerritories";
import TopVideos from "./components/TopVideos";
import TopLanguage from "./components/TopLanguage";
import TopComposer from "./components/TopComposer";
import TopAlbum from "./components/TopAlbum";
import TopLyricist from "./components/TopLyricist";
import { theme } from "../App";
function Analytics() {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <>
      {" "}
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab
          label="Top tracks"
          icon={<AlbumIcon />}
          sx={{
            color:
              activeTab === 0
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top releases"
          icon={<WorkOutlineIcon />}
          sx={{
            color:
              activeTab === 1
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top artists"
          icon={<FaceIcon />}
          sx={{
            color:
              activeTab === 2
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top labels"
          icon={<PeopleIcon />}
          sx={{
            color:
              activeTab === 3
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top videos"
          icon={<PlayCircleOutlineIcon />}
          sx={{
            color:
              activeTab === 4
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        {/* <Tab label="Top ringtones" icon={<SpeakerPhoneIcon />} /> */}
        <Tab
          label="Top stores"
          icon={<MusicVideoIcon />}
          sx={{
            color:
              activeTab === 5
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top territories"
          icon={<LocationOnIcon />}
          sx={{
            color:
              activeTab === 6
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top Language"
          icon={<PeopleIcon />}
          sx={{
            color:
              activeTab === 7
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top Composer"
          icon={<PeopleIcon />}
          sx={{
            color:
              activeTab === 8
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top Album"
          icon={<PeopleIcon />}
          sx={{
            color:
              activeTab === 9
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="Top Lyricist"
          icon={<PeopleIcon />}
          sx={{
            color:
              activeTab === 10
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
      </Tabs>
      {activeTab === 0 && (
        <div style={{ height: 400, width: "100%" }}>
          <TopTracks />
        </div>
      )}
      {activeTab === 1 && <TopReleases />}
      {activeTab === 2 && <TopArtists />}
      {activeTab === 3 && <TopLabels />}
      {activeTab === 4 && <TopVideos />}
      {activeTab === 5 && <TopStores />}
      {activeTab === 6 && <TopTerritories />}
      {activeTab === 7 && <TopLanguage />}
      {activeTab === 8 && <TopComposer />}
      {activeTab === 9 && <TopAlbum />}
      {activeTab === 10 && <TopLyricist />}
    </>
  );
}

export default Analytics;
