/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv({ baseApi, a3TunesApi, socketApi }) {
  return {
    route: {
      baseRoute: "/react-redux-architecture", // Fixes issue with Github Pages
    },
    api: {
      baseApi: `${baseApi}`,
      a3TunesApi: `${a3TunesApi}`,
      socketApi: `${socketApi}`,
      login: `${baseApi}/login`,
      logout: `${baseApi}/logout`,
      artist: `${baseApi}/artist`,
      assets: `${baseApi}/assets`,
      track: `${baseApi}/track`,
      client: `${baseApi}/client`,
      release: `${baseApi}/musiclabels_release`,
      revenueReport: `${baseApi}/revenue_report`,
      filterRevenueReport: `${baseApi}/revenue_report_filter`,
      trackwiseRevenueSummary: `${baseApi}/musiclabels_track_wise_summary`,
      revenueClaim: `${baseApi}/musiclabels_revenue_claim`,
      clientRevenueBalance: `${baseApi}/musiclabels_client_revenue_balance`,
      revenueClaimRequest: `${baseApi}/revenue_claim_request`,
      netRevenueByMonth: `${baseApi}/net_revenue_by_month`,
      updateBankDetails: `${baseApi}/update_my_bank_details`,

      trackwiseRevenue: `${baseApi}/musiclabels_track_wise_revenue`,
      countrywiseRevenueReport: `${baseApi}/revenue_by_country`,
      labelRevenueReport: `${baseApi}/musiclabels_label_revenue`,
      releasewiseRevenue: `${baseApi}/musiclabels_release_wise_revenue`,
      artistwiseRevenue: `${baseApi}/musiclabels_artist_revenue`,

      storewiseRevenueReport: `${baseApi}/musiclabels_store_revenue`,
      syncplaylink: `${baseApi}/update_track_play_link`,
      subscription_order_callback: `${baseApi}/subscription_order_callback`,
      language: `${baseApi}/listoflanguage`,
      genre: `${baseApi}/genre`,
      subgenre: `${baseApi}/subgenre`,
      withdrawRelease: `${baseApi}/withdraw_release_from_review`,
      release: `${baseApi}/release`,
      plan: `${baseApi}/plan`,
      actor: `${baseApi}/actor`,
      actress: `${baseApi}/actress`,
      director: `${baseApi}/director`,
      status: `${baseApi}/release_status`,
      stream_platform: `${baseApi}/stream_platform`,
      label: `${baseApi}/label`,
      territories: `${baseApi}/territories`,
      register: `${baseApi}/user_register`,
      send_mail_otp: `${baseApi}/send_mail_otp`,
      verify_mail_otp: `${baseApi}/verify_mail_otp`,
      send_mobile_otp: `${baseApi}/send_mobile_otp`,
      verify_mobile_otp: `${baseApi}/verify_mobile_otp`,
      subscriptionPlan: `${baseApi}/subscription_plan`,
      subscriptionBasePlan: `${baseApi}/base_subscription_plan`,
      selectSubscriptionPlan: `${baseApi}/select_subscription_plan`,
      subscription: `${baseApi}/subscription`,
      subscriptionOrder: `${baseApi}/subscription_order`,
      buySuperSaver: `${baseApi}/buy_super_saver`,
      claimSubscription: `${baseApi}/claim_subscription`,
      user_details: `${baseApi}/user_details`,
      uprofile_percentage: `${baseApi}/uprofile_percentage`,
      update_mobile_number: `${baseApi}/update_mobile_number`,
      retriveTrackAsset: `${baseApi}/track_preview`,
      postlabel: `${baseApi}/request_label`,
      mailSubscription: `${baseApi}/mail_subscription`,
      notificationSubscription: `${baseApi}/notification_subscription`,
      userResetPassword: `${baseApi}/reset_password`,
      userforgotPassword: `${baseApi}/forgot_password`,
      fileUpload: `${baseApi}/file_upload`,
      retriveAsset: `${baseApi}/retrive_asset`,
      userprofileupdate: `${baseApi}/update_my_profile_picture`,
      finalReleases: `${baseApi}/final_release`,
      notificationsData: `${baseApi}/a3_notifications_data`,
      notificationsDataBulkUpdate: `${baseApi}/a3_notifications_bulk_update`,
      releasesShadowTable: `${baseApi}/release_shadow`,
      ticket: `${baseApi}/ticket`,
      tickets: `${baseApi}/tickets`,
      // trackRevenue: `${baseApi}/musiclabels_track_revenue`,
      //reports
      audioStreams: `${baseApi}/audio_streams`,
      topArtist: `${baseApi}/top_artists_report`,
      topLabel: `${baseApi}/top_label_report`,
      topTracks: `${baseApi}/top_track_report`,
      topTracksWithDateFilter: `${baseApi}/top_track_with_date_filter`,
      topReleases: `${baseApi}/top_releases_list`,
      topStore: `${baseApi}/top_stores_report`,
      topDevices: `${baseApi}/top_device_type_report`,
      top_territories_report: `${baseApi}/top_territories_report`,
      top_languages_report: `${baseApi}/top_languages_report`,
      top_lyricists_report: `${baseApi}/top_lyricists_report`,
      top_albums_report: `${baseApi}/top_albums_report`,
      top_composers_report: `${baseApi}/top_composers_report`,
      export_revenue_report: `${baseApi}/export_revenue_report`,
    },
    isProduction: true,
    isDevelopment: false,
    isTesting: false,
  };
}
