import React, { useEffect, useState } from "react";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { Box, Grid, useTheme } from "@mui/material";
import TopComposerAction from "../../../stores/topComposer/TopComposerAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import TopLyricistAction from "../../../stores/topLyricist/TopLyricistAction";
import TopAlbumAction from "../../../stores/topAlbum/TopAlbumAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [TopAlbumAction.REQUEST_TOP_ALBUM]),
  top_albums_report: state.top_albums_report.top_albums_report || [],
  pagination: state.top_lyricists_report.top_lyricists_report || [],
});

function TopAlbum(props) {
  console.log("props", props);
  const theme = useTheme();
  const { isRequesting } = props;

  useEffect(() => {
    let params = {
      current_page: "analytics",
    };
    fetchtopalbum(params);
  }, []);

  function fetchtopalbum(params) {
    props.dispatch(TopAlbumAction.requestTopAlbum(params));
  }

  const rows = [];
  props?.top_albums_report?.top_albums_list?.map((item, index) => {
    const artistname = item.artists?.artists[0]?.artist_id.artist_name;
    const streamplatformname =
      item?.artists?.artists[0]?.artist_id?.stream_platforms?.map(
        (itemone) => itemone.stream_platform_name
      );

    rows.push({
      sl_no: ++index,
      id: ++index,
      audio_streams: item.audio_streams,
      main_release_date: item.main_release_date,
      stream_percentage: item.stream_percentage,
      title: item.title,
      artist_name: artistname,
      stream_platform_name: streamplatformname,
    });
  });

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "artist_name",
      headerName: "Artist Name",
      hide: true,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 3,
    },

    {
      field: "audio_streams",
      headerName: "Audio Streams",
      flex: 3,
    },

    {
      width: 280,
      field: "main_release_date",
      headerName: "Main Release Date",
      flex: 3,
    },

    {
      field: "stream_percentage",
      headerName: "Stream Percentage",
      flex: 3,
    },

    {
      field: "stream_platform_name",
      headerName: "Stream Platform Name",
      flex: 3,
    },
  ];
  return (
    <React.Fragment>
      {!isRequesting ? (
        <Grid
          container
          item
          xs={12}
          pr={1}
          xl={12}
          lg={12}
          md={12}
          justifyContent="flex-start"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
            <LoadingIndicator isActive={isRequesting}>
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={80}
                isRequesting={isRequesting}
              />
            </LoadingIndicator>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(TopAlbum);
