import React from "react";
import LoginImage from "../../Images/LoginImage.webp";
import Tunes from "../../Images/A3Tunes.webp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { theme } from "../../views/App";
function LoginBackground({ isMobile }) {
  return (
    <>
      {!isMobile && (
       <Grid container item xs={12} md={6}>
  <Box
    sx={{
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img
      src={LoginImage}
      alt="Login Image"
      loading="eager"
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover", // Use "cover" to maintain aspect ratio
      }}
    />
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.text.primary,
        textAlign: "center",
        // backgroundColor: "yellowgreen",
        padding: 2,
        borderRadius: 1, 
      }}
    >
     <Grid container direction="column" alignItems="center" spacing={3}>
  <Grid item xs={12} lg={1} xl={2} md={1} sm={1}>
    <img
      src={Tunes}
      alt="Tunes"
      style={{
        width: "341px",
        height: "206px",
        objectFit: "contain",
      }}
    />
  </Grid>
  <Grid container direction="column" alignItems="center" spacing={3} style={{ marginTop: "35px" }}>
    <Grid item xs={12} lg={12} xl={12} md={12} sm={12}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography
        component="p"
        sx={{
          fontSize: "31px",
          color: theme.palette.primary.loginbackgroundsecondary,
          textTransform: 'uppercase',
          textAlign: 'center', // Center-align text within the Typography
        }}
      >
        Join the A3 Tunes Community
      </Typography>
    </Grid>
    <Grid item xs={12} lg={12} xl={12} md={12} sm={12}  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , mx: {
      xs: '10px',
      sm: '20px', 
      md: '30px', 
      lg: '112px', 
      xl: '210px'  
    }}}>
      <Typography
        variant="body1"
        component="p"
        sx={{
          fontSize: "18px",
          color: theme.palette.primary.loginbackgroundtext,
          textAlign: 'center', // Center-align text within the Typography
        }}
      >
        Ready to take your music career to the next level? Sign up
        for A3 Tunes and start distributing your music
        effortlessly. Join thousands of independent artists who
        trust us to deliver their music worldwide.
      </Typography>
    </Grid>
  </Grid>
</Grid>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
}

export default LoginBackground;
