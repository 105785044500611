import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import MuiSnackbarAlert from "../../components/muiSnackbar/MuiSnackbarAlert";
import { theme } from "../App";
import A3ReportsAction from "../../stores/reportsA3/A3ReportsAction";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import { getRandomColor } from "./ReportsReusedFunctions";
import ReactEcharts from "echarts-for-react";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    A3ReportsAction.REQUEST_A3LABEL_REVENUE_REPORT,
  ]),
  labelRevenueReport: state.labelRevenueReport.labelRevenueReport || [],
});

function LabelRevenueReport(props) {
  const { isRequesting, reportViewType } = props;
  const [alertPopUp, setAlertPopUp] = useState(false);

  function requestLabelRvenueReport(params) {
    props.dispatch(A3ReportsAction.requestLabelRevenueReport(params));
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "key",
      headerName: `Label Name`,
      flex: 3,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 3,
    },
    {
      field: "percentage",
      headerName: "Percentage (%)",
      flex: 3,
    },
  ];

  const rows = [];
  if (props.labelRevenueReport?.label_wise_revenue) {
    props.labelRevenueReport?.label_wise_revenue?.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: index + 1,
        revenue: item.revenue,
        key: typeof item.key === "string" ? item.key : item.key.label_name,
        percentage: item.percentage,
      });
    });
  }

  const colors = [
    `${theme.pieChart.color1}`,
    `${theme.pieChart.color2}`,
    `${theme.pieChart.color3}`,
    `${theme.pieChart.color4}`,
    `${theme.pieChart.color5}`,
    `${theme.pieChart.color6}`,
  ];

  let countData = [];
  let nameLabels = [];
  let percentlables = [];

  nameLabels = rows?.map((item) => {
    return item.key;
  });

  countData = rows?.map((item) => {
    return item.revenue;
  });

  let percentagedata = [];

  percentagedata = rows.map((item) => {
    return Number(item.percentage).toFixed(2);
  });

  countData &&
    countData.map((item, index) =>
      index < colors.length ? null : colors.push(getRandomColor())
    );

  const state = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        type: "doughnut",
        label: nameLabels && nameLabels,
        data: countData && countData,
        borderWidth: 2,
        backgroundColor: colors,
      },
    ],
  };
  console.log(rows);
  const getOptions = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    const seriesData = rows.map((item, index) => ({
      name: item.key,
      value: item.revenue,
      itemStyle: {
        color: colors[index], // Use theme colors dynamically
      },
    }));
    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          // params.percent gives you the percentage of the data item
          return `${colorSpan} ${formattedvalue}: ${params.value} (${params.percent}%)`;
        },
      },
      legend: {
        orient: "vertical",
        icon: "circle", // Set the orientation of the legend=        icon: "circle",
        right: 10,
        top: "15%",
        type: "scroll",
        itemGap: 20,
        itemStyle: {
          borderColor: "#ffff",
          borderWidth: 1,
        },
        itemHeight: 17,
        textStyle: {
          color: "#ffffff", // Set your desired text color here
        },
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          padAngle: 1,
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            focus: "series",
          },
          labelLine: {
            show: false,
          },

          data: seriesData,
        },
      ],
    };
  };
  useEffect(() => {
    let params = {
      youtube: true,
      current_page: "raw_report",
    };
    requestLabelRvenueReport(params);
  }, []);

  const data = props.labelRevenueReport;

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes popIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            }
            50% {
              transform: scale(1.05);
              box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
    `,
        }}
      />
      {navigator.onLine ? (
        <Grid
          container
          item
          xs={12}
          xl={12}
          lg={12}
          md={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          px={5}
          gap={3}
        >
          <Grid
            item
            sx={{
              fontSize: "24px",
            }}
          >
            <Grid item>
              <span style={{ fontWeight: "bold" }}>
                {formatName("Label Revenue Report")} - Audio Streaming
              </span>
              {/* <span>|</span> */}
              <span
                style={{
                  color: "grey",
                }}
              >
                {/* <DateSelectionPopperReports onDateSelect={handleDateSelection} /> */}
              </span>
              {/* {selectedDate && (
              <>
                <span>|</span>
                <span
                  style={{
                    fontSize: "17px",
                    color: "grey",
                  }}
                >
                  {formatDateRange(selectedDate)}&nbsp;
                </span>
              </>
            )} */}
            </Grid>
          </Grid>

          <hr className="p-0 m-0" style={{ color: theme.palette.text.gray }} />

          <LoadingIndicator isActive={isRequesting}>
            {!isRequesting && props.labelRevenueReport ? (
              <>
                {reportViewType === "grid" ? (
                  <DataGridFunction
                    columns={columns}
                    rows={rows}
                    rowHeight={60}
                    height={"74vh"}
                  />
                ) : data.label_wise_revenue?.length ? (
                  <Typography
                    sx={{
                      width: "90vh",
                      marginLeft: "auto",
                      marginRight: "auto",
                      animation: "popIn 0.4s ease-in-out",
                      border: "2px solid rgba(0, 0, 0, 0.1)",
                      boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#212121",
                      borderRadius: "8px",
                    }}
                  >
                    <div>
                      <ReactEcharts
                        style={{
                          height: "61vh",
                          width: "90%",
                        }}
                        option={getOptions()}
                      />
                    </div>
                  </Typography>
                ) : (
                  <NoDataFound />
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </LoadingIndicator>
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"Please select a filter first"}
      />
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(LabelRevenueReport);
