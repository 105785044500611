import BaseReducer from "../../utilities/BaseReducer";
import TicketsAction from "./TicketsAction";

// TicketsReduser
export default class TicketsReduser extends BaseReducer {
  initialState = {
    tickets: [],
  };
  [TicketsAction.REQUEST_TICKETS_FINISHED](state, action) {
    return {
      ...state,
      tickets: action.payload,
    };
  }

  [TicketsAction.REQUEST_TICKETS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      tickets: [],
    };
  }
}
