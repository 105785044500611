import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import MuiButton from "../../components/muiButton/MuiButton";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import { theme } from "../App";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const MailSubscription = (props) => {
  let initial = {};

  if (props.edit) {
    initial = {
      label:
        props.mail_subscription &&
        props.mail_subscription._source &&
        props.mail_subscription._source.events &&
        props.mail_subscription._source.events.filter((item) => {
          return item.event_name === "label";
        }) &&
        props.mail_subscription._source.events.filter((item) => {
          return item.event_name === "label";
        })[0] &&
        props.mail_subscription._source.events.filter((item) => {
          return item.event_name === "label";
        })[0].subscribed,
      release:
        props.mail_subscription &&
        props.mail_subscription._source &&
        props.mail_subscription._source.events &&
        props.mail_subscription._source.events.filter((item) => {
          return item.event_name === "release";
        }) &&
        props.mail_subscription._source.events.filter((item) => {
          return item.event_name === "release";
        })[0] &&
        props.mail_subscription._source.events.filter((item) => {
          return item.event_name === "release";
        })[0].subscribed,
    };
  } else {
    initial = {
      label: true,
      release: true,
    };
  }

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const passwordButtonRef = React.useRef(null);
  const confirmPasswordButtonRef = React.useRef(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    setShowAlert(false);
  }, [props.open]);

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
        {/* Scope of Formik starts here */}
        <Formik
          initialValues={initial}
          // validate={(values) => {
          //   let errors = {};

          //   return errors;
          // }}
          onSubmit={(values, formikHelpers) => {
            let page_params = {
              current_page: "profile",
            };

            setLoading(true);
            if (props.edit) {
              let post_obj = {};

              Object.assign(post_obj, {
                events: [
                  {
                    event_name: "label",
                    subscribed:
                      typeof values.label === "boolean"
                        ? values.label
                        : values.label[0] === "on"
                        ? true
                        : false,
                  },
                  {
                    event_name: "release",
                    subscribed:
                      typeof values.release === "boolean"
                        ? values.release
                        : values.release[0] === "on"
                        ? true
                        : false,
                  },
                ],
              });

              props
                .dispatch(
                  UserProfileAction.requestPutMailSubsciption(
                    post_obj,
                    page_params
                  )
                )
                .then(() => {
                  setLoading(false);
                  let params = {
                    current_page: "profile",
                  };

                  props.dispatch(
                    UserProfileAction.requestMailSubsciption(params)
                  );
                  props.onClose();
                });
            } else {
              let post_obj = {};

              Object.assign(post_obj, {
                events: [
                  {
                    event_name: "label",
                    subscribed:
                      typeof values.label === "boolean"
                        ? values.label
                        : values.label[0] === "on"
                        ? true
                        : false,
                  },
                  {
                    event_name: "release",
                    subscribed:
                      typeof values.release === "boolean"
                        ? values.release
                        : values.release[0] === "on"
                        ? true
                        : false,
                  },
                ],
              });

              props
                .dispatch(
                  UserProfileAction.requestPostMailSubsciption(
                    post_obj,
                    page_params
                  )
                )
                .then(() => {
                  setLoading(false);
                  let params = {
                    current_page: "profile",
                  };
                  props.dispatch(
                    UserProfileAction.requestMailSubsciption(params)
                  );
                  props.onClose();
                });
            }
          }}
          validateOnBlur={true}
          validateOnChange
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            // Form Tag from Formik
            <Form>
              {/* Content of the dialog Box  */}
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* text Content inside DialogBox  */}
                <DialogContentText sx={{ width: "100%" }}>
                  <div className="text-center pb-2">
                    <div className="row p-3">
                      <div className="col-10 ">
                        <h3
                          className="dailogtext"
                          style={{ color: theme.palette.text.primary }}
                        >
                          <b>
                            <span
                              style={{ color: theme.palette.text.first_letter }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;M
                            </span>
                            ail &nbsp;
                            <span
                              style={{ color: theme.palette.text.first_letter }}
                            >
                              S
                            </span>
                            ubscription &nbsp;
                          </b>
                        </h3>
                      </div>
                      <div className="col-2 closebtn">
                        <IconButton
                          title="Close"
                          onClick={() => {
                            props.onClose();
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
                {/* Form Filed inside Dialog Box  */}
                <Grid
                  item
                  container
                  //   flexDirection={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                  //   className="bg-warning"
                >
                  <Grid
                    item
                    xl={6}
                    md={6}
                    lg={6}
                    sm={6}
                    // className="bg-success"
                    alignItems={"flex-start"}
                    justifyContent="flex-start"
                  >
                    <CheckboxField
                      label="Label Subscription"
                      name="label"
                      value={values.label}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item>
                    <CheckboxField
                      label="Release Subscription"
                      name="release"
                      value={values.release}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              {/* Actions performed from form action buttons  */}
              <DialogActions className="m-1 my-4">
                {/* Calling ButtonComponent to perform form actions  */}
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  display="flex"
                  justifyContent="space-evenly"
                >
                  <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                    <MuiButton
                      name={"Submit"}
                      type="submit"
                      loading={loading}
                      size="large"
                      width="100%"
                      //color="primary"
                      sx={{ backgroundColor: "blue", color: "white" }}
                    />
                  </Grid>
                  <Grid item lg={5} md={5} sm={5} minWidth={200}>
                    <MuiButton
                      name={"Cancel"}
                      type="reset"
                      size="large"
                      width="100%"
                      cancel
                      //color="secondary"
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default connect(mapDispatchToProps)(MailSubscription);

const CheckboxField = ({ label, name, value, onChange }) => {
  return (
    <Grid item lg={12} md={12} sm={12} container>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            style={{ marginTop: "-1px" }}
            onChange={onChange}
          />
        }
        label={label}
        name={name}
        value={value}
        // label={<Typography style={{ marginTop: "17px" }}>{label}</Typography>}
      />
    </Grid>
  );
};
