import React from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// loading
function CircularLoadingIndicator(props) {
  const { children, isActive, size = 40 } = props;

  return (
    <Box>
      {isActive && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={size} />
        </Box>
      )}
      {children}
    </Box>
  );
}

export default CircularLoadingIndicator;
