import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import NotificationAction from "../../stores/notifications/NotificationAction";
import DataGridFunction from "../../components/dataGridComponent/CustomDataGrid";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import NotificationDialog from "./NotificationDialog";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import MuiButton from "../../components/muiButton/MuiButton";
import { theme } from "../App";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    NotificationAction.REQUEST_NOTIFICATIONS,
    NotificationAction.REQUEST_FILTER_NOTIFICATIONS,
  ]),
  Notifications: state.Notifications.Notifications || [],
  NotificationsFilter: state.NotificationsFilter.NotificationsFilter || [],
  pagination: state.Notifications.Notifications || [],
});

const NotificationList = (props) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [notificationsData, setNotificationData] = useState({});
  const [openNotificationDialog, setOpenNotificationsDialog] = useState(false);
  const [page_limit] = useState(15);

  function fetchNotifications(params) {
    props.dispatch(NotificationAction.requestNotifications(params));
  }
  function fetchFilterNotifications() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(NotificationAction.requestFilterNotifications(params));
  }

  useEffect(() => {
    let params = {
      page_limit: page_limit,
    };

    fetchNotifications(params);
    fetchFilterNotifications();
  }, []);

  const rows = [];

  if (props.Notifications && props.Notifications.hits?.hits) {
    props.Notifications.hits.hits.length > 0 &&
      props.Notifications.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          created_by: item._source.created_by,
          created_on: item._source.created_on,
          modified_by: item._source.modified_by,
          modified_on: item._source.modified_on,
          notification_image_url: item._source.notification_image_url,
          notification_msg: item._source.notification_msg,
          notification_msg_read_status:
            item._source.notification_msg_read_status,
          notification_sent: item._source.notification_sent,
          notification_title: item._source.notification_title,
          notification_type: item._source.notification_type,
        });
      });
  }

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 130,
    },

    {
      width: 130,

      field: "notification_title",
      headerName: "Title",
    },
    {
      width: 130,

      field: "notification_msg",
      headerName: "Message",
    },

    {
      width: 130,

      field: "notification_image_url",
      headerName: "Image",
      hide: true,
    },

    {
      width: 130,

      field: "created_by",
      headerName: "Created By",

      renderCell: (params) => {
        return params.row.created_by.user_name
          ? params.row.created_by.user_name
          : "";
      },
    },

    {
      width: 200,

      field: "created_on",
      headerName: "Created On",
      renderCell: (params) => {
        return formatDate(params.row.created_on);
      },
    },

    {
      width: 200,

      field: "modified_on",
      headerName: "Last Updated",
      renderCell: (params) => {
        return formatDate(params.row.modified_on);
      },
    },
    {
      width: 200,

      field: "notification_type",
      headerName: "Notification Type",
      hide: true,
    },
    {
      width: 200,

      field: "notification_sent",
      headerName: "Notification Sent",
      hide: true,
    },
    {
      width: 200,

      field: "notification_msg_read_status",
      headerName: "Read Status",
      hide: true,
    },
  ];

  function BulkUpdateNotification() {
    let unreadNotificationList = [];
    props.NotificationsFilter &&
      props.NotificationsFilter.hits?.hits &&
      props.NotificationsFilter.hits?.hits.filter(
        (item) => item._source.notification_msg_read_status === false
      ).length &&
      (unreadNotificationList = props.NotificationsFilter.hits.hits.filter(
        (item) => item._source.notification_msg_read_status === false
      ));

    let bulkUpdate = [];
    // unreadNotificationList.length &&
    unreadNotificationList.map((item) => {
      return bulkUpdate.push({
        _id: item._id,
        _index: item._index,
        _score: item._score,
        _source: {
          notification_msg_read_status: true,
        },
      });
    });
    unreadNotificationList?.length &&
      props
        .dispatch(
          NotificationAction.requestPutNotificationsBulkUpdate(
            JSON.stringify({ data: bulkUpdate })
          )
        )
        .then(() => {
          let params = {
            page_limit: page_limit,
            page_number: props.pagination.current_page_no,
          };
          let filterParams = {
            page_limit: "none",
          };

          fetchNotifications(params);
          props.dispatch(
            NotificationAction.requestFilterNotifications(filterParams)
          );
        });
  }

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let params = {
      page_number: `${value}` - 1,
      page_limit: `${page_limit}`,
    };
    if (value - 1 !== props.pagination.current_page_no) {
      fetchNotifications(params);
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Grid
        container
        pb={1}
        sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <MuiButton name={"Mark all as read"} onClick={BulkUpdateNotification} />
      </Grid>
      <Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          sx={{
            "& .super-app-theme--true": {
              backgroundColor: theme.palette.datagrid.background.duplicate,

              "&:hover": {
                backgroundColor:
                  theme.palette.datagrid.background.duplicateHover,
              },
            },
            "& .super-app-theme--false": {
              backgroundColor: theme.palette.datagrid.background.datagridCell,

              "&:hover": {
                backgroundColor: theme.palette.datagrid.background.hover,
              },
            },
          }}
        >
          <LoadingIndicator isActive={props.isRequesting}>
            {!props.isRequesting ? (
              <DataGridFunction
                curser="pointer"
                columns={columns}
                rows={rows}
                rowHeight={80}
                pagelength={props.pagination?.total_page_no}
                page={props.pagination?.current_page_no}
                handleChange={handleChange}
                onRowClick={(params) => {
                  setNotificationData(params.row);
                  setOpenNotificationsDialog(true);

                  let post_data = {
                    notification_msg_read_status: true,
                  };

                  params.row.notification_msg_read_status === false &&
                    props
                      .dispatch(
                        NotificationAction.requestPutNotifications(
                          JSON.stringify(post_data),
                          params.row.id
                        )
                      )
                      .then(() => {
                        let params = {
                          page_limit: page_limit,
                          page_number: props.pagination.current_page_no,
                        };
                        let filterParams = {
                          page_limit: "none",
                        };

                        fetchNotifications(params);
                        props.dispatch(
                          NotificationAction.requestFilterNotifications(
                            filterParams
                          )
                        );
                      });
                }}
                getRowClassName={(params) => {
                  return `super-app-theme--${
                    params?.row?.notification_msg_read_status === false
                  }`;
                }}
              />
            ) : (
              <Box>
                <LoadingBgImage />
              </Box>
            )}
          </LoadingIndicator>
        </Grid>
      </Grid>
      <NotificationDialog
        open={openNotificationDialog}
        onClose={() => {
          setOpenNotificationsDialog(false);
        }}
        notificationsData={notificationsData}
      />
    </Box>
  );
};

export default connect(mapStateToProps)(NotificationList);
