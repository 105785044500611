import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import CircleLoader from "../circularLoder/CircleLoader";

const YesOrNoDailog = (props) => {
  return (
    props && (
      <Dialog open={props.open} fullWidth maxWidth="sm">
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>{props.content}</h5>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            style={{
              height: "35px",
              width: "30px",
            }}
            size="small"
            onClick={() => {
              props.cancel();
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              props.submit();
            }}
            style={{
              height: "35px",
              width: "30px",
            }}
          >
            {props.loading ? (
              <CircleLoader width="25px" height="25px" />
            ) : (
              <CheckIcon />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default YesOrNoDailog;
