import "../App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainNav from "../components/mainnav/MainNav";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Toasts from "../components/toasts/Toasts";

export const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000000",
      main: "#030303",
      grey: "#333333",
      darkGrey: "#2e2d2d",
      drawerMenu: "#7c7c7c",
      loginbutton: "#367989",
      textfield: "#808080",
      loginbackground: "#0b0b0b",
      header: "#212121",
      icon: "#2a5e6c",
    },
    dashboardCharts: [
      "#9db9ac",
      "#c67969",
      "#26714f",
      "#9a6fb1",
      "#6e93b7",
      "#006579",
      "#617474",
      "#74b99a",
      "#e2a79b",
    ],
    text: {
      primary: "#FFFFFF",
      validationcolor: "#d32f2f",
      secondary: "#000000",
      white: "#fff",
      createaccountcolor: "#efefef",
    },
    primary: {
      main: "#367989",
      hover: "#2e6776",
      loginbackgroundtext: "#9b9b9b",
      loginbackgroundsecondary: "#e3e3e3",
      otptextcolor: "#e2e2e2",
    },
    secondary: {
      main: "#a09153",
      hover: "#8c7a4a",
    },
    borderColor: {
      textField: "#a0bfc9",
      imageBox: "#e7eef0",
    },
    addButton: {
      height: "40px",
      width: "40px",
      borderRadius: "20%",
    },
    // Datagrid
    datagrid: {
      background: {
        datagridCell: "#000000",
        datagridHeader: "#bee1e5",
        hover: "#eeeeee",
        duplicate: "#444",
        duplicateHover: "#eeeeee",
      },
      Columns: {
        fontSize: 14,
      },
    },
    calendar: {
      background: {
        header: "#d0dfe3",
        body: "#f3f9fb",
      },
    },
    buttonComponent: {
      borderRadius: {
        small: "5px",
        medium: "10px",
        large: "25px",
      },
      background: {
        cancel: "#d8aa4f",
        primary: "#57828e",
        success: "#35C37D",
        error: "#ff4444",
      },
      fontSize: {
        small: 10,
        medium: 12,
        large: 14,
        extraLarge: 16,
      },
      hover: {
        primary: "#0b2e36",
        success: "#035029",
        error: "#CC0000",
      },
    },
    link: "#367989",
    dropdown: "#83acb6",
    secondarycolor: "#898989",
    goldenYellow: "#a09153",
  },
  pieChart: {
    color1: "#fc8d72",
    color2: "#22bcba",
    color3: "#cae5e1",
    color4: "#b8c7c0",
    color5: "#41808e",
    color6: "#a69bac",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
    },
  },
  typography: {
    fontWeight: {
      regular: "normal",
      bold: "bold",
    },
    fontSize: {
      tiny: 10,
      extraSmall: 11,
      small: 12,
      normal: 14,
      medium: 18,
      Largesmall: 20,
      large: 24,
      extraLarge: 30,
      huge: 32,
    },
    fontFamily: "Inter",
    fontStyle: "normal",
    fontStretch: "normal",
    appIcon: {
      width: 25,
      height: 25,
    },
  },
});

function App() {
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [navigator.onLine]);

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainNav online={online} />
          <Toasts />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
