import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormGroup,
  styled,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import {
  inputProps,
  menuProps,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import SubscriptionPlansAction from "../../stores/subscriptionPlans/SubscriptionPlansAction";
import RazorPayPayment from "../razorPay/RazorPayPayment";
import { theme } from "../App";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiButton from "../../components/muiButton/MuiButton";
import { useNavigate } from "react-router";
import subscriptionplanBG from "../assets/subscriptionplanBG.png";
import img2 from "../assets/2.png";
import img3 from "../assets/3.png";
import img4 from "../assets/4.png";
import img5 from "../assets/5.png";
import img6 from "../assets/6.png";
import img7 from "../assets/7.png";
const PlanCard = ({ input, selectedPlan }) => {
  const plan = input?._source;

  const booleanKeys = Object.keys(plan).filter(
    (key) => typeof plan[key] === "boolean" && plan[key] === true
  );

  const booleanNegKeys = Object.keys(plan).filter(
    (key) => typeof plan[key] === "boolean" && plan[key] === false
  );

  // Get the keys that are not boolean
  const nonBooleanKeys = Object.keys(plan).filter(
    (key) => typeof plan[key] !== "boolean" && typeof plan[key] !== "object"
  );

  const capitalizeAndRemoveUnderscores = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const BenefitList = styled(Box)(({ theme }) => ({
    textAlign: "center",
    margin: theme.spacing(2, 0),
  }));

  const BenefitStringItem = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
  }));

  const BenefitItem = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    "&:before": {
      content: '"✔"',
      marginRight: theme.spacing(1),
      color: "#fff",
    },
  }));

  const BenefitNegItem = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    textDecoration: "line-through",
    "&:before": {
      content: '"x"',
      marginRight: theme.spacing(1),
      color: "#fff",
      textDecoration: "none", // Reset text-decoration for the pseudo-element
    },
  }));

  return (
    <Box
      border={1}
      borderRadius={2}
      p={2}
      m={2}
      sx={{
        backgroundColor:
          selectedPlan?._id === input._id
            ? theme.palette.secondary.main
            : "transparent",

        color: "#fff",
        padding: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
        "&:before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: "linear-gradient(to bottom right, #000, #FEE101)",
          opacity: 0.2,
        },
      }}
    >
      <Typography variant="subtitle1">{plan.sub_plan_name}</Typography>
      <Typography variant="body2">Release Type: {plan.release_type}</Typography>

      <hr
        style={{
          backgroundColor: "gray",
        }}
      />
      <Box mt={2}>
        <BenefitList>
          {nonBooleanKeys.map((key) => (
            <BenefitStringItem key={key}>
              {`${capitalizeAndRemoveUnderscores(key)}: ${plan[key]}`}
            </BenefitStringItem>
          ))}

          {booleanKeys.map((key) => (
            <BenefitItem key={key}>
              {capitalizeAndRemoveUnderscores(key)}
            </BenefitItem>
          ))}
          {booleanNegKeys.map((key) => (
            <BenefitNegItem key={key}>
              {capitalizeAndRemoveUnderscores(key)}
            </BenefitNegItem>
          ))}
        </BenefitList>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    SubscriptionPlansAction.REQUEST_SUBSCRIPTION_PLANS,
    UserProfileAction.REQUEST_USERPROFILE,
  ]),
  subscriptionPlan: state.subscriptionPlan.subscriptionPlan || [],
  userprofile: state.userprofile.userprofile || [],
});

const SubscriptionPlans = (props) => {
  const [highlightedPlan, setHighlightedPlan] = useState(null);
  const [userBasePlanId, setUserBasePlanId] = useState(0);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [superSaverSelected, setSupersaverSelected] = useState(false);
  const [superSaverQuantity, setSupersaverQuantity] = useState(2);
  const navigate = useNavigate();
  function fetchUserprofile(callback) {
    props.dispatch(UserProfileAction.requestUserprofile({}, callback));
  }

  let filteredPlans = [];
  let groupedPlans = [];

  if (props?.subscriptionPlan?.hits?.hits) {
    filteredPlans = props?.subscriptionPlan?.hits?.hits?.map((plan) => plan);

    groupedPlans = filteredPlans?.reduce((acc, plan) => {
      const planName = plan._source.plan_name;
      if (!acc[planName]) {
        acc[planName] = [];
      }
      acc[planName].push(plan);
      return acc;
    }, {});
  }

  let PlanNameToDisplayTypeOfPlans = groupedPlans[
    Object.keys(groupedPlans)
  ]?.map((item) => item._source.sub_plan_name);
  const getSelectedPlanObj = (params) => {
    let plansArray = groupedPlans[Object.keys(groupedPlans)[0]];
    return plansArray?.find((item) => item._source.sub_plan_name === params);
  };
  const fetchSubscriptionPlans = (plan_id) => {
    const param = {
      filters: `[["subscription_plan_id.keyword","must","","match","${plan_id}"]]`,
    };

    props.dispatch(SubscriptionPlansAction.requestSubscriptionPlans(param));
  };

  useEffect(() => {
    const callback = (response) => {
      let planId;
      if (response) {
        planId = response._source.subscription_plan_id;

        setUserBasePlanId(planId);
        fetchSubscriptionPlans(planId);
      }
    };

    fetchUserprofile(callback);
  }, []);

  const PlanName = "Professional Plan";
  //         groupedPlans[planName].map((plan));

  return (
    // !props.isRequesting && (
    //   <Box>
    //     <Grid item container>
    //       {Object.keys(groupedPlans)?.map((planName) =>
    //         groupedPlans[planName].map((plan) => (
    //           <Grid item xs={12} sm={12} md={6} lg={6} key={plan._id}>
    //             <Typography
    //               component={"div"}
    //               sx={{
    //                 cursor: "pointer",
    //               }}
    //               onClick={() => {
    //                 setSelectedPlan(plan);
    //               }}
    //             >
    //               <PlanCard input={plan} selectedPlan={selectedPlan} />
    //             </Typography>
    //           </Grid>
    //         ))
    //       )}
    //     </Grid>
    //     <Typography
    //       component={"div"}
    //       sx={{
    //         border: `2px dashed ${theme.palette.primary.main}`,
    //         padding: 3,
    //         borderRadius: 1,
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "space-evenly",
    //         marginX: 2,
    //         flexDirection: {
    //           xs: "column",
    //           sm: "column",
    //           md: "row",
    //           lg: "row",
    //           xl: "row",
    //         },
    //       }}
    //     >
    //       <FormGroup>
    //         <FormControlLabel
    //           control={
    //             <Checkbox
    //               checked={superSaverSelected}
    //               onChange={() => {
    //                 setSupersaverSelected(!superSaverSelected);
    //               }}
    //             />
    //           }
    //           label="Buy Super Saver"
    //         />
    //       </FormGroup>
    //       {superSaverSelected && (
    //         <Box sx={{ textAlign: "left" }}>
    //           <Typography>Quantity</Typography>
    //           <MuiTextField
    //             name={"Quantity"}
    //             type={"number"}
    //             value={superSaverQuantity}
    //             width={250}
    //             onChange={(e, v) => {
    //               setSupersaverQuantity(e.target.value);
    //             }}
    //           />
    //         </Box>
    //       )}
    //     </Typography>
    //     <Grid mt={1}>
    //       <RazorPayPayment
    //         selectedPlan={selectedPlan}
    //         superSaverQuantity={superSaverQuantity}
    //         superSaverSelected={superSaverSelected}
    //       />
    //     </Grid>
    //   </Box>
    // )
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        backgroundImage: `url(${subscriptionplanBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      {!props.isRequesting && (
        <Grid container>
          <Grid item xs={5}>
            {/* Content for the left side of the grid */}
            {/* You can add any content here */}
          </Grid>
          <Grid
            container
            xs={7}
            sx={{ paddingRight: "230px" }}
            direction="column"
          >
            <Grid item xs={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  // Adjusted padding as per your original request
                }}
              >
                {/* Conditional rendering based on the plan type */}
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "26px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "0.39px",
                    textAlign: "left",
                    color: userBasePlanId === 2 ? "#a09153" : "#367989",
                    textTransform: "uppercase",
                  }}
                >
                  {Object.keys(groupedPlans)}
                </Typography>
                {userBasePlanId === 2 ? (
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start", // Align items at the start of the flex container
                      flexDirection: "column",
                      paddingY: 2,
                    }}
                  >
                    <Grid container spacing={2} justifyContent="space-between">
                      {PlanNameToDisplayTypeOfPlans?.includes(
                        "Professional Plan Single"
                      ) && (
                        <Grid item>
                          <Box
                            sx={{
                              cursor: "pointer",
                              width: "282px",
                              height: "268px",
                              backgroundImage: `url(${img2})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              border:
                                highlightedPlan === "Professional Plan Single"
                                  ? "2px solid #367989"
                                  : "none",
                              borderRadius:
                                highlightedPlan === "Professional Plan Single"
                                  ? "8%"
                                  : "none",
                            }}
                            onClick={() => {
                              setHighlightedPlan("Professional Plan Single");
                              setSelectedPlan(
                                getSelectedPlanObj("Professional Plan Single")
                              );
                              setSupersaverSelected(false);
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <Box
                          sx={{
                            width: "282px",
                            cursor: "pointer",
                            height: "268px",
                            backgroundImage: `url(${img3})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            border: superSaverSelected
                              ? "2px solid #367989"
                              : "none",
                            borderRadius: superSaverSelected ? "8%" : "none",
                          }}
                          onClick={() => {
                            setSupersaverSelected(true);
                            setHighlightedPlan(null);
                            setSelectedPlan(
                              getSelectedPlanObj("Professional Plan Single")
                            );
                          }}
                        />
                        {superSaverSelected && (
                          <Box sx={{ textAlign: "left" }}>
                            <Typography>Quantity</Typography>
                            <MuiTextField
                              name={"Quantity"}
                              type={"number"}
                              value={superSaverQuantity}
                              width={250}
                              onChange={(e, v) => {
                                setSupersaverQuantity(e.target.value);
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      {PlanNameToDisplayTypeOfPlans?.includes(
                        "Professional Plan EP/ALBUM"
                      ) && (
                        <Grid item>
                          <Box
                            sx={{
                              width: "282px",
                              height: "268px",
                              cursor: "pointer",
                              backgroundImage: `url(${img4})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              border:
                                highlightedPlan === "Professional Plan EP/ALBUM"
                                  ? "2px solid #367989"
                                  : "none",
                              borderRadius:
                                highlightedPlan === "Professional Plan EP/ALBUM"
                                  ? "8%"
                                  : "none",
                            }}
                            onClick={() => {
                              setSelectedPlan(
                                getSelectedPlanObj("Professional Plan EP/ALBUM")
                              );

                              setSupersaverSelected(false);
                              setHighlightedPlan("Professional Plan EP/ALBUM");
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                ) : userBasePlanId === 1 ? (
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start", // Align items at the start of the flex container
                      flexDirection: "column",
                      paddingY: 2,
                    }}
                  >
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item>
                        <Box
                          sx={{
                            width: "282px",
                            height: "268px",
                            backgroundImage: `url(${img5})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            border:
                              highlightedPlan ===
                              "Beginers (Start-up) Plan Single"
                                ? "2px solid #367989"
                                : "none",
                            borderRadius:
                              highlightedPlan ===
                              "Beginers (Start-up) Plan Single"
                                ? "8%"
                                : "none",
                          }}
                          onClick={() => {
                            setSelectedPlan(
                              getSelectedPlanObj(
                                "Beginers (Start-up) Plan Single"
                              )
                            );

                            setSupersaverSelected(false);
                            setHighlightedPlan(
                              "Beginers (Start-up) Plan Single"
                            );
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            width: "282px",
                            height: "268px",
                            backgroundImage: `url(${img6})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            border: superSaverSelected
                              ? "2px solid #367989"
                              : "none",
                            borderRadius: superSaverSelected ? "8%" : "none",
                          }}
                          onClick={() => {
                            setSupersaverSelected(true);
                            setSelectedPlan(
                              getSelectedPlanObj(
                                "Beginers (Start-up) Plan Single"
                              )
                            );
                            setHighlightedPlan(null);
                          }}
                        />
                        {superSaverSelected && (
                          <Box sx={{ textAlign: "left" }}>
                            <Typography>Quantity</Typography>
                            <MuiTextField
                              name={"Quantity"}
                              type={"number"}
                              value={superSaverQuantity}
                              width={250}
                              onChange={(e, v) => {
                                setSupersaverQuantity(e.target.value);
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            width: "282px",
                            height: "268px",
                            backgroundImage: `url(${img7})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            border:
                              highlightedPlan ===
                              "Beginers (Start-up) Plan EP/ALBUM"
                                ? "2px solid #367989"
                                : "none",
                            borderRadius:
                              highlightedPlan ===
                              "Beginers (Start-up) Plan EP/ALBUM"
                                ? "8%"
                                : "none",
                          }}
                          onClick={() => {
                            setSelectedPlan(
                              getSelectedPlanObj(
                                "Beginers (Start-up) Plan EP/ALBUM"
                              )
                            );

                            setSupersaverSelected(false);
                            setHighlightedPlan(
                              "Beginers (Start-up) Plan EP/ALBUM"
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <RazorPayPayment
                selectedPlan={selectedPlan}
                superSaverQuantity={superSaverQuantity}
                superSaverSelected={superSaverSelected}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default connect(mapStateToProps)(SubscriptionPlans);
const SelectField = ({
  label,
  name,
  options,
  value,
  size,
  placeholder,
  handleSelectChange,
}) => {
  return (
    <Grid container>
      <Select
        input={<OutlinedInput {...inputProps} />}
        MenuProps={menuProps}
        value={value}
        displayEmpty
        size={size}
        style={{ width: "100%" }}
        onChange={handleSelectChange}
        sx={{
          backgroundColor: "transparent",
        }}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
          </MenuItem>
        )}
        {options.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
