import { Box, Grid } from "@mui/material";
import React from "react";
import { theme } from "../../views/App";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import LoadingBgImage from "../loadingBgImage/LoadingBgImage";
import { Bar } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { connect } from "react-redux";
import TopStoreAction from "../../stores/topStoresReport/TopStoreAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReactEcharts from "echarts-for-react";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [TopStoreAction.REQUEST_AUDIO_STREAMS]),
  audioStreams: state.audioStreams.audioStreams || [],
});

const TrackAudioStream = (props) => {
  let tableArray;
  let totalTableValue;

  if (props && props.audioStreamsReport) {
    const retailerValues = {};

    props.audioStreamsReport.forEach((entry) => {
      Object.entries(entry.retailer).forEach(([retailer, value]) => {
        if (retailerValues[retailer]) {
          retailerValues[retailer] += value;
        } else {
          retailerValues[retailer] = value;
        }
      });
    });

    tableArray = Object.entries(retailerValues)
      .map(([retailer, value]) => ({ retailer, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 3);

    totalTableValue = tableArray.reduce((sum, obj) => sum + obj.value, 0);
  }

  const colors = [
    `${theme.pieChart.color5}`,
    `${theme.pieChart.color1}`,
    `${theme.pieChart.color2}`,
  ];

  const retailerNames = tableArray?.map((item) => item.retailer).reverse();
  const retailerData = props.audioStreamsReport?.map((entry) => ({
    date: entry.key,
    values: retailerNames.map((name) => entry.retailer[name] || 0),
  }));

  let labels = [];
  let datasets = [];

  if (props && props.audioStreamsReport) {
    labels = retailerData?.map((entry) => entry.date);
    datasets = retailerNames?.map((name, index) => ({
      label: `${name} Values`,
      backgroundColor: colors[index],
      borderColor: `rgba(75, 192, 192, 1)`,
      borderWidth: 1,
      barThickness: 20,
      hoverBorderColor: theme.palette.primary.main,
      hoverBorderWidth: 2,
      data: retailerData?.map((entry) => entry.values[index]),
    }));
  }

  const state = {
    labels: labels,
    datasets: datasets,
  };

  const getOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };

    const series = datasets.map((item) => ({
      name: item.label,
      type: "bar",
      stack: "total",
      data: item.data,
      emphasis: {
        focus: "series",
      },
      itemStyle: {
        color: item.backgroundColor,
        borderColor: item.borderColor,
        borderWidth: item.borderWidth,
      },
      barWidth: item.barThickness,
    }));
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        confine: true,
        formatter: (params) => {
          let tooltipContent = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            if (item.value !== 0) {
              let val = item.value;
              let formattedvalue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
              tooltipContent += `${colorSpan}${item.seriesName}: ${formattedvalue}<br/>`;
            }
          });
          return tooltipContent;
        },
      },
      legend: {
        textStyle: {
          color: "#ddd", // Set the text color for the legend
        },
        icon: "circle",
        type: "scroll",
        data: datasets.map((item) => item.label),
      },
      grid: {
        top: "10%",

        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      labelLayout: {
        hideOverlap: true,
      },
      xAxis: {
        type: "category",
        data: labels,
        axisLabel: {
          show: true,
          padding: [0, 30, 0, 0],
          hideOverlap: true,
        },

        axisTick: {
          show: true,
        },
      },

      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],

      yAxis: {
        type: "value",

        axisLabel: {
          formatter: (value) => formatValue(value),
          hideOverlap: true,
        },
      },
      series: series,
    };
  };
  return (
    <Box>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes blink {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
    `,
        }}
      />
      <LoadingIndicator isActive={props.isRequesting}>
        {!props.isRequesting ? (
          <Box
            my={5}
            pb={4}
            width="99%"
            sx={{
              boxShadow: theme.shadows,
              backgroundColor: theme.palette.background.default,
              borderRadius: 1,
            }}
          >
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent="center"
              padding={0}
            >
              <Grid
                item
                container
                alignItems={"center"}
                justifyContent="center"
              >
                <Box paddingY={2} paddingX={3} width="100%">
                  <Box marginY={2}>
                    <Grid
                      item
                      container
                      justifyContent={"flex-start"}
                      alignItems="center"
                      gap={5}
                    >
                      <Grid item mr={5}>
                        <span
                          style={{
                            fontSize: 24,
                            fontWeight: "bold",
                          }}
                        >
                          STREAMS
                        </span>
                      </Grid>

                      <span
                        style={{
                          fontSize: "17px",
                          color: "grey",
                        }}
                      >
                        {props.formattedDate}&nbsp;
                      </span>
                    </Grid>
                  </Box>
                  <Grid
                    item
                    container
                    justifyContent={"flex-start"}
                    alignItems="center"
                    mb={1}
                  >
                    <Box
                      sx={{
                        paddingX: "10px",
                        paddingY: "5px",
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        width: "170px",
                        borderRadius: "2px",
                        marginLeft: "-25px",
                        marginTop: "-5px",
                        fontSize: "13px",
                      }}
                    >
                      NUMBER OF STREAMS
                    </Box>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid
                      item
                      sm={12}
                      md={3}
                      alignItems="center"
                      justifyContent={"flex-end"}
                      ml={3}
                    >
                      {tableArray?.length > 0 && (
                        <TableContainer
                          component={Paper}
                          sx={{
                            padding: "-10px",
                            // borderBottom: `1px solid ${theme.palette.primary.main}`,
                            marginTop: "10px",
                            boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.5)",
                          }}
                        >
                          <Table
                            sx={{
                              border: `1px solid white`,
                              background: theme.palette.background.grey,
                            }}
                          >
                            <TableRow
                              sx={{
                                border: `1px solid white`,
                              }}
                            >
                              <TableCell
                                // sx={{
                                //   borderBottom: `1px solid ${theme.palette.primary.main}`,
                                // }}
                                sx={{
                                  border: `1px solid white`,
                                  borderBottomColor: theme.palette.common.grey,
                                }}
                              ></TableCell>
                              <TableCell
                                sx={{
                                  border: `1px solid white`,
                                  borderBottomColor: theme.palette.common.grey,
                                }}
                              ></TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "right",
                                  border: `1px solid white`,
                                  borderBottomColor: theme.palette.common.grey,
                                }}
                              >
                                Streams
                              </TableCell>
                            </TableRow>
                            <TableBody>
                              {tableArray?.map((platform, index) => (
                                <TableRow
                                  key={index}
                                  // sx={{
                                  //   borderBottom:
                                  //     index === tableArray.length - 1
                                  //       ? `2px solid ${theme.palette.primary.main}`
                                  //       : "2px solid #E8E8E8",
                                  // }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "0px",
                                      paddingLeft: "20px",
                                      border: `1px solid white`,
                                      borderBottomColor:
                                        theme.palette.common.grey,
                                      borderRightColor:
                                        theme.palette.common.grey,
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: 15,
                                        height: 15,
                                        backgroundColor:
                                          colors[tableArray.length - index - 1], // For reverse order
                                        borderRadius: "50%",
                                        animation: "blink 1s infinite",
                                      }}
                                    ></div>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      border: `1px solid white`,
                                      borderBottomColor:
                                        theme.palette.common.grey,
                                    }}
                                  >
                                    {platform.retailer}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      border: `1px solid white`,
                                      textAlign: "right",
                                      borderBottomColor:
                                        theme.palette.common.grey,
                                    }}
                                  >
                                    {platform.value}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {tableArray?.length > 0 && (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      border: `1px solid white`,
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    sx={{
                                      border: `1px solid white`,
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "right",
                                      border: `1px solid white`,
                                    }}
                                  >
                                    {totalTableValue}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                    <Grid
                      container
                      md={8.5}
                      sm={12}
                      justifyContent={"flex-end"}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        p={0}
                        m={0}
                        sx={{
                          height: "500px",
                          background: theme.palette.background.grey,
                          padding: "30px",
                        }}
                      >
                        <ReactEcharts
                          option={getOption()}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <LoadingBgImage />
          </Box>
        )}
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(TrackAudioStream);
