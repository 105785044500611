import {
  Box,
  Typography,
  Breadcrumbs,
  Stack,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TermsandConditions from "./TermsandConditions";
import { useNavigate, useLocation } from "react-router";
import CommonCheckbox from "./CommonCheckbox";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import RegisterAction from "../../stores/register/RegisterAction";
import MuiSnackbarAlert from "../../components/muiSnackbar/MuiSnackbarAlert";
import CloseIcon from "@mui/icons-material/Close";
// Validation schema for form
const validationSchema = Yup.object().shape({
  termsAndCondition: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions."
  ),
});

// LinkItem component to reduce redundancy
const LinkItem = ({ href, activeLink, setActiveLink, children }) => (
  <Link
    underline="hover"
    color="inherit"
    href={href}
    onClick={(event) => {
      event.preventDefault();
      setActiveLink(href);
    }}
    sx={{
      fontWeight: activeLink === href ? "bold" : "normal",
      color: activeLink === href ? "white" : "white",
      textDecoration: activeLink === href ? "underline" : "none",
      "&:hover": { color: "#e1e2e3" },
      textTransform: "uppercase",
    }}
  >
    {children}
  </Link>
);

const AcceptTermsDailogue = (props) => {
  console.log("propsdatapost", props);
  const navigate = useNavigate();
  const location = useLocation();
  const postObj = props.postObj;
  const phoneNumber = postObj?.phone_number;
  const countryCode = postObj?.country_code;
  const dispatch = useDispatch();
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [activeLink, setActiveLink] = useState("/terms-of-service");
  const scrollBoxRef = useRef(null);
  const [scrollEndReached, setScrollEndReached] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarstaus, setSnackbarStatus] = useState("warning");

  console.log("snackbarstaus", snackbarstaus);

  // Form submission handler
  const submitForm = (values) => {
    const callBack = (res) => {
      console.log("res", res);
      if (res.status === "success") {
        // window.alert(" OTPhas been sent successfully");
        setSnackbarMessage(res.message);
        setSnackbarOpen(true);
        setSnackbarStatus(res.status);
        let Obj = {
          ...postObj,
          document_consent: {
            terms_and_condition: true,
            privacy_and_policy: true,
          },
        };
        setTimeout(() => {
          navigate("/ValidateOtp", { state: { action: "mobile_otp", Obj } });
        }, [3000]);
      } else {
        setSnackbarStatus("warning");
        setSnackbarMessage(res?.raw?.data?.message);
        setSnackbarOpen(true);
        navigate("/ValidateMobile", { replace: true });
        props.onClose();
        return;
      }
    };

    let postObjdata = {
      phone_number: phoneNumber,
      country_code: countryCode,
    };

    dispatch(
      RegisterAction.requestPostMobileNumber(
        JSON.stringify(postObjdata),
        callBack
      )
    );
  };

  // Scroll event handler
  const handleScroll = () => {
    if (scrollBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollBoxRef.current;
      if (scrollHeight - scrollTop <= clientHeight) {
        setScrollEndReached(true);
      }
    }
  };

  // Effect to add/remove scroll event listener
  useEffect(() => {
    const scrollBox = scrollBoxRef.current;
    if (scrollBox) {
      scrollBox.addEventListener("scroll", handleScroll);
      return () => {
        scrollBox.removeEventListener("scroll", handleScroll);
      };
    }
  }, [activeLink]);

  // Reset state and scroll position when breadcrumb changes
  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
    setIsCheckboxEnabled(scrollEndReached);
  }, [activeLink, scrollEndReached]);

  const breadcrumbsData = [
    { href: "/terms-of-service", label: "Terms of Service" },
    {
      href: "/music-distribution-contract",
      label: "Music Distribution Contract",
    },
    { href: "/publishing-administration", label: "Publishing Administration" },
    { href: "/social-platforms", label: "Social Platforms" },
    { href: "/privacy-policy", label: "Privacy Policy" },
    { href: "/copyright-policy", label: "Copyright Policy" },
    { href: "/synchronization-license", label: "Synchronization License" },
    {
      href: "/youtube-sound-recording-revenue",
      label: "YouTube Sound Recording Revenue",
    },
    { href: "/cookies", label: "Cookies" },
  ];

  useEffect(() => {
    if (props?.open) {
      setSnackbarOpen(true);
      setSnackbarMessage("Please read the Terms and Conditions and accept the terms to Continue.");
      setSnackbarStatus("warning");
    }
  }, [props?.open]);

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullScreen>
        <Box></Box>
        <Box
          sx={{
            marginTop: {
              xl: "64px",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              onClick={props.onClose}
              color="#efefef"
              sx={{ fontSize: "30px", marginRight: "30px", cursor: "pointer" }}
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "32px",
              textTransform: "uppercase",
              lineHeight: "42px",
              color: "#efefef",
            }}
          >
            {activeLink === "/terms-of-service"
              ? "Terms and Conditions"
              : activeLink.split("/").pop().replace(/-/g, " ")}
          </Typography>
        </Box>

        <Stack
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: {
              xl: "127px",
            },
            marginRight: {
              xl: "120px",
            },
          }}
        >
          <Breadcrumbs
            maxItems={10}
            sx={{ color: "#efefef", fontSize: "14px" }}
            separator="|"
            aria-label="breadcrumb"
          >
            {breadcrumbsData.map(({ href, label }) => (
              <LinkItem
                key={href}
                href={href}
                activeLink={activeLink}
                setActiveLink={setActiveLink}
              >
                {label}
              </LinkItem>
            ))}
          </Breadcrumbs>
          <Box sx={{ padding: "10px" }}>
            <Typography>
              {activeLink === "/terms-of-service" && (
                <TermsandConditions
                  postObj={postObj}
                  scrollBoxRef={scrollBoxRef}
                  setScrollEndReached={setScrollEndReached}
                />
              )}
              {/* Add other breadcrumb content here as needed */}
              {activeLink === "/music-distribution-contract" &&
                "Music distribution contract"}
              {activeLink === "/publishing-administration" &&
                "Publishing administration"}
              {activeLink === "/social-platforms" && "Social platforms"}
              {activeLink === "/privacy-policy" && "Privacy policy"}
              {activeLink === "/copyright-policy" && "Copyright policy"}
              {activeLink === "/synchronization-license" &&
                "Synchronization license"}
              {activeLink === "/youtube-sound-recording-revenue" &&
                "YouTube sound recording revenue"}
              {activeLink === "/cookies" && "Cookies"}
            </Typography>
          </Box>
          <Box>
            <Formik
              initialValues={{ termsAndCondition: false }}
              validationSchema={validationSchema}
              onSubmit={submitForm}
            >
              {({ setFieldValue, values, errors, touched }) => (
                <Form>
                  <CommonCheckbox
                    setFieldValue={setFieldValue}
                    values={values}
                    submitForm={submitForm}
                    isCheckboxEnabled={isCheckboxEnabled}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </Stack>
      </Dialog>
      <MuiSnackbarAlert
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        snackbarstaus={snackbarstaus}
       
      />
    </>
  );
};

export default AcceptTermsDailogue;
