import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { theme } from "../App";
import { useNavigate, useLocation } from "react-router";
import { Form, Formik } from "formik";
import OtpInput from "react-otp-input";
import RegisterAction from "../../stores/register/RegisterAction";
import LoginBackground from "../../components/loginBackground/LoginBackground";
import Tunes from "../../Images/A3Tunes.webp";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import ResetProfilePassword from "../../components/resetProfilePassword/ResetProfilePassword";
import MuiSnackbarAlert from "../../components/muiSnackbar/MuiSnackbarAlert";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const ValidationOtp = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [resetPassWordOpen, setResetPassWordOpen] = useState(false);
  const [token, setToken] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const action = location.state?.action || "";
  const phoneNumber = location.state?.Obj ?? "";

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const handleResizeLogin = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as per your requirement
    };

    // Initial check on mount
    handleResizeLogin();

    // Listen to window resize events
    window.addEventListener("resize", handleResizeLogin);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResizeLogin);
  }, []);

  return (
    <>
      <Formik
        initialValues={{ otp: "" }}
        validate={(values) => {
          let errors = {};
          if (!values.otp) {
            errors.otp = "Otp is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          // if (action === "mobile_otp") {
          setLoading(true);

          let callBack = (res) => {
            if (res?.status === "success") {
              navigate("/AccountVerified", {
                state: {
                  action: phoneNumber,
                  token: res?.token,
                },
              });
              setLoading(false);
            }
            setLoading(false);
          };

          let profileCallBack = (res) => {
            if (res?.status === "success") {
              navigate("/profile", {
                state: { action: "profile_update", token: res?.token },
              });
              setLoading(false);
              localStorage.setItem("profile_stored_token", res?.token);
            }
            setLoading(false);
          };

          let updatePhoneCallBack = (res) => {
            if (res?.status === "success") {
              navigate("/ValidateMobile", {
                state: { action: "update_phone", token: res?.token },
              });
              setLoading(false);
            }
            setLoading(false);
          };
          let updatePhonePostCallBack = (res) => {
            if (res?.status === "success") {
              const postObj = {
                phone_number: location?.state?.Obj?.phone_number,
                country_code: location?.state?.Obj?.country_code,
              };

              const headers = {
                "X-One-Time-Token":
                  location?.state?.headers["X-One-Time-Token"],
                "N-One-Time-Token": res?.token,
              };
              const callBack = (res) => {
                if (res?.status === "success") {
                  navigate("/profile");
                }
              };

              props.dispatch(
                UserProfileAction.requestPutPhoneNumber(
                  postObj,
                  headers,
                  callBack
                )
              );
            }
            setLoading(false);
          };

          let forgotPasswordCallBack = (res) => {
            if (res?.status === "success") {
              setResetPassWordOpen(true);
              setToken(res?.token);
            }
            setLoading(false);
          };

          let postObj = {
            phone_number: phoneNumber.phone_number,
            otp: values.otp,
          };
          console.log("postObjotpdatta", postObj);
          props.dispatch(
            RegisterAction.requestPostMobileNumberOTP(
              JSON.stringify(postObj),
              action === "profile"
                ? profileCallBack
                : action === "update_phone"
                ? updatePhoneCallBack
                : action === "update_phone_post"
                ? updatePhonePostCallBack
                : action === "forgot_password"
                ? forgotPasswordCallBack
                : callBack
            )
          );
          // }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
          submitForm,
        }) => (
          <Form
            style={{
              width: "100%",
              height:
                action === "profile" ||
                action === "update_phone" ||
                action === "update_phone_post"
                  ? "85vh"
                  : "100vh",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Grid
                container
                style={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                }}
              >
                {action !== "profile" &&
                action !== "update_phone" &&
                action !== "update_phone_post" ? (
                  <LoginBackground isMobile={isMobile} />
                ) : null}
                <Grid
                  item
                  xs={12}
                  md={
                    isMobile
                      ? 12
                      : action === "profile" ||
                        action === "update_phone" ||
                        action === "update_phone_post"
                      ? 12
                      : 6
                  }
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.background.loginbackground,
                    height: "100%",
                    overflowY: "auto",
                  }}
                >
                  {isMobile && (
                    <img
                      src={Tunes}
                      alt="Tunes"
                      style={{
                        width: "15%",
                        height: "auto",
                        objectFit: "contain",
                        marginBottom: "30px",
                      }}
                    />
                  )}
                  <Grid
                    container
                    item
                    xs={8}
                    xl={8}
                    lg={8}
                    md={8}
                    sm={8}
                    sx={{
                      // flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      // mt: 0.5,
                    }}
                    // className="bg-warning"
                  >
                    <Grid
                      item
                      xs={12}
                      lg={
                        action === "profile" ||
                        action === "update_phone" ||
                        action === "update_phone_post"
                          ? 12
                          : 9
                      }
                      sx={{
                        textAlign:
                          action === "profile" ||
                          action === "update_phone" ||
                          action === "update_phone_post"
                            ? "center"
                            : "left",
                        color: theme.palette.text.createaccountcolor,
                        fontSize: 38,
                      }}
                    >
                      VERIFY YOUR ACCOUNT
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent:
                            action === "profile" ||
                            action === "update_phone" ||
                            action === "update_phone_post"
                              ? "center"
                              : "flex-start",
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            color: theme.palette.primary.main,
                            marginTop: -15,
                          }}
                        >
                          <span style={{ fontSize: "22px" }}>
                            Enter the verification Code&nbsp;
                          </span>
                          <span
                            style={{
                              color: theme.palette.primary.otptextcolor,
                              fontSize: "22px",
                            }}
                          >
                            Sent your phone
                          </span>
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            action === "profile" ||
                            action === "update_phone" ||
                            action === "update_phone_post"
                              ? "center"
                              : "flex-start",
                          alignItems:
                            action === "profile" ||
                            action === "update_phone" ||
                            action === "update_phone_post"
                              ? "center"
                              : "flex-start",
                        }}
                      >
                        <OtpInput
                          shouldAutoFocus
                          value={values.otp}
                          onChange={(otp) => {
                            console.log("otpdata", otp);
                            setFieldValue("otp", otp);
                            if (otp.length === 6) {
                              // Trigger form submission once OTP is fully entered
                              submitForm();
                            }
                          }}
                          inputStyle={{
                            width: "3rem",
                            height: "4rem",
                            margin: "2rem 0rem",
                            fontSize: "2rem",
                            borderRadius: 9,
                            border: "1px solid rgba(0,0,0,0.3)",
                            color: "#2b2a2a",
                            justifyContent:
                              action === "profile" ||
                              action === "update_phone" ||
                              action === "update_phone_post"
                                ? "center"
                                : "flex-start",
                          }}
                          numInputs={6}
                          renderSeparator={
                            <span style={{ margin: "0 1rem" }}></span>
                          }
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent:
                            action === "profile" ||
                            action === "update_phone" ||
                            action === "update_phone_post"
                              ? "center"
                              : "flex-start",
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            color: "#b1b1b1",
                            marginTop: -30,
                          }}
                        >
                          <span style={{ fontSize: "22px" }}>
                            Haven’t received the OTP?&nbsp;
                          </span>
                          <button
                            type="button"
                            onClick={() => {
                              let callBack = (res) => {
                                console.log("rescccc", res);
                                if (res) {
                                  setSnackbarMessage(res.message);
                                  setSnackbarOpen(true);
                                  // window.alert("OTP has been sent successfully");
                                  setLoading(false);
                                } else {
                                  setLoading(false);
                                }
                              };
                              let postObj = {
                                phone_number: phoneNumber.phone_number,
                                country_code: phoneNumber.country_code,
                              };

                              Object.assign(
                                postObj,
                                action === "profile" && { flow_type: "3" },
                                action === "update_phone" && {
                                  flow_type: "4",
                                },
                                action === "forgot_password" && {
                                  flow_type: "2",
                                }
                              );
                              console.log("postObj", postObj);
                              props.dispatch(
                                RegisterAction.requestPostMobileNumber(
                                  JSON.stringify(postObj),
                                  callBack
                                )
                              );
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                              }
                            }}
                            style={{
                              color: theme.palette.primary.main,
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              fontSize: "22px",
                            }}
                          >
                            Send again
                          </button>
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <ResetProfilePassword
              open={resetPassWordOpen}
              onClose={() => {
                setResetPassWordOpen(false);
              }}
              from="forgot_password"
              phone_number={location?.state?.Obj?.phone_number}
              token={token}
            />
          </Form>
        )}
      </Formik>
      <MuiSnackbarAlert
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        snackbarstaus={"success"}
      />
    </>
  );
};
export default connect(mapDispatchToProps)(ValidationOtp);
