import { Box } from "@mui/material";
import React from "react";
import DynamicReport from "./DynamicReport";
import NewDashboard from "./NewDashboard";

function Dashboard() {
  return (
    <Box>
      <NewDashboard />
      {/* <DynamicReport /> */}
    </Box>
  );
}

export default Dashboard;
