import {
  Alert,
  Box,
  Grid,
  IconButton,
  ListItem,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TrackAction from "../../stores/track/TrackAction";
import ArtistAction from "../../stores/artist/ArtistAction";
import ClientAction from "../../stores/client/ClientAction";
import LanguageAction from "../../stores/language/LanguageAction";
import GenreAction from "../../stores/genre/GenreAction";
import SubGenreAction from "../../stores/subgenre/SubGenreAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import PlanAction from "../../stores/plan/PlanAction";
import { useLocation } from "react-router";
import ActorAction from "../../stores/actor/ActorAction";
import ActressAction from "../../stores/actress/ActressAction";
import DirectorAction from "../../stores/director/DirectorAction";
import DeliveredTrackDetailsDialog from "./DeliveredTrackDetailsDialog";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LaunchIcon from "@mui/icons-material/Launch";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SyncIcon from "@mui/icons-material/Sync";
import { theme } from "../App";
import copy from "copy-to-clipboard";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import environment from "environment";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK,
    TrackAction.REQUEST_EXISTINGTRACKS,
    ArtistAction.REQUEST_ARTIST_FILTER,
    ClientAction.REQUEST_CLIENT_FILTER,
    LanguageAction.REQUEST_LANGUAGE,
    GenreAction.REQUEST_GENRE_FILTER,
    SubGenreAction.REQUEST_SUBGENRE_FILTER,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    PlanAction.REQUEST_PLAN,
    ActorAction.REQUEST_ACTOR_FILTER,
    ActressAction.REQUEST_ACTRESS_FILTER,
    DirectorAction.REQUEST_DIRECTOR_FILTER,
  ]),
  track: state.track.track.hits || [],
  existingTracks: state.existingTracks.existingTracks || [],
  artistFilter: state.artistFilter.artistFilter || [],
  clientFilter: state.clientFilter.clientFilter || [],
  language: state.language.language || [],
  genreFilter: state.genreFilter.genreFilter || [],
  subgenreFilter: state.subgenreFilter.subgenreFilter || [],
  releaseById: state.releaseById.releaseById || {},
  plan: state.plan.plan || {},
  actorFilter: state.actorFilter.actorFilter || [],
  actressFilter: state.actressFilter.actressFilter || [],
  directorFilter: state.directorFilter.directorFilter || [],
});

function DeliveredReleasesTracks(props) {
  const [openTrackDialog, setOpenTrackDialog] = useState(false);
  const [trackData, setTrackData] = useState(null);
  const [copied, setCopied] = useState(false);
  const location = useLocation();

  function fetchTracks(params) {
    props.dispatch(TrackAction.requestTrack(params));
  }
  function clearExistingTracks() {
    props.dispatch(TrackAction.requestExistingTracksClear());
  }
  function fetchReleaseByID() {
    props.dispatch(ReleasesAction.requestReleaseById(props.releaseId));
  }

  useEffect(() => {
    fetchReleaseByID();
    clearExistingTracks();

    const params = {
      order_by: `[["created_on","desc"]]`,
      page_limit: "none",
      current_page: "delivered_release_details",
      filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
    };

    fetchTracks(params);
  }, []);

  const rows = [];

  if (props.track.hits) {
    rowCreator(props.track.hits, rows);
  }

  function rowCreator(data, row) {
    data.map((item, index) => {
      return row.push({
        sl_no: index + 1,
        id: item._id,
        _index: item._index,
        arranger: item._source.arranger,
        ask_to_generate_an_isrc: item._source.ask_to_generate_an_isrc,
        author: item._source.author,
        client_id: item._source.client_id,
        client_id_object: item._source.client_id,
        composer: item._source.composer,
        content_type: item._source.content_type,
        created_by:
          item._source.created_by && item._source.created_by.user_name,
        created_on: item._source.created_on,
        featuring: item._source.featuring,
        genre: item._source.genre,
        director: item._source.director,
        duplication_number: item._source.duplication_number,
        primary_actor: item._source.primary_actor,
        primary_actress: item._source.primary_actress,
        instrumental: item._source.instrumental,
        isrc: item._source.isrc,
        lyrics: item._source.lyrics,
        lyrics_language: item._source.lyrics_language,
        parental_advisory: item._source.parental_advisory,
        plan_id: item._source.plan_id ? item._source.plan_id : "",
        preview_start: item._source.preview_start,
        primary_artists: item._source.primary_artists,
        primary_track_type: item._source.primary_track_type,
        producer: item._source.producer,
        producer_catalouge_number: item._source.producer_catalouge_number,
        production_year: item._source.production_year,
        published_by: item._source.published_by,
        publisher: item._source.publisher,
        remixer: item._source.remixer,
        release_id: item._source.release_id,
        secondary_genre: item._source.secondary_genre,
        secondary_track_type: item._source.secondary_track_type,
        seconday_sub_genre: item._source.seconday_sub_genre,
        stream_id: item._source.stream_id,
        sub_genre_name: item._source.sub_genre_name,
        title: item._source.title,
        track_title_language: item._source.track_title_language,
        ["music/album"]: item._source["music/album"],
        views: item._source.views,
        phonographic_copyright_year: item._source.phonographic_copyright_year,
        copyright_year: item._source.copyright_year,
        copyrighted_to: item._source.copyrighted_to,
        label: item._source.label?.label_name,
        release: item._source.release_id?.title,
      });
    });
  }

  let planList = [];

  if (props.plan && props.plan.hits) {
    props.plan.hits.hits &&
      props.plan.hits.hits.length > 0 &&
      props.plan.hits.hits.map((plan) => {
        planList.push({
          id: plan._id,
          name: plan._source.plan_name,
        });
      });
  }
  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      flex: 0.5,
      renderCell: (params, index) => {
        let play_link = params.row.title;
        play_link = play_link?.replace(/ /g, "-");

        let tunesUrl = `${environment.api.a3TunesApi}/${play_link}${
          params.row.duplication_number
            ? params.row.duplication_number === 0
              ? ""
              : "--" + params.row.duplication_number
            : ""
        }`;

        return (
          <>
            <Box display="flex" alignItems="center">
              <Typography>{params.row ? params.row.sl_no : " "}</Typography>
              <IconButton
                title="Play Link url"
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  copyToClipboard(tunesUrl);
                }}
              >
                <ContentCopyRoundedIcon sx={{ fontSize: "20px" }} />
              </IconButton>
            </Box>

            <Snackbar
              message="Play Link url to clipboard"
              sx={{ height: "70%" }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={500}
              onClose={() => setCopied(false)}
              open={copied}
            />
          </>
        );
      },
    },
    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0">
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <div style={{ width: "60%" }}>
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.primary_artists?.length ? "By " : ""}
                  {params?.row?.primary_artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id.artist_name}
                      {index !== params?.row?.primary_artists?.length - 1 &&
                        ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      field: "music/album",
      headerName: "Version",
      width: 150,
      hide: true,
      flex: 1,
    },
    {
      field: "genre",
      headerName: "Genre",
      width: 200,
      renderCell: (params) => {
        return `${params.row.genre?.genre_name} > ${params.row.sub_genre_name?.subgenre_name}`;
      },
      flex: 2,
    },
    // {
    //   field: "isrc",
    //   headerName: "ISRC",
    //   width: 150,
    //   flex: 2,
    // },
    {
      field: "plan_id",
      headerName: "Price tier",
      width: 150,
      renderCell: (params) => {
        return params.row.plan_id?.plan_name;
      },
      flex: 2,
    },
    {
      field: "link",
      headerName: "Play Links",
      width: 150,
      renderCell: (params, event) => {
        let track_name = params.row.title;
        track_name = track_name?.replace(/ /g, "-") || "";

        let tunesUrl = `${
          environment.api.a3TunesApi
        }/quick-links/${track_name}${
          params.row.duplication_number
            ? params.row.duplication_number === 0
              ? ""
              : "--" + params.row.duplication_number
            : ""
        }`;

        return (
          <a href={tunesUrl} target="_blank" style={{ textDecoration: "none" }}>
            <LaunchIcon
              style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
            />
          </a>
        );
      },
    },
    {
      // field: "link",
      headerName: "View details",
      type: "actions",
      width: 100,
      renderCell: (params, event) => {
        return (
          <>
            <RemoveRedEyeIcon
              onClick={() => {
                setTrackData(params.row);
                setOpenTrackDialog(true);
              }}
            />
          </>
        );
      },
    },

    {
      field: "update_play_links",
      headerName: "Update Play Links",
      width: 150,
      hide: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              props.setValue();
              props.settrackId(params.id);
              props.settabvalue(true);
            }}
          >
            <FindReplaceIcon color={theme.palette.primary.main} />
          </IconButton>
        );
      },
      flex: 2,
    },
  ];

  const [isSyncing, setisSyncing] = useState(false);

  const syncPlaylinkFunction = () => {
    setisSyncing(true);
    let params = {
      current_page: "delivered_release_details",
    };
    props
      .dispatch(TrackAction.requestSyncTrackPlaylink(props.releaseId, params))
      .then(() => {
        const params = {
          current_page: "delivered_release_details",
        };
        setisSyncing(false);
        props.dispatch(
          ReleasesAction.requestFinalReleaseById(props.releaseId, params)
        );
      });
  };

  // ExistingTracks
  function fetchExistingTracks() {
    if (
      props.releaseById._source?.existing_tracks &&
      props.releaseById._source?.existing_tracks.length > 0
    ) {
      let trackIdsArray = props.releaseById._source?.existing_tracks.map(
        (item) => item.track_id.id
      );
      let params = {
        filters: `[["_id","must","","terms",[${trackIdsArray
          .map((id) => `"${id}"`)
          .join(",")}]]]`,
      };

      props.dispatch(TrackAction.requestExistingTracks(params));
    }
  }

  const existingTrack = [];
  if (props.existingTracks && props.existingTracks.hits?.hits) {
    rowCreator(props.existingTracks.hits.hits, existingTrack);
  }
  const existingTrackColumns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      flex: 0.5,
      align: "center",
    },
    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0">
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <div style={{ width: "60%" }}>
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.primary_artists?.length ? "By " : ""}
                  {params?.row?.primary_artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id.artist_name}
                      {index !== params?.row?.primary_artists?.length - 1 &&
                        ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      field: "genre",
      headerName: "Genre",
      width: 200,
      renderCell: (params) => {
        return `${params.row.genre?.genre_name} > ${params.row.sub_genre_name?.subgenre_name}`;
      },
      flex: 2,
    },
    // {
    //   field: "isrc",
    //   headerName: "ISRC",
    //   width: 150,
    //   flex: 1.5,
    // },
    {
      field: "plan_id",
      headerName: "Price tier",
      renderCell: (params) => {
        return params.row.plan_id?.plan_name;
      },
      flex: 2,
    },

    {
      field: "label",
      headerName: "Label",
      width: 150,
      flex: 1.5,
    },
    {
      field: "release",
      headerName: "Release Name",
      width: 150,
      flex: 2,
    },
    {
      field: "link",
      headerName: "Play Links",
      align: "center",
      headerAlign: "center",
      width: 150,
      renderCell: (params, event) => {
        let track_name = params.row.title;
        track_name = track_name.replace(/ /g, "-");

        let tunesUrl = `https://a3tunes.com/${track_name}${
          params.row.duplication_number
            ? params.row.duplication_number === 0
              ? ""
              : "--" + params.row.duplication_number
            : ""
        }`;

        return (
          <IconButton>
            <a
              href={tunesUrl}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <LaunchIcon
                style={{
                  color: "#2a5e6c",
                  marginBottom: "5px",
                }}
              />
            </a>
          </IconButton>
        );
      },
    },
    {
      field: "view_details",
      headerName: "View details",
      type: "actions",
      width: 100,
      renderCell: (params, event) => {
        return (
          <IconButton>
            <RemoveRedEyeIcon
              onClick={() => {
                setTrackData(params.row);
                setOpenTrackDialog(true);
              }}
            />
          </IconButton>
        );
      },
    },
    {
      field: "update_play_links",
      headerName: "Update Play Links",
      width: 150,
      hide: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              props.setValue();
              props.settrackId(params.id);
              props.settabvalue(true);
            }}
          >
            <FindReplaceIcon color={theme.palette.primary.main} />
          </IconButton>
        );
      },
      flex: 2,
    },
  ];

  useEffect(() => {
    !props.isRequesting && existingTrack.length == 0 && fetchExistingTracks();
  }, [props]);

  return (
    <Box width={"100%"}>
      <DeliveredTrackDetailsDialog
        open={openTrackDialog}
        onClose={() => {
          setOpenTrackDialog(false);
          setTrackData(null);
        }}
        trackData={trackData}
      />
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        // className="bg-warning"
        alignItems={"center"}
        justifyContent="space-between"
        my={0.5}
      >
        <Grid item>
          <Typography component={"span"}>
            {formatName(`${rows?.length}`)} Results found
          </Typography>
        </Grid>

        <Grid item>
          <Box px={4}>
            <ListItem className="p-0 m-0">
              <div>
                <IconButton
                  title="Sync Playlinks"
                  onClick={() => {
                    syncPlaylinkFunction();
                  }}
                >
                  <SyncIcon
                    sx={{
                      color: theme.palette.primary.main,
                      animation: isSyncing
                        ? "rotateAnimation 2s linear infinite"
                        : "",
                    }}
                  />
                </IconButton>
              </div>
              <span>Sync</span>
            </ListItem>
          </Box>
        </Grid>
      </Grid>

      {navigator.onLine ? (
        <Grid
          display="flex"
          justifyContent="center"
          container
          item
          lg={12}
          md={12}
          sm={12}
        >
          <LoadingIndicator isActive={props.isRequesting}>
            {props.track.hits ? (
              <DataGridFunction
                curser={"pointer"}
                // onRowClick={(params) => {
                //   setOpenTrackDialog(true);
                //   setTrackData(params.row);
                // }}
                columns={columns}
                rows={rows}
                rowHeight={60}
                height={rows.length * 60 + 100}
              />
            ) : null}
          </LoadingIndicator>
          <Grid item xs={12} my={5}>
            {existingTrack.length > 0 ? (
              <>
                <Typography component={"span"}>
                  {formatName(`${existingTrack?.length}`)} Existing Tracks found
                </Typography>
                <DataGridFunction
                  columns={existingTrackColumns}
                  rows={existingTrack}
                  rowHeight={60}
                  height={existingTrack.length * 60 + 50}
                />
              </>
            ) : null}
          </Grid>
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(DeliveredReleasesTracks);
