import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import ToastsAction from "../../stores/toasts/ToastsAction";
import { Button } from "@mui/material";

const mapStateToProps = (state) => ({
  toasts: state.toasts,
});

function Toasts(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.toasts.items.length === 0) {
      return;
    }

    props.toasts.items.forEach((model) => {
      const action = (key) => (
        <>
          {model.type !== "success" && (
            <Button
              onClick={() => {
                props.dispatch(ToastsAction.removeById(key));
                closeSnackbar(key);
              }}
            >
              Dismiss
            </Button>
          )}
        </>
      );

      enqueueSnackbar(model.messagesString ? model.messagesString : "", {
        preventDuplicate: true,
        autoHideDuration: model.type === "success" ? 1000 : null,
        action: action(model.id),
        key: model.id,
        variant: model.type,
      });

      if (model.type === "success") {
        setTimeout(() => {
          props.dispatch(ToastsAction.removeById(model.id));
          closeSnackbar(model.id);
        }, 500);
      }
    });
  }, [props.toasts.items, enqueueSnackbar, closeSnackbar, props]);

  return null;
}

export { Toasts as Unconnected };

export default connect(mapStateToProps)(Toasts);
