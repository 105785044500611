import ActionUtility from "../../utilities/ActionUtility";
import SubscriptionEffect from "./SubscriptionEffect";

export default class SubscriptionAction {
  // SubscriptionAction action with an function declaration
  static REQUEST_SUBSCRIPTION = "SubscriptionAction.REQUEST_SUBSCRIPTION";
  static REQUEST_SUBSCRIPTION_FINISHED =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_FINISHED";

  static REQUEST_SUBSCRIPTION_FILTER =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_FILTER";
  static REQUEST_SUBSCRIPTION_FILTER_FINISHED =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_FILTER_FINISHED";

  static REQUEST_SUBSCRIPTION_BUY_SUPER_SAVER =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_BUY_SUPER_SAVER";
  static REQUEST_SUBSCRIPTION_BUY_SUPER_SAVER_FINISHED =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_BUY_SUPER_SAVER_FINISHED";

  static REQUEST_CLAIM_SUBSCRIPTION =
    "SubscriptionAction.REQUEST_CLAIM_SUBSCRIPTION";
  static REQUEST_CLAIM_SUBSCRIPTION_FINISHED =
    "SubscriptionAction.REQUEST_CLAIM_SUBSCRIPTION_FINISHED";

  static REQUEST_POST_SUBSCRIPTION =
    "SubscriptionAction.REQUEST_POST_SUBSCRIPTION";
  static REQUEST_POST_SUBSCRIPTION_FINISHED =
    "SubscriptionAction.REQUEST_POST_SUBSCRIPTION_FINISHED";

  static REQUEST_SUBSCRIPTION_CLEAR =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_CLEAR";
  static REQUEST_SUBSCRIPTION_CLEAR_FINISHED =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_CLEAR_FINISHED";

  static REQUEST_SUBSCRIPTION_FILTER_CLEAR =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_FILTER_CLEAR";
  static REQUEST_SUBSCRIPTION_FILTER_CLEAR_FINISHED =
    "SubscriptionAction.REQUEST_SUBSCRIPTION_FILTER_CLEAR_FINISHED";

  // METHODS
  // Organization GET function
  static requestSubscription(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionAction.REQUEST_SUBSCRIPTION,
        SubscriptionEffect.requestSubscription,
        params
      );
    };
  }

  static requestSubscriptionClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionAction.REQUEST_SUBSCRIPTION_CLEAR,
        SubscriptionEffect.requestSubscriptionClear
      );
    };
  }

  static requestSubscriptionFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionAction.REQUEST_SUBSCRIPTION_FILTER_CLEAR,
        SubscriptionEffect.requestSubscriptionFilterClear
      );
    };
  }

  static requestSubscriptionFilter(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionAction.REQUEST_SUBSCRIPTION_FILTER,
        SubscriptionEffect.requestSubscriptionFilter,
        params,
        callBack
      );
    };
  }
  static requestBuySuperSaverSubscription(data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionAction.REQUEST_SUBSCRIPTION_BUY_SUPER_SAVER,
        SubscriptionEffect.requestBuySuperSaverSubscription,
        data,
        callback
      );
    };
  }

  static requestClaimSubscription(data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionAction.REQUEST_CLAIM_SUBSCRIPTION,
        SubscriptionEffect.requestClaimSubscription,
        data,
        callback
      );
    };
  }

  static requestPostSubscription(data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionAction.REQUEST_POST_SUBSCRIPTION,
        SubscriptionEffect.requestPostSubscription,
        data,
        callback
      );
    };
  }
}
