import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import SubscriptionPlansAction from "../../stores/subscriptionPlans/SubscriptionPlansAction";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import { useNavigate } from "react-router";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS,
    UserProfileAction.REQUEST_USERPROFILE,
  ]),
  subscriptionBasePlan: state.subscriptionBasePlan.subscriptionBasePlan || [],
  userprofile: state.userprofile.userprofile || [],
});

const OfferBox = styled(Box)(({ theme }) => ({
  color: "#fff",
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  textAlign: "center",
  position: "relative",
  overflow: "hidden",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: "linear-gradient(to bottom right, #000, #FEE101)",
    opacity: 0.2,
  },
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  margin: theme.spacing(2, 0),
}));

const OldPriceText = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  textDecoration: "line-through",
  color: "#888",
}));

const BenefitList = styled(Box)(({ theme }) => ({
  textAlign: "left",
  margin: theme.spacing(2, 0),
}));

const BenefitStringItem = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const BenefitItem = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  "&:before": {
    content: '"✔"',
    marginRight: theme.spacing(1),
    color: "#fff",
  },
}));

const BenefitNegItem = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  textDecoration: "line-through",
  "&:before": {
    content: '"x"',
    marginRight: theme.spacing(1),
    color: "#fff",
    textDecoration: "none", // Reset text-decoration for the pseudo-element
  },
}));

const BuyButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#444",
  textTransform: "capitalize",
  color: "#fff",
  marginTop: theme.spacing(2),
  "&:hover": {
    backgroundColor: "#fff",
    color: "#000",
  },
}));

const MyPlans = (props) => {
  const navigate=useNavigate()
  const hasSubscriptionPlanId = props.userprofile?._source?.hasOwnProperty(
    "subscription_plan_id"
  );
  const isSubscriptionPlanIdNull =
    props.userprofile?._source?.subscription_plan_id === null;
  console.log(
    "idata",
    props.userprofile?._source?.hasOwnProperty("subscription_plan_id")
  );
  const ReturnMyPlansCard = (input) => {
    const plan = input?.plan?._source;

    const booleanKeys = Object.keys(plan).filter(
      (key) => typeof plan[key] === "boolean" && plan[key] === true
    );

    const booleanNegKeys = Object.keys(plan).filter(
      (key) => typeof plan[key] === "boolean" && plan[key] === false
    );

    // Get the keys that are not boolean
    const nonBooleanKeys = Object.keys(plan).filter(
      (key) =>
        typeof plan[key] !== "boolean" &&
        typeof plan[key] !== "object" &&
        key !== "order_number" &&
        key !== "plan_name"
    );

    const capitalizeAndRemoveUnderscores = (str) => {
      return str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const buyNow = (plantobuy) => {
      const order_number = plantobuy?.order_number;

      let data = {
        plan_id: order_number,
      };

      props
        .dispatch(
          SubscriptionPlansAction.requestSelectSubscriptionPlans(
            JSON.stringify(data)
          )
        )
        .then(() => {
          fetchRequestUserprofile();
          navigate('/profile')
        });
    };

    return (
      <Grid item xl={6} lg={6} md={6}>
        <OfferBox>
          <Typography variant="h6">{plan?.plan_name}</Typography>

          <BenefitList>
            {nonBooleanKeys.map((key) => (
              <BenefitStringItem key={key}>
                {`${capitalizeAndRemoveUnderscores(key)}: ${plan[key]}`}
              </BenefitStringItem>
            ))}

            {booleanKeys.map((key) => (
              <BenefitItem key={key}>
                {capitalizeAndRemoveUnderscores(key)}
              </BenefitItem>
            ))}

            {booleanNegKeys.map((key) => (
              <BenefitNegItem key={key}>
                {capitalizeAndRemoveUnderscores(key)}
              </BenefitNegItem>
            ))}
          </BenefitList>
          {/* <Typography variant="body2">ONLY TODAY</Typography> */}
          {isSubscriptionPlanIdNull || !hasSubscriptionPlanId ? (
            <BuyButton
              onClick={() => {
                buyNow(plan);
              }}
            >
              BUY NOW
            </BuyButton>
          ) : null}

          {props.userprofile?._source?.subscription_plan_id === 1 ? (
            plan?.order_number === 1 ? (
              <BuyButton
                disabled
                // onClick={() => {
                //   buyNow(plan);
                // }}
              >
                Active
              </BuyButton>
            ) : (
              plan?.order_number === 2 && (
                <BuyButton
                  onClick={() => {
                    buyNow(plan);
                  }}
                >
                  Upgrade
                </BuyButton>
              )
            )
          ) : (
            props.userprofile?._source?.subscription_plan_id === 2 &&
            (plan?.order_number === 1 ? (
              <BuyButton
                disabled
                style={{
                  textDecoration: "line-through",
                }}
              >
                Degrade
              </BuyButton>
            ) : (
              plan?.order_number === 2 && <BuyButton disabled>Active</BuyButton>
            ))
          )}
        </OfferBox>
        <br />
      </Grid>
    );
  };

  const fetchSubscriptionPlans = () => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
    };

    props.dispatch(SubscriptionPlansAction.requestSubscriptionBasePlans());
  };

  const fetchRequestUserprofile = () => {
    props.dispatch(UserProfileAction.requestUserprofile());
  };

  useEffect(() => {
    fetchSubscriptionPlans();
    fetchRequestUserprofile();
  }, []);

  return (
    !props.isRequesting && (
      <Box width={"100%"} height={"100%"} position={"relative"}>
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          position={"relative"}
        >
          <Grid
            item
            container
            // className="bg-info"
            xl={10}
            lg={10}
            md={10}
            xs={10}
            sm={10}
            height={"100%"}
            position={"relative"}
          >
            {props?.subscriptionBasePlan?.hits?.hits?.map((eachPlan) => {
              return <ReturnMyPlansCard plan={eachPlan} />;
            })}
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default connect(mapStateToProps)(MyPlans);
