import BaseReducer from "../../utilities/BaseReducer";
import GenreAction from "./GenreAction";

// StatusReduser
export default class GenreReduser extends BaseReducer {
  initialState = {
    status: [],
  };
  [GenreAction.REQUEST_GENRE_FINISHED](state, action) {
    return {
      ...state,
      genre: action.payload,
    };
  }

  [GenreAction.REQUEST_GENRE_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      genre: [],
    };
  }
}
