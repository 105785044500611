import {
  Tooltip,
  Box,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, FastField, Field, Form, Formik, getIn } from "formik";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../components/muiButton/MuiButton";
import CloseIcon from "@mui/icons-material/Close";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import environment from "environment";
import { theme } from "../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import UpdateUserProfile from "./UpdateUserProfile";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import MailSubscription from "./MailSubscription";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import NotificationSubscription from "./NotificationSubscription";
import ResetProfilePassword from "../../components/resetProfilePassword/ResetProfilePassword";
import RegisterAction from "../../stores/register/RegisterAction";
import MuiSnackbarAlert from "../../components/muiSnackbar/MuiSnackbarAlert";
import ProfileChart from "../../stores/ProfileChart";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserProfileAction.REQUEST_USERPROFILE,
    UserProfileAction.REQUEST_MAILSUBSCIPTION,
    UserProfileAction.REQUEST_NOTIFICATION_SUBSCIPTION,
    UserProfileAction.REQUEST_USERPROFILE_PERCENTAGE,
  ]),
  userprofile: state.userprofile.userprofile || [],
  uprofile_percentage: state.uprofile_percentage.uprofile_percentage || [],

  mail_subscription: state.mail_subsription.mail_subsription || [],
  notificationSubscription:
    state.notificationSubscription.notificationSubscription || [],
});

const Profile = (props) => {
  console.log("logcheck", props);

  // Sample profile data

  const location = useLocation();
  const { selectedPlan, superSaverQuantity, superSaverSelected } =
    location.state || {};
  const { isRequesting } = props;
  const { id } = useParams();

  const [url, setUrl] = useState(null);
  const [openPassword, setOpenPassword] = useState(false);
  const [updateProfile, setupdateProfile] = useState(
    location?.state?.action === "profile_update" ? true : false 
  ); 

  console.log("updateProfile", updateProfile);

  const [openUpdate, setOpenUpdate] = useState(
    localStorage.getItem("profile_stored_token") ? true : false
  );

  const [openSubsription, setOpenSubsription] = useState(false);
  const [OpenNotificationSubsription, setOpenNotificationSubsription] =
    useState(false);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarstaus, setSnackbarStatus] = useState("warning");

  const [miniImage, setMiniImage] = useState("");
  const [maxImage, setMaxImage] = useState(null);
  const [miniImageLoader, setMiniImageLoader] = useState(false);
  const [maxImageLoader, setMaxImageLoader] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const formRef = useRef(null); // to reference second Formik form
  let page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  page_permission = [...new Set(page_permission)];

  function fetchUserprofile() {
    let Id = localStorage.getItem("userId");

    let params = {
      current_page: "profile",
      user_id: Id,
    };
    props.dispatch(UserProfileAction.requestUserprofile(params));
  }

  function fetchUserprofilePercentage() {
    let Id = localStorage.getItem("userId");

    props.dispatch(UserProfileAction.requestUserprofilePercentage());
  }

  function fetchSubsriptionData() {
    let Id = localStorage.getItem("userId");

    let params = {
      current_page: "profile",
    };
    props.dispatch(UserProfileAction.requestMailSubsciption(params));
  }

  function fetchNotificationSubsriptionData() {
    let params = {
      current_page: "profile",
    };
    props.dispatch(UserProfileAction.requestNotificationSubsciption(params));
  }

  // function fetchTerritoriesData() {
  //   let params = {
  //     page_limit: "none",
  //   };
  //   props.dispatch(TerritoriesAction.requestTerritories(params));
  // }

  useEffect(() => {
    fetchUserprofile();
    fetchUserprofilePercentage();
    // fetchSubsriptionData();
    fetchNotificationSubsriptionData();
  }, []);

  useEffect(() => {
    if (!props.isRequesting) {
      setMiniImage(() => {
        !props.isRequesting &&
          props?.userprofile?._source?.user_profile_url &&
          RetrieveMiniImageUrl(props?.userprofile?._source?.user_profile_url);
      });
    }
  }, [!props.isRequesting]);

  let countryList = [];

  const [openCoverPicUploader, setOpenCoverPicUploader] = useState(false);

  const handlFileUpload = (value, setFieldValue, event) => {
    setMaxImageLoader(true);
    let formData = new FormData();
    formData.append("file", value);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    fetch(`${environment.api.baseApi}/profile_picture_upload`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_tocken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.status === "success") {
          setFieldValue("thumbnail_url", response.url);
          RetrieveMaxImageUrl(response.url);
        } else {
          setMaxImageLoader(false);
        }
      });
  };

  props.territories &&
    props.territories.territory_data &&
    Object.values(props.territories.territory_data).length > 0 &&
    Object.values(props.territories.territory_data).map(
      (continentList) =>
        continentList.length > 0 &&
        continentList.map((countryData) => {
          countryList.push(countryData.country_name);
        })
    );

  let timezonelist = [];
  props.timezone &&
    props.timezone.timezonedata &&
    Object.values(props.timezone.timezonedata).length > 0 &&
    Object.values(props.timezone.timezonedata).map((valuedata) => {
      return timezonelist.push(valuedata.abbr);
    });

  function RetrieveMaxImageUrl(thumbnail_url) {
    setMaxImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    let userId = localStorage.getItem("userId");
    fetch(
      `${environment.api.baseApi}/get_profile_picture?path=${thumbnail_url}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        imageUrl = URL.createObjectURL(blob);
        setMaxImage(imageUrl);
        setMaxImageLoader(false);
      });
  }

  function RetrieveMiniImageUrl(thumbnail_url) {
    setMiniImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    let userId = localStorage.getItem("userId");
    fetch(
      `${environment.api.baseApi}/get_profile_picture?path=${thumbnail_url}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        imageUrl = URL.createObjectURL(blob);
        setMiniImage(imageUrl);
        setMiniImageLoader(false);
      });
  }

  useEffect(() => {
    if (openCoverPicUploader) {
      props.userprofile &&
        props.userprofile._source &&
        props.userprofile._source.user_profile_url &&
        RetrieveMaxImageUrl(props.userprofile._source.user_profile_url);
    }
  }, [openCoverPicUploader]);

  function SaveThumbnailUrl(values) {
    if (initial) {
      let obj = {
        user_profile_url: values.thumbnail_url,
      };
      props
        .dispatch(UserProfileAction.requestPutUserprofilePic(obj))
        .then(() => {
          props.dispatch(UserProfileAction.requestUserprofile());
          setOpenCoverPicUploader(false);
        });
    }
  }

  let initial = {};
  let formProps = {};
  const { _id, _source } = props?.userprofile;

  if (props?.userprofile) {
    Object.assign(
      initial,
      { id: _id },
      { account_manager: _source?.account_manager },
      { all_content_delivered: _source?.all_content_delivered },
      { business_unit: _source?.business_unit },
      { channel_manager: _source?.channel_manager },
      { city: _source?.city },
      { client_classification: _source?.client_classification },
      { client_name: _source?.client_name },
      { contact: _source?.contact },
      { contract_end_date: _source?.contract_end_date },
      { contract_signature_date: _source?.contract_signature_date },
      { contracting_a3_entity: _source?.contracting_a3_entity },
      { cost_centre: _source?.cost_centre },
      { country: _source?.country },
      { default_language: _source?.default_language },
      { default_time_zone: _source?.default_time_zone },
      { digital_marketing_manager: _source?.digital_marketing_manager },
      { enabled: _source?.enabled },
      { first_account_signatory: _source?.first_account_signatory },
      { first_name: _source?.first_name },
      { last_name: _source?.last_name },
      { main_email_address: _source?.main_email_address },
      { main_music_genre: _source?.main_music_genre },
      { phone_number: _source?.phone_number },
      { postal_address: _source?.postal_address },
      { postal_code: _source?.postal_code },
      { royalties_email_address: _source?.royalties_email_address },
      { type_of_client: _source?.type_of_client },
      {
        user_profile_url: _source?.user_profile_url,
      },
      { account_holder_name: _source?.bank_details?.account_holder_name },
      { account_number: _source?.bank_details?.account_number },
      { bank_name: _source?.bank_details?.bank_name },
      { bank_address: _source?.bank_details?.bank_address },
      { branch_name: _source?.bank_details?.branch_name },
      { gst_no: _source?.bank_details?.gst_no },
      { bank_gst_no_checkbox: _source?.bank_details?.bank_gst_no_checkbox },
      { pan_no: _source?.bank_details?.pan_no },
      { ifsc: _source?.bank_details?.ifsc },
      { username: _source?.username },
      { country_code: _source?.country_code }
    );
  }

  if (initial) {
    formProps = {
      type_of_client: initial.type_of_client,
      main_email_address: initial.main_email_address,
      royalties_email_address: initial.royalties_email_address,
      first_name: initial.first_name,
      last_name: initial.last_name,
      phone_number: initial.phone_number,
      postal_address: initial.postal_address,
      postal_code: initial.postal_code,
      city: initial.city,
      country: initial.country,
      default_time_zone: initial.default_time_zone,
      thumbnail_url: initial.user_profile_url,
      client_name: initial.client_name,
      account_holder_name: initial.account_holder_name,
      account_number: initial.account_number,
      bank_name: initial.bank_name,
      bank_address: initial.bank_address,
      branch_name: initial.branch_name,
      gst_no: initial.gst_no,
      bank_gst_no_checkbox: initial.bank_gst_no_checkbox,
      pan_no: initial.pan_no,
      ifsc: initial.ifsc,
      username: initial.username,
      country_code: initial.country_code,
    };
  } else {
    formProps = {
      type_of_client: "",
      main_email_address: "",
      royalties_email_address: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      postal_address: "",
      postal_code: "",
      city: "",
      country: "",
      default_time_zone: "",
      account_holder_name: "",
      account_number: "",
      bank_name: "",
      bank_address: "",
      branch_name: "",
      gst_no: "",
      bank_gst_no_checkbox: true,
      pan_no: "",
      ifsc: "",
      username: "",
      country_code: "",
    };
  }

  initial = {
    id: initial.id,
    type_of_client: initial.type_of_client,
    main_email_address: initial.main_email_address,
    royalties_email_address: initial.royalties_email_address,
    first_name: initial.first_name,
    last_name: initial.last_name,
    phone_number: initial.phone_number,
    postal_address: initial.postal_address,
    postal_code: initial.postal_code,
    city: initial.city,
    country: initial.country,
    default_time_zone: initial.default_time_zone,
    thumbnail_url: initial.user_profile_url,
    client_name: initial.client_name,
    account_holder_name: initial.account_holder_name,
    account_number: initial.account_number,
    bank_name: initial.bank_name,
    bank_address: initial.bank_address,
    branch_name: initial.branch_name,
    gst_no: initial.gst_no,
    bank_gst_no_checkbox: initial.bank_gst_no_checkbox,
    pan_no: initial.pan_no,
    username: initial.username,
    ifsc: initial.ifsc,
    country_code: initial.country_code,
  };

  function capitalizeWords(input) {
    return input.toUpperCase();
  }

  console.log("_source", _source, initial);

  const isFirstlogin = localStorage.getItem("isFirstLogin");
  console.log("isFirstlogin", isFirstlogin);
  return (
    <Box
      // height={"100%"}
      sx={{
        paddingTop: 2,
        borderRadius: theme.shape.borderRadius,
        textAlign: "center",
        position: "relative",
        //   boxShadow: `
        // inset 0px 0px 10px rgba(0, 0, 0, 0.4),
        // inset 10px 10px 20px rgba(0, 0, 0, 0.3),
        // inset -10px -10px 20px rgba(254, 225, 1, 0.3)`,
      }}
    >
      <LoadingIndicator isActive={props.isRequesting}>
        {
          <>
            {updateProfile ? (
              <Formik
                enableReinitialize
                initialValues={initial}
                onSubmit={(values, formikHelpers) => {
                  console.log("initialdata", values);
                  // setLoading(true);

                  let Obj = {};
                  Object.assign(
                    Obj,
                    values.main_email_address
                      ? {
                          main_email_address: values.main_email_address,
                        }
                      : {
                          main_email_address: "",
                        },
                    values.first_name
                      ? {
                          first_name: values.first_name,
                        }
                      : {
                          first_name: "",
                        },
                    values.last_name
                      ? {
                          last_name: values.last_name,
                        }
                      : {
                          last_name: "",
                        },
                    values.username && {
                      username: values.username,
                    },
                    values.postal_code
                      ? {
                          postal_code: Number(values.postal_code),
                        }
                      : {
                          postal_code: null,
                        },
                    values.city
                      ? {
                          city: values.city,
                        }
                      : {
                          city: "",
                        },
                    values.country
                      ? {
                          country: values.country,
                        }
                      : {
                          country: "",
                        }
                  );

                  let xToken = "";
                  if (localStorage?.getItem("profile_stored_token")) {
                    xToken = localStorage?.getItem("profile_stored_token");
                  }

                  const headers = {
                    "X-One-Time-Token": xToken,
                  };
                  console.log("putobjprofile", Obj, headers);
                  props
                    .dispatch(
                      UserProfileAction.requestPutUserprofile(Obj, headers)
                    )
                    .then(() => {
                      setupdateProfile(false);
                      props.dispatch(UserProfileAction.requestUserprofile());
                    });
                }}
                // validationSchema={validationSchema}
              >
                {({
                  errors,
                  isValid,
                  helperText,
                  touched,
                  dirty,
                  handleChange,
                  values,
                  options,
                  setFieldValue,
                  submitForm,
                }) => (
                  <Form style={{ width: "100%" }}>
                    <Grid
                      container
                      lg={12}
                      sm={12}
                      md={12}
                      xl={12}
                      pl={3}
                      pb={10}
                    >
                      <Grid item lg={8} xl={8} md={8} sm={8}>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={7} md={5} sm={5}>
                            <Typography>Main Email Address</Typography>
                          </Grid>
                          <Grid item lg={4} md={6} sm={6}>
                            <Field
                              as={MuiTextField}
                              variant="outlined"
                              placeholder="Main Email Address"
                              id="main_email_address"
                              size="small"
                              name="main_email_address"
                              fieldheight="25px"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={7} md={5} sm={5}>
                            <Typography>First Name</Typography>
                          </Grid>
                          <Grid item lg={4} md={6} sm={6}>
                            <Field
                              as={MuiTextField}
                              variant="outlined"
                              placeholder="First Name"
                              id="first_name"
                              size="small"
                              name="first_name"
                              fieldheight="25px"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={7} md={5} sm={5}>
                            <Typography>Last Name</Typography>
                          </Grid>
                          <Grid item lg={4} md={6} sm={6}>
                            <Field
                              as={MuiTextField}
                              variant="outlined"
                              placeholder="Last Name"
                              id="last_name"
                              size="small"
                              name="last_name"
                              fieldheight="25px"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={7} md={5} sm={5}>
                            <Typography>Username</Typography>
                          </Grid>
                          <Grid item lg={4} md={6} sm={6}>
                            <Field
                              as={MuiTextField}
                              variant="outlined"
                              placeholder="Username"
                              id="username"
                              size="small"
                              name="username"
                              fieldheight="25px"
                              fullWidth
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={7} md={5} sm={5}>
                            <Typography>Postal Code</Typography>
                          </Grid>
                          <Grid item lg={4} md={6} sm={6}>
                            <Field
                              as={MuiTextField}
                              variant="outlined"
                              placeholder="Postal Code"
                              id="postal_code"
                              size="small"
                              name="postal_code"
                              fieldheight="25px"
                              inputProps={{
                                maxLength: 6,
                              }}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={7} md={5} sm={5}>
                            <Typography>City</Typography>
                          </Grid>
                          <Grid item lg={4} md={6} sm={6}>
                            <Field
                              as={MuiTextField}
                              variant="outlined"
                              placeholder="City"
                              id="city"
                              size="small"
                              name="city"
                              fieldheight="25px"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={7} md={5} sm={5}>
                            <Typography>Country / Region</Typography>
                          </Grid>
                          <Grid item lg={4} md={6} sm={6}>
                            <Field
                              as={MuiTextField}
                              variant="outlined"
                              placeholder="Country"
                              id="country"
                              size="small"
                              name="country"
                              fieldheight="25px"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <MuiButton
                      title="Update Profile"
                      onClick={(event) => {
                        submitForm();
                      }}
                      name="Update Profile"
                      width="150px"
                    ></MuiButton>
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={initial}
                enableReinitialize
                onSubmit={(values) => {}}
                innerRef={formRef}
              >
                {({ values }) => (
                  <Form style={{ width: "100%" }}>
                    <Grid container lg={12} sm={12} md={12} xl={12} pl={3}>
                      <Grid
                        item
                        lg={7}
                        xl={7}
                        md={6}
                        sm={6}
                        // className="bg-warning"
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Typography
                            component={"span"}
                            sx={{ fontSize: "24px" }}
                          >
                            {" "}
                            {values.username}{" "}
                            <span style={{ fontSize: "18px" }}>
                              ({values.first_name} {values.last_name})
                            </span>
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={5} md={5} sm={5}>
                            <Typography>Main Email Address</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6}>
                            <Typography component={"div"}>
                              {props.userprofile &&
                              props.userprofile._source &&
                              props.userprofile._source
                                ? props.userprofile._source.main_email_address
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={5} md={5} sm={5}>
                            <Typography>Phone Number</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6}>
                            <Typography component={"div"}>
                              {props.userprofile &&
                              props.userprofile._source &&
                              props.userprofile._source
                                ? props.userprofile._source.phone_number
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={5} md={5} sm={5}>
                            <Typography>Country Code</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6}>
                            <Typography component={"div"}>
                              {props.userprofile &&
                              props.userprofile._source &&
                              props.userprofile._source
                                ? props.userprofile._source.country_code
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={5} md={5} sm={5}>
                            <Typography>Postal Code</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6}>
                            <Typography component={"div"}>
                              {props.userprofile &&
                              props.userprofile._source &&
                              props.userprofile._source
                                ? props.userprofile._source.postal_code
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={5} md={5} sm={5}>
                            <Typography>City</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6}>
                            <Typography component={"div"}>
                              {props.userprofile &&
                              props.userprofile._source &&
                              props.userprofile._source
                                ? props?.userprofile?._source?.city
                                : ""}
                              {console.log(
                                "citydata",
                                props?.userprofile?._source?.city
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          container
                          gap={1}
                          pt={2}
                        >
                          <Grid item lg={5} md={5} sm={5}>
                            <Typography>Country / Region</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6}>
                            <Typography component={"div"}>
                              {props.userprofile &&
                              props.userprofile._source &&
                              props.userprofile._source
                                ? props?.userprofile?._source?.country
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        gap={1}
                        pt={2}
                      >
                        <Grid item lg={5} md={5} sm={5}>
                          <Typography>Default Time Zone</Typography>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6}>
                          <Typography component={"div"}>
                            {props.userprofile &&
                            props.userprofile._source &&
                            props.userprofile._source
                              ? props.userprofile._source.default_time_zone
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid> */}

                        {/* {props.mail_subscription &&
                        props.mail_subscription._source && (
                          <>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              gap={1}
                              pt={2}
                              alignItems="center"
                            >
                              <Grid item lg={5} md={5} sm={5}>
                                <Typography>Label Subscription</Typography>
                              </Grid>

                              <Grid item lg={6} md={6} sm={6}>
                                {props.mail_subscription &&
                                props.mail_subscription._source &&
                                props.mail_subscription._source.events &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "label";
                                  }
                                ) &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "label";
                                  }
                                )[0] &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "label";
                                  }
                                )[0].subscribed ? (
                                  <MuiButton
                                    name="Subscribed"
                                    burronBackground="green"
                                    // onClick={() => {
                                    //   updateSubscription(
                                    //     props.mail_subscription._source.events.filter(
                                    //       (item) => {
                                    //         return item.event_name === "label";
                                    //       }
                                    //     )[0].subscribed,
                                    //     "label"
                                    //   );
                                    // }}
                                  />
                                ) : (
                                  <MuiButton
                                    name="Unsubscribed"
                                    burronBackground="red"
                                    // onClick={() => {
                                    //   updateSubscription(
                                    //     props.mail_subscription._source.events.filter(
                                    //       (item) => {
                                    //         return item.event_name === "label";
                                    //       }
                                    //     )[0].subscribed,
                                    //     "label"
                                    //   );
                                    // }}
                                  />
                                )}
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              gap={1}
                              pt={2}
                              // pb={1}
                              alignItems="center"
                            >
                              <Grid item lg={5} md={5} sm={5}>
                                <Typography>Release Subscription</Typography>
                              </Grid>

                              <Grid item lg={6} md={6} sm={6}>
                                {props.mail_subscription &&
                                props.mail_subscription._source &&
                                props.mail_subscription._source.events &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "release";
                                  }
                                ) &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "release";
                                  }
                                )[0] &&
                                props.mail_subscription._source.events.filter(
                                  (item) => {
                                    return item.event_name === "release";
                                  }
                                )[0].subscribed ? (
                                  <MuiButton
                                    name="Subscribed"
                                    burronBackground="green"
                                    // onClick={() => {
                                    //   updateSubscription(
                                    //     props.mail_subscription._source.events.filter(
                                    //       (item) => {
                                    //         return item.event_name === "release";
                                    //       }
                                    //     )[0].subscribed,
                                    //     "release"
                                    //   );
                                    // }}
                                  />
                                ) : (
                                  <MuiButton
                                    name="Unsubscribed"
                                    burronBackground="red"
                                    // onClick={() => {
                                    //   updateSubscription(
                                    //     props.mail_subscription._source.events.filter(
                                    //       (item) => {
                                    //         return item.event_name === "release";
                                    //       }
                                    //     )[0].subscribed,
                                    //     "release"
                                    //   );
                                    // }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )} */}

                        {props.notificationSubscription &&
                          props.notificationSubscription &&
                          props.notificationSubscription.length !== 0 && (
                            <>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                gap={1}
                                pt={2}
                                pb={5}
                                alignItems="center"
                              >
                                <Grid item lg={5} md={5} sm={5}>
                                  <Typography>
                                    Notification Subscription
                                  </Typography>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6}>
                                  {props.notificationSubscription &&
                                  props.notificationSubscription
                                    .notification_subscribed ? (
                                    <MuiButton
                                      name="Subscribed"
                                      burronBackground="green"
                                      width="150px"
                                    />
                                  ) : (
                                    <MuiButton
                                      name="Unsubscribed"
                                      burronBackground="red"
                                      width="150px"
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          )}
                      </Grid>

                      <Grid
                        item
                        lg={2}
                        xl={2}
                        md={2}
                        sm={2}
                        // className="bg-info"
                      >
                        <Typography
                          component={"div"}
                          onClick={() => {
                            setOpenCoverPicUploader(true);
                          }}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            width: { xl: "240px", lg: "240px" },
                            height: { xl: "192px", lg: "192px" },
                            cursor: "pointer",
                            backgroundColor: theme.palette.background.paper,
                            border: `1px dashed ${theme.palette.text.dark}`,
                          }}
                        >
                          <Field name="thumbnail_url">
                            {({
                              field: { value },
                              form: { setFieldValue },
                            }) => (
                              <>
                                {value &&
                                props.userprofile &&
                                props.userprofile._source &&
                                props.userprofile._source.user_profile_url ? (
                                  <>
                                    {miniImage ? (
                                      <img
                                        src={miniImage}
                                        alt="Preview"
                                        onError={() => {
                                          // Error handling code goes here
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    ) : miniImageLoader ? (
                                      <CircleLoader />
                                    ) : null}
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faFileArrowUp}
                                      style={{
                                        color: theme.palette.common.grey,
                                        width: "37px",
                                        height: "50px",
                                      }}
                                    ></FontAwesomeIcon>
                                    <Typography
                                      component={"span"}
                                      sx={{
                                        color: theme.palette.common.grey,
                                        fontSize: "16px",
                                        mt: "15px",
                                      }}
                                    >
                                      Upload Profile
                                    </Typography>
                                  </Box>
                                )}

                                <input
                                  id="thumbnail-upload"
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    setMaxImageLoader(true);
                                    handlFileUpload(
                                      e.target.files[0],
                                      setFieldValue,
                                      e
                                    );
                                  }}
                                />
                              </>
                            )}
                          </Field>
                        </Typography>
                        <UpdateUserProfile
                          open={openCoverPicUploader}
                          imgUrl={maxImage}
                          maxImageLoader={maxImageLoader}
                          onClose={() => {
                            setOpenCoverPicUploader(false);
                          }}
                          onSave={() => {
                            SaveThumbnailUrl(values);
                          }}
                          artists={values.artists}
                          title={values.title}
                        />
                        <ResetProfilePassword
                          open={openPassword}
                          onClose={() => {
                            setOpenPassword(false);
                          }}
                        />
                        <MailSubscription
                          edit={props.mail_subscription._source ? true : false}
                          open={openSubsription}
                          onClose={() => {
                            setOpenSubsription(false);
                          }}
                          mail_subscription={props.mail_subscription}
                        />

                        <NotificationSubscription
                          edit={
                            props.notificationSubscription &&
                            props.notificationSubscription.length !== 0
                              ? true
                              : false
                          }
                          open={OpenNotificationSubsription}
                          onClose={() => {
                            setOpenNotificationSubsription(false);
                          }}
                          notificationSubscription={
                            props.notificationSubscription
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        lg={2}
                        xl={2}
                        md={2}
                        sm={3}
                        // className="bg-info"
                      >
                        <ProfileChart
                          profileData={props?.uprofile_percentage}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}

            <Grid
              item
              textAlign={"center"}
              container
              gap={3}
              alignItems={"center"}
              justifyContent="center"
            >
              {/* <MuiButton
                title={
                  props.mail_subscription._source
                    ? "Edit Mail Subscription"
                    : "Subscribe to mails"
                }
                onClick={(event) => {
                  setOpenSubsription(true);
                }}
                name={
                  props.mail_subscription._source
                    ? "Edit mail Subscription"
                    : "Subscribe to mails"
                }
              ></MuiButton> */}
              {!updateProfile && (
                <>
                  <MuiButton
                    title={
                      props.notificationSubscription &&
                      props.notificationSubscription.length !== 0
                        ? "Edit Notification Subscription"
                        : "Subscribe to Notifications"
                    }
                    onClick={(event) => {
                      setOpenNotificationSubsription(true);
                    }}
                    name={
                      props.notificationSubscription &&
                      props.notificationSubscription.length !== 0
                        ? "Edit Notification Subscription"
                        : "Subscribe to Notifications"
                    }
                    width="250px"
                  ></MuiButton>

                  <MuiButton
                    title="Reset Password"
                    onClick={(event) => {
                      setOpenPassword(true);
                    }}
                    name="Reset Password"
                    width="150px"
                  ></MuiButton>
                  <MuiButton
                    title="Update Profile"
                    onClick={(event) => {

                      if (isFirstlogin === "false" && openUpdate === false) {

                        const callBack = (res) => {
                          if (res?.status === "success") {
                            setSnackbarMessage(res.message);
                            setSnackbarStatus("success");
                            setSnackbarOpen(true);
                            const Obj = {
                              phone_number:
                                props?.userprofile?._source?.phone_number,
                              country_code:
                                props?.userprofile?._source?.country_code,
                              flow_type: "3",
                            };

                            // localStorage.removeItem("isFirstLogin");

                            localStorage.setItem("setOpenUpdate", true);

                            setTimeout(() => {
                              navigate("/ValidateOtp", {
                                state: { action: "profile", Obj },
                              });
                            }, 1500);
                          } else {
                            setSnackbarMessage(res?.raw?.data?.message);
                            setSnackbarStatus("warning");
                            setSnackbarOpen(true);
                          }
                        };
                        let postObjdata = {
                          phone_number:
                            props?.userprofile?._source?.phone_number,
                          country_code:
                            props?.userprofile?._source?.country_code,
                          flow_type: "3",
                        };

                        // setupdateProfile(!updateProfile);
                        props.dispatch(
                          RegisterAction.requestPostMobileNumber(
                            JSON.stringify(postObjdata),
                            callBack
                          )
                        );
                      } else {
                        setupdateProfile(!updateProfile);
                      }
                    }}
                    name="Update Profile"
                    width="150px"
                  ></MuiButton>

                  <MuiButton
                    title="Update Phone Number"
                    onClick={(event) => {
                      const callBack = (res) => {
                        if (res?.status === "success") {
                          setSnackbarMessage(res.message);
                          setSnackbarStatus("success");
                          setSnackbarOpen(true);
                          const Obj = {
                            phone_number:
                              props?.userprofile?._source?.phone_number,
                            country_code:
                              props?.userprofile?._source?.country_code,
                            flow_type: "3",
                          };

                          setTimeout(() => {
                            navigate("/ValidateOtp", {
                              state: { action: "update_phone", Obj },
                            });
                          }, 1500);
                        } else {
                          setSnackbarMessage(res?.raw?.data?.message);
                          setSnackbarStatus("warning");
                          setSnackbarOpen(true);
                        }
                      };
                      let postObjdata = {
                        phone_number: props?.userprofile?._source?.phone_number,
                        country_code: props?.userprofile?._source?.country_code,
                        flow_type: "4",
                      };

                      props.dispatch(
                        RegisterAction.requestPostMobileNumber(
                          JSON.stringify(postObjdata),
                          callBack
                        )
                      );
                    }}
                    name="Update Phone Number"
                    width="250px"
                  ></MuiButton>
                </>
              )}
            </Grid>
            <Grid item pt={5}>
              {!updateProfile && (
                <MuiButton
                  title="Payment"
                  onClick={(event) => {
                    navigate("/subscriptionPlans");
                  }}
                  name="Payment"
                  width="150px"
                ></MuiButton>
              )}
            </Grid>

            <MuiSnackbarAlert
              open={snackbarOpen}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarMessage}
              snackbarstaus={snackbarstaus}
            />
          </>
        }
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(Profile);
