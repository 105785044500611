import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import LibraryMusicTwoToneIcon from "@mui/icons-material/LibraryMusicTwoTone";
import QueueMusicRoundedIcon from "@mui/icons-material/QueueMusicRounded";
import TracksPlayLinks from "./TracksPlayLinks";
import AlbumPlayLinks from "./AlbumPlayLinks";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_FINAL_RELEASE_BY_ID,
  ]),
  finalReleaseById: state.finalReleaseById.finalReleaseById || {},
});

const MainPlayLink = (props) => {
  const subTabsUI = {
    textTransform: "none",
    fontSize: "16px",
    "&.Mui-selected": {
      fontWeight: "bold",
      boxShadow: "0px 0px 20px 4px rgba(0, 0, 0, 0.6)",
      backgroundColor: theme.palette.background.light,
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        transition: "transform 0.9s",
        transform: "scale(1.3)",
      },
    },
  };

  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <LoadingIndicator isActive={props.isRequesting}>
        {
          <div style={{ marginBottom: "20px" }}>
            <Tabs
              variant="fullWidth"
              value={activeTab}
              scrollButtons
              allowScrollButtonsMobile
              onChange={handleTabChange}
              centered
              style={{
                marginTop: "5px",
                marginBottom: "10px",
                backgroundColor: theme.palette.background.default,
                borderRadius: "10px",
                zIndex: 10,
                "&.Mui-focusVisible": {
                  backgroundColor: "rgba(100, 95, 228, 0.32)",
                },
              }}
            >
              <Tab
                label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "32px",
                    }}
                  >
                    <QueueMusicRoundedIcon
                      style={{
                        fontSize: 30,
                        marginRight: 18,
                        transition: "transform 0.3s",
                        color: theme.palette.text.primary
                      }}
                    />
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      Tracks Play Links
                    </Typography>
                  </div>
                }
                sx={subTabsUI}
              />
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LibraryMusicTwoToneIcon
                      style={{
                        fontSize: 30,
                        marginRight: 18,
                        transition: "transform 0.3s",
                        color: theme.palette.text.primary,
                      }}
                    />
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      Album Play Links
                    </Typography>
                  </div>
                }
                sx={subTabsUI}
              />
            </Tabs>
          </div>
        }
        {activeTab === 0 && (
          <TracksPlayLinks
            releaseId={props.releaseId}
            trackId={props.trackId}
            releasedate={
              props?.releaseById?._source ? props?.releaseById?._source : null
            }
            projectEditData={props.projectEditData}
          />
        )}
        {activeTab === 1 && (
          <AlbumPlayLinks
            releaseId={props.releaseId}
            trackId={props.trackId}
            releasedate={
              props?.releaseById?._source ? props?.releaseById?._source : null
            }
            releaseData={props.releaseData}
            projectEditData={props.projectEditData}
          />
        )}
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(MainPlayLink);
