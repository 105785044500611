import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Grid, IconButton, useTheme, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndustry, faTrash } from "@fortawesome/free-solid-svg-icons";
import AlbumIcon from "@mui/icons-material/Album";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PublicIcon from "@mui/icons-material/Public";
import DialogContent from "@mui/material/DialogContent";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DialogTitle from "@mui/material/DialogTitle";
import StreamPlatformAction from "../../../stores/streamPlatform/StreamPlatformAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import GenreAction from "../../../stores/genre/GenreAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../App";
import { makeStyles } from "@material-ui/core";
import { Palette } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
// import Rettrive from "../../releases/Rettrive";
import TopStoreAction from "../../../stores/topStoresReport/TopStoreAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [TopStoreAction.REQUEST_TOPSTORE]),

  topStore: state.topStore.topStore || [],
});

function TopStores(props) {
  const theme = useTheme();
  const { isRequesting } = props;
  const navigate = useNavigate();

  const [editRowsModel, setEditRowsModel] = useState({});
  const [page_limit] = useState(15);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("body");
  const [title, setTitle] = useState(null);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);

  useEffect(() => {
    let params = {
      current_page: "analytics",
    };
    fetchTopStores(params);
  }, []);

  function fetchStores(params) {
    props.dispatch(StreamPlatformAction.requesPlatform(params));
  }

  function fetchStoresFilter(params) {
    props.dispatch(StreamPlatformAction.requestPlatformFilter(params));
  }

  function fetchGenre() {
    const param = {
      page_limit: "none",
      order_by: `[["genre_name.keyword", "asc"]]`,
    };
    props.dispatch(GenreAction.requestGenre(param));
  }

  function fetchTopStores(params) {
    props.dispatch(TopStoreAction.requestTopStore(params));
  }

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const titleList = [];

  props?.releaseFilter?.hits?.hits?.map((item) => {
    titleList.push(item._source.title);
  });

  const TitleListHandleChange = (event, title) => {
    if (title != null) {
      setTitle(title);
    } else {
      setTitle("");
    }
  };

  const releaseTypeList = ["audio", "video", "ringtone"];

  function applyFilter() {
    const filter = [];

    if (title !== "") {
      filter.push(`["title.keyword","must","","term","${title}"]`);
    }

    if (title !== "") {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetchStores(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  }

  const ClearState = () => {
    // Clear the state
    if (title !== "") {
      setClearLoader(true);
      setFilterLoader(false);
      setTitle("");
    }
    setFilterClicked(false);
    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: page_limit,
    };
    FilterClicked && fetchStores(params);
  };

  //Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      // width: 80,
      flex: 1,
    },

    {
      // width: 280,
      flex: 3,
      field: "stores",
      headerName: "Stores",
    },

    {
      // width: 140,
      flex: 4,
      field: "percent",
      headerName: "Stream Percentage",
    },

    {
      // width: 150,
      flex: 4,
      field: "count",
      headerName: "Audio Streams",
    },
  ];

  const rows = [];

  if (props.topStore && props.topStore.top_stores_report) {
    props.topStore.top_stores_report &&
      props.topStore.top_stores_report.length > 0 &&
      props.topStore.top_stores_report.map((item, index) => {
        rows.push({
          sl_no: ++index,
          id: ++index,
          count: item.count,
          stores: item.key,
          percent: item.percentage.toFixed(2),
        });
      });
  }

  return (
    <React.Fragment>
      {!isRequesting ? (
        <Grid
          container
          item
          xs={12}
          pr={1}
          xl={12}
          lg={12}
          md={12}
          justifyContent="flex-start"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <LoadingIndicator isActive={isRequesting}>
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={80}
                isRequesting={isRequesting}
              />
            </LoadingIndicator>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(TopStores);
