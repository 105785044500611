import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { theme } from "../App";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Typography,
  TextField,
  Slide,
  DialogContent,
  DialogTitle,
  DialogActions,
  Avatar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import environment from "environment";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import DataGridFunction from "../../components/dataGridComponent/CustomDataGrid";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiSnackbarAlert from "../../components/muiSnackbar/MuiSnackbarAlert";
import ArtistAction from "../../stores/artist/ArtistAction";
import AssetsAction from "../../stores/assets/AssetsAction";
import LanguageAction from "../../stores/language/LanguageAction";
import ClientAction from "../../stores/client/ClientAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import TrackAction from "../../stores/track/TrackAction";
import ValidationAction from "../../stores/validation/ValidationAction";
import UploadPopper from "../../components/poppers/UploadPopper";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import SubscriptionAction from "../../stores/subscriptions/SubscriptionAction";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import SubscriptionPlansAction from "../../stores/subscriptionPlans/SubscriptionPlansAction";
import BreadCrumbs from "./BreadCrumbs";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK,
    ClientAction.REQUEST_CLIENT_FILTER,
    ArtistAction.REQUEST_ARTIST_FILTER,
    AssetsAction.REQUEST_ASSETS,
    AssetsAction.REQUEST_ASSETS_FILTER,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    LanguageAction.REQUEST_LANGUAGE,
  ]),
  track: state.track.track.hits || [],
  artistFilter: state.artistFilter.artistFilter || [],
  clientFilter: state.clientFilter.clientFilter || [],
  assets: state.assets.assets.hits || [],
  language: state.language.language || [],
  validateReleaseById: state.validateReleaseById.validateReleaseById || {},
  assetsFilter: state.assetsFilter.assetsFilter || [],
  releaseById: state.releaseById.releaseById || {},
});

const CommonButton = ({ count, onClick }) => {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        borderRadius: "10px",
        color: "white",
        backgroundColor:
          count === 0
            ? `${theme.palette.background.drawerMenu}`
            : `${theme.palette.secondary.main}`,
        "&:hover": {
          backgroundColor: "black",
        },
      }}
      onClick={onClick}
    >
      {count === 0 ? (
        <>
          <DoneAllRoundedIcon />
          &nbsp;&nbsp;Details
        </>
      ) : (
        <>
          <ErrorOutlinedIcon />
          &nbsp;
          {`${count} Error(s)`}
        </>
      )}
    </Button>
  );
};

function Submission(props) {
  const [imgUrl, setImgUrl] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [assetSnackBar, setAssetSnackBar] = useState(false);
  const [finalSubmitLoading, setFinalSubmitLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [authButtonVisible, setAuthButtonVisible] = useState(false);
  const [initiateloading, setInitiateloading] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [openMapDSPDialog, setMapDSPDialog] = useState(false);
  const navigate = useNavigate();
  const [saveloading, setsaveloading] = useState(false);
  const isFirstLogin = localStorage.getItem("isFirstLogin");
  console.log("isFirstLogin", isFirstLogin);

  function fetchResource(resourceId) {
    props.dispatch(ReleasesAction.requestReleaseById(resourceId));
  }
  function fetchTracks(params) {
    props.dispatch(TrackAction.requestTrack(params));
  }
  function fetchArtistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }
  function fetchlanguageFilter(param) {
    props.dispatch(LanguageAction.requestLanguageFilter(param));
  }
  function fetchClientFilter(param) {
    props.dispatch(ClientAction.requestClientFilter(param));
  }
  function fetchAssetsFilter(param) {
    props.dispatch(AssetsAction.requestAssetsFilter(param));
  }
  function fetchAssets(param) {
    props.dispatch(AssetsAction.requestAssets(param));
  }
  function FetchValidationStatus(releaseId) {
    props.dispatch(ValidationAction.requestValidationById(releaseId));
  }

  useEffect(() => {
    const params = {
      // order_by: `[["track_number","asc"]]`,
      page_limit: "none",
      current_page: "submission",
      filters: `[["release_id.keyword","must","","match","${localStorage.getItem(
        "id"
      )}"]]`,
    };

    const filterparam = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "submission",
    };
    let id = localStorage.getItem("id");
    fetchTracks(params);
    // fetchArtistFilter(filterparam);
    // fetchClientFilter(filterparam);
    delete params.order_by;
    fetchAssets(params);
    fetchResource(id);
    // FetchValidationStatus(id);
    // fetchArtistFilter(params);
  }, []);

  const { _source } = props?.releaseById;

  useEffect(() => {
    if (!props.isRequesting) {
      setImgUrl(() => {
        !props.isRequesting &&
          props?.releaseById?._source?.thumbnail_url &&
          RetrieveImageUrl(props?.releaseById?._source?.thumbnail_url);
      });
    }
    let validateData = props.validateReleaseById;

    if (
      validateData?._source?.artwork &&
      validateData?._source?.asset &&
      validateData?._source?.duplication &&
      validateData?._source?.meta_data
    )
      setValidated(true);
    else setValidated(false);
  }, [!props.isRequesting]);

  function RetrieveImageUrl(thumbnail_url) {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
    setImageLoader(true);

    let imageUrl = "";
    fetch(
      `${environment.api.retriveAsset}?path=${thumbnail_url}&release_id=${props?.releaseById?._id}&client_id=${props?.releaseById?._source?.client_id.id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageLoader(false);
        imageUrl = URL.createObjectURL(blob);
        setImgUrl(imageUrl);
      });
  }

  let assets_list = [];
  if (props?.assets?.hits) {
    props.assets.hits.length !== 0 &&
      props.assets.hits.map((item) => {
        assets_list.push({
          assets_id: item._id,
          assets_name: item._source.assets_name,
          assets_url: item._source.asset_url,
        });
      });
  }
  assets_list =
    assets_list?.length !== 0
      ? assets_list.filter((item) => item.assets_url !== null)
      : [];

  const InitiateAuthentication = () => {
    setInitiateloading(true);
    props.dispatch(ReleasesAction.requestPostToInitiateToken(null)).then(() => {
      setInitiateloading(false);
      setAuthButtonVisible(false);
    });
  };

  let releaseInfoError = 0;
  let priceError = 0;
  let releaseDateError = 0;
  let assetsError = 0;
  let tracksError = 0;
  let assetMapWarning = 0;
  let individualTrackErrorPresent = false;

  if (_source) {
    if (_source.tracks?.length !== 0) {
      _source.tracks.map((item) => {
        if (item.assets_id === "" || item.assets_id === null) {
          assetsError++;
        }
      });
    } else if (_source.existing_tracks?.length == 0) assetsError++;
  }

  function fetchTrackErrors(trackData) {
    const { _source } = trackData;

    let tracksErrors = 0;

    if (!_source?.title) {
      tracksErrors++;
    }
    if (_source?.primary_artists.length == 0) {
      tracksErrors++;
    }
    if (_source?.author?.length == 0 && !_source.instrumental) {
      tracksErrors++;
    }
    if (_source?.composer.length == 0) {
      tracksErrors++;
    }
    if (!_source.genre) {
      tracksErrors++;
    }
    if (!_source.sub_genre_name) {
      tracksErrors++;
    }
    if (!_source.plan_id) {
      // tracksErrors++;
    }
    if (!_source.instrumental) {
      if (!_source.track_title_language) {
        tracksErrors++;
      }
    }
    if (!_source.instrumental) {
      if (!_source.lyrics_language) {
        tracksErrors++;
      }
    }
    if (!_source.copyright_year) {
      tracksErrors++;
    }
    if (!_source.production_year) {
      tracksErrors++;
    }
    if (!_source.preview_start) {
      tracksErrors++;
    }
    let publisher_array = _source.publisher.split("-");

    if (publisher_array.length > 0) {
      if (!publisher_array[0] || !publisher_array[1]) {
        tracksErrors++;
      }
    }

    // if (!_source.ask_to_generate_an_isrc) {
    //   if (!_source.isrc) {
    //     tracksErrors++;
    //   }
    // }

    if (tracksErrors) individualTrackErrorPresent = true;

    if (!_source.copyrighted_to) {
      tracksErrors++;
    }

    if (!_source.published_by) {
      tracksErrors++;
    }

    if (!_source.phonographic_copyright_year) {
      tracksErrors++;
    }

    if (!_source.producer_catalouge_number) {
      tracksErrors++;
    }

    function isAlphanumericWithBoth(values) {
      const containsLetters = /[a-zA-Z]/.test(values);
      const containsNumbers = /[0-9]/.test(values);
      const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

      if (containsLetters && containsNumbers && !containsSpecialChars) {
        // The string contains both letters and numbers (alphanumeric) and no special characters.
        return true;
      } else {
        // The string does not meet the criteria.
        return false;
      }
    }

    if (_source?.producer_catalouge_number) {
      if (!isAlphanumericWithBoth(_source.producer_catalouge_number)) {
        tracksErrors++;
      }
    }
    if (!_source.content_type) {
      tracksErrors++;
    }
    if (!_source.primary_track_type) {
      tracksErrors++;
    }
    if (!_source.secondary_track_type) {
      tracksErrors++;
    }

    return tracksErrors;
  }

  function errorValidation() {
    const dateString = _source?.main_release_date;
    const targetDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isReleaseBeforeToday = targetDate < yesterday;
    const preedateString = _source?.pre_order_release_date;
    const preetargetDate = new Date(preedateString);

    function isAlphanumericWithBoth(values) {
      const containsLetters = /[a-zA-Z]/.test(values);
      const containsNumbers = /[0-9]/.test(values);
      const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

      if (containsLetters && containsNumbers && !containsSpecialChars) {
        return true;
      } else {
        return false;
      }
    }

    if (!_source?.title) {
      releaseInfoError++;
    }
    if (!_source?.label) {
      releaseInfoError++;
    }
    if (!_source?.thumbnail_url) {
      releaseInfoError++;
    }
    if (!_source?.format) {
      releaseInfoError++;
    }
    if (!_source?.producer_catalogue_number) {
      // releaseInfoError++;
    }
    if (_source?.producer_catalogue_number) {
      if (!isAlphanumericWithBoth(_source.producer_catalogue_number)) {
        releaseInfoError++;
      }
    }
    if (!_source?.genre_type?.id) {
      releaseInfoError++;
    }
    if (!_source?.sub_genre_type?.id) {
      releaseInfoError++;
    }
    if (!_source?.physical_release_date) {
      releaseInfoError++;
    }
    if (_source?.artists.length == 0) {
      releaseInfoError++;
    }
    if (!_source?.type_of_release) {
      releaseInfoError++;
    }
    if (_source?.ask_to_generate_upc === false) {
      !_source?.UPC_EAN && releaseInfoError++;
    }
    if (!_source?.meta_language) {
      releaseInfoError++;
    }
    if (!_source?.tracks.length && !_source?.existing_tracks?.length) {
      tracksError++;
    } else {
      _source.tracks.map((track) => {
        if (track.assets_id !== "") {
          if (track?.assets_id?.id) {
            let isIdPresent =
              assets_list?.length > 0 &&
              assets_list?.some(
                (item) => item.assets_id === track.assets_id?.id
              );
            !isIdPresent && tracksError++;
          } else {
            tracksError++;
          }
        } else {
          tracksError++;
        }
      });
    }
    if (
      !_source?.main_release_date ||
      (_source?.main_release_date && isReleaseBeforeToday)
    ) {
      releaseDateError++;
    }
    if (!_source?.copyrighted_to) {
      releaseInfoError++;
    }
    if (!_source?.published_by) {
      releaseInfoError++;
    }
  }
  errorValidation();

  let rows = [];
  if (props.track.hits) {
    props.track.hits.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: item._id,
        _index: item._index,
        content_type: item._source.content_type,
        primary_artists_string:
          item._source.primary_artists &&
          item._source.primary_artists.length !== 0
            ? item._source.primary_artists
                .map((item) => item?.artist_id?.artist_name)
                .join(", ")
            : null,
        isrc: item._source.isrc,
        errors: fetchTrackErrors(item),
        arranger: item._source.arranger,
        ask_to_generate_an_isrc: item._source.ask_to_generate_an_isrc,
        author: item._source.author,
        composer: item._source.composer,
        created_by: item?._source?.created_by?.user_name,
        created_on: item._source.created_on,
        featuring: item._source.featuring,
        genre: item._source.genre,
        instrumental: item._source.instrumental,
        lyrics: item._source.lyrics,
        lyrics_language: item._source?.lyrics_language
          ? item._source.lyrics_language
          : null,
        parental_advisory: item._source.parental_advisory,
        plan_id: item._source.plan_id,
        preview_start: item._source.preview_start,
        primary_artists: item._source.primary_artists,
        primary_track_type: item._source.primary_track_type,
        producer: item._source.producer,
        producer_catalouge_number: item._source.producer_catalouge_number,
        production_year: item._source.production_year,
        published_by: item._source.published_by,
        publisher: item._source.publisher,
        remixer: item._source.remixer,
        release_id: item._source.release_id,
        secondary_genre: item._source.secondary_genre,
        secondary_track_type: item._source.secondary_track_type,
        seconday_sub_genre: item._source.seconday_sub_genre,
        stream_id: item._source.stream_id,
        sub_genre_name: item._source.sub_genre_name,
        title: item._source.title,
        track_title_language: item._source?.track_title_language
          ? item._source.track_title_language
          : null,
        version_subtitle: item._source["music/album"],
        views: item._source.views,
        phonographic_copyright_year: item._source.phonographic_copyright_year,
        copyright_year: item._source.copyright_year,
        copyrighted_to: item._source.copyrighted_to,
      });
    });
  }

  const returnAssetsUrl = (assets_id) => {
    return assets_list &&
      assets_list.length !== 0 &&
      assets_list.filter((item) => {
        return item.assets_id === assets_id.id;
      })[0]
      ? assets_list &&
          assets_list.length !== 0 &&
          assets_list.filter((item) => {
            return item.assets_id === assets_id.id;
          })[0].assets_url
      : "";
  };
  if (
    props.releaseById &&
    props.releaseById._source &&
    props.releaseById._source.tracks &&
    props.releaseById._source.tracks.length
  ) {
    let tracks = props.releaseById._source.tracks;
    for (let i = 0; i < tracks.length; i++) {
      for (let j = 0; j < rows.length; j++) {
        if (tracks[i].track_id.id === rows[j].id) {
          rows[j].assets = {
            assets_id: tracks[i]?.assets_id?.id ? tracks[i].assets_id.id : "",
            assets_url: tracks[i]?.assets_id
              ? returnAssetsUrl(tracks[i].assets_id)
              : "",
          };
          break;
        }
      }
    }
  }

  let columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
      flex: 1,
    },

    {
      field: "type_of_release",
      width: 150,
      flex: 2,
      headerName: "Type",
      renderCell: (params) => {
        return params.row.content_type ? params.row.content_type : "";
      },
    },

    {
      field: "title",
      headerName: "Title",
      resizable: true,
      align: "center",
      headerAlign: "center",
      width: 150,
      flex: 2.5,
    },

    {
      field: "file_name_from_assets",
      width: 150,
      flex: 2,
      headerName: "File Name",
      renderCell: (params) => {
        return params?.row?.assets?.assets_url
          ? extractFileName(params.row.assets.assets_url)
          : "";
      },
    },

    {
      field: "file_name",
      headerName: "Asset(s)",
      width: 150,
      flex: 3,
      renderCell: (params) => {
        if (params?.row?.assets?.assets_id === "" || !params?.row?.assets) {
          ++assetMapWarning;
        }
        return (
          <>
            {(params?.row?.assets?.assets_id === "" || !params?.row?.assets) &&
            isWarningVisible ? (
              <WarningIcon
                color="warning"
                titleAccess="Please map asset to this track"
                className="shake-animation"
              />
            ) : (
              (params?.row?.assets === "" || !params?.row?.assets) && (
                <WarningIcon
                  color="warning"
                  titleAccess="Please map asset to this track"
                />
              )
            )}
            <AutoCompleteComponent
              // value={params.value ? returnAssetsObject(params.row.assets) : ""}
              defaultValue={
                params.row.assets ? returnAssetsObject(params.row.assets) : null
              }
              disabled={
                _source
                  ? _source.status &&
                    ["1", "4", "10", "9"].includes(_source.status.id)
                    ? false
                    : true
                  : false
              }
              disableClearable={true}
              handleChange={async (e, val) => {
                const targetId = params.row.id;
                const newValue = val.assets_id;
                const targetObject = rows.find((item) => item.id === targetId);
                if (targetObject) {
                  targetObject.assets = newValue;
                }
                localStorage.setItem("submission_data", JSON.stringify(rows));
              }}
              list={assets_list ? assets_list : []}
              isOptionEqualToValue={(option, value) =>
                option.assets_id === value.assets_id
              }
              getOptionLabel={(option) => option.assets_name}
              size="small"
              width="100%"
              textFieldProps={{
                placeholder: "Select Assets",
              }}
            />
            {(params?.row?.assets === "" || !params?.row?.assets) && (
              <UploadPopper
                format={"Choose a track you want to map with the asset type."}
              >
                <InfoIcon sx={{ fontSize: 15 }} />
              </UploadPopper>
            )}
          </>
        );
      },
    },

    {
      field: "primary_artists_string",
      width: 350,
      flex: 3,
      headerName: "Artist(s)",
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "isrc",
    //   width: 250,
    //   headerName: "ISRC",
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 2,
    // },
    {
      field: "errors",
      width: 250,
      headerName: "Errors",
      align: "center",
      headerAlign: "center",
      flex: 2,
      renderCell: (params) => {
        return (
          <CommonButton
            count={params.row.errors}
            onClick={(event) => {
              if (params.row.errors > 0) {
                navigate("/tracks", {
                  state: {
                    trackError: true,
                    trackId: params.id,
                    trackData: params.row,
                  },
                });
              }
            }}
          />
        );
      },
    },
  ];

  function submitClick() {
    let obj = {};

    let parsed_data = localStorage.getItem("submission_data")
      ? JSON.parse(localStorage.getItem("submission_data"))
      : [];

    if (parsed_data.length <= 0) return;
    setsaveloading(true);

    Object.assign(
      obj,
      parsed_data.length && {
        tracks: parsed_data.map((item) => {
          return {
            track_id: item.id,
            assets_id:
              typeof item.assets === `object`
                ? item.assets.assets_id
                : item.assets,
          };
        }),
      }
    );

    let params = {
      current_page: "submission",
    };
    props
      .dispatch(
        ReleasesAction.requestPutRelease(obj, props?.releaseById?._id, params)
      )
      .then(() => {
        setsaveloading(false);
        props.dispatch(
          ReleasesAction.requestReleaseById(props.releaseById._id, params)
        );
        localStorage.removeItem("submission_data");
      });
  }

  let existingTracksRows = [];
  if (_source?.existing_tracks) {
    _source.existing_tracks.map((item, index) => {
      return existingTracksRows.push({
        sl_no: index + 1,
        id: index + 1,
        release_name: item.release_id?.title,
        track_name: item.track_id?.title,
        asset_name: item.assets_id?.assets_name,
      });
    });
  }

  let existingTracksColumns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 0.5,
    },
    {
      field: "track_name",
      headerName: "Existing Track Name",
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 2.5,
    },
    {
      field: "release_name",
      headerName: "Release Name",
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 2.5,
    },
    {
      field: "asset_name",
      headerName: "Asset Name",
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 2.5,
    },
  ];

  let nameslist = [];
  if (
    props.language &&
    typeof props.language.list_of_language_data === "object"
  )
    for (let key in props.language.list_of_language_data) {
      const item = props.language.list_of_language_data[key];
      nameslist.push({ id: key, name: item.name });
    }

  const returnAssetsObject = (assets_id) => {
    let filtered_assets = [];

    filtered_assets =
      assets_list &&
      assets_list.length !== 0 &&
      assets_list.filter((item) => {
        return item.assets_id === assets_id.assets_id;
      });

    return filtered_assets.length !== 0
      ? filtered_assets[0]
        ? filtered_assets[0]
        : null
      : null;
  };

  function FinalSubmit() {
    let data = {
      title: props.releaseById._source.title,
    };
    let params = {
      final_submit: "true",
    };

    let subscriptionParams = {
      filters: `[["status.keyword","must","","match","active"],["release_id.keyword","must_not","","exists",""]]`,
      page_limit: "none",
    };

    const subscriptionMappingCallback = (response) => {
      if (response?.hits?.hits.length === 0) {
        function fetchUserprofile(inputCallback) {
          props.dispatch(
            UserProfileAction.requestUserprofile({}, inputCallback)
          );
        }

        const fetchSubscriptionPlans = (plan_id) => {
          const param = {
            filters: `[["subscription_plan_id.keyword","must","","match","${plan_id}"]]`,
          };

          const subscroptionPlanCallBack = (subPlanCallback) => {
            if (subPlanCallback?.hits?.hits.length === 0) {
              if (isFirstLogin) {
                navigate("/profile", { state: { action: "profile_update" } });
              } else {
                navigate("/myPlans");
              }
            } else if (subPlanCallback?.hits?.hits.length > 0) {
              navigate("/subscriptionPlans");
            }
          };

          props.dispatch(
            SubscriptionPlansAction.requestSubscriptionPlans(
              param,
              subscroptionPlanCallBack
            )
          );
        };

        const userProfileCallback = (response) => {
          let planId;
          if (response) {
            planId = response._source.subscription_plan_id;
            fetchSubscriptionPlans(planId);
          }
        };

        fetchUserprofile(userProfileCallback);
      } else if (response?.hits?.hits.length > 0) {
        navigate("/subscriptions", {
          state: {
            fromSubmission: true,
            releaseById: props?.releaseById,
            finalSubmissionData: data,
            finalSubmissionParams: params,
          },
        });
      }
    };

    const postFinalReleaseCallback = (callbackResponse) => {
      if (callbackResponse?.status === "success") {
        localStorage.getItem("submission_data") &&
          localStorage.removeItem("submission_data");
        localStorage.getItem("changes_made_on_A3page") &&
          localStorage.removeItem("changes_made_on_A3page");
        localStorage.getItem("deliveredChangeRequest") &&
          localStorage.removeItem("deliveredChangeRequest");
        navigate("/release");
      } else {
        props.dispatch(
          SubscriptionAction.requestSubscriptionFilter(
            subscriptionParams,
            subscriptionMappingCallback
          )
        );
      }
    };

    props.dispatch(
      ReleasesAction.requestPostFinalRelease(
        data,
        props?.releaseById?._id,
        params,
        postFinalReleaseCallback
      )
    );
  }

  const changesFunction = () => {
    if (_source && _source.tracks && localStorage.getItem("submission_data")) {
      let array1 =
        _source &&
        _source.tracks &&
        _source.tracks.length !== 0 &&
        _source.tracks.map((item) => {
          return { asset_id: item.assets_id?.id, track_id: item.track_id.id };
        });

      let array2 =
        localStorage.getItem("submission_data") &&
        JSON.parse(localStorage.getItem("submission_data")) &&
        JSON.parse(localStorage.getItem("submission_data")).length !== 0 &&
        JSON.parse(localStorage.getItem("submission_data")).map((item) => {
          return {
            asset_id: item.assets,
            track_id: item.id,
          };
        });

      function areAssetIdsEqual(array1, array2) {
        if (array1.length !== array2.length) {
          return false;
        }

        for (let i = 0; i < array1.length; i++) {
          if (array1[i].track_id !== array2[i].track_id) {
            return false;
          }

          if (array1[i].asset_id !== array2[i].asset_id) {
            return false;
          }
        }

        return true;
      }
      const result = areAssetIdsEqual(array1, array2);

      if (
        _source.tracks &&
        _source.tracks.length === 0 &&
        localStorage.getItem("submission_data")
      ) {
        localStorage.setItem("changes_made_on_A3page", true);
      } else if (
        _source.tracks.length !== 0 &&
        localStorage.getItem("submission_data")
      ) {
        if (!result) {
          localStorage.setItem("changes_made_on_A3page", true);
        } else {
          localStorage.getItem("changes_made_on_A3page") &&
            localStorage.removeItem("changes_made_on_A3page");
        }
      }
    }
  };

  return (
    <Box pb={5}>
      {/* <div
        style={{
          textAlign: "left",
          padding: "1rem 10rem",
          color: "#7c7c7c",
          fontSize: "57px",
        }}
      >
        <h2>{`${_source?.title}`}</h2>

        <Grid item>
          <BreadCrumbs pathname={window.location.pathname} />
        </Grid>
      </div> */}

      <Grid
        item
        xl={10}
        lg={10}
        md={10}
        sm={10}
        container
        alignItems={"center"}
        justifyContent="flex-start"
        gap={2}
        paddingTop={1}
      >
        <Grid item>
          {/* <h2>{formatName(`${_source?.title}`)}</h2> */}
          <h2> {`${_source?.title}`}</h2>
        </Grid>

        <Grid item>
          <BreadCrumbs pathname={window.location.pathname} />
        </Grid>
      </Grid>

      <Box sx={{ paddingX: "10rem" }} mx={5}>
        {(releaseInfoError !== 0 ||
          releaseDateError !== 0 ||
          assetsError !== 0) && (
          <Grid
            item
            container
            xs={12}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              padding: 1,
            }}
          >
            <Typography
              sx={{
                color: theme.palette.secondary.main,
              }}
            >
              Please correct all errors to submit your release
            </Typography>
          </Grid>
        )}

        <Grid
          container
          item
          xs={12}
          gap={2}
          justifyContent={"space-between"}
          sx={{
            paddingTop: 4,
          }}
        >
          <Grid item lg={6} md={12} sm={12}>
            <Grid
              item
              className="d-flex justify-content-between align-items-center"
            >
              <Typography sx={{ fontSize: theme.typography.fontSize.huge }}>
                Release Information
              </Typography>

              <CommonButton
                count={releaseInfoError}
                onClick={(event) => {
                  if (releaseInfoError > 0) {
                    navigate("/releaseInfo", {
                      state: { shouldSubmit: true },
                    });
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              gap={1}
              className="d-flex justify-content-between"
            >
              <Grid
                item
                lg={8}
                md={8}
                sm={8}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
              >
                <Box className="d-flex justify-content-between p-1">
                  <Typography>Title</Typography>
                  <Typography>
                    {_source?.title ? _source?.title : ""}
                  </Typography>
                </Box>

                <Box className="d-flex justify-content-between p-1">
                  <Typography>Artist(s)</Typography>
                  <Box textAlign={"right"}>
                    {_source?.artists && _source?.artists.length !== 0
                      ? _source?.artists.map((artist, index) => (
                          <div key={index}>
                            {artist?.artist_id?.artist_name
                              ? artist.artist_id.artist_name
                              : ""}

                            <br />
                          </div>
                        ))
                      : ""}
                  </Box>
                </Box>

                <Box className="d-flex justify-content-between p-1">
                  <Typography>Label </Typography>
                  <Typography>
                    {_source?.label ? _source?.label.label_name : ""}
                  </Typography>
                </Box>

                <Box className="d-flex justify-content-between p-1">
                  <Typography>Genre </Typography>
                  <Typography>
                    {_source?.genre_type ? _source?.genre_type.genre_name : ""}
                  </Typography>
                </Box>

                <Box className="d-flex justify-content-between p-1">
                  <Typography>Subgenre </Typography>
                  <Typography>
                    {_source?.sub_genre_type
                      ? _source?.sub_genre_type?.subgenre_name
                      : ""}
                  </Typography>
                </Box>

                <Box className="d-flex justify-content-between p-1">
                  <Typography>Number of track(s) / asset(s)</Typography>
                  <Typography>
                    {_source?.tracks ? _source?.tracks.length : ""} /{" "}
                    {props?.assets?.hits ? props.assets.hits.length : ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                paddingX={1}
                paddingY={1}
                className="d-flex justify-content-end"
              >
                <Box
                  style={{
                    width: 80,
                    height: 80,
                    boxShadow: theme.shadows,
                    backgroundColor: "white",
                    display: "grid",
                    placeItems: "center",
                    overflow: "hidden",
                    borderRadius: 5,
                  }}
                >
                  {props.releaseById &&
                  props.releaseById._source &&
                  props.releaseById._source.thumbnail_url ? (
                    !imageLoader ? (
                      <img
                        src={imgUrl}
                        alt="Preview"
                        onError={() => {}}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <CircleLoader />
                    )
                  ) : (
                    <Avatar size="small" />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} sm={12}>
            <Grid
              item
              className="d-flex justify-content-between align-items-center"
            >
              <Typography sx={{ fontSize: theme.typography.fontSize.huge }}>
                Price
              </Typography>
              <CommonButton
                count={priceError}
                onClick={(event) => {
                  if (priceError > 0) {
                    navigate("/releaseInfo", {
                      state: { shouldSubmit: true, priceError: true },
                    });
                  }
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-between p-1"
              >
                <Typography>Plan </Typography>
                <Typography>
                  {_source?.plan_id?.plan_name ? _source.plan_id.plan_name : ""}
                </Typography>
              </Grid>
            </Grid>
            <br />
          </Grid>
        </Grid>

        <Grid item lg={11} md={12} sm={12} paddingY={4}>
          <Divider sx={{ borderTop: "2px solid #dddddd" }} />
        </Grid>

        <Grid
          container
          item
          sm={12}
          gap={2}
          sx={{
            paddingTop: 1,
          }}
        >
          <Grid item lg={6} sm={12}>
            <Grid
              item
              className="d-flex justify-content-between align-items-center"
            >
              <Typography sx={{ fontSize: theme.typography.fontSize.huge }}>
                Release Date
              </Typography>
              <CommonButton
                count={releaseDateError}
                onClick={(event) => {
                  if (releaseDateError > 0) {
                    navigate("/releaseDate", {
                      state: { shouldSubmit: true },
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} gap={1}>
              <Grid item xs={12}>
                <Box className="d-flex justify-content-between p-1">
                  <Typography>Main release date</Typography>
                  <Typography>
                    {_source?.main_release_date
                      ? _source?.main_release_date
                      : "(empty)"}
                  </Typography>
                </Box>

                <Box className="d-flex justify-content-between p-1">
                  <Typography>Pre-order release date</Typography>
                  <Typography>
                    {_source?.pre_order_release_date
                      ? _source?.pre_order_release_date
                      : "(empty)"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <br />
        </Grid>

        <Grid item xs={12} paddingY={4}>
          <Divider sx={{ borderTop: "2px solid #dddddd" }} />
        </Grid>

        <Grid
          container
          item
          xs={12}
          gap={2}
          sx={{
            paddingTop: 1,
          }}
        >
          <Grid item lg={6} xs={12} pb={1}>
            <Grid
              item
              className="d-flex justify-content-between align-items-center"
            >
              <Typography sx={{ fontSize: theme.typography.fontSize.huge }}>
                Assets
              </Typography>
              <CommonButton
                count={assetsError}
                onClick={(event) => {
                  if (
                    assetsError > 0 &&
                    _source &&
                    _source.tracks.length === 0
                  ) {
                    navigate("/tracks", {
                      state: { shouldSubmit: true },
                    });
                  } else if (
                    assetsError > 0 &&
                    _source &&
                    _source.tracks.length !== 0
                  ) {
                    if (
                      props.assets &&
                      props.assets.hits &&
                      props.assets.hits.length !== 0
                    ) {
                      setIsWarningVisible(true);
                      setTimeout(() => {
                        setIsWarningVisible(false);
                      }, 1000);
                    } else {
                      navigate("/upload", {
                        state: { shouldSubmit: true },
                      });
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className="d-flex justify-content-between align-items-center"
          >
            <Typography>{rows.length}&nbsp; Results Found</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className="d-flex justify-content-between align-items-center"
          >
            <DataGridFunction
              columns={columns}
              rows={rows}
              rowHeight={80}
              height={rows.length * 60 + 100}
            />
          </Grid>

          {existingTracksRows.length > 0 && (
            <>
              <Typography mt={3}>
                {existingTracksRows.length}&nbsp; Existing Tracks Found
              </Typography>
              <DataGridFunction
                columns={existingTracksColumns}
                rows={existingTracksRows}
                rowHeight={80}
                height={existingTracksRows.length * 60 + 100}
              />
            </>
          )}
        </Grid>

        <Grid
          item
          container
          xs={12}
          sx={{
            paddingTop: 4,
          }}
          justifyContent={"space-between"}
        >
          <Grid item sm={2} xs={12}>
            {/* <MuiButton
              color="primary"
              width="100%"
              name={"Map DSP ID"}
              onClick={() => {
                setMapDSPDialog(true);
              }}
            /> */}
          </Grid>
          <Grid
            container
            item
            sm={6}
            xs={12}
            gap={2}
            justifyContent={"flex-end"}
          >
            <MuiButton
              color="primary"
              width="30%"
              loading={saveloading}
              name={"Update Asset"}
              // onClick={SubmitAssets}
              disabled={
                _source
                  ? _source.status &&
                    ["1", "4", "10", "9"].includes(_source.status.id)
                    ? false
                    : true
                  : false
              }
              onClick={submitClick}
            />
            {/* {!_source?.active_subscription_id && ( */}
            <MuiButton
              color="primary"
              width="30%"
              loading={finalSubmitLoading}
              name={"Final Submit"}
              onClick={() => {
                setFinalSubmitLoading(true);
                FinalSubmit();
              }}
              disabled={
                releaseInfoError > 0 ||
                releaseDateError > 0 ||
                assetsError > 0 ||
                tracksError > 0 ||
                individualTrackErrorPresent ||
                (_source && !["1", "4", "10", "9"].includes(_source.status.id))
              }
            />
            {/* // )} */}
          </Grid>
        </Grid>
      </Box>
      <MapDSPDialog
        openMapDSPDialog={openMapDSPDialog}
        setMapDSPDialog={setMapDSPDialog}
        releaseById={props.releaseById._id}
        validated={validated}
      />
      <MuiSnackbarAlert
        open={assetSnackBar}
        onClose={() => setAssetSnackBar(false)}
        message={"Please Add or Update an Asset !!"}
      />
    </Box>
  );
}

function extractFileName(inputString) {
  const parts = inputString.split("_");
  const extractedWord = parts[4]?.substring(6);
  return extractedWord;
}

// Map To DSP ID / Ingrooves SECTION
function MapDSPDialog({
  openMapDSPDialog,
  setMapDSPDialog,
  validated,
  releaseById,
}) {
  const [DSPIDValue, setDSPIDValue] = useState(null);
  const [mapDSPLoading, setMapDSPLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTextFieldChange = (event) => {
    const inputValue = event.target.value;
    if (!/\s/.test(inputValue)) {
      setDSPIDValue(event.target.value);
    }
  };

  const handleDSPIDSubmit = () => {
    if (!DSPIDValue) return;
    let params = {
      dsp_id: DSPIDValue,
      final_release_id: releaseById,
    };
    const callBack = (res) => {
      if (res.status != 200) DSPDialogClose();
      else if (res.status == 200 && validated) navigate("/A3release");
    };
    dispatch(ReleasesAction.requestPostDSPID(params, callBack));
  };

  function DSPDialogClose() {
    setMapDSPLoading(false);
    setMapDSPDialog(false);
    setDSPIDValue(null);
  }

  return (
    <Dialog
      open={openMapDSPDialog}
      onClose={() => {
        DSPDialogClose();
      }}
      TransitionComponent={Slide}
      transitionDuration={400}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: "18px" }}>
        Enter DSP ID
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 10,
            cursor: "pointer",
            padding: 1,
          }}
          onClick={() => {
            DSPDialogClose();
          }}
        >
          <HighlightOffIcon
            style={{ fontSize: "29px" }}
            sx={{
              "&:hover": {
                transition: "transform 0.5s, color 0.2s",
                transform: "scale(1.2)",
                color: theme.palette.secondary.main,
              },
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: "10px" }}>
          <TextField
            placeholder="Enter DSP ID from Ingrooves"
            variant="outlined"
            value={DSPIDValue}
            onChange={handleTextFieldChange}
            sx={{
              width: "100%",
            }}
            error={mapDSPLoading && !DSPIDValue}
            style={{ "&:hover": { borderColor: "red" } }}
          />
          <Box
            sx={{
              color: theme.palette.secondary.main,
              marginTop: 0.5,
              marginX: 1,
            }}
          >
            {mapDSPLoading && !DSPIDValue && (
              <>
                {"Value cannot be empty "}
                <NewReleasesIcon />
              </>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "16px" }}>
        <MuiButton
          width="30%"
          name={"Submit"}
          loading={mapDSPLoading}
          onClick={() => {
            setMapDSPLoading(true);
            handleDSPIDSubmit();
          }}
        />
        <MuiButton
          buttonBackground={theme.palette.secondary.main}
          width="30%"
          name={"Close"}
          onClick={() => {
            DSPDialogClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export default connect(mapStateToProps)(Submission);
