import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import LabelModel from "./model/LabelModel";
import LabelPostModel from "./model/LabelPostModel";
import LabelPutModel from "./model/LabelPutModel";

// LabelEffect
export default class LabelEffect {
  // calling an API according to the label model
  static async requestLabel(params) {
    const endpoint = environment.api.label;
    return EffectUtility.getToModel(LabelModel, endpoint, params);
  }

  static async requestLabelClear() {
    return {};
  }

  static async requestLabelFilterClear() {
    return {};
  }

  // Get method for the label filter
  static async requestLabelFilter(params) {
    const endpoint = environment.api.label;
    return EffectUtility.getToModel(LabelModel, endpoint, params);
  }

  // put method for the label
  static async requestPutLabel(data, id, params) {
    const endpoint = environment.api.label + "/" + id;
    return EffectUtility.putToModel(LabelPutModel, endpoint, data, params);
  }

  // post method for the label
  static async requestPostLabel(data, params, callback) {
    const endpoint = environment.api.postlabel;
    let response = EffectUtility.postToModel(
      LabelPostModel,
      endpoint,
      data,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }
}
