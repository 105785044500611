import React from "react";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import routes from "../Route/routecoll";
import { useLocation } from "react-router";

export default function MuiTextField(props) {
  const theme = useTheme();
const currentPath = useLocation().pathname;


  //theme overrides for mui textfield component
  theme.components = {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            outline: `1px ${theme.palette.borderColor.textField}`,
            borderRadius:props.borderRadius?props.borderRadius:0,
            fontSize: 16,
            color:"#000000",
            // color:props.color?props.color:"#fff",
            height: props.fieldheight ? props.fieldheight : "100%",
            padding: "0px",
            "&.Mui-focused fieldset": {
              border: `1.5px solid ${theme.palette.primary.subcolor}`,
              "& legend": {
                display: "none",
              },
            },
          },
        },
      },
    },
  };

  return (
    <FormControl fullWidth>
      <TextField
        type={props.type ? props.type : "text"}
        inputProps={{
          readOnly: props.readOnly ? props.readOnly : false,
          inputMode: props.inputMode ? props.inputMode : "text",
          onKeyPress: props.handleKeyPress,
          maxLength: props.maxLength ? props.maxLength : undefined,
        }}
        {...props}
        autoComplete="off"
        variant="outlined"
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          width: props.width ? props.width : "100%",
          svg: { margin: "10px" },
          height: props.fieldheight ? props.fieldheight : "100%", // Decreased field height
          backgroundColor: props.backgroundColor?props.backgroundColor:"#808080",
          borderRadius:props.borderRadius?props.borderRadius:0,
          // color:props.color?props.color:"#000000"
          // "& .MuiOutlinedInput-input": {
          //   padding: "8px 12px", // Adjust padding to fit the decreased height
          // },
        }}
      />
    </FormControl>
  );
}
