import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import AccountVerifiedModel from "./models/AccountVerifiedModel";
import { jwtDecode } from "jwt-decode";

export default class AccountVerifiedEffect {
  static async requestaccountverified(obj, callBackFunction, params, headers) {
    const endpoint = environment.api.register;
    const response = await HttpUtility.post(endpoint, obj, params, headers);

    // if (response?.status === 400) {
    //   console.log("logcheck", response?.raw?.data?.message);
    //   if (response?.raw?.data?.message === "Provided Phone number is not verified, Please verifiy the Phone Number") {
    //     callBackFunctionthree(response);
    //   }
    // }

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    localStorage.setItem("Auth", JSON.stringify(response.data));
    localStorage.setItem("isFirstLogin",true)
    const token = response.data.access_token;
    const decoded = jwtDecode(token);
    // localstorage set
    localStorage.setItem("roles", JSON.stringify(decoded.realm_access.roles));
    localStorage.getItem("userId") === null &&
      localStorage.setItem("userId", decoded.sub);
    if (response.status === 200) {
      if (localStorage.getItem("userId") !== null) {
        if (localStorage.getItem("userId") === decoded.sub) {
          callBackFunction();
        }
      } else {
        localStorage.setItem("userId", decoded.sub);
      }
    }
    return new AccountVerifiedModel(response.data);
  }
}
