import React from "react";
import { Box, Typography, Card, CardContent, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { theme } from "../../views/App.js";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentSuccessful = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("order_id");
  const paymentId = queryParams.get("payment_id");
  const currentPath = `${location.pathname}?order_id=${orderId}&payment_id=${paymentId}`;
  const handleOkClick = () => {
    if (currentPath) {
      navigate(-1);
    }
  };

  return (
    <Card
      sx={{
        maxWidth: { xs: 200, sm: 300, md: 400 },
        height: { xs: 200, sm: 300, md: 400 },
        mx: "auto",
        mt: 5,
        textAlign: "center",
        boxShadow: 3,
        background: theme.palette.background.grey,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <CheckCircleOutlineIcon
            sx={{
              fontSize: { xs: 30, sm: 50, md: 70 },
              color: "green",
            }}
          />
        </Box>
        <Typography variant="h5" gutterBottom>
          Payment Successful
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for your payment. Your order is being processed. <br />
        </Typography>
        <br />
        <br />
        <Typography variant="h5" gutterBottom>
          OrderId:{orderId}
        </Typography>
        <Typography variant="h5" gutterBottom>
          PaymentId:{paymentId}
        </Typography>
        <Button
          sx={{
            backgroundColor: "#fff",
            mt: 5,
          }}
          onClick={handleOkClick}
        >
          Ok
        </Button>
      </CardContent>
    </Card>
  );
};

export default PaymentSuccessful;
