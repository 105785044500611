import BaseReducer from "../../utilities/BaseReducer";
import SubscriptionPlansAction from "./SubscriptionPlansAction";

// SubscriptionPlansReduser
export default class SubscriptionPlansReduser extends BaseReducer {
  initialState = {
    subscriptionPlan: [],
  };
  [SubscriptionPlansAction.REQUEST_SUBSCRIPTION_PLANS_FINISHED](state, action) {
    return {
      ...state,
      subscriptionPlan: action.payload,
    };
  }
}
