import ToastStatusEnum from "../constants/ToastStatusEnum";
import ToastsAction from "../stores/toasts/ToastsAction";
import environment from "environment";

export const ErrorLogs = [];

const errorToastMiddleware = () => (store) => (next) => (action) => {
  let toasts = store.getState().toasts;
  if (action.error) {
    const errorAction = action;

    // ErrorLogs.push({
    //   error: errorAction.error,
    //   endpoint: errorAction.payload.url,
    //   type: errorAction.type,
    //   status: errorAction.payload.status,
    //   data: errorAction.payload.raw.data,
    // });

    if (errorAction.payload.status !== 403 && toasts.items.length === 0) {
      // let post_log_data = {
      //   // message: { errorData: action, userId: localStorage.getItem("userId") },
      //   type: action.type,
      //   status: action.payload.status,
      //   url: action.payload.url,
      //   error: errorAction.payload.raw.data,
      //   payload_data: errorAction.payload?.raw?.config?.data,
      //   method: errorAction.payload.raw.config.method,
      // };

      // fetch(`${environment.api.logsApi}/api/logs/a3LabelsClient`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(post_log_data),
      // });

      if (errorAction.payload.status !== 0) {
        next(
          ToastsAction.add(
            errorAction.payload.message,
            ToastStatusEnum.Warning,
            errorAction.payload.raw.data.message
              ? errorAction.payload.raw.data.message
              : errorAction.payload.raw.data.Message
              ? errorAction.payload.raw.data.Message
              : errorAction.payload.raw.data.error_description
          )
        );
      } else {
        next(
          ToastsAction.add(
            errorAction?.payload?.message,
            ToastStatusEnum.Error,
            errorAction.payload.raw.message
              ? errorAction.payload.raw.message
              : errorAction.payload.raw.data.error_description
          )
        );
      }
    }
  }
  if (
    action.error === false &&
    action.payload !== undefined &&
    action.payload.status !== undefined &&
    ((action.payload.status &&
      action.payload.status.toLowerCase() === "success") ||
      action.payload.status === "Deleted")
  ) {
    const errorAction = action;

    next(
      ToastsAction.add(
        errorAction.payload.message,
        ToastStatusEnum.Success,
        errorAction.payload.status
      )
    );
  }

  next(action);
};

export default errorToastMiddleware;
