import React from "react";
import { Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { Field } from "formik";
import { theme } from "../App";

const CommonCheckbox = ({
  setFieldValue,
  values,
  submitForm,
  isCheckboxEnabled,
}) => (
  <Box 
    sx={{
      textAlign: "center",
      // marginY: "20px",
      pointerEvents: isCheckboxEnabled ? "auto" : "none",
      opacity: isCheckboxEnabled ? 1 : 0.5,
    }}
  >
    <Field name="termsAndCondition">
      {({ field, form: { touched, errors } }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={field.value}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setFieldValue("termsAndCondition", isChecked);
                if (isChecked && isCheckboxEnabled) {
                  submitForm(values);
                }
              }}
              sx={{
                color: theme.palette.primary.loginbackgroundsecondary,
                "&.Mui-checked": {
                  color: theme.palette.primary.loginbackgroundsecondary,
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "1rem",
                },
                "&.Mui-disabled": {
                  color: "#9e9e9e",
                  "& .MuiSvgIcon-root": {
                    color: "#9e9e9e",
                  },
                },
              }}
              disabled={!isCheckboxEnabled}
            />
          }
          label={
            <Typography
              component="span"
              sx={{
                color: isCheckboxEnabled ? "#efefef" : "#9e9e9e",
                fontSize: {
                  xl: "16px",
                },
                fontWeight: "bold",
                pointerEvents: isCheckboxEnabled ? "auto" : "none",
              }}
            >
              I Agree to the A3 Tunes Terms and Conditions
            </Typography>
          }
        />
      )}
    </Field>

    {/* Error message */}
    <Field name="termsAndCondition">
      {({ form: { errors, touched } }) =>
        errors.termsAndCondition && touched.termsAndCondition ? (
          <Typography sx={{ color: "red", fontSize: "12px" }}>
            {errors.termsAndCondition}
          </Typography>
        ) : null
      }
    </Field>
  </Box>
);

export default CommonCheckbox;
