import { BaseModel } from "sjs-base-model";

// SendMailPostModel
export default class MobileNumberPostModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point
  message = "";
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
