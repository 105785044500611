import * as React from "react";
import clsx from "clsx";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MailIcon from "@mui/icons-material/Mail";
import DeleteIcon from "@mui/icons-material/Delete";
import Label from "@mui/icons-material/Label";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import InfoIcon from "@mui/icons-material/Info";
import ForumIcon from "@mui/icons-material/Forum";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Root,
  TreeItem2GroupTransition,
} from "@mui/x-tree-view/TreeItem2";
import { unstable_useTreeItem2 as useTreeItem } from "@mui/x-tree-view/useTreeItem2";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { theme } from "../../views/App";
import { useNavigate } from "react-router";

const CustomTreeItemRoot = styled(TreeItem2Root)(({}) => ({
  color: theme.palette.text.primary,
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    cursor: "pointer",
  },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({}) => ({
  marginBottom: theme.spacing(0.3),
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(2),
  paddingRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  transition: "background-color 0.3s ease, color 0.3s ease",
  "&.expanded": {
    fontWeight: theme.typography.fontWeightRegular,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.secondary,
  },
  "&.focused, &.selected, &.selected.focused": {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.background.default})`,
    color: `var(--tree-view-color, ${theme.palette.text.primary})`,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)", // Slightly enlarge the icon on hover
    },
  })
);

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
  ({ theme }) => ({
    marginLeft: 0,
    [`& .content`]: {
      paddingLeft: theme.spacing(2),
    },
    transition: "all 0.3s ease",
  })
);
function EndIcon() {
  return <div style={{ width: 24 }} />;
}

export default function GmailTreeView({
  routes,
  onClose,
  setformat,
  setOpenReleaseDialog,
}) {
  const groupedItems = routes?.data
    ?.filter((route) => route?.navCategory)
    .reduce((acc, item) => {
      if (!acc[item.navCategory]) {
        acc[item.navCategory] = [];
      }
      acc[item.navCategory].push(item);
      return acc;
    }, {});
  const navigate = useNavigate();

  const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const {
      id,
      itemId,
      label,
      disabled,
      children,
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      colorForDarkMode,
      bgColorForDarkMode,
      path,
      ...other
    } = props;

    const {
      getRootProps,
      getContentProps,
      getIconContainerProps,
      getLabelProps,
      getGroupTransitionProps,
      status,
    } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });

    //   const style = {
    //     "--tree-view-color":
    //       theme.palette.mode !== "dark" ? color : colorForDarkMode,
    //     "--tree-view-bg-color":
    //       theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
    //   };

    const handleItemClick = () => {
      const libraryArray = ["/release", "/submitted", "/drafts", "/rejected"];

      if (label === "Single" || label === "Album") {
        setformat(label?.toLowerCase());
        localStorage.setItem("pathIndex", "newRelease");
        setOpenReleaseDialog(true);
        onClose();
      } else if (libraryArray?.includes(path)) {
        navigate(path, {
          state: {
            status:
              label?.toLowerCase() === "release"
                ? null
                : label?.toLowerCase() === "submitted"
                ? { id: 2, status_name: "Submitted" }
                : label?.toLowerCase() === "drafts"
                ? { id: 1, status_name: "Draft" }
                : label?.toLowerCase() === "rejected"
                ? { id: 9, status_name: "Rejected" }
                : null,
          },
        });
        onClose();
      } else if (path) {
        navigate(path); // Navigate to the provided path
        onClose();
      }
    };

    return (
      <TreeItem2Provider itemId={itemId}>
        <CustomTreeItemRoot {...getRootProps({ ...other })}>
          <CustomTreeItemContent
            {...getContentProps({
              className: clsx("content", {
                expanded: status.expanded,
                selected: status.selected,
                focused: status.focused,
              }),
              onClick: handleItemClick,
            })}
          >
            <CustomTreeItemIconContainer {...getIconContainerProps()}>
              <TreeItem2Icon status={status} />
            </CustomTreeItemIconContainer>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
                p: 0.5,
                pr: 0,
              }}
            >
              <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
              <Typography
                {...getLabelProps({
                  variant: "body2",
                  sx: { display: "flex", fontWeight: "inherit", flexGrow: 1 },
                })}
              />
            </Box>
          </CustomTreeItemContent>
          {children && (
            <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
          )}
        </CustomTreeItemRoot>
      </TreeItem2Provider>
    );
  });

  return (
    <SimpleTreeView
      aria-label="gmail"
      //   defaultExpandedItems={["3"]}
      //   defaultSelectedItems="5"
      slots={{
        expandIcon: ArrowRightIcon,
        collapseIcon: ArrowDropDownIcon,
        endIcon: EndIcon,
      }}
      sx={{
        width: "100%",
        overflowY: "auto",
        maxHeight: "80vh",
        "&::-webkit-scrollbar": {
          width: "3px",
          height: "3px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#b0d2da", // Color of the scrollbar thumb
          borderRadius: "3px", // Round the scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#b0d2da", // Darken the scrollbar thumb on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // Background color of the scrollbar track
        },
      }}
    >
      <CustomTreeItem
        itemId="1"
        label="Create Release"
        //   labelIcon={Label}
      >
        <CustomTreeItem
          itemId="2"
          label="Single"
          //   labelIcon={SupervisorAccountIcon}
          color="#1a73e8"
          bgColor="#e8f0fe"
          colorForDarkMode="#B8E7FB"
          bgColorForDarkMode={alpha("#00b4ff", 0.2)}
        />
        <CustomTreeItem
          itemId="3"
          label="Album"
          //   labelIcon={InfoIcon}
          color="#e3742f"
          bgColor="#fcefe3"
          colorForDarkMode="#FFE2B7"
          bgColorForDarkMode={alpha("#ff8f00", 0.2)}
        />
      </CustomTreeItem>

      {Object.keys(groupedItems).map((navCategory, parentIndex) => (
        <CustomTreeItem
          key={parentIndex}
          itemId={`parent-${parentIndex}`}
          label={navCategory}
          //   labelIcon={LabelIcon} // Use a suitable icon for the parent category
        >
          {groupedItems[navCategory].map((item, childIndex) => (
            <CustomTreeItem
              key={`${parentIndex}-${childIndex}`}
              itemId={item.id}
              label={item.title}
              path={item.path}
              //   labelIcon={getIconForItem(item)} // Function to get icon based on item
              color="#1a73e8" // Customize as needed
              bgColor="#e8f0fe"
              colorForDarkMode="#B8E7FB"
              bgColorForDarkMode={alpha("#00b4ff", 0.2)}
            />
          ))}
        </CustomTreeItem>
      ))}

      <CustomTreeItem
        itemId={Object.keys(groupedItems).length + 1}
        label="News & Update"
        color="#1a73e8"
        bgColor="#e8f0fe"
        path="/news&update"
        colorForDarkMode="#B8E7FB"
        bgColorForDarkMode={alpha("#00b4ff", 0.2)}
      />

      <CustomTreeItem
        itemId={Object.keys(groupedItems).length + 2}
        label="FAQ"
        path="/faq"
        color="#1a73e8"
        bgColor="#e8f0fe"
        colorForDarkMode="#B8E7FB"
        bgColorForDarkMode={alpha("#00b4ff", 0.2)}
      />
    </SimpleTreeView>
  );
}
