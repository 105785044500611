import React, { useEffect, useState } from "react";
import SubscriptionAction from "../../stores/subscriptions/SubscriptionAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { Alert, Box, Grid } from "@mui/material";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import DataGridFunction from "../../components/dataGridComponent/CustomDataGrid";
import { useLocation, useNavigate } from "react-router";
import YesOrNoDailog from "../../components/yesOrNoDailog/YesOrNoDailog";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import MuiSnackbarAlert from "../../components/muiSnackbar/MuiSnackbarAlert";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    SubscriptionAction.REQUEST_SUBSCRIPTION,
  ]),
  pagination: state.subscription.subscription || [],
  subscription: state.subscription.subscription || [],
});

function Subscriptions(props) {
  const [page_limit] = useState(15);
  const location = useLocation();

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const navigate = useNavigate();
  const [content, setContent] = useState(null);
  const [selectedSubsciption, setSelectedSubsciption] = useState(null);

  const { isRequesting } = props;

  useEffect(() => {
    const params = {
      page_limit: page_limit,
      order_by: `[["created_on","desc"]]`,
    };

    const submission_params = {
      page_limit: page_limit,
      order_by: `[["created_on","desc"]]`,
      filters: `[["status.keyword","must","","match","active"],["release_id.keyword","must_not","","exists",""]]`,
    };

    fetchSubscriptions(
      location?.state?.fromSubmission ? submission_params : params
    );
  }, []);

  function fetchSubscriptions(params) {
    props.dispatch(SubscriptionAction.requestSubscription(params));
  }

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {
      page_number: `${value}` - 1,
      order_by: `[["created_on","desc"]]`,
      page_limit: `${page_limit}`,
    };

    const submission_params = {
      page_limit: page_limit,
      order_by: `[["created_on","desc"]]`,
      page_number: `${value}` - 1,
      filters: `[["status.keyword","must","","match","active"],["release_id.keyword","must_not","","exists",""]]`,
    };

    if (value - 1 !== props.pagination.current_page_no) {
      fetchSubscriptions(
        location?.state?.fromSubmission ? submission_params : params
      );
    }
  };

  const rows = [];

  if (props.subscription.hits) {
    props.subscription.hits.hits?.length !== 0 &&
      props.subscription.hits.hits.map((item, index) => {
        return rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          CRBT_price: item._source.CRBT_price,
          auto_release_new_platform: item._source.auto_release_new_platform,
          caller_ringback_tone_per_track:
            item._source.caller_ringback_tone_per_track,
          choose_release_date: item._source.choose_release_date,
          choose_stores: item._source.choose_stores,
          created_on: item._source.created_on,
          custom_label_names: item._source.custom_label_names,
          dashboard_access: item._source.dashboard_access,
          detailed_sale_report: item._source.detailed_sale_report,
          duration_limit: item._source.duration_limit,
          end_date: item._source.end_date,
          fb_insta_tiktok: item._source.fb_insta_tiktok,
          keep_music_long_term: item._source.keep_music_long_term,
          modified_on: item._source.modified_on,
          music_distrubution: item._source.music_distrubution,
          music_publishing_royalties: item._source.music_publishing_royalties,
          order_id: item._source.order_id,
          order_number: item._source.order_number,
          placement_pitch: item._source.placement_pitch,
          plan_name: item._source.plan_name,
          plan_price: item._source.plan_price,
          priority_support_working_hours:
            item._source.priority_support_working_hours,
          purchased_date: item._source.purchased_date,
          real_time_analytics: item._source.real_time_analytics,
          release_id: item._source.release_id,
          release_type: item._source.release_type,
          revenue_splits_artists: item._source.revenue_splits_artists,

          sales_report_summary: item._source.sales_report_summary,
          smart_url: item._source.smart_url,
          start_date: item._source.start_date,
          status: item._source.status,
          sub_plan_name: item._source.sub_plan_name,
          subscription_plan_id: item._source.subscription_plan_id,
          subscription_sub_plan_id: item._source.subscription_sub_plan_id,
          super_saver_discount_percentage:
            item._source.super_saver_discount_percentage,
          super_saver_release_type_allowed:
            item._source.super_saver_release_type_allowed,
          use_existing_isrc_upc: item._source.use_existing_isrc_upc,
          valid_till: item._source.valid_till,
          validity: item._source.validity,
          withdraw_royalties_without_active_subscription:
            item._source.withdraw_royalties_without_active_subscription,
          youtube_content_id: item._source.youtube_content_id,
        });
      });
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "plan_name",
      headerName: "Plan Name",
      width: 150,
    },
    {
      field: "plan_price",
      headerName: "Plan Price",
      width: 150,
    },
    {
      field: "release_type",
      headerName: "Release Type",
      width: 150,
    },
    {
      field: "purchased_date",
      headerName: "Purchased Date",
      width: 150,
    },
    {
      field: "start_date",
      headerName: "Start date",
      width: 150,
    },

    {
      field: "end_date",
      headerName: "End date",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "validity",
      headerName: "Validity",
      width: 150,
    },
    {
      field: "release_id",
      headerName: "Release ID",
      width: 150,
    },
    {
      field: "valid_till",
      headerName: "Valid Till",
      width: 150,
    },
  ];

  const onCellClick = (cell) => {
    setConfirmDialog(true);
    setSelectedSubsciption(cell?.id);
    setContent(
      `Claim - ${cell?.row?.plan_name} for - ${location?.state?.releaseById?._source?.title}`
    );
  };

  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(null);

  const claimSubscription = () => {
    setClaimLoading(true);
    const claim_object = {
      release_id: location?.state?.releaseById?._id,
      subscription_id: selectedSubsciption,
    };

    const callback = (resp) => {
      if (resp?.status === "success") {
        const params = {
          page_limit: page_limit,
          order_by: `[["created_on","desc"]]`,
          filters: `[["status.keyword","must","","match","active"],["release_id.keyword","must_not","","exists",""]]`,
        };

        props
          .dispatch(
            ReleasesAction.requestPostFinalRelease(
              location?.state?.finalSubmissionData,
              location?.state?.releaseById?._id,
              location?.state?.finalSubmissionParams
            )
          )
          .then(() => {
            localStorage.getItem("submission_data") &&
              localStorage.removeItem("submission_data");
            localStorage.getItem("changes_made_on_A3page") &&
              localStorage.removeItem("changes_made_on_A3page");
            localStorage.getItem("deliveredChangeRequest") &&
              localStorage.removeItem("deliveredChangeRequest");
            setSelectedSubsciption(null);
            fetchSubscriptions(params);
            setClaimLoading(false);
            navigate("/release");
          });
      } else if (resp?.status === "Failed") {
        setShowAlert(true);
        setAlertContent(resp?.message);
        setSelectedSubsciption(null);
        setConfirmDialog(false);
        setClaimLoading(false);
      } else {
        setClaimLoading(false);
      }
    };

    props.dispatch(
      SubscriptionAction.requestClaimSubscription(
        JSON.stringify(claim_object),
        callback
      )
    );
  };

  return (
    <React.Fragment>
      <Box>
        {navigator.onLine ? (
          <>
            <Grid
              container
              item
              lg={12}
              xl={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                height: "-webkit-fill-available",
                px: 2,
              }}
              // className="bg-warning"
            >
              {/* <Grid item lg={10} md={12} sm={12} xs={12}> */}
              <LoadingIndicator isActive={isRequesting}>
                {isRequesting ? (
                  <Box>
                    <LoadingBgImage />
                  </Box>
                ) : (
                  <DataGridFunction
                    columns={columns}
                    rows={rows}
                    rowHeight={80}
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChange}
                    onCellClick={
                      location?.state?.fromSubmission ? onCellClick : null
                    }
                    cursor={
                      location?.state?.fromSubmission ? "pointer" : "default"
                    }
                  />
                )}
              </LoadingIndicator>
              {/* </Grid> */}
            </Grid>
          </>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}

        <YesOrNoDailog
          open={Boolean(confirmDialog)}
          content={content}
          cancel={() => {
            setConfirmDialog(false);
            setSelectedSubsciption(null);
          }}
          loading={claimLoading}
          submit={() => {
            claimSubscription();
          }}
        />

        <MuiSnackbarAlert
          open={showAlert}
          onClose={() => setShowAlert(false)}
          message={alertContent}
        />
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(Subscriptions);
