import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import SubscriptionOrders from "../subscriptionOrders/SubscriptionOrders";
import Subscriptions from "../subscriptions/Subscriptions";

import { theme } from "../App";
function SubscriptionTabs() {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab
          label="My Subscriptions"
          sx={{
            color:
              activeTab === 0
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
        <Tab
          label="My Orders"
          sx={{
            color:
              activeTab === 1
                ? theme.palette.primary.main
                : theme.palette.primary.loginbackgroundtext,
          }}
        />
      </Tabs>

      {activeTab === 0 && <Subscriptions />}
      {activeTab === 1 && <SubscriptionOrders />}
    </div>
  );
}

export default SubscriptionTabs;
