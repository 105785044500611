import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faShield,
  faTicket,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../views/App";

const TermsAndConditions = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
      <DialogContent className="p-0 m-0">
        <DialogContentText>
          <Box
            // className="bg-warning"
            sx={{
              borderBottom: "1px solid #c0bfbf",
            }}
          >
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="space-between"
              alignItems={"center"}
              //   className="bg-warning"
            >
              <Grid item px={1} sx={{ display: "flex" }}>
                <Typography>
                  &nbsp;&nbsp;
                  <span style={{ color: theme.palette.secondary.main }}>T</span>
                  <span style={{ color: theme.palette.primary.main }}>
                    erms
                  </span>
                  &nbsp;&nbsp;
                  <span style={{ color: theme.palette.primary.main }}>And</span>
                  &nbsp;&nbsp;
                  <span style={{ color: theme.palette.secondary.main }}>C</span>
                  <span style={{ color: theme.palette.primary.main }}>
                    onditions
                  </span>
                </Typography>
              </Grid>
              <Grid item px={1}>
                <IconButton
                  onClick={() => {
                    props.onClose();
                  
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{
                      fontSize: "20px",
                    }}
                  ></FontAwesomeIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>

        <Box p={5}>
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.subcolor }}
          >
            Description of Service:
          </Typography>
          <Typography>
            The Software is a web-based platform that allows users to manage
            their online reputation and customers. The Service Provider reserves
            the right to add, modify, or discontinue any aspect or feature of
            the Software at any time.
          </Typography>
          <br />
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.subcolor }}
          >
            Restrictions on Use:
          </Typography>
          <Typography>
            The User agrees to use the Software only for lawful purposes and in
            accordance with this Agreement. The User shall not use the Software
            in any way that violates any local, state, or federal laws or
            regulations. The User shall not attempt to access or use any
            unauthorized features of the Software or interfere with the
            operation of the Software.
          </Typography>
          <br />
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.subcolor }}
          >
            Data Storage and Security:
          </Typography>
          <Typography>
            The Service Provider shall maintain appropriate security measures to
            protect the User's data. However, the User acknowledges that the
            transmission of data over the internet is not completely secure and
            agrees to assume the risk of data loss or unauthorized access.
          </Typography>
          <br />
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.subcolor }}
          >
            Intellectual Property Rights:
          </Typography>
          <Typography>
            The Service Provider owns all rights, title, and interest in the
            Software, including all intellectual property rights. The User shall
            not reproduce, distribute, or create derivative works from the
            Software without the express written consent of the Service
            Provider.
          </Typography>
          <br />
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.subcolor }}
          >
            Payment and Billing:
          </Typography>
          <Typography>
            The User shall pay the Service Provider the fees for the use of the
            Software as outlined in the pricing plan selected by the User. The
            Service Provider reserves the right to change the fees at any time,
            but will provide notice to the User before doing so.
          </Typography>
          <br />
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.subcolor }}
          >
            Termination and Cancellation:
          </Typography>
          <Typography>
            The User may terminate this Agreement at any time by canceling their
            account. The Service Provider may terminate this Agreement at any
            time if the User breaches any of the terms of this Agreement.
          </Typography>
          <br />
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.subcolor }}
          >
            Liability and Indemnification:
          </Typography>
          <Typography>
            The Service Provider shall not be liable for any damages arising
            from the use of the Software, including, but not limited to, loss of
            data or damage to the User's business. The User shall indemnify and
            hold the Service Provider harmless from any claims or damages
            arising from the User's use of the Software.
          </Typography>
          <br />
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.subcolor }}
          >
            Dispute Resolution:
          </Typography>
          <Typography>
            In the event of a dispute arising under this Agreement, the parties
            agree to first try to resolve the dispute through mediation. If
            mediation is unsuccessful, the parties agree to resolve the dispute
            through binding arbitration.
          </Typography>
        </Box>
        
      </DialogContent>
    </Dialog>
  );
};

export default TermsAndConditions;
