import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Typography,
  Chip,
  Tooltip,
  Box,
  Link,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { Form, Formik, Field, getIn, ErrorMessage, FastField } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import ClientAction from "../../stores/client/ClientAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { theme } from "../App";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import DatePickerComponent from "../../components/datePicker/DatePickerComponent";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import CloseIcon from "@mui/icons-material/Close";
import environment from "environment";
import DeleteIcon from "@mui/icons-material/Delete";
// import AvatarLogo from "./AvatarLogo";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";
import LoginBackground from "../../components/loginBackground/LoginBackground";
import RegisterAction from "../../stores/register/RegisterAction";
import TermsAndConditions from "../../components/termsandconditions/TermsAndConditions";
import Tunes from "../../Images/A3Tunes.webp";
// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const passwordRegExp =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegExp = /^\d{10}$/;

// const validationSchema = yup.object({
//   main_email_address: string().matches(emailRegExp, "Email is not valid"),
//   phone_number: string()
//     .matches(phoneRegExp, "Phone number is not valid")
//     .length(10, "Phone number should be 10 digits"),

//   // confirm_password: yup.string().required("Password is Required"),
// });

const Register = (props) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = React.useState(false);
  const [termsAndconditionsopen, setTermsandConditionsopen] =
    React.useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [phoneCodes, setPhoneCodes] = useState([]);
  const buttonForLoginRef = React.useRef(null);
  const clientfieldRef = React.useRef(null);
  const usernameRef = React.useRef(null);
  const emailaddressRef = React.useRef(null);
  const firstnameRef = React.useRef(null);
  const lastnameRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const confirmpasswordRef = React.useRef(null);
  const phoneref = React.useRef(null);
  const countryref = React.useRef(null);
  const countrycoderef = React.useRef(null);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setshowconfirmPassword(!showconfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const handleResizeLogin = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as per your requirement
    };

    // Initial check on mount
    handleResizeLogin();

    // Listen to window resize events
    window.addEventListener("resize", handleResizeLogin);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResizeLogin);
  }, []);

  function fetchPhoneCodes() {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
    console.log("data", access_token, environment.api.baseApi);
    fetch(`${environment.api.baseApi}/phone_codes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response) {
          response.country_codes && setPhoneCodes(response.country_codes);
        }
      });
  }
  // Project post and Put method

  // Formik
  // console.log("initialdata", initial);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    fetchPhoneCodes();
  }, []);
  let countryNames = phoneCodes?.map((item) => item.name);
  const defaultPhoneCode = phoneCodes?.find((code) => code.dial_code === "+91");

  let initial = {};
  initial = {
    client_name: "",
    main_email_address: "",
    royalties_email_address: "",
    first_name: "",
    last_name: "",
    username: "",
    phone_number: "",
    country_code: defaultPhoneCode || null,
    enabled: false,
    country: countryNames[97] || "",
    fullname: "",
    credentials: [
      {
        type: "password",
        value: "",
      },
    ],
    confirm_password: "",
    document_consent: {
      terms_and_condition: true,
      privacy_and_policy: true,
    },
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initial}
      validate={(values) => {
        let errors = {};
        if (values.confirm_password !== values.credentials[0].value) {
          errors.confirm_password = "Passwords do not match";
        }
        if (!values.credentials[0].value) {
          errors.credentials = [{ value: "Password is Required" }];
        } else if (!passwordRegExp.test(values.credentials[0].value)) {
          errors.credentials = [
            {
              value:
                "Password must be at least 8 characters long and contain at least 1 upper case letter, 1 number, and one of these special characters: !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~",
            },
          ];
        }
        if (!values.confirm_password) {
          errors.confirm_password = "Confirm Password is Required";
        }
        if (!values.client_name) {
          errors.client_name = "Client Name is Required";
        }
        if (!values.username) {
          errors.username = "Username is Required";
        }
        if (!values.main_email_address) {
          errors.main_email_address = "Email Address is Required";
        } else if (!emailRegExp.test(values.main_email_address)) {
          errors.main_email_address = "Invalid email address";
        }
        if (!values.first_name) {
          errors.first_name = "First Name is Required";
        }
        if (!values.last_name) {
          errors.last_name = "Last Name is Required";
        }
        if (!values.phone_number) {
          errors.phone_number = "Phone Number is Required";
        } else if (!phoneRegExp.test(values.phone_number)) {
          errors.phone_number = "Phone number should be exactly 10 digits";
        }

        return errors;
      }}
      onSubmit={(values, formikHelpers) => {
        console.log("initialdata", values);
        setLoading(true);

        let Obj = {};
        Object.assign(
          Obj,
          values.client_name && {
            client_name: values.client_name,
          },
          values.username && {
            username: values.username,
          },
          values.main_email_address && {
            main_email_address: values.main_email_address,
          },
          values.main_email_address && {
            royalties_email_address: values.main_email_address,
          },
          values.first_name && {
            first_name: values.first_name,
          },
          values.last_name && {
            last_name: values.last_name,
          },
          { fullname: values.fullname },
          values.country_code && {
            country_code: values.country_code.dial_code,
          },
          values.phone_number && {
            phone_number: values.phone_number,
          },
          values.country && {
            country: values.country ? values.country : countryNames[97],
          },
          values.credentials && { credentials: values.credentials },
          { enabled: true },
          values.document_consent && {
            document_consent: values.document_consent,
          }
        );

        const callBack = (response) => {
          console.log("responseregistercallled",response)
          navigate("/validateEmail", { 
                state: { 
                  action: "mobile_no",  
                } 
              });
        };

        console.log("postobj", Obj);
        props.dispatch(RegisterAction.requestPostRegister(Obj, callBack));
      }}
      // validationSchema={validationSchema}
    >
      {({
        errors,
        isValid,
        helperText,
        touched,
        dirty,
        handleChange,
        values,
        options,
        setFieldValue,
      }) => (
        <Form style={{ width: "100%", height: "100vh" }}>
          <Box sx={{ width: "100%", height: "100%" }}>
            <Grid
              container
              style={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                height: "100%",
              }}
            >
              <LoginBackground isMobile={isMobile} />
              <Grid
                item
                xs={12}
                md={isMobile ? 12 : 6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: theme.palette.background.loginbackground,
                  height: "100%",
                  overflowY: "auto",
                  pt: { xs: 16, sm: 10, md: 18, lg: 10, xl: 10 },
                  pb: { xs: 4, sm: 4, md: 2, lg: 6, xl: 8 },
                }}
              >
                {isMobile && (
                  <img
                    src={Tunes}
                    alt="Tunes"
                    style={{
                      width: "15%",
                      height: "auto",
                      objectFit: "contain",
                      marginBottom: "30px",
                    }}
                  />
                )}
                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 0.5,
                  }}
                  // className="bg-warning"
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    Client Name *
                  </Grid>

                  <Grid item xs={12} lg={9} sx={{ mt: -2 }}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Client Name"
                      id="client_name"
                      size="small"
                      name="client_name"
                      fieldheight="25px"
                      inputRef={clientfieldRef}
                      InputProps={{
                        onKeyPress: (event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            usernameRef.current && usernameRef.current.focus();
                          }
                        },
                      }}
                      fullWidth
                    />

                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "10px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="client_name" />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    User Name *
                  </Grid>
                  <Grid item xs={12} lg={9} sx={{ mt: -2 }}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="User Name"
                      id="username"
                      size="small"
                      name="username"
                      fieldheight="25px"
                      inputRef={usernameRef}
                      InputProps={{
                        onKeyPress: (event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            emailaddressRef.current &&
                              emailaddressRef.current.focus();
                          }
                        },
                      }}
                      // error={
                      //   Boolean(errors.username) && Boolean(touched.username)
                      // }
                      // helperText={Boolean(touched.username) && errors.username}
                      fullWidth
                    />
                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "10px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="username" />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    Main Email Address *
                  </Grid>
                  <Grid item xs={12} lg={9} sx={{ mt: -2 }}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Main Email Address"
                      id="main_email_address"
                      size="small"
                      name="main_email_address"
                      fieldheight="25px"
                      inputRef={emailaddressRef}
                      InputProps={{
                        onKeyPress: (event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            firstnameRef.current &&
                              firstnameRef.current.focus();
                          }
                        },
                      }}
                      // error={
                      //   Boolean(errors.main_email_address) &&
                      //   Boolean(touched.main_email_address)
                      // }
                      // helperText={
                      //   Boolean(touched.main_email_address) &&
                      //   errors.main_email_address
                      // }
                      // fullWidth
                    />
                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "10px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="main_email_address" />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    First Name *
                  </Grid>
                  <Grid item xs={12} lg={9} sx={{ mt: -2 }}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="First Name"
                      id="first_name"
                      size="small"
                      name="first_name"
                      fieldheight="25px"
                      inputRef={firstnameRef}
                      InputProps={{
                        onKeyPress: (event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            lastnameRef.current && lastnameRef.current.focus();
                          }
                        },
                      }}
                      // error={
                      //   Boolean(errors.first_name) &&
                      //   Boolean(touched.first_name)
                      // }
                      // helperText={
                      //   Boolean(touched.first_name) && errors.first_name
                      // }
                      // fullWidth
                    />
                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "10px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="first_name" />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    Last Name *
                  </Grid>
                  <Grid item xs={12} lg={9} sx={{ mt: -2 }}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Last Name"
                      id="last_name"
                      size="small"
                      name="last_name"
                      fieldheight="25px"
                      inputRef={lastnameRef}
                      InputProps={{
                        onKeyPress: (event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            phoneref.current && phoneref.current.focus();
                          }
                        },
                      }}
                      // error={
                      //   Boolean(errors.last_name) && Boolean(touched.last_name)
                      // }
                      // helperText={
                      //   Boolean(touched.last_name) && errors.last_name
                      // }
                      // fullWidth
                    />
                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "10px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="last_name" />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    Phone Number
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      mt: -2,
                    }}
                  >
                    <Grid item xs={4} lg={3}>
                      <AutoCompleteComponent
                        value={values.country_code}
                        list={phoneCodes ? phoneCodes : []}
                        textFieldProps={{
                          placeholder: "Country Code",
                        }}
                        // ref={countrycoderef}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Enter") {
                        //     event.preventDefault();
                        //     phoneref.current && phoneref.current.focus();
                        //   }
                        // }}
                        handleChange={(e, value) => {
                          if (value.dial_code !== "+91") {
                            window.alert(
                              "We are not accepting any code other than +91."
                            );
                            return;
                          }
                          setFieldValue("country_code", value);
                        }}
                        height="25px"
                        defaultValue={defaultPhoneCode}
                        isOptionEqualToValue={(option, value) =>
                          option.code === value.code
                        }
                        getOptionLabel={(option) =>
                          `${option.name} (${option.dial_code})`
                        }
                        renderOption={(props, option) => {
                          return (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              <span style={{ fontSize: "12px" }}>
                                {" "}
                                {option.name} ({option.code}) {option.dial_code}
                              </span>
                            </Box>
                          );
                        }}
                        size="small"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={8} lg={6}>
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Phone Number"
                        id="phone_number"
                        size="small"
                        inputRef={phoneref}
                        name="phone_number"
                        fieldheight="25px"
                        inputProps={{
                          maxLength: 10,
                        }}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        InputProps={{
                          onKeyPress: (event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              passwordRef.current.focus();
                            }
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    component="div"
                    sx={{
                      color: theme.palette.text.validationcolor,
                      textAlign: "right",
                      fontSize: "10px",
                      marginLeft: "15%",
                    }}
                  >
                    <ErrorMessage name="phone_number" />
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    Country *
                  </Grid>
                  <Grid item xs={12} lg={9} sx={{ mt: -2 }}>
                    <Field
                      name="country"
                      size="small"
                      value={values.country}
                      component={AutoCompleteMultiSelect}
                      options={countryNames ? countryNames : []}
                      textFieldProps={{
                        placeholder: "Country",
                      }}
                      // ref={countryref}
                      // onKeyDown={(event) => {
                      //   if (event.key === "Enter") {
                      //     event.preventDefault();
                      //     passwordRef.current && passwordRef.current.focus();
                      //   }
                      // }}
                      onChange={(newValue) => {
                        if (newValue !== countryNames[97]) {
                          window.alert(
                            `We are not accepting any country other than ${countryNames[97]}.`
                          );
                          return;
                        }
                        setFieldValue("country", newValue);
                      }}
                      height="25px"
                      defaultValue={countryNames[97]}
                      error={
                        Boolean(errors.country) && Boolean(touched.country)
                      }
                      helperText={Boolean(touched.country) && errors.country}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.background.drawerMenu,
                      fontSize: theme.palette.buttonComponent.fontSize.large,
                    }}
                  >
                    Password *
                  </Grid>
                  <Grid item xs={12} lg={9} sx={{ mt: -2 }}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Password"
                      id="credentials"
                      type={showPassword ? "text" : "password"}
                      size="small"
                      name="credentials[0].value"
                      error={Boolean(
                        getIn(touched, "credentials") &&
                          getIn(errors, "credentials")
                      )}
                      fieldheight="25px"
                      inputRef={passwordRef}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton size="small">
                              <LockIcon transform="scale(0.8)" color="#808080" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        onKeyPress: (event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            confirmpasswordRef.current &&
                              confirmpasswordRef.current.focus();
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                              sx={{ mr: 1,color:"#808080" }}
                            >
                              {showPassword ? (
                                <VisibilityOff transform="scale(0.8)" color="#808080"/>
                              ) : (
                                <Visibility transform="scale(0.8)" color="#808080" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "10px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="credentials[0].value" />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.primary.loginbackgroundsecondary,
                      fontSize: "20px",
                    }}
                  >
                    Confirm Password *
                  </Grid>
                  <Grid item xs={12} lg={9} sx={{ mt: -2 }}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Confirm Password"
                      id="confirm_password"
                      type={showconfirmPassword ? "text" : "password"}
                      size="small"
                      name="confirm_password"
                      error={Boolean(
                        getIn(touched, "confirm_password") &&
                          getIn(errors, "confirm_password")
                      )}
                      inputRef={confirmpasswordRef}
                      fieldheight="25px"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton size="small">
                              <LockIcon transform="scale(0.8)" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        onKeyPress: (event) => {
                          if (event.key === "Enter") {
                            // event.preventDefault();
                            buttonForLoginRef.current &&
                              buttonForLoginRef.current.click();
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                              sx={{ mr: 1 }}
                            >
                              {showconfirmPassword ? (
                                <VisibilityOff transform="scale(0.8)" />
                              ) : (
                                <Visibility transform="scale(0.8)" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                    <Typography
                      component="div"
                      sx={{
                        color: theme.palette.text.validationcolor,
                        textAlign: "left",
                        fontSize: "10px",
                        marginLeft: "14px",
                      }}
                    >
                      <ErrorMessage name="confirm_password" />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={8}
                  xl={6}
                  lg={6}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.enabled}
                        sx={{
                          color: theme.palette.primary.loginbackgroundsecondary,
                          "&.Mui-checked": {
                            color:
                              theme.palette.primary.loginbackgroundsecondary,
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "1rem",
                          },
                        }}
                      />
                    }
                    label={
                      <Box>
                        <span style={{ fontSize: "12px" }}>
                          {" "}
                          I Agree to the A3 Tunes{" "}
                        </span>
                        <Link
                          sx={{
                            color: theme.palette.background.drawerMenu,
                            cursor: "pointer",
                            textDecoration: "underline",
                            "&:hover": {
                              textDecoration: "underline",
                              color: theme.palette.background.drawerMenu,
                            },
                          }}
                          onClick={() => setTermsandConditionsopen(true)}
                        >
                          <span style={{ fontSize: "12px" }}>
                            Terms and Conditions
                          </span>
                        </Link>
                      </Box>
                    }
                    name="enabled"
                    value={values.enabled}
                    onChange={handleChange}
                  />
                  <TermsAndConditions
                    open={termsAndconditionsopen}
                    onClose={() => setTermsandConditionsopen(false)}
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={8}
                  xl={6}
                  lg={6}
                  md={8}
                  sm={8}
                  sx={{
                    mt: 0.5,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  // className="bg-warning"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: theme.palette.background.drawerMenu,
                      }}
                    >
                      <span style={{ fontSize: "14px" }}>
                        Already have an account?&nbsp;
                      </span>
                      <button
                        type="button"
                        onClick={() => navigate("/")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                        style={{
                          color: theme.palette.primary.main,
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        Login
                      </button>
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <MuiButton
                        loading={loading}
                        type="submit"
                        name={"Submit"}
                        width="100%"
                        disabled={!values.enabled}
                        buttonRef={buttonForLoginRef}
                        borderRadius={
                          theme.palette.buttonComponent.borderRadius.large
                        }
                        background={"secondary"}
                        fontSize="14px"
                        textTransform="uppercase"
                        // letterSpacing="1px"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <MuiButton
                        // loading={loading}
                        type="reset"
                        name={"Reset"}
                        width="100%"
                        // buttonRef={buttonForLoginRef}
                        borderRadius={
                          theme.palette.buttonComponent.borderRadius.large
                        }
                        login
                        fontSize="14px"
                        textTransform="uppercase"
                        // letterSpacing="1px"
                      />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default connect(mapDispatchToProps)(Register);
