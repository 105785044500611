import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import UserProfileModel from "./model/UserProfileModel";
import UserProfilePutModel from "./model/UserProfilePutModel";
import UserProfileResetPasswordPutModel from "./model/UserProfileResetPasswordPutModel";
import UserAdminResetPasswordPutModel from "./model/UserAdminResetPasswordPutModel";
import LabelPostModel from "../label/model/LabelPostModel";
import NotificationModel from "./model/NotificationModel";
import PhoneNumberPutModel from "./model/PhoneNumberPutModel";
import UserProfilePercentageModel from "./model/UserProfilePercentageModel";

export default class UserProfileEffect {
  static async requestUserprofile(params, callback) {
    const endpoint = environment.api.user_details;
    let response = EffectUtility.getToModel(UserProfileModel, endpoint, params);

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestUserprofilePercentage(params, callback) {
    const endpoint = environment.api.uprofile_percentage;
    let response = EffectUtility.getToModel(
      UserProfilePercentageModel,
      endpoint,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestMailSubsciption(params) {
    const endpoint = environment.api.mailSubscription;
    return EffectUtility.getToModel(UserProfileModel, endpoint, params);
  }

  static async requestPutMailSubsciption(data, params) {
    const endpoint = environment.api.mailSubscription;
    return EffectUtility.putToModel(
      UserProfilePutModel,
      endpoint,
      data,
      params
    );
  }

  static async requestPostMailSubsciption(data, params) {
    const endpoint = environment.api.mailSubscription;
    return EffectUtility.postToModel(LabelPostModel, endpoint, data, params);
  }

  static async requestNotificationSubsciption(params) {
    const endpoint = environment.api.notificationSubscription;
    return EffectUtility.getToModel(NotificationModel, endpoint, params);
  }

  static async requestPutNotificationSubsciption(data, params) {
    const endpoint = environment.api.notificationSubscription;
    return EffectUtility.putToModel(
      UserProfilePutModel,
      endpoint,
      data,
      params
    );
  }

  static async requestPostNotificationSubsciption(data, params) {
    const endpoint = environment.api.notificationSubscription;
    return EffectUtility.postToModel(LabelPostModel, endpoint, data, params);
  }

  static async requestPutUserprofile(data, headers) {
    const endpoint = environment.api.user_details;
    return EffectUtility.putToModel(
      UserProfilePutModel,
      endpoint,
      data,
      {},
      headers
    );
  }

  static async requestPutPhoneNumber(data, headers, callback) {
    const endpoint = environment.api.update_mobile_number;
    const response = EffectUtility.putToModel(
      PhoneNumberPutModel,
      endpoint,
      data,
      {},
      headers
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestPutUserprofilePic(data) {
    const endpoint = environment.api.userprofileupdate;
    return EffectUtility.putToModel(UserProfilePutModel, endpoint, data);
  }

  static async requestUserResetPassword(data) {
    const endpoint = environment.api.userResetPassword;
    return EffectUtility.putToModel(
      UserProfileResetPasswordPutModel,
      endpoint,
      data
    );
  }

  static async requestUserForgotPassword(data, headers, callback) {
    const endpoint = environment.api.userforgotPassword;
    const response = EffectUtility.putToModel(
      UserProfileResetPasswordPutModel,
      endpoint,
      data,
      {},
      headers
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestAdminResetPassword(data) {
    const endpoint = environment.api.adminResetPassword;
    return EffectUtility.putToModel(
      UserAdminResetPasswordPutModel,
      endpoint,
      data
    );
  }

  static async updateBankDetails(data) {
    const endpoint = environment.api.updateBankDetails;
    return EffectUtility.putToModel(
      UserAdminResetPasswordPutModel,
      endpoint,
      data
    );
  }
}
