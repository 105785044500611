import {
  Box,
  Grid,
  Button,
  Typography,
  Alert,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import AssetsAction from "../../stores/assets/AssetsAction";
import { connect } from "react-redux";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import VideocamIcon from "@mui/icons-material/Videocam";
import environment from "environment";
import Assets from "../assets/Assets";
import AssetsPost from "../assets/AssetsPost";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import BreadCrumbs from "./BreadCrumbs";
import UploadPopper from "../../components/poppers/UploadPopper";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import MuiButton from "../../components/muiButton/MuiButton";
import { useNavigate } from "react-router";
// import ReactPlayer from "react-player";
import axios from "axios";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AssetsAction.REQUEST_ASSETS,
    AssetsAction.REQUEST_ASSETS_FILTER,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
  ]),
  assets: state.assets.assets.hits || [],
  assetsFilter: state.assetsFilter.assetsFilter || [],
  releaseById: state.releaseById.releaseById || {},
});

function Upload(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [openAssetsForm, setOpenAssetsForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const boxRef = useRef(null);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [alertPopUp, setAlertPopUp] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  function fetchResource(resourceId) {
    const params = {
      current_page: "upload",
    };
    props.dispatch(ReleasesAction.requestReleaseById(resourceId, params));
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  const [mediaType, setMediaType] = useState(null);

  const OpenDailog = (val) => {
    setMediaType(val);
    setOpenAssetsForm(true);
  };

  const [rows, setRows] = useState([]);

  const [assetIds, setAssetIds] = useState(() => {
    return localStorage.getItem("assetIds")
      ? JSON.parse(localStorage.getItem("assetIds"))
      : [];
  });

  const [uploadProgress, setUploadProgress] = useState([]);

  const { _id, _source } = props?.releaseById && props?.releaseById;

  function sanitizeFilename(filename) {
    // Remove spaces and special characters from the filename
    let cleanedFilename;
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      cleanedFilename = filename.replace(/[^\w]/g, (match, index) => {
        return index === lastDotIndex ? match : "";
      });
    }
    return cleanedFilename;
  }

  const uploadChunks = async (
    chunks,
    file,
    multipart_upload_id,
    chunkIndex,
    chunkUploadPercentage,
    assetId
  ) => {
    try {
      let access_token = localStorage.getItem("Auth")
        ? JSON.parse(localStorage.getItem("Auth")).access_token
        : "";

      // CHUNK MULTIPART UPLOAD

      const uploadPromises = chunks.map(async (chunk, i) => {
        const formData = new FormData();
        formData.append("file", chunk);

        try {
          const chunk_upload_response = await axios.post(
            `${environment.api.baseApi}/upload_multipart/${
              chunkIndex + i + 1
            }?path=${_source?.client_id?.id}/${_id}/${sanitizeFilename(
              file.name
            )}&upload_id=${multipart_upload_id}`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + access_token,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // console.log("chunk_upload response", chunk_upload_response);

          // Function to update an object based on ID
          function updateObjectById(array, idToUpdate, updatedData) {
            const updatedArray = array?.map((obj) => {
              if (obj.asset_id === idToUpdate) {
                return { ...obj, ...updatedData };
              }
              return obj;
            });

            return updatedArray;
          }

          if (chunk_upload_response.status === 200) {
            if (
              localStorage.getItem(
                `multipart_keys${sanitizeFilename(file.name)}`
              )
            ) {
              let previous_keys = JSON.parse(
                localStorage.getItem(
                  `multipart_keys${sanitizeFilename(file.name)}`
                )
              );

              if (localStorage.getItem("upload_progress")) {
                let prev = JSON.parse(localStorage.getItem("upload_progress"));

                localStorage.setItem(
                  "upload_progress",
                  JSON.stringify(
                    updateObjectById(prev, assetId, {
                      percentUploaded:
                        previous_keys.length * chunkUploadPercentage +
                        chunkUploadPercentage,
                    })
                  )
                );

                setUploadProgress(
                  updateObjectById(prev, assetId, {
                    percentUploaded:
                      previous_keys.length * chunkUploadPercentage +
                      chunkUploadPercentage,
                  })
                );
              } else {
                localStorage.setItem(
                  "upload_progress",
                  JSON.stringify([
                    {
                      asset_id: assetId,
                      percentUploaded: chunkUploadPercentage,
                      fileName: sanitizeFilename(file.name),
                    },
                  ])
                );

                setUploadProgress([
                  {
                    asset_id: assetId,
                    percentUploaded: chunkUploadPercentage,
                    fileName: sanitizeFilename(file.name),
                  },
                ]);
              }

              // console.log(
              //   "previous_keys if",
              //   previous_keys,
              //   JSON.stringify([chunk_upload_response.data])
              // );

              const updatedKeysArray = [
                ...previous_keys,
                chunk_upload_response.data,
              ];

              localStorage.setItem(
                `multipart_keys${sanitizeFilename(file.name)}`,
                JSON.stringify(updatedKeysArray)
              );
            } else {
              // console.log(
              //   "previous_keys else",
              //   JSON.stringify([chunk_upload_response.data])
              // );

              if (localStorage.getItem("upload_progress")) {
                let prev = JSON.parse(localStorage.getItem("upload_progress"));

                localStorage.setItem(
                  "upload_progress",
                  JSON.stringify([
                    ...prev,
                    {
                      asset_id: assetId,
                      percentUploaded: chunkUploadPercentage,
                      fileName: sanitizeFilename(file.name),
                    },
                  ])
                );

                setUploadProgress((prev) => {
                  return [
                    ...prev,
                    {
                      asset_id: assetId,
                      percentUploaded: chunkUploadPercentage,
                      fileName: sanitizeFilename(file.name),
                    },
                  ];
                });
              } else {
                localStorage.setItem(
                  "upload_progress",
                  JSON.stringify([
                    {
                      asset_id: assetId,
                      percentUploaded: chunkUploadPercentage,
                      fileName: sanitizeFilename(file.name),
                    },
                  ])
                );

                setUploadProgress([
                  {
                    asset_id: assetId,
                    percentUploaded: chunkUploadPercentage,
                    fileName: sanitizeFilename(file.name),
                  },
                ]);
              }

              localStorage.setItem(
                `multipart_keys${sanitizeFilename(file.name)}`,
                JSON.stringify([chunk_upload_response.data])
              );
            }
          }
        } catch (err) {
          console.log("multipart_upload failed", err);
        }
      });

      await Promise.all(uploadPromises);

      // Handle successful chunk upload, update progress, etc.
    } catch (error) {
      // Handle errors, retry, or resume uploads
      console.log("get_multipart_id error", error);
    }
  };

  async function handleFileUpload(event) {
    setLoading(true);
    setUploadStarted(true);
    const file = event.target.files[0];

    if (!event.target.accept.split(",").includes(file.type)) {
      setAlertPopUp(true);
      return;
    }

    // Check if the file type matches the allowed format
    const allowedFormats = event.target.accept
      .split(",")
      .map((format) => format.trim());

    let allowedSampleRates = event.target.getAttribute("acceptedrates");
    allowedSampleRates =
      allowedSampleRates &&
      allowedSampleRates.split(",").map((format) => format.trim());

    let allowedchannels = event.target.getAttribute("allowedchannels");
    allowedchannels =
      allowedchannels &&
      allowedchannels.split(",").map((format) => format.trim());

    const isAllowedFormat = allowedFormats.includes(file.type);

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    let filesSampleRate = (audioContext.sampleRate / 1000).toFixed(1);

    let filesChannel = audioContext.destination.channelCount;

    const isAllowedsampleRate = allowedSampleRates.includes(
      `${filesSampleRate}KHz`
    );

    const isAllowedchannels = allowedchannels.includes(`${filesChannel}`);

    // if (!isAllowedFormat || !isAllowedsampleRate || !isAllowedchannels) {
    //   alert("File format is not allowed");
    //   setLoading(false);
    //   return; // Do not proceed with the upload
    // } else {

    // }

    if (file) {
      const updatedRows = rows.map((item) => {
        setAssetIds([...assetIds, assetId.toString()]);

        const updatedAssetIdsString = JSON.stringify([
          ...assetIds,
          assetId.toString(),
        ]);
        localStorage.setItem("assetIds", updatedAssetIdsString);
        if (item.id.toString() === assetId.toString()) {
          return {
            ...item,
            upload: "loading",
          };
        }
        return item;
      });

      setRows(updatedRows);

      let access_token = localStorage.getItem("Auth")
        ? JSON.parse(localStorage.getItem("Auth")).access_token
        : "";

      const chunkSize = 1024 * 1024 * 15; // 15 MB chunk size
      const totalChunks = Math.ceil(file.size / chunkSize);
      const chunkBatchSize = 5; // Number of chunks to upload at a time
      let chunkIndex = 0;
      const chunkUploadPercentage = 100 / totalChunks;

      // Clearing if the previous upload keys present in localStorage

      if (
        localStorage.getItem(`multipart_keys${sanitizeFilename(file.name)}`)
      ) {
        localStorage.removeItem(`multipart_keys${sanitizeFilename(file.name)}`);
      }

      let multipart_upload_id;

      // MULTIPART UPLOAD ID REQUEST
      try {
        let getMultipartIdPostData = {
          client_id: _source?.client_id?.id,
          filename: sanitizeFilename(file.name),
          release_id: _id,
        };

        const get_multipart_id_response = await axios.post(
          `${environment.api.baseApi}/get_multipart_id`,
          getMultipartIdPostData,
          {
            headers: {
              Authorization: "Bearer " + access_token,
              Accept: "application/json", // Specify the expected response format
              "Content-Type": "application/json", // Specify the request data format
            },
          }
        );

        if (get_multipart_id_response.status === 200) {
          // storing the multipart upload id
          multipart_upload_id = await get_multipart_id_response.data?.upload_id;

          while (chunkIndex < totalChunks) {
            const originalChunkIndex = chunkIndex;
            const remainingChunks = totalChunks - chunkIndex;
            const batchSize = Math.min(chunkBatchSize, remainingChunks);

            const batchChunks = [];

            for (let i = 0; i < batchSize; i++) {
              const start = chunkIndex * chunkSize;
              const end = Math.min((chunkIndex + 1) * chunkSize, file.size);
              const chunk = file.slice(start, end);
              batchChunks.push(chunk);
              chunkIndex++;
            }

            await uploadChunks(
              batchChunks,
              file,
              multipart_upload_id,
              originalChunkIndex,
              chunkUploadPercentage,
              assetId
            );

            // Optionally, you can update progress here
            if (chunkIndex < totalChunks) {
              // Implement a delay before the next batch (e.g., for better UI responsiveness)
              await new Promise((resolve) => setTimeout(resolve, 100));
            }
          }
          // COMPLETE MULTIPART Upload

          try {
            let eTagsArray = localStorage.getItem(
              `multipart_keys${sanitizeFilename(file.name)}`
            )
              ? JSON.parse(
                  localStorage.getItem(
                    `multipart_keys${sanitizeFilename(file.name)}`
                  )
                )
              : [];

            // console.log("etagsArray", eTagsArray);

            const postObj = {
              release_id: _id,
              client_id: _source?.client_id?.id,
              filename: sanitizeFilename(file.name),
              upload_id: `${multipart_upload_id}`,
              etags: eTagsArray,
              file_type: mediaType === "audio" ? "audio" : "",
            };

            const complete_multipart_response = await axios.post(
              `${environment.api.baseApi}/complete_multipart_upload`,
              JSON.stringify(postObj),
              {
                headers: {
                  Authorization: "Bearer " + access_token,
                  "Content-Type": "application/json", // Specify the request data format
                },
              }
            );

            // console.log("MULTIPART_response: ", complete_multipart_response);

            if (complete_multipart_response.status === 200) {
              localStorage.removeItem(
                `multipart_keys${sanitizeFilename(file.name)}`
              );

              if (localStorage.getItem("upload_progress")) {
                let prev = JSON.parse(localStorage.getItem("upload_progress"));

                let filteredUploadProgress = prev?.filter(
                  (obj) => obj.asset_id !== assetId
                );

                if (filteredUploadProgress?.length > 0) {
                  localStorage.setItem(
                    "upload_progress",
                    JSON.stringify(filteredUploadProgress)
                  );

                  setUploadProgress(filteredUploadProgress);
                } else {
                  localStorage.removeItem("upload_progress");
                  setUploadProgress([]);
                  setUploadStarted(false);
                }
              }

              let url = sanitizeFilename(file.name);
              setUrl(url);
              // await setOpenAssetsForm(true);
              setLoading(false);

              let obj = {
                asset_url: url,
              };

              if (url) {
                let params = { current_page: "upload" };
                props
                  .dispatch(
                    AssetsAction.requestPutAssets(
                      JSON.stringify(obj),
                      assetId,
                      params
                    )
                  )
                  .then(() => {
                    let params = {
                      current_page: "upload",
                      page_limit: "none",
                      filters: `[["release_id.keyword","must","","match","${localStorage.getItem(
                        "id"
                      )}"]]`,
                    };
                    props.dispatch(AssetsAction.requestAssets(params));
                    setLoading(false);
                    setAssetId(null);
                  });
              }
            }
          } catch (error) {
            console.log("complete multipart upload failed", error);
          }
        }
      } catch (error) {
        console.log("multipart upload error: ", error);
      }

      // console.log("FILE", { file, chunks, totalChunks });
    }
  }

  useEffect(() => {
    let id = localStorage.getItem("id");
    fetchResource(id);
  }, []);

  function extractFileName(inputString) {
    const parts = inputString.split("_");
    const extractedWord = parts[4] && parts[4].substring(6);
    return extractedWord;
  }

  useEffect(() => {
    const updatedRows = [];

    if (props.assets.hits) {
      props.assets.hits.map((item, index) => {
        return updatedRows.push({
          sl_no: item._id ? ++index : "",
          id: item._id,
          _index: item._index,
          assets_name: item._source.assets_name,
          file_name: item._source.asset_url ? item._source.asset_url : "",
          upload: item._source.asset_url
            ? "checked"
            : assetIds.includes(item._id)
            ? "loading"
            : "unchecked",
          created_by: item._source.created_by.user_name,
          order_number: item._source.order_number,
          client_id: item._source.client_id,
          stream_id: item._source.stream_id,
          track_id: item._source.track_id,
          asset_url: item._source.asset_url,
        });
      });
    }

    let filteredAssetIds = [];
    filteredAssetIds = assetIds?.filter((id) => {
      return !updatedRows.some((row) => row.id === id && row.asset_url);
    });
    localStorage.setItem("assetIds", JSON.stringify(filteredAssetIds));

    // if every rows has asset url set null assetids
    const everyItemHasAssetUrl = updatedRows?.every((item) => item?.asset_url);
    if (everyItemHasAssetUrl) {
      setAssetIds([]);
      localStorage.removeItem("assetIds");
    }

    setRows(updatedRows);
  }, [props.assets.hits]);

  const navigate = useNavigate();

  return (
    <Box ref={boxRef} paddingLeft={2} paddingRight={2} paddingBottom={2}>
      <Grid
        item
        container
        xl={12}
        lg={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent={"space-between"}
        style={{
          position: "sticky",
          top: `${boxRef?.current?.offsetTop}px`,
          backgroundColor: theme.palette.background.default,
          zIndex: 10,
        }}
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={10}
          container
          alignItems={"center"}
          justifyContent="flex-start"
          gap={2}
          paddingTop={1}
        >
          {/* <Grid item>
                          <IconButton>
                            <ArrowBackIcon
                              title="Back"
                              sx={{
                                color: "#2a5e6c",
                                height: "25px",
                                width: "25px",
                              }}
                              onClick={(event) => {
                                event.ignore = true;
                                navigate("/releases");
                                localStorage.removeItem("id");
                              }}
                            />
                          </IconButton>
                        </Grid> */}

          <Grid item>
            <h2>{formatName(`${_source?.title}`)}</h2>
          </Grid>

          <Grid item>
            <BreadCrumbs pathname={window.location.pathname} />
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          md={12}
          xl={12}
          className="d-flex justify-content-end"
          sx={{
            paddingTop: "10px",
            paddingX: {
              lg: 20,
              md: 1,
              sm: 1,
            },
          }}
        >
          <Grid item lg={1} md={2} sm={2}>
            <MuiButton
              name={"Next"}
              onClick={() => {
                navigate("/tracks");
              }}
              size="small"
              width={"100%"}
            />
          </Grid>
        </Grid>
      </Grid>

      {_source?.type_of_release === null && (
        <Alert severity="error">
          Please Select the type of release in Release information to add
          file(s).
        </Alert>
      )}

      <Box sx={{ paddingX: 10, paddingY: 5 }}>
        <Grid
          container
          className="d-flex align-items-center justify-content-between"
        >
          <Typography component={"span"} fontSize={"28px"}>
            {/* {formatName("Upload Music & Videos")} */}
          </Typography>
        </Grid>
        {/* <Typography component={"div"} fontSize={"28px"}>
        {formatName(`${_source?.title}`)}
      </Typography> */}

        {/* <h2>{formatName(`${_source?.title}`)}</h2> */}

        {_source &&
          _source.status &&
          (_source.status.id === "1" ||
            _source.status.id === "4" ||
            _source.status.id === "9" ||
            _source.status.id === "10") &&
          _source?.type_of_release && (
            <>
              <Typography
                id={`asset-audio/wav-upload`}
                component={"input"}
                ref={audioRef}
                type="file"
                //multiple
                accept={`audio/wav,audio/x-wav,aif,aiff,flac`}
                acceptedrates={`44.1KHz, 48KHz, 88.2KHz, 96KHz`}
                allowedchannels={`1 , 2`}
                onChange={handleFileUpload}
                sx={{ display: "none" }}
              ></Typography>
              <Typography
                id={`asset-video/wav-upload`}
                component={"input"}
                ref={videoRef}
                type="file"
                //multiple
                accept={`video/mp4, video/quicktime`}
                acceptedrates={`44.1KHz, 96KHz, `}
                allowedchannels={`1 , 2`}
                onChange={handleFileUpload}
                sx={{ display: "none" }}
              ></Typography>
              {/* <ReactPlayer url={audioFile} controls={true} /> */}
              <Grid
                container
                sx={{
                  marginY: 2,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <UploadSection
                  disabled={
                    props.releaseById._source.type_of_release === "video"
                      ? true
                      : false
                  }
                  accept="audio/wav"
                  icon="audio"
                  title="Upload Audio"
                  handleFileUpload={handleFileUpload}
                  OpenDailog={OpenDailog}
                  format={
                    "Accepted file types are .wav, .aif, .aiff, and .flac. Supported sample rates include 44.1KHz, 48KHz, 88.2KHz, and 96KHz, with bit depths of 16 bit or 24 bit. Audio can be in stereo or mono channels."
                  }
                  type={"Field Type :"}
                  cursor={
                    props.releaseById._source.type_of_release === "video"
                      ? "not-allowed"
                      : "pointer"
                  }
                />

                <UploadSection
                  disabled={
                    props.releaseById._source.type_of_release === "audio"
                      ? true
                      : false
                  }
                  accept="video/quicktime"
                  icon="video"
                  title="Upload Apple Video"
                  handleFileUpload={handleFileUpload}
                  OpenDailog={OpenDailog}
                  format={
                    "Accepted file types: .wav, .aif, .aiff, .flac. Supported sample rates: 44.1KHz, 96KHz. Bit Depth: 24 bit. Channels: Stereo or Mono."
                  }
                  type={"Field Type :"}
                  cursor={
                    props.releaseById._source.type_of_release === "audio"
                      ? "not-allowed"
                      : "pointer"
                  }
                />

                <UploadSection
                  disabled={
                    props.releaseById._source.type_of_release === "video"
                      ? true
                      : false
                  }
                  accept="audio/wav"
                  icon="audio"
                  title="Upload Dolby Audio"
                  OpenDailog={OpenDailog}
                  handleFileUpload={handleFileUpload}
                  format={
                    "Accepted file type is ADM BWAV (.wav) conforming to Dolby Atmos Master ADM profile, with a sample rate of 48KHz, 24-bit depth, up to 128 PCM channels, up to 118 objects, and 24 fps timecode."
                  }
                  type={"Field Type :"}
                  cursor={
                    props.releaseById._source.type_of_release === "video"
                      ? "not-allowed"
                      : "pointer"
                  }
                />

                <UploadSection
                  disabled={
                    props.releaseById._source.type_of_release === "audio"
                      ? true
                      : false
                  }
                  accept="video/quicktime"
                  icon="video"
                  title="Upload Music Video"
                  OpenDailog={OpenDailog}
                  handleFileUpload={handleFileUpload}
                  format={
                    "Accepted file types are .mov and .mxf. Supported codecs include Apple ProRes 422 (HQ), Avid DNxHD 175x, 185x, or 220x. Dimensions should be 1920 x 1080 with a 16:9 aspect ratio and 1:1 (square) pixel aspect ratio. Framerates can be 30, 29.97, 25, 24, 23.98, 23.976, or 23.97. The bitrate is variable (VBR) with approximately 220 Mbps. Closed captioning is not supported."
                  }
                  type={"Field Type :"}
                  cursor={
                    props.releaseById._source.type_of_release === "audio"
                      ? "not-allowed"
                      : "pointer"
                  }
                />
              </Grid>
            </>
          )}

        {/* <Box className=" p-0 m-0">
          <Grid
            item
            container
            xl={12}
            alignItems="center"
            justifyContent={"center"}
          >
            <Grid item xl={7}>
              {audioFile && (
                <ReactPlayer
                  url={audioFile}
                  controls={true}
                  width="100%"
                  height={
                    fileName && fileName.type === "audio/wav" ? "50px" : "auto"
                  }
                  maxWidth="100%"
                  maxHeight={
                    fileName && fileName.type === "audio/wav" ? "50px" : "auto"
                  }
                  className=" p-0 m-0"
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                />
              )}
            </Grid>

            <Grid item xl={7} textAlign="center">
              {fileName && (
                <Typography variant="subtitle1">{fileName.name}</Typography>
              )}
            </Grid>
          </Grid>
        </Box> */}

        <Grid container sx={{ mt: 2 }}>
          {/* <LoadingIndicator isActive={loading}></LoadingIndicator> */}
          <Assets
            handleReupload={async (item) => {
              setAssetId(item);
              if (props?.releaseById?._source?.type_of_release === "video") {
                videoRef.current.value = null;
                videoRef?.current?.click();
              } else if (
                props?.releaseById?._source?.type_of_release === "audio"
              ) {
                audioRef.current.value = null;
                audioRef?.current?.click();
              }
            }}
            rows={rows}
            releaseById={props.releaseById}
            // releaseId={props.releaseById._id}
            releaseId={localStorage.getItem("id")}
            _source={_source}
            progress={uploadProgress}
            uploadStarted={uploadStarted}
          />
          <AssetsPost
            url={url}
            // releaseId={props.releaseById._id}
            releaseId={localStorage.getItem("id")}
            open={openAssetsForm}
            setAssetId={setAssetId}
            onSubmit={() => {
              //setLoading(true);
              let params = {
                page_limit: "none",
                filters: `[["release_id.keyword","must","","match","${localStorage.getItem(
                  "id"
                )}"]]`,
                current_page: "upload",
              };
              let param = {
                order_by: `[["modified_on","desc"]]`,
                page_limit: 15,
                current_page: "upload",
              };
              console.log("params", params);
              props.dispatch(AssetsAction.requestAssets(params));
              props.dispatch(AssetsAction.requestAssetsFilter(param));

              if (mediaType === "audio") {
                audioRef.current.value = null;
                audioRef?.current?.click();
              } else if (mediaType === "video") {
                videoRef.current.value = null;
                videoRef?.current?.click();
              }
            }}
            onClose={() => setOpenAssetsForm(false)}
          />
        </Grid>
      </Box>
      <Snackbar
        open={alertPopUp}
        autoHideDuration={1500}
        onClose={() => setAlertPopUp(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
          message={
            <Typography
              variant="body1"
              color={"white"}
              fontWeight={"bold"}
              mx={5}
            >
              Please Upload correct file format
            </Typography>
          }
          sx={{
            borderRadius: "5px",
            background: theme.palette.primary.main,
          }}
        />
      </Snackbar>
    </Box>
  );
}

export default connect(mapStateToProps)(Upload);

const UploadSection = ({
  accept,
  icon,
  title,
  handleFileUpload,
  OpenDailog,
  format,
  type,
  disabled,
}) => {
  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.primary,
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Grid
        item
        sx={{
          width: {
            xl: "192px",
            lg: "200px",
            md: "100px",
            sm: "100px",
            xs: "100px",
          },
          height: {
            xl: "192px",
            lg: "200px",
            md: "100px",
            sm: "100px",
            xs: "100px",
          },
          backgroundColor: theme.palette.background.paper,
          border: `1px dashed ${theme.palette.primary.main}`,
        }}
      >
        <Typography
          component={"label"}
          // htmlFor={`asset-${accept}-upload`}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            cursor: disabled ? "not-allowed" : "pointer",
            marginY: 1,
          }}
        >
          <Typography
            onClick={() => {
              if (!disabled) {
                OpenDailog(icon);
              }
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {icon === "audio" ? (
              <LibraryMusicIcon
                sx={{
                  fontSize: {
                    lg: "40px",
                    md: "30px",
                    sm: "20px",
                  },
                  color: theme.palette.primary.light,
                }}
              />
            ) : (
              <VideocamIcon
                sx={{
                  fontSize: {
                    lg: "40px",
                    md: "30px",
                    sm: "20px",
                  },
                  color: theme.palette.primary.light,
                }}
              />
            )}

            <Typography
              component={"span"}
              sx={{
                minHeight: {
                  lg: "100px",
                  md: "50px",
                  sm: "50px",
                },
                padding: "10px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: {
                  lg: "16px",
                  md: "13px",
                  sm: "13px",
                },
              }}
            >
              <span>{title}</span>
            </Typography>
          </Typography>
        </Typography>
      </Grid>

      <UploadPopper format={format} type={type} disabled={disabled} />
    </Grid>
  );
};
