import BaseReducer from "../../utilities/BaseReducer";
import SubscriptionPlansAction from "./SubscriptionPlansAction";

// SubscriptionBasePlansReduser
export default class SubscriptionBasePlansFilterReduser extends BaseReducer {
  initialState = {
    subscriptionBasePlanFilter: [],
  };
  [SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      subscriptionBasePlanFilter: action.payload,
    };
  }
}
