import BaseReducer from "../../utilities/BaseReducer";
import LabelAction from "./LabelAction";

// LabelReuser
export default class LabelReuser extends BaseReducer {
  initialState = {
    label: [],
  };
  [LabelAction.REQUEST_LABELS_FINISHED](state, action) {
    return {
      ...state,
      label: action.payload,
    };
  }

  [LabelAction.REQUEST_LABELS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      label: [],
    };
  }
}
