import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ClientModel from "../client/model/ClientModel";
import TrackPostModel from "../track/model/TrackPostModel";

export default class SubscriptionPlansEffect {
  // calling an API according to the status model
  // Get method for the
  static async requestSubscriptionPlans(params, callBack) {
    const endpoint = environment.api.subscriptionPlan;
    const response = EffectUtility.getToModel(ClientModel, endpoint, params);

    response?.then((resp) => {
      if (callBack && resp) {
        callBack(resp);
      }
    });

    return response;
  }

  static async requestSubscriptionBasePlans(params) {
    const endpoint = environment.api.subscriptionBasePlan;
    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  static async requestSubscriptionBasePlansFilter(params) {
    const endpoint = environment.api.subscriptionBasePlan;
    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  static async requestSelectSubscriptionPlans(data, params) {
    const endpoint = environment.api.selectSubscriptionPlan;
    return EffectUtility.postToModel(TrackPostModel, endpoint, data, params);
  }
}
