import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import RegisterPostModel from "./model/RegisterPostModel";
import SendMailPostModel from "./model/SendMailPostModel";
import MailOtpPOstModal from "./model/MailOtpPOstModal";
import MobileNumberPostModel from "./model/MobileNumberPostModel";
import MobileNumberotpPostModel from "./model/MobileNumberotpPostModel";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
export default class RegisterEffect {
  // post method for the register
 static async requestPostRegister(data, callBack) {
  const endpoint = environment.api.register;
    let response = EffectUtility.postToModel(RegisterPostModel, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
  }

  return response;
}

  static async requestPostMailAddress(data, callBack) {
    const endpoint = environment.api.send_mail_otp;
    let response = EffectUtility.postToModel(SendMailPostModel, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestPostMailOTP(data, callBack) {
    const endpoint = environment.api.verify_mail_otp;
    let response = EffectUtility.postToModel(MailOtpPOstModal, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }
   static async requestPostMobileNumber(data, callBack) {
    const endpoint = environment.api.send_mobile_otp;
    let response = EffectUtility.postToModel(MobileNumberPostModel, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestPostMobileNumberOTP(data, callBack) {
    const endpoint = environment.api.verify_mobile_otp;
    let response = EffectUtility.postToModel(MobileNumberotpPostModel, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }
}
