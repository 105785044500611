import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import ListnerBehaviour from "../../../components/trackAnalyticsComponents/ListnerBehaviour";
import StreamsComponent from "../../../components/trackAnalyticsComponents/StreamsComponent";
import TopRetailer from "../../../components/trackAnalyticsComponents/TopRetailer";
import TopTerritory from "../../../components/trackAnalyticsComponents/TopTerritory";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TopStoreAction from "../../../stores/topStoresReport/TopStoreAction";
import TopTerritoriesAction from "../../../stores/TopTerritories/TopTerritoriesAction";
import { theme } from "../../App";
import DateSelectionPopper from "../../dashboard/components/DateSelectionPopper";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import TrackAudioStream from "../../../components/trackAnalyticsComponents/TrackAudioStream";
import ForwardIcon from "@mui/icons-material/Forward";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TopStoreAction.REQUEST_TOPRETAILER,
    TopTerritoriesAction.REQUEST_TOP_TERRITORY,
    TopStoreAction.REQUEST_AUDIO_STREAMS,
  ]),
  topRetailer: state.topRetailer.topRetailer || [],
  top_territory: state.top_territory.top_territory || [],
  audioStreams: state.audioStreams.audioStreams || [],
});

const TracksAnalytics = (props) => {
  const navigate = useNavigate();
  let location = useLocation();

  const fetchTopRetailer = (params) => {
    props.dispatch(TopStoreAction.requestTopRetailer(params));
  };

  const fetchTopTerritory = (params) => {
    props.dispatch(TopTerritoriesAction.requestTopTerritory(params));
  };

  const fetchAudioStreams = (params) => {
    props.dispatch(TopStoreAction.requestAudioStreams(params));
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [createdDatelistSelected, setCreatedDatelistSelected] = useState();

  useEffect(() => {
    if (selectedDate && !props.isRequesting) {
      let filters = [];

      filters.push(
        `["track_id.keyword","must","","match","${location?.state?.trackData}"]`
      );

      filters.push(selectedDate);
      let params = {
        filters: `[${filters}]`,
        current_page: "tracksAnalytics",
      };
      fetchTopRetailer(params);
      fetchTopTerritory(params);
      fetchAudioStreams(params);
    }
  }, [selectedDate]);

  let topRetailerReport = props?.topRetailer?.top_stores_report;
  let topTerritoryReport = props?.top_territory?.top_territories;
  let audioStreamsReport = props?.audioStreams?.audio_streams;

  const handleDateSelection = (date) => {
    setSelectedDate(date);
  };

  const [dateSelection] = useState([
    // "Last 2 Days",
    // "Today",
    // "Previous Day",
    "Last 7 Days",
    "Last 30 Days",
    "Custom",
  ]);

  function formatDateRange(dateRangeString) {
    const dateRanges = dateRangeString?.split("],[");

    if (dateRanges?.length >= 2) {
      let [, , , , startDate] = dateRanges[0].split(",");
      let [, , , , endDate] = dateRanges[1].split(",");
      endDate = endDate.replace("]", "");

      const options = { year: "numeric", month: "short", day: "numeric" };
      startDate = new Date(startDate).toLocaleDateString("en-US", options);
      endDate = new Date(endDate).toLocaleDateString("en-US", options);

      return `${endDate} - ${startDate}`;
    }
  }

  return (
    <Box sx={{ paddingBottom: "15px" }}>
      <LoadingIndicator isActive={props.isRequesting}>
        {!props.isRequesting ? (
          <>
            <Grid item container sm={1} my={2}>
              <Box
                sx={{
                  padding: "3px",
                  border: `1px solid ${theme.palette.common.grey}`,
                  borderRadius: "4px",
                  backgroundColor: theme.palette.primary.main,
                  cursor: "pointer",
                  "&:hover": {
                    border: `1px solid ${theme.palette.primary.main}`,
                    boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.9)",
                  },
                }}
                onClick={() => {
                  navigate(`/analytics`);
                }}
              >
                <ForwardIcon
                  style={{
                    color: "white",
                    transform: "rotate(180deg)",
                    marginBottom: "4px",
                  }}
                />
                <span style={{ color: "white", marginRight: "5px" }}>Back</span>
              </Box>
            </Grid>
            <Box
              mb={5}
              pb={4}
              width="99%"
              sx={{
                boxShadow: theme.shadows,
                backgroundColor: theme.palette.background.default,
                borderRadius: 1,
              }}
            >
              <Grid
                item
                container
                alignItems={"center"}
                justifyContent="center"
                padding={0}
              >
                <Grid
                  item
                  container
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <Box paddingY={2} paddingX={3} width="100%">
                    <Box marginY={2}>
                      <Grid
                        item
                        container
                        justifyContent={"flex-start"}
                        alignItems="center"
                        gap={5}
                      >
                        <Grid item>
                          <span
                            style={{
                              fontSize: 24,
                              fontWeight: "bold",
                            }}
                          >
                            AT A GLANCE
                          </span>
                        </Grid>

                        <span
                          style={{
                            fontSize: "17px",
                            color: "grey",
                          }}
                        >
                          {formatDateRange(selectedDate)}&nbsp;
                        </span>
                        <Grid
                          item
                          sx={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            paddingX: "10px",
                            paddingY: "6px",
                            borderRadius: "6px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            marginLeft: "auto",
                          }}
                        >
                          <span>
                            <DateSelectionPopper
                              onDateSelect={handleDateSelection}
                              dateSelection={dateSelection}
                              createdDatelistSelected={
                                createdDatelistSelected
                                  ? createdDatelistSelected
                                  : "Last 30 Days"
                              }
                              setCreatedDatelistSelected={
                                setCreatedDatelistSelected
                              }
                              color="grey"
                              fontSize={"17px"}
                            />
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      xs={12}
                      sm={12}
                      alignItems="center"
                      justifyContent={"space-between"}
                      // gap={1}
                    >
                      <Grid
                        item
                        container
                        alignItems={"center"}
                        justifyContent="center"
                        xl={2.8}
                        lg={2.8}
                        md={2.8}
                        xs={2.8}
                        sm={2.8}
                        sx={{
                          backgroundColor: "#f4f8fe",
                          borderRadius: 6,
                        }}
                      >
                        <StreamsComponent
                          audioStreamsReport={audioStreamsReport}
                        />
                      </Grid>

                      <Grid
                        item
                        container
                        alignItems={"center"}
                        justifyContent="center"
                        xl={2.8}
                        lg={2.8}
                        md={2.8}
                        xs={2.8}
                        sm={2.8}
                        pb={3}
                        sx={{
                          backgroundColor: theme.palette.background.grey,
                          borderRadius: 6,
                        }}
                      >
                        <TopRetailer topRetailerReport={topRetailerReport} />
                      </Grid>

                      <Grid
                        item
                        container
                        alignItems={"center"}
                        justifyContent="center"
                        xl={2.8}
                        lg={2.8}
                        md={2.8}
                        xs={2.8}
                        sm={2.8}
                        pb={3}
                        sx={{
                          backgroundColor: theme.palette.background.grey,
                          borderRadius: 6,
                        }}
                      >
                        <TopTerritory topTerritoryReport={topTerritoryReport} />
                      </Grid>

                      {/* <Grid
                  item
                  container
                  alignItems={"center"}
                  justifyContent="center"
                  xl={2.8}
                  lg={2.8}
                  md={2.8}
                  xs={2.8}
                  sm={2.8}
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                  }}
                >
                  <ListnerBehaviour />
                </Grid> */}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <TrackAudioStream
              formattedDate={formatDateRange(selectedDate)}
              isRequesting={props.isRequesting}
              audioStreamsReport={audioStreamsReport}
            />
          </>
        ) : (
          <Box>
            <LoadingBgImage />
          </Box>
        )}
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(TracksAnalytics);
