import { Box, Typography, Divider, Link, styled } from "@mui/material";
import React, { useEffect } from "react";

const typographyStyle = {
  marginY: "10px",
  color: "#9b9b9b",
  fontSize: {
    xl: "14px",
  },
  lineHeight: {
    xl: "19px",
  },
};

// Custom scrollbar styles
const CustomScrollBox = styled(Box)({
  bgcolor: "#000",
  width: {
    xl: "1595px",
  },
  height: {
    xl: "691px",
  },
  padding: "2px",
  overflowY: "auto",
  overflowX: "hidden",
  border: "1px solid #2f2f2f",
  borderRadius: "5px",
  height: "250px",
  cursor: "pointer",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#484848",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#c4c4c4",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#000",
  },
});

const TermsandConditions = (props) => {
  const { scrollBoxRef, setScrollEndReached } = props;

  // Handle scroll event to detect when user reaches end of the scrollable area
  const handleScroll = () => {
    if (scrollBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollBoxRef.current;
      if (scrollHeight - scrollTop <= clientHeight) {
        setScrollEndReached(true);
      }
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    const scrollBox = scrollBoxRef.current;
    if (scrollBox) {
      scrollBox.addEventListener("scroll", handleScroll);
      return () => {
        scrollBox.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollBoxRef, setScrollEndReached]);

  return (
    <>
      <CustomScrollBox
        ref={scrollBoxRef}
        sx={{
          bgcolor: "#000",
          width: "100%",
          height: "726px",
          padding: "22px 30px 14px 25px",
          overflowY: "auto",
          border: "1px solid #2f2f2f",
        }}
      >
        <Typography sx={typographyStyle}>
          <strong>A3 Tunes/ A3 Labels Terms and Conditions</strong>
        </Typography>
        <Typography sx={typographyStyle}>
          <strong>Last Revised and Effective as of August 1, 2024</strong>
        </Typography>
        <Typography sx={typographyStyle}>
          <strong>Welcome to </strong>
          <Link
            href="https://a3tunes.com/"
            target="_blank"
            sx={{
              color: "#8cafba",
              "&:hover": {
                color: "#8db8c7",
              },
            }}
            underline="hover"
          >
            https://a3tunes.com/
          </Link>
          <strong>, the official website for A3 Tunes</strong>, a digital music
          distribution service for individuals based in India and
          internationally.
        </Typography>

        <Typography sx={typographyStyle}>
          <div>
            These Terms and Conditions (the “Terms”) apply to all users of
            <Link
              href="https://a3tunes.com/"
              target="_blank"
              sx={{
                color: "#8cafba",
                "&:hover": {
                  color: "#8db8c7",
                },
              }}
              underline="hover"
            >
              {" "}
              https://a3tunes.com
            </Link>{" "}
            and its associated websites (collectively, the “A3 Tunes Site”),
            which is operated by A3 Tunes, a division of A3 Labels Company
            (hereafter referred to as “Company”). These Terms also apply to all
            users of{" "}
            <Link
              href="https://www.a3labels.in/"
              target="_blank"
              sx={{
                color: "#8cafba",
                "&:hover": {
                  color: "#8db8c7",
                },
              }}
              underline="hover"
            >
              www.a3labels.in
            </Link>{" "}
            and its associated websites (collectively, the “A3 Labels Site,” and
            together with the A3 Tunes Site, the “Distributor Sites”), also
            owned and operated by A3 Labels (“A3 Labels”). A3 Labels and A3
            Tunes are collectively referred to as “Distributor,” “we,” or “us.”
            These Terms represent a legally binding agreement between you, an
            individual user or a single entity (collectively or individually,
            “Users”), and Distributor regarding your use of the A3 Tunes Site or
            A3 Labels Site. Users and Distributor are each referred to herein
            individually as a “Party” and collectively as the “Parties.”
          </div>
          <div style={{ marginBottom: "10px" }}>
            When using the A3 Tunes Site or A3 Labels Site, you may be subject
            to additional terms or rules applicable to specific services and
            features, which may be posted from time to time on the applicable
            site (the “Supplemental Terms”). All Supplemental Terms are
            incorporated by reference into these Terms.
          </div>
        </Typography>
        <Typography sx={typographyStyle}>
          <strong>
            PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE DISTRIBUTOR
            SITES. BY REGISTERING FOR, ACCESSING, BROWSING, OR USING THE
            DISTRIBUTOR SITES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD,
            AND AGREE TO BE BOUND BY THESE TERMS, INCLUDING THE SUPPLEMENTAL
            TERMS, AND ANY FUTURE MODIFICATIONS. IF YOU DO NOT AGREE TO THESE
            TERMS, PLEASE IMMEDIATELY TERMINATE YOUR USE OF THE DISTRIBUTOR
            SITES.
          </strong>
        </Typography>
        <Typography component="p" sx={typographyStyle}>
          <ol style={{ width: "100%" }}>
            <li>
              <strong>Eligibility</strong>
              <div>
                To use the Distributor Sites, you must be at least eighteen (18)
                years of age or older. If you are between thirteen (13) and
                eighteen (18) years of age, you may use the Sites only with the
                consent of your parent(s) and/or legal guardian. You must be
                fully able and competent to (i) enter into the terms,
                conditions, obligations, affirmations, representations, and
                warranties set forth in these Terms; and (ii) abide by and
                comply with these Terms. By using the Distributor Sites, you
                represent that you meet these eligibility requirements.
              </div>
            </li>
            <li>
              <strong>Privacy Notice</strong>
              <div>
                Your privacy is important to us. Please review our Privacy
                Policy [insert link] for details regarding how Distributor
                collects, uses, and discloses your personal information.
              </div>
            </li>
            <li>
              <strong>Modification of the Terms</strong>
              <div>
                Distributor reserves the right, at our sole discretion, to
                change, modify, add, or remove portions of these Terms,
                including the Supplemental Terms, at any time for any reason.
                Changes may be reflected in the “Last Updated” date at the top
                of these Terms or through other reasonable means. All changes
                are effective immediately upon posting. If a material change
                affects your account, we will use reasonable efforts to notify
                you via the most recent email address you provided. It is your
                responsibility to review these Terms periodically. Your
                continued use of the Distributor Sites following the posting of
                changes constitutes your binding acceptance of those changes.
              </div>
            </li>
            <li>
              <strong>Access and Use of Distributor Sites</strong>
              <div>
                Distributor grants you permission to use the Distributor Sites
                as set forth in these Terms, provided that:
                <ol>
                  <li>
                    You use the Distributor Sites solely for personal use;
                  </li>
                  <li>
                    You do not download, reproduce, redistribute, retransmit,
                    publish, resell, distribute, publicly display, or otherwise
                    exploit any portion of the Distributor Sites in any medium
                    without prior written authorization from Distributor, except
                    as expressly permitted in these Terms;
                  </li>
                  <li>
                    You do not alter or modify any part of the Distributor Sites
                    beyond what is reasonably necessary for their intended
                    purposes;
                  </li>
                  <li>
                    You do not engage in any prohibited activities as described
                    in Section 11 below; and
                  </li>
                  <li>You fully comply with these Terms.</li>
                </ol>
                The A3 Labels Site is controlled and operated from facilities in
                India. Distributor makes no representations that the Distributor
                Sites are appropriate or available for use in other locations.
                If you access or use the Distributor Sites from outside India,
                you are responsible for compliance with local laws. To the
                extent allowed in your jurisdiction, you waive your right to
                assert claims or avail yourself of protections afforded by local
                laws that are not available in India
              </div>
            </li>
          </ol>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />

        <Typography component="p" sx={typographyStyle}>
          <strong>Distributor Ownership; Proprietary Rights</strong>
          <ol>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                General
              </strong>
              <div style={{ marginBottom: "10px" }}>
                The Distributor Sites, including but not limited to the content,
                visual interfaces, interactive features, audio, video,
                audio-visual material, information, graphics, design,
                compilation, computer code, products, software, services,
                proprietary information, service marks, trademarks, trade names,
                distinctive information (such as logos), the selection,
                sequence, “look and feel,” and arrangement of items, and all
                other elements provided by Distributor (collectively, the
                “Distributor Materials”), are owned and/or licensed by
                Distributor. These materials are legally protected under Indian
                laws and regulations, as well as applicable foreign laws,
                regulations, and treaties. Distributor Materials do not include
                Third Party Content (as defined below)
              </div>
              <div style={{ marginTop: "10px" }}>
                Except as expressly authorized by Distributor, you agree not to
                sell, license, distribute, copy, modify, publicly perform or
                display, transmit, publish, edit, adapt, create derivative works
                from, or otherwise make unauthorized use of the Distributor
                Sites and/or the Distributor Materials. Distributor reserves all
                rights not expressly granted in these Terms. You acknowledge
                that you do not acquire any right, title, or interest in the
                Distributor Materials, except for the limited rights expressly
                set forth in these Terms
              </div>
            </li>
            <Divider
              sx={{
                color: "white",
                width: "100%",
                borderBottomWidth: 5,
                marginY: "10px",
              }}
            />
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Apps and Other Downloadable Software
              </strong>
              <div style={{ marginBottom: "10px" }}>
                If any of the Distributor Materials provided to you includes
                software that can be downloaded, such software, along with all
                associated files, images, and data, is licensed to you by
                Distributor. This license is personal, limited,
                non-transferable, non-sublicensable, and revocable. Distributor
                reserves the right to modify or revoke this license at any time
                by providing notice to you. Upon receiving such notice, you must
                immediately destroy all copies of the software in your
                possession or on any systems under your control.
              </div>
              <div style={{ marginBottom: "10px" }}>
                You acknowledge that you do not own the downloaded software, and
                no ownership rights are transferred to you. Distributor retains
                full ownership and title to the software, including all related
                intellectual property rights. You are prohibited from
                redistributing, selling, decompiling, reverse engineering,
                disassembling, or otherwise converting the software into a
                human-readable form, except where such activities are expressly
                permitted by applicable law despite this limitation.
              </div>
              <div>
                Furthermore, software downloaded from the Distributor Sites is
                subject to Indian export control laws. By downloading such
                software, you represent and warrant that you are not violating
                any applicable export laws or regulations.
              </div>
            </li>

            <Divider
              sx={{
                color: "white",
                width: "100%",
                borderBottomWidth: 5,
                marginY: "10px",
              }}
            />
          </ol>
        </Typography>
        <Typography component="p" sx={typographyStyle}>
          <strong>User Content</strong>
          <ol>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                General
              </strong>
              <div style={{ marginBottom: "10px" }}>
                The Distributor Sites may allow you and other Users to post,
                link, or share media, text, audio and video recordings, photos,
                graphics, commentary, or other content ("User Content").
                Distributor does not control User Content and makes no
                guarantees regarding its availability on the Distributor Sites.
                We reserve the right to remove any User Content at our sole
                discretion. You understand that any User Content you post on the
                Distributor Sites is publicly accessible, and Distributor does
                not guarantee confidentiality or protection of your intellectual
                or proprietary rights in such User Content.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Grant of Rights
              </strong>
              <div style={{ marginBottom: "10px" }}>
                By submitting User Content to Distributor, you grant Distributor
                and its affiliates a worldwide, non-exclusive, fully paid-up,
                royalty-free, transferable license, with the right to grant and
                authorize sublicenses, to use, reproduce, digitize, encode,
                store, transmit, distribute, modify, adapt, translate, create
                derivative works of, display, perform, and otherwise exploit
                such User Content in connection with the Distributor Sites and
                Distributor's (and its successors, transferees, sublicensees,
                and their respective affiliates') business, including for
                promoting and redistributing part or all of the Distributor
                Sites (and derivative works thereof) in any media formats and
                through any media channels, or through merchandising. You also
                grant Distributor and its affiliates, transferees, and
                sublicensees (and their respective affiliates) the right to use
                the name you submit in connection with such User Content if they
                choose to do so.
              </div>
              <div style={{ marginBottom: "10px" }}>
                Additionally, you grant each User of the Distributor Sites a
                non-exclusive license to access your User Content through the
                Distributor Sites, and to use, reproduce, distribute, modify,
                adapt, translate, create derivative works of, display, and
                perform such User Content as permitted by the functionality of
                the Distributor Sites and these Terms.
              </div>
              <div style={{ marginBottom: "10px" }}>
                The licenses granted by you for User Content you submit shall be
                perpetual and irrevocable, except for User Content that you have
                removed or deleted while maintaining your Distributor Sites user
                account, or following the deactivation or deletion of your
                account. You may notify Distributor to terminate the foregoing
                license for specific User Content you identify, and Distributor
                will terminate the license within a commercially reasonable time
                after receiving your notice. However, Distributor may retain,
                but not display or perform, server copies of such User Content.
                Notwithstanding the above, the licenses granted by you for any
                Feedback (as defined below) you submit are perpetual and
                irrevocable.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                No Responsibility for User Content
              </strong>
              <div style={{ marginBottom: "10px" }}>
                Except for the limited license granted above, Distributor does
                not obtain or control any rights in, and does not exert
                editorial control over, User Content. Distributor is not
                obligated to verify, and may not have verified, the
                representations and warranties made by Users regarding such User
                Content.
              </div>
            </li>
          </ol>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography sx={typographyStyle}>
          <strong>Your Representations and Warranties</strong>
          <ol>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Age Requirement
              </strong>
              <div style={{ marginBottom: "10px" }}>
                <ol type="i">
                  <li>
                    You represent and warrant that you are at least eighteen
                    (18) years of age, or
                  </li>
                  <li>
                    If you are between the ages of thirteen (13) and eighteen
                    (18), you have obtained written consent from your parent or
                    legal guardian to enter into these Terms.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                User Content Representations and Warranties
              </strong>
              <div style={{ marginBottom: "10px" }}>
                <ol type="i">
                  <li>
                    In connection with any content you submit, post, or
                    otherwise make available (referred to as "User Content"),
                    you affirm, represent, and warrant that:
                    <ol type="a">
                      <li>
                        You either own all the intellectual property rights
                        (including patents, trademarks, copyrights, and other
                        proprietary rights) in and to your User Content or have
                        obtained all necessary licenses, rights, consents, and
                        permissions to use and authorize Distributor to use such
                        User Content as contemplated by these Terms. This
                        includes the right to grant the licenses outlined in
                        these Terms.
                      </li>
                      <li>
                        Neither your User Content, nor Distributor's use of your
                        User Content in accordance with these Terms, nor
                        Distributor's exercise of the rights granted in these
                        Terms will:
                        <ol type="i">
                          <li>
                            Infringe, violate, or misappropriate any third
                            party’s intellectual property or proprietary rights,
                            including copyrights, trademarks, patents, trade
                            secrets, moral rights, privacy rights, rights of
                            publicity, or any other intellectual property or
                            proprietary rights;
                          </li>
                          <li>
                            Defame, libel, or slander any person or entity, or
                            violate any person’s right to privacy, publicity, or
                            other property rights;
                          </li>
                          <li>
                            Violate any applicable laws, regulations, or rules.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Prohibited Uses of User Content
              </strong>
              <div style={{ marginBottom: "10px" }}>
                <ol type="i">
                  <li>
                    You further agree that you will not use the Distributor's
                    platform to publish, post, submit, transmit, or otherwise
                    make available any User Content that:
                    <ol type="a">
                      <li>
                        Contains falsehoods or misrepresentations that could
                        harm Distributor or any third party;
                      </li>
                      <li>
                        Is unlawful, defamatory, libelous, slanderous,
                        pornographic, obscene, abusive, profane, vulgar,
                        sexually explicit, threatening, harassing, harmful,
                        hateful, racially or ethnically offensive, or otherwise
                        objectionable, or encourages conduct that would
                        constitute a criminal offense, give rise to civil
                        liability, violate any law, or infringe upon the rights
                        of privacy or publicity;
                      </li>
                      <li>
                        Contains advertisements or solicitations of business for
                        any products or services;
                      </li>
                      <li>Would be harmful to minors in any way.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Third Party Content Disclaimer</strong>
          <Box sx={{ marginY: "10px" }}>
            Except for "Compositions" and "Recordings" (as defined in the
            Supplemental Terms – Publishing Administration), which are governed
            by separate terms, you acknowledge that by using the Distributor's
            platform, you may be exposed to content from third parties ("Third
            Party Content"). This Third Party Content may include User Content,
            advertisements, or other materials, and you understand that such
            content may be inaccurate, offensive, indecent, or otherwise
            objectionable.
          </Box>
          <Box sx={{ marginY: "10px" }}>
            Distributor does not endorse any Third Party Content or any opinion,
            recommendation, or advice expressed therein. Under no circumstances
            will Distributor be liable for any Third Party Content, including,
            but not limited to, inaccuracies, errors, or omissions in any Third
            Party Content, any infringement of intellectual property rights, or
            any loss or damage of any kind incurred as a result of the use of
            any Third Party Content posted, emailed, or otherwise made available
            via the Distributor's platform
          </Box>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Non-Monitoring of Users and Third Party Conten</strong>
          <Box sx={{ marginY: "10px" }}>
            Except for "Compositions" and "Recordings" (as defined in the
            Supplemental Terms – Publishing Administration), which are subject
            to different monitoring terms, you acknowledge that you, and not
            Distributor, are solely responsible for all User Content you upload,
            post, email, transmit, or otherwise make available through the
            Distributor's platform.
          </Box>
          <Box sx={{ marginY: "10px" }}>
            Distributor does not control Third Party Content posted by users or
            made available by other entities and has no obligation to monitor
            such content. Even if Distributor chooses to monitor Third Party
            Content at its discretion, it assumes no responsibility for such
            content, no obligation to modify or remove inappropriate content,
            and no responsibility for the conduct of any user or third party who
            submits content. You agree that you must evaluate and bear all risks
            associated with the use of any User Content or other Third Party
            Content, including reliance on its accuracy, completeness,
            usefulness, non- infringement, or legality.
          </Box>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Removal of Content</strong>
          <Box sx={{ marginY: "10px" }}>
            Except for "Compositions" and "Recordings" (as defined in the
            Supplemental Terms – Publishing Administration), which are governed
            by separate terms, Distributor and its designees reserve the right,
            in their sole discretion, to refuse to post or to remove any User
            Content or Third Party Content from the Distributor's platform, in
            whole or in part, at any time, for any reason or no reason, with or
            without notice, and without any liability.
          </Box>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Prohibited Uses of the Distributor Sites</strong>
          <ol>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Unlawful or Prohibited Use
              </strong>
              <div style={{ marginBottom: "10px" }}>
                As a condition of your use of the Distributor Sites, you
                represent and warrant that you will not use the Distributor
                Sites for any purpose that is unlawful or prohibited by these
                Terms, including but not limited to the prohibitions outlined in
                this Section.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Personal Use Limitation
              </strong>
              <div style={{ marginBottom: "10px" }}>
                The Distributor Sites and all materials available through them
                (collectively, "Distributor Materials") are provided solely for
                your personal, non- commercial use. You agree not to reproduce,
                duplicate, copy, sell, trade, resell, distribute, or exploit any
                part of the Distributor Sites, your access to the Distributor
                Sites, or any Third Party Content obtained through the
                Distributor Sites for any purpose other than personal use.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Eligibility Compliance
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to use the Distributor Sites if you do not meet
                the eligibility requirements outlined in Section 1 of these
                Terms. If you are found to be ineligible, your access to the
                Distributor Sites may be terminated without notice.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Prohibition Against Harassment and Fraud
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to defame, harass, abuse, threaten, stalk, or
                defraud any users of the Distributor Sites. Additionally, you
                will not collect or attempt to collect personal information
                about other users or third parties without their explicit
                consent.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Interference with Operations
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to intentionally interfere with, damage, impair,
                or disable the operation of the Distributor Sites or any user's
                enjoyment of them. This includes, but is not limited to, actions
                such as uploading or disseminating viruses, worms, spyware,
                adware, or other malicious code, or placing a disproportionate
                load on the Distributor Sites to deny service to other users
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Security Features
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to remove, circumvent, disable, damage, or
                otherwise interfere with any security-related features of the
                Distributor Sites, including any features that prevent or
                restrict the use or copying of any part of the Distributor
                Sites, or enforce limitations on the use of the Distributor
                Sites.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Unauthorized Access
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to attempt to gain unauthorized access to the
                Distributor Sites, other accounts, computer systems, or networks
                connected to the Distributor Sites through hacking, password
                mining, or any other unauthorized means. Furthermore, you will
                not interfere or attempt to interfere with the proper
                functioning of the Distributor Sites or any activities conducted
                on them.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Unauthorized Retrieval of Information
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to obtain or attempt to obtain any materials or
                information through any means not intentionally made available
                through the Distributor Sites. Additionally, you will not modify
                the Distributor Sites in any manner or form, except as
                explicitly allowed for contributing User Content, and will not
                use modified versions of the Distributor Sites to gain
                unauthorized access
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Automated Access Restrictions
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to use any robot, spider, scraper, or other
                automated means to access the Distributor Sites for any purpose
                without express prior written permission from Distributor. This
                includes bypassing any robot exclusion headers or other measures
                used to prevent or restrict access to the Distributor Sites.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Framing and Hidden Tex
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to use framing techniques to enclose any
                trademark, logo, or other Distributor Materials without express
                prior written consent from Distributor. Additionally, you will
                not use any meta tags or other "hidden text" that utilizes
                Distributor’s name or trademarks without Distributor’s express
                prior written consent
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Use of Distributor Branding
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to use any Distributor logos, graphics, or
                trademarks as part of any website links, including but not
                limited to links to the Distributor Sites, without express prior
                written consent from Distributor.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Unsolicited Communications
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to make unsolicited offers, advertisements,
                proposals, or send junk mail, spam, or any other form of
                unsolicited communications to other users of the Distributor
                Sites. This includes, but is not limited to, unsolicited
                advertising, promotional materials, bulk mailing of commercial
                advertising, chain letters, informational announcements, charity
                requests, and petitions for signatures.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Reverse Engineering
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to reverse engineer, decompile, disassemble, or
                otherwise attempt to discover the source code of the Distributor
                Sites or any part thereof, except and only to the extent that
                such activity is expressly permitted by applicable law
                notwithstanding this limitation.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                Modification and Derivative Works
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to modify, adapt, translate, or create derivative
                works based upon the Distributor Sites or any part thereof,
                except and only to the extent that such activity is expressly
                permitted by applicable law notwithstanding this limitation.
              </div>
            </li>
            <li>
              <strong style={{ display: "block", marginBottom: "10px" }}>
                False Identity and Misrepresentation
              </strong>
              <div style={{ marginBottom: "10px" }}>
                You agree not to impersonate any person or entity, or falsely
                state or otherwise misrepresent your affiliation with a person
                or entity, while using the Distributor Sites
              </div>
            </li>
          </ol>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Consequences of Unauthorized Use</strong>
          <Box sx={{ marginY: "10px" }}>
            Unauthorized or prohibited use of the Distributor Sites or the
            Distributor Materials may result in civil liability, criminal
            prosecution, or both under applicable federal, state, and local
            laws.
          </Box>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Account Information</strong>
          <ol>
            <li>
              <strong>Account Creation and Access</strong>
              <div style={{ marginBottom: "10px" }}>
                To subscribe to the services provided under these Terms and
                access certain features of the Distributor Sites, you must
                create an account. By creating an account, you acknowledge,
                consent, and agree that Distributor may access, preserve, and
                disclose your account information and User Content as required
                by law or when Distributor, in good faith, believes that such
                access, preservation, or disclosure is reasonably necessary to:
              </div>
              <ul>
                <li>Comply with legal processes;</li>
                <li>Enforce these Terms;</li>
                <li>
                  Respond to claims that your User Content violates the rights
                  of third parties;
                </li>
                <li>
                  Provide customized features of the Distributor Sites, if
                  applicable;
                </li>
                <li>Respond to your communications with Distributor;</li>
                <li>
                  Protect the rights, property, or personal safety of
                  Distributor, other users, and the public;
                </li>
              </ul>
              <div style={{ marginBottom: "10px" }}>
                You may cancel your account at any time by contacting our
                support team via www.a3tunes.com. Distributor reserves the right
                to reject or immediately terminate your account at its sole
                discretion and without prior notice.
              </div>
            </li>
            <li>
              <strong>Purchase of Products</strong>
              <ol>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Order Placement and Acceptance</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    When you place an order for products on the Distributor
                    Site, you are making an offer to purchase those products
                    under the terms specified in your order. Upon receipt of
                    your order, we will send you an email acknowledging its
                    receipt. However, this email does not constitute acceptance
                    of your offer to purchase. We reserve the right to reject
                    any order for any reason or no reason, subject to compliance
                    with applicable laws.
                  </Box>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Authorization of Payment</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    By submitting an order, you authorize us or our third-party
                    payment processor to charge your provided payment method for
                    purposes of verifying your identity, validating your payment
                    card, obtaining payment authorization, and processing the
                    transaction. You consent to our use of your information,
                    including personally identifiable information, to conduct
                    anti-fraud checks at our discretion. This authorization
                    extends to our third-party payment processor, which may
                    disclose your information to credit reference and fraud
                    prevention service providers, who may retain records of such
                    information
                  </Box>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Product Availability and Inspection</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    Distributor does not guarantee the immediate or continued
                    availability of any product. If we are unable to fulfill
                    your order, you are entitled to a full refund. You are
                    responsible for inspecting your order upon receipt.
                  </Box>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong>Pricing and Fees</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    Prices for products on the Distributor Site are displayed in
                    INR or USD. All charges will be reflected in a final summary
                    before your order is accepted. In addition to product
                    purchases, certain features or services on the Distributor
                    Site may require additional fees, as detailed on relevant
                    parts of the site. You agree to pay all fees and applicable
                    taxes incurred by you or anyone using your account.
                    Distributor reserves the right to revise pricing at any
                    time. You also agree to pay A3 Tunes a 10% platform and
                    conveyance fee on total revenue generated from your User
                    Content. All fees and charges are payable in accordance with
                    the payment terms in effect at the time the fee or charge
                    becomes payable. If there is a dispute regarding fees,
                    products, or services, or if your User Content fails to meet
                    the requirements outlined in the Representations and
                    Warranties section, your account may be terminated without
                    notice. Except as provided in applicable return or
                    cancellation policies, all fees and charges are
                    non-refundable. You are fully liable for all charges to your
                    account, including any unauthorized charges.
                  </Box>
                </li>
              </ol>
            </li>
            <li>
              <strong>Password Security</strong>
              <Box sx={{ marginBottom: "10px" }}>
                If you register for an account, you will be required to create a
                password. You are responsible for maintaining the
                confidentiality of your password and account and for restricting
                access to your computer. You agree to accept responsibility for
                all activities that occur under your account or password. If you
                suspect that your account is no longer secure (e.g., due to
                loss, theft, or unauthorized use of your account ID or
                password), you must notify Distributor immediately, within 24
                hours. You are solely liable for losses incurred by you,
                Distributor, or others due to any unauthorized use of your
                account.
              </Box>
            </li>
            <li>
              <strong>Change of Residency for Tax Purposes</strong>
              <ol type="1">
                <li>
                  <strong>Notification of Residency Change</strong>
                  <p> If you:</p>
                  <ol type="a">
                    <li>
                      Change your residency to another jurisdiction or cease to
                      be a citizen of the country you entered while accessing
                      the Distributor Sites, resulting in a change in your tax
                      residency, or
                    </li>
                    <li>
                      Change your residency to or become a citizen of India,
                      affecting your tax residency,
                    </li>
                  </ol>
                  you must notify Distributor within thirty (30) days of such
                  change. The notification must include your full name, new
                  address, date of residency change, telephone number, email
                  address, and, if applicable, your Distributor account
                  username. Notification by email is sufficient.
                </li>
                <li>
                  <strong>Assignment of Terms</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    You acknowledge that a change in tax residency, as described
                    above, may result in the assignment of these Terms from A3
                    Tunes to A3 Labels, or vice versa. Consequently, you may no
                    longer have a contractual relationship with the same entity
                    with which you initially entered into the Terms. You further
                    acknowledge that A3 Tunes and A3 Labels are independent
                    services, and each is not liable for the actions or
                    omissions of the other, regardless of when they occur
                    relative to your contractual relationship with either
                    service.
                  </Box>
                </li>
              </ol>
            </li>
            <li>
              <strong>Dealings with Advertisers and Other Users</strong>
              <Box sx={{ marginBottom: "10px" }}>
                Your correspondence or business dealings with advertisers or
                other users found through the Distributor Sites are solely
                between you and the respective advertiser or user. Distributor
                is not responsible or liable for any loss or damage incurred as
                a result of such dealings or the presence of such advertisers or
                users on the Distributor Sites
              </Box>
            </li>
            <li>
              <strong>Service Availability</strong>
              <Box sx={{ marginBottom: "10px" }}>
                Distributor reserves the right to modify or discontinue any
                media, web communities, products, or services available on the
                Distributor Sites at any time without notice. The media,
                products, or services on the Distributor Sites may become
                outdated, and Distributor is under no obligation to update these
                materials.
              </Box>
            </li>
            <li>
              <strong>Feedback</strong>
              <Box sx={{ marginBottom: "10px" }}>
                You agree that any feedback, analysis, suggestions, or comments
                you provide to Distributor (collectively, "Feedback") will
                become the property of Distributor. In consideration of
                Distributor providing access to the Distributor Sites free of
                charge, or in cases where Feedback is provided using features
                that require a fee, you hereby assign to Distributor all rights,
                title, and interest, including intellectual property rights, in
                and to the Feedback. Distributor is granted the perpetual,
                irrevocable, and worldwide right to use, copy, display, perform,
                translate, modify, license, sublicense, and otherwise exploit
                the Feedback in any manner or media, now known or hereafter
                devised, without remuneration or credit to you. You represent
                and warrant that you have the right to make this assignment and
                that the Feedback does not infringe any third-party intellectual
                property rights. Notwithstanding this assignment, Distributor
                grants you a non-exclusive, non-transferable, non-
                sublicensable, worldwide, perpetual, and irrevocable license to
                use the Feedback for your personal, non-commercial purposes that
                do not compete with Distributor's use of such Feedback.
              </Box>
            </li>
            <li>
              <strong>Other Agreements</strong>
              <Box sx={{ marginBottom: "10px" }}>
                You acknowledge that in providing the Services and processing
                payments, Distributor may enter into agreements with various
                third parties, including but not limited to digital service
                providers and payment processors. The selection of these third
                parties is at the sole discretion of Distributor. These Terms
                are subject to any applicable terms and conditions of such
                agreements. You acknowledge that certain digital service
                providers may require your audiovisual recordings to be
                available on their platforms in specific formats and timelines
                relative to their availability elsewhere. Failure to meet these
                requirements may result in the discontinuation of your
                recordings on their platforms. Upon written request, Distributor
                will provide you with the specifics of such requirements.
              </Box>
            </li>
            <li>
              <strong>Bar Codes and Universal Product Codes (UPCs)</strong>
              <Box sx={{ marginBottom: "10px" }}>
                Distributor will provide you with free bar codes and Universal
                Product Codes (UPCs) for your use only. These codes may not be
                transferred or resold. If you transfer or resell these codes,
                Distributor will charge you INR 2,000 or the equivalent in any
                other currency per bar code or UPC, plus any revenue generated
                from such transfer or resale. Distributor may deduct these
                charges from any payments owed to you or charge the amounts to
                any alternative payment method you provide, such as PayPal,
                Payoneer, or a credit/debit card
              </Box>
            </li>
            <li>
              <strong>Terms and Conditions Violations; Termination</strong>
              <Box sx={{ marginBottom: "10px" }}>
                You agree that Distributor may terminate your account or your
                use of the Distributor Sites at any time, for any reason, or for
                no reason, at Distributor’s sole discretion. This includes the
                removal of all or any part of your account. Your access to the
                Distributor Sites or any part of it may be terminated without
                prior notice. Distributor shall not be liable to you or any
                third party for any such termination. These remedies are in
                addition to any other legal or equitable remedies available to
                Distributor.
              </Box>
            </li>
            <li>
              <strong>Indemnification; Hold Harmless</strong>
              <Box sx={{ marginBottom: "10px" }}>
                You agree to indemnify, defend, and hold harmless Distributor,
                including its parent companies, subsidiaries, affiliates, and
                any related entities (including those with substantially common
                ownership), along with their respective officers, directors,
                employees, agents, and representatives, from and against any and
                all claims, losses, obligations, damages, liabilities, costs,
                debts, and expenses (including reasonable attorneys’ fees)
                arising out of or related to:
              </Box>
              <ol type="a">
                <li>
                  <strong>Your Use or Misuse of the Distributor Sites:</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    Any claim resulting from your use or misuse of the
                    Distributor Sites, including but not limited to unauthorized
                    or unlawful activities conducted through your account
                  </Box>
                </li>
                <li>
                  <strong>Violation of These Terms:</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    Any breach or violation of these Terms by you, including any
                    of the representations, warranties, and covenants made by
                    you under this agreement.
                  </Box>
                </li>
                <li>
                  <strong>Violation of Third-Party Rights:</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    Any claim that your User Content infringes or violates any
                    intellectual property rights, privacy rights, or other
                    rights of any third party.
                  </Box>
                </li>
                <li>
                  <strong>Breach of Representations and Warranties:</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    Any breach by you of the representations, warranties, and
                    covenants you have made under these Terms
                  </Box>
                </li>
                <li>
                  <strong>Unauthorized Use of Your Account:</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    Any unauthorized use of your account that is not caused by
                    Distributor, including but not limited to unauthorized
                    access to your account due to your failure to maintain the
                    confidentiality of your account information.
                  </Box>
                </li>
              </ol>
            </li>
          </ol>
          <Box sx={{ marginBottom: "10px" }}>
            Distributor reserves the right, at its own expense, to assume the
            exclusive defense and control of any matter otherwise subject to
            indemnification by you. In such a case, you agree to cooperate fully
            with Distributor in asserting any available defenses.
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            You also agree not to settle any claim, action, or proceeding giving
            rise to your indemnification obligations without the prior written
            consent of Distributor. Distributor will use reasonable efforts to
            notify you of any such claim, action, or proceeding as soon as it
            becomes aware of it
          </Box>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Disclaimers; No Warranties:</strong>

          <ol type="1">
            <li>
              <strong>Acknowledgment:</strong>
              <div style={{ marginBottom: "10px" }}>
                You expressly acknowledge that, for the purposes of this Section
                20 and Sections 21 and 22 below, the term “Distributor” includes
                Distributor's parent companies, subsidiaries, affiliates, and
                other related entities (including those with substantially
                common ownership), as well as each of their respective officers,
                directors, employees, shareholders, members, agents, and
                subcontractors.
              </div>
            </li>
            <li>
              <strong>No Warranties:</strong>
              <div style={{ marginBottom: "10px" }}>
                To the fullest extent permitted by applicable law, Distributor
                disclaims all warranties, whether statutory, express, or
                implied, related to the Distributor Sites and any User Content,
                Third Party Content, goods, services, or features sold or
                otherwise made available to users on the Distributor Sites. This
                includes, but is not limited to, implied warranties of
                merchantability, fitness for a particular purpose, title, and
                non- infringement. No advice or information, whether oral or
                written, obtained by you from Distributor or through the
                Distributor Sites, will create any warranty not expressly stated
                herein
              </div>
            </li>
            <li>
              <strong>Website Operation and Third-Party Content:</strong>
              <div style={{ marginBottom: "10px" }}>
                Distributor does not warrant that the Distributor Sites,
                Distributor Materials, Third-Party Content, or any other
                information offered on or through the Distributor Sites or any
                reference sites will be uninterrupted, or free of errors,
                viruses, or other harmful components, nor does Distributor
                warrant that any such issues will be corrected
              </div>
            </li>
            <li>
              <strong>Accuracy:</strong>
              <div style={{ marginBottom: "10px" }}>
                Distributor does not warrant or make any representations
                regarding the accuracy, reliability, or correctness of the use,
                or results of the use of, the Distributor Sites or any reference
                sites
              </div>
            </li>
            <li>
              <strong>Harm to Your Computer:</strong>
              <div style={{ marginBottom: "10px" }}>
                You understand and agree that your use, access, downloading, or
                otherwise obtaining of information, materials, or data through
                the Distributor Sites (including RSS feeds) or any reference
                sites is done at your own discretion and risk. You will be
                solely responsible for any damage to your property (including
                your computer system) or loss of data that results from the use
                or download of, or other access to, such material or data.
              </div>
            </li>
          </ol>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Limitation of Liability and Damages</strong>

          <ol type="1">
            <li>
              <strong>Limitation of Liability</strong>
              <div style={{ marginBottom: "10px" }}>
                Subject always to Section 22.1, under no circumstances and under
                no legal theory, including but not limited to negligence, shall
                Distributor or its third-party partners, licensors, or suppliers
                be liable for any special, indirect, incidental, consequential,
                or exemplary damages (including, without limitation, loss of
                profits, data, or use, or the cost of cover) arising out of or
                related to these Terms. This includes damages resulting from
                your use of, or the inability to use, the Distributor Materials
                and User Content on the Distributor Sites or any reference
                sites, the Distributor Sites itself, or any other interactions
                with Distributor, even if Distributor has been advised of the
                possibility of such damages.
              </div>
            </li>
            <li>
              <strong>Limitation of Damages</strong>
              <div style={{ marginBottom: "10px" }}>
                Subject always to Section 22.1, in no event shall the total
                liability of Distributor or its third-party partners, licensors,
                or suppliers to you for all damages, losses, and causes of
                action arising out of or related to these Terms or your use of
                the Distributor Sites (whether in contract, tort, including
                negligence, warranty, or otherwise) exceed the greater of One
                Hundred Dollars (USD $100) or one-half of the annual fees paid
                by you to Distributor (if applicable) in the twelve (12) months
                immediately preceding the date that such claim arose.
              </div>
            </li>
            <li>
              <strong>Third-Party Products and Services</strong>
              <div style={{ marginBottom: "10px" }}>
                Some Users may use the Distributor Sites to market products
                and/or services. The above limitations shall also apply to
                damages incurred by reason of any such products or services
                marketed, sold, or provided by Users or other third parties,
                excluding Distributor, and received by you through or advertised
                on the Distributor Sites or third-party sites, including without
                limitation any reference sites.
              </div>
            </li>
          </ol>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Limitations by Applicable Law; Basis of the Bargain</strong>

          <ol type="1">
            <li>
              <strong>Limitations by Applicable Law</strong>
              <div style={{ marginBottom: "10px" }}>
                Certain jurisdictions do not allow limitations on implied
                warranties or the exclusion or limitation of certain damages. If
                you reside in such a jurisdiction, some or all of the above
                disclaimers, exclusions, or limitations may not apply to you,
                and you may have additional rights. The limitations or
                exclusions of warranties, remedies, or liability contained in
                these terms apply to you to the fullest extent such limitations
                or exclusions are permitted under the laws of the jurisdiction
                in which you are located. Nothing in these terms attempts to
                exclude or limit Distributor’s liability for:
                <ol type="a">
                  <li>Fraud or fraudulent misrepresentation;</li>
                  <li>Death or personal injury caused by negligence; or</li>
                  <li>
                    Any other liability which cannot be excluded or limited by
                    applicable law.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <strong>Basis of the Bargain</strong>
              <div style={{ marginBottom: "10px" }}>
                You acknowledge and agree that Distributor has offered its
                products and services and entered into these Terms in reliance
                upon the warranty disclaimers and the limitations of liability
                set forth herein. These warranty disclaimers and limitations of
                liability reflect a reasonable and fair allocation of risk
                between you and Distributor, and they form an essential basis of
                the bargain between you and Distributor. You further acknowledge
                and agree that Distributor would not be able to provide the
                Distributor Sites to you on an economically reasonable basis
                without these limitations.
              </div>
            </li>
          </ol>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Copyright Compliance</strong>

          <ol type="1">
            <li>
              <strong>Infringement Claims</strong>
              <ul>
                <li>
                  <strong>Submission of Claims:</strong>
                  <Box sx={{ marginBottom: "10px" }}>
                    A3 Tunes or A3 Labels operates the A3 Tunes Site. If you are
                    a copyright owner or an agent thereof, and believe that any
                    User Content or other Third Party Content infringes upon
                    your copyrights or intellectual property rights, you may
                    submit a notification pursuant to the Indian Copyright Act,
                    1957, by providing our Copyright Agent with the following
                    information in writing:
                    <ol>
                      <li>
                        A physical or electronic signature of a person
                        authorized to act on behalf of the owner of an exclusive
                        right that is allegedly infringed.
                      </li>
                      <li>
                        Identification of the copyrighted work claimed to have
                        been infringed, or, if multiple copyrighted works are
                        covered by a single notification, a representative
                        listing of such works, or a link or URL to all such
                        works.
                      </li>
                      <li>
                        The location of the material that is claimed to be
                        infringing or to be the subject of infringing activity
                        on the Distributor Sites, including the link or URL for
                        all such material on the Distributor Sites.
                      </li>
                      <li>
                        Information reasonably sufficient to permit Distributor
                        to contact the complaining party, such as an address,
                        telephone number, and, if available, an electronic mail
                        address at which the complaining party may be contacted
                      </li>
                      <li>
                        A statement that the complaining party has a good faith
                        belief that use of the material in the manner complained
                        of is not authorized by the copyright owner, its agent,
                        or the law.
                      </li>
                      <li>
                        A statement that the information in the notification is
                        accurate, and under penalty of perjury, that the
                        complaining party is authorized to act on behalf of the
                        owner of an exclusive right that is allegedly infringed.
                      </li>
                    </ol>
                  </Box>
                </li>
                <li>
                  <strong>Copyright Agent Contact:</strong>
                  <div style={{ marginBottom: "10px" }}>
                    Notifications of claimed infringement should be directed to
                    the Copyright Agent designated on the websites of A3 Tunes
                    or A3 Labels. Failure to include all of the above
                    information, especially specific information about where
                    infringing content may be found, will result in a delay in
                    processing your notification and may require you to repeat
                    some or all of the process.
                  </div>
                </li>
              </ul>
            </li>
            <li>
              <strong>Notice and Takedown</strong>
              <ul>
                <li>
                  <strong>Action Upon Receiving Notification:</strong>
                  <div style={{ marginBottom: "10px" }}>
                    Upon receiving proper notification of claimed copyright
                    infringement, Distributor will respond expeditiously by
                    removing or disabling access to the material that is claimed
                    to be infringing or to be the subject of infringing
                    activity. Distributor will also comply with appropriate
                    provisions of the DMCA in the event a counter-notification
                    is received.
                  </div>
                </li>
                <li>
                  <strong>Account Termination::</strong>
                  <div style={{ marginBottom: "10px" }}>
                    At its discretion, Distributor may deny access to the
                    Distributor Sites by, or disable and/or terminate the
                    accounts of, Users who may be infringers
                  </div>
                </li>
              </ul>
            </li>
            <li>
              <strong>Copyright Counter-Notices</strong>
              <ul>
                <li>
                  <strong>Filing a Counter-Notification:</strong>
                  <div style={{ marginBottom: "10px" }}>
                    If content you posted on the Distributor Sites was removed
                    for copyright or intellectual property infringement, and you
                    would like to dispute that removal, you must file a
                    counter-notification with our Copyright Agent at the address
                    set forth above. This process is governed by Section 512(g)
                    of the DMCA
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    To file a counter-notification, provide the following in
                    your written communication:
                  </div>
                  <ol>
                    <li>
                      The specific URLs of, or other information sufficient to
                      allow us to identify, material that Distributor has
                      removed or to which Distributor has disabled access.
                    </li>
                    <li>
                      Your full name, address, telephone number, email address,
                      and, if you are a registered User, the User name of your
                      Distributor account.
                    </li>
                    <li>
                      A statement that you consent to the jurisdiction of India
                      and that you will accept service of process from the
                      person who provided notification to our Copyright Agent or
                      an agent of such person
                    </li>
                    <li>
                      The following statement: “I swear, under penalty of
                      perjury, that I have a good faith belief that the material
                      was removed or disabled as a result of a mistake or
                      misidentification of the material to be removed or
                      disabled."
                    </li>
                    <li>
                      A signature. If you are providing notice by email, a
                      scanned physical signature or a valid electronic signature
                      will be accepted.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Processing the Counter-Notification: </strong>
                  <div style={{ marginBottom: "10px" }}>
                    After receiving your counter-notification, Distributor will
                    forward it to the party who submitted the original claim of
                    copyright infringement. Note that when Distributor forwards
                    the counter-notification, it includes any of your personally
                    identifiable information set forth in the counter-
                    notification. By submitting a counter-notification, you
                    consent to having such Identifying Information revealed in
                    this way. Distributor will not forward the
                    counter-notification to any party other than the original
                    claimant
                  </div>
                </li>
                <li>
                  <strong>Next Steps: </strong>
                  <div style={{ marginBottom: "10px" }}>
                    The claimant must then notify Distributor within ten (10)
                    days that they have filed an action seeking a court order to
                    restrain you from engaging in infringing activity relating
                    to the material on the Distributor Sites. If such
                    notification is received, Distributor will be unable to
                    restore the items. If not, Distributor may, but is not
                    obligated to, reinstate the disputed item(s)
                  </div>
                </li>
                <li>
                  <strong>Important Considerations: </strong>
                  <div style={{ marginBottom: "10px" }}>
                    If you reside outside of India, be aware that filing a
                    counter-notice may lead to legal proceedings between you and
                    the complaining party to determine ownership. There may be
                    adverse legal consequences in India if you make a false or
                    bad faith allegation by using this process. Distributor
                    enforces a policy that provides for the termination, in
                    appropriate circumstances, of access privileges for Users
                    who make a false or bad faith allegation of infringement or
                    a false or bad faith counter- notification in response to a
                    notice of infringement. If you are unsure whether content
                    you posted on the Distributor Sites is being infringed, or
                    whether to file a counter-notification, it is strongly
                    recommended that you contact a lawyer knowledgeable in
                    Indian laws (as applicable).
                  </div>
                </li>
              </ul>
            </li>
            <li>
              <strong>Disclaimer</strong>
              <ul>
                <li>
                  Distributor is not your attorney, and the information
                  presented here is not legal advice. This information is
                  provided for informational purposes only.
                </li>
              </ul>
            </li>
          </ol>
        </Typography>
        <Divider sx={{ color: "white", width: "100%", borderBottomWidth: 5 }} />
        <Typography component="p" sx={typographyStyle}>
          <strong>Miscellaneous</strong>
          <ol>
            <li>
              <strong>Notice</strong>
              <ul>
                <li>
                  Distributor may provide you with notices, including changes to
                  these Terms, via email, regular mail, or postings on the
                  Distributor Sites. If notice is sent by email or mail, it will
                  be directed to the email or postal address provided in your
                  account information. It is your responsibility to keep this
                  information current. Notice will be deemed given 24 hours
                  after an email is sent, unless Distributor is notified that
                  the email address is invalid. For postal mail, notice is
                  deemed given three (3) days after the date of mailing. You may
                  provide notices to Distributor only by mail to the address
                  indicated in subsection (11) below.
                </li>
              </ul>
            </li>
            <li>
              <strong>Governing Law</strong>
              <ul>
                <li>
                  These Terms shall be governed by and construed in accordance
                  with the laws of India, without giving effect to any
                  principles of conflicts of law. For clarity, the Uniform
                  Computer Information Transactions Act does not apply to these
                  Terms.
                </li>
              </ul>
            </li>
            <li>
              <strong>Jurisdiction</strong>
              <ul>
                <li>
                  You agree that any legal action or proceeding arising out of
                  or relating to these Terms or the Distributor Sites shall be
                  filed exclusively in Bengaluru, Karnataka, India. You consent
                  to the personal and exclusive jurisdiction of these courts for
                  litigation purposes. You also agree not to file or participate
                  in a class action against us. In any legal action or
                  proceeding to enforce any right or obligation under this
                  agreement, you waive any right to a trial by jury.
                </li>
              </ul>
            </li>
            <li>
              <strong>Claims</strong>
              <ul>
                <li>
                  Any cause of action brought by you arising out of or related
                  to the Distributor Sites must commence within six (6) months
                  after the cause of action accrues. Otherwise, such cause of
                  action is permanently barred. If A3 Labels or A3 Tunes
                  suspects, has reason to believe, or is notified of any actions
                  or circumstances that may:
                  <ol>
                    <li>
                      Compromise or endanger the health, well-being, or safety
                      of any person,
                    </li>
                    <li>
                      Cause or lead to damage to persons or property (tangible
                      or intangible)
                    </li>
                    <li>
                      Adversely affect, infringe upon, or misappropriate the
                      rights of others,
                    </li>
                    <li>
                      Harass or interfere with any other user or person, firm,
                      or enterprise,
                    </li>
                    <li>
                      Interfere with or bypass our security or protective
                      measures,
                    </li>
                    <li>Breach or violate these Terms, or</li>
                    <li>Violate any law or regulation,</li>
                  </ol>
                </li>
                <li>
                  We reserve the right, cumulatively with all other rights and
                  remedies available at law, equity, or under this agreement, to
                  report and provide information to any regulatory and law
                  enforcement authorities and take any legally permitted action.
                  You may only bring a cause of action against the specific
                  entity (A3 Tunes or A3 Labels) with which you had a
                  contractual relationship at the time the event giving rise to
                  the cause of action occurred
                </li>
              </ul>
            </li>
            <li>
              <strong>Waiver</strong>
              <ul>
                <li>
                  A provision of these Terms may only be waived by a written
                  instrument executed by the Party entitled to the benefit of
                  that provision. Failure by either Party to exercise or enforce
                  any right or provision of these Terms does not constitute a
                  waiver of such right or provision.
                </li>
              </ul>
            </li>
            <li>
              <strong>Severability</strong>
              <ul>
                <li>
                  If any provision of these Terms is found to be unlawful, void,
                  or unenforceable, that provision will be severable from these
                  Terms and will not affect the validity and enforceability of
                  any remaining provisions.
                </li>
              </ul>
            </li>
            <li>
              <strong>Assignment</strong>
              <ul>
                <li>
                  You may not transfer or assign these Terms, or any rights and
                  licenses granted hereunder. Distributor may transfer or assign
                  these Terms, and any rights and licenses granted hereunder, to
                  an affiliate or any third party without restriction. Any
                  assignment by you in violation of these Terms will be void.
                </li>
              </ul>
            </li>
            <li>
              <strong>No Relationship</strong>
              <ul>
                <li>
                  No joint venture, partnership, employment, or agency
                  relationship exists between you and Distributor as a result of
                  these Terms or your use of the Distributor Sites. By
                  submitting User Content or Third Party Content, you
                  acknowledge that no confidential, fiduciary, contractually
                  implied, or other relationship is created between you and
                  Distributor other than as set forth in these Terms
                </li>
              </ul>
            </li>
            <li>
              <strong>Survival</strong>
              <ul>
                <li>
                  The provisions of Sections 5, 12, 15, 18, 19, 20, 21, 22, 23,
                  and 24 (excluding your duty to update account information),
                  and any other provisions of these Terms that by their nature
                  would survive termination, will survive any termination of
                  these Terms or your account, whether by you or by Distributor.
                </li>
              </ul>
            </li>
            <li>
              <strong>Headings</strong>
              <ul>
                <li>
                  The headings used in these Terms are for convenience only and
                  do not constitute a part of these Terms. They shall not be
                  deemed to limit or affect any of the provisions hereof.
                </li>
              </ul>
            </li>
            <li>
              <strong>Entire Agreement</strong>
              <ul>
                <li>
                  These Terms constitute the entire agreement between you and
                  Distributor regarding the subject matter hereof and supersede
                  all prior communications, representations, understandings, and
                  agreements, either oral or written, with respect to said
                  subject matter. This agreement may not be modified except in
                  writing signed by both parties, or by a change to these Terms
                  made by Distributor as set forth in Section 3.
                </li>
              </ul>
            </li>
          </ol>
        </Typography>
        <Typography
          sx={[{ marginY: "10px", textAlign: "center" }, typographyStyle]}
        >
          <strong>
            Copyright © {new Date().getFullYear()} [A3 Tunes/A3 Labels]. All
            Rights Reserved
          </strong>
        </Typography>
      </CustomScrollBox>
    </>
  );
};

export default TermsandConditions;
