import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";
import UserAccessModel from "./UserAccessModel";

export default class UserDataModel extends BaseModel {
  //USER data model
  access = UserAccessModel;
  attributes = {};
  createdTimestamp = "";
  disableableCredentialTypes = [];
  email = "";
  emailVerified = boolean;
  enabled = boolean;
  firstName = "";
  id = "";
  lastName = "";
  notBefore = 0;
  requiredActions = [];
  totp = "";
  username = "";
  roles = [
    {
      role_id: "",
      role_name: "",
    },
  ];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
