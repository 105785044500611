import React, { forwardRef, useEffect, useState } from "react";
import { fieldToTextField } from "formik-material-ui";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { theme } from "../../views/App";
import { makeStyles } from "@material-ui/core";

const CustomListbox = forwardRef((props, ref) => (
  <Paper
    {...props}
    ref={ref}
    sx={{
      // maxHeight: 200,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#b0d2da",
        borderRadius: "3px",
        "&:hover": {
          backgroundColor: "#85bac6",
        },
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
    }}
  />
));

const AutoCompleteMultiSelect = ({
  disabled,
  readOnly,
  disableCloseOnSelect,
  diasbleSvg,
  disableClearable,
  textFieldProps,
  size,
  limitTags,
  fieldValue,
  renderOption,
  freeSolo,
  filterOptions,
  onChange,
  margin,
  padding,
  openProp,
  onInputChange,
  onKeyDown,
  inputValue,
  defaultValue,
  height,
  ref,
  ...props
}) => {
  const useStyles = makeStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      zIndex: 1,
      overflow: "hidden",
    },
  });

  const {
    form: { setTouched, setFieldValue, touched },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    openProp ? openProp.length <= 2 && setOpen(true) : setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   openProp && openProp.length === 3 && setOpen(false);

  //   console.log("openProp", openProp, open);
  // }, [openProp]);

  return (
    <Autocomplete
      classes={{
        paper: classes.root,
      }}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onKeyDown={onKeyDown}
      ref={ref}
      style={{ zIndex: 1 }}
      disableCloseOnSelect={disableCloseOnSelect}
      defaultValue={defaultValue}
      disableClearable={disableClearable}
      readOnly={readOnly ? readOnly : false}
      {...props}
      {...field}
      freeSolo={freeSolo}
      renderOption={renderOption}
      ListboxComponent={CustomListbox}
      limitTags={limitTags ? limitTags : 3}
      disabled={disabled}
      onChange={
        onChange
          ? onChange
          : (_, value) => {
              setFieldValue(name, value);
            }
      }
      onInputChange={onInputChange}
      filterOptions={filterOptions}
      inputValue={inputValue}
      // isOptionEqualToValue={(option, value) =>
      //   //setting value property to custom value
      //   option.project_name === value.project_name
      // }
      // //setting custom label to custom value
      // getOptionLabel={(option) => option.project_name}
      onBlur={() => setTouched({ ...touched, [name]: true })}
      // getoptionselected={(item, current) => item.value === current.value}
      renderInput={(props) => (
        <TextField
          InputLabelProps={{ shrink: true }}
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
          size={size}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            svg: { display: diasbleSvg ? diasbleSvg : "block" },
            margin: margin,
            padding: padding,
            height: height ? height : "100%",
            backgroundColor:"#808080",
          }}
        />
      )}
    />
  );
};

export default AutoCompleteMultiSelect;
