import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grid,
  Typography,
  Text,
  Link,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { theme } from "../App";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { useNavigate, useLocation } from "react-router";
import environment from "environment";
import { Field, Form, Formik } from "formik";
import MobileNumberField from "../../components/muiTextField/MobileNumberField";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiButton from "../../components/muiButton/MuiButton";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import RegisterAction from "../../stores/register/RegisterAction";
import LoginBackground from "../../components/loginBackground/LoginBackground";
import Tunes from "../../Images/A3Tunes.webp";
import TermsAndConditions from "../../components/termsandconditions/TermsAndConditions";
import AutoCompleteFlagDisplay from "../../components/autoCompleteComponent/AutoCompleteFlagDisplay";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import MuiSnackbarAlert from "../../components/muiSnackbar/MuiSnackbarAlert";
import AcceptTermsDailogue from "./AcceptTermsDailogue";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const ValidationDailoge = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [termsAndconditionsopen, setTermsandConditionsopen] = useState(false);
  const [phoneCodes, setPhoneCodes] = useState([]);
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const action = location.state?.action || "";
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarstaus, setSnackbarStatus] = useState("warning");
  const [postObj, setPostObj] = useState(null);
  // console.log("validatedataregisster",action,location.state?.phoneNumber)

  // Set initial values based on action
  // if (action === "mobile_no") {
  //   initial = { phone_number: "", country_code: defaultPhoneCode || null };
  // }
  //  else if(action !== "mobile_no") {
  //   initial = { phone_number: "", otp: "" };
  // }

  const phoneRegExp = /^\d{10}$/;
  const defaultPhoneCode = {
    code: "IN",
    dial_code: "+91",
    name: "India",
  };
  function fetchPhoneCodes() {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
    console.log("data", access_token, environment.api.baseApi);
    fetch(`${environment.api.baseApi}/phone_codes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response) {
          response.country_codes && setPhoneCodes(response.country_codes);
        }
      });
  }

  // useEffect(() => {
  //   fetchRequestUserprofile();
  // }, []);
  const sortedPhoneCode = phoneCodes.sort((a, b) => {
    if (a.dial_code === "+91") return -1; // Move "+91" to the beginning
    if (b.dial_code === "+91") return 1;
    return 0; // Maintain the order for the rest
  });

  useEffect(() => {
    setLoading(false);
    fetchPhoneCodes();
  }, []);
  useEffect(() => {
    const handleResizeLogin = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as per your requirement
    };

    // Initial check on mount
    handleResizeLogin();

    // Listen to window resize events
    window.addEventListener("resize", handleResizeLogin);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResizeLogin);
  }, []);
  let initial = {};
  initial = {
    phone_number: "",
    country_code: defaultPhoneCode || null,
    enabled: false,
    credentials: [
      {
        type: "password",
        value: "",
      },
    ],
    confirm_password: "",
    document_consent: {
      terms_and_condition: true,
      privacy_and_policy: true,
    },
  };
  return (
    <>
      <Formik
        initialValues={initial}
        validate={(values) => {
          let errors = {};
          const phoneWithoutSpaces = values.phone_number?.replace(/\s/g, "");

          if (!values.phone_number) {
            errors.phone_number = "Phone Number is Required";
          } else if (!phoneRegExp.test(phoneWithoutSpaces)) {
            errors.phone_number = "Phone number should be exactly 10 digits";
          }
          if (!values.country_code) {
            errors.country_code = "Country Code is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          if (action === "update_phone") {
            let Obj = {
              phone_number: String(values.phone_number.replace(/\s/g, "")),
              country_code: values.country_code.dial_code,
            };

            const callBack = (res) => {
              console.log("results check", res);
              if (res?.status === "success") {
                console.log("results check1", res);

                let Obj = {
                  phone_number: String(values.phone_number.replace(/\s/g, "")),
                  country_code: values.country_code.dial_code,
                };

                const headers = {
                  "X-One-Time-Token": location.state.token,
                };

                setSnackbarMessage(res.message);
                setSnackbarStatus("success");
                setSnackbarOpen(true);

                setTimeout(() => {
                  navigate("/ValidateOtp", {
                    state: { action: "update_phone_post", Obj, headers },
                  });
                }, 1500);
              } else {
                setSnackbarStatus("warning");
                setSnackbarMessage(res?.raw?.data?.message);
                // setSnackbarOpen(true);
              }
            };

            props.dispatch(
              RegisterAction.requestPostMobileNumber(
                JSON.stringify(Obj),
                callBack
              )
            );
          } else if (action === "forgot_password") {
            let postObj = {
              phone_number: String(values.phone_number.replace(/\s/g, "")),
              country_code: values.country_code.dial_code,
              flow_type: "2",
            };

            const callBack = (res) => {
              if (res?.status === "success") {
                setSnackbarStatus("success");
                setSnackbarMessage(res.message);
                setSnackbarOpen(true);
                setSnackbarStatus(res.status);
                let Obj = {
                  phone_number: String(values.phone_number.replace(/\s/g, "")),
                  country_code: values.country_code.dial_code,
                };

                setTimeout(() => {
                  navigate("/ValidateOtp", {
                    state: {
                      action: "forgot_password",
                      Obj,
                    },
                  });
                }, [3000]);
              } else {
                setSnackbarStatus("warning");
                setSnackbarMessage(res?.raw?.data?.message);
                // setSnackbarOpen(true);
              }
            };

            props.dispatch(
              RegisterAction.requestPostMobileNumber(
                JSON.stringify(postObj),
                callBack
              )
            );
          } else {
            let postObj = {
              phone_number: String(values.phone_number.replace(/\s/g, "")),
              country_code: values.country_code.dial_code,
              document_consent: {
                terms_and_condition: false,
                privacy_and_policy: false,
              },
              credentials: [
                {
                  type: "password",
                  value: "",
                },
              ],
              confirm_password: "",
            };
            console.log("postobjctnumber", postObj);
            setPostObj(postObj);
            setTermsandConditionsopen(true);
            // navigate("/AcceptTermsDailogue", {
            //   state: { action: "mobile_otp", postObj },
            // });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
        }) => (
          <Form
            style={{
              width: "100%",
              height: action === "update_phone" ? "85vh" : "100vh",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Grid
                container
                style={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                }}
              >
                {action === "update_phone" ? null : (
                  <LoginBackground isMobile={isMobile} />
                )}
                <Grid
                  item
                  xs={12}
                  md={isMobile ? 12 : action === "update_phone" ? 12 : 6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.background.loginbackground,
                    height: "100%",
                    overflowY: "auto",
                    // pt: { xs: 16, sm: 10, md: 18, lg: 10, xl: 10 },
                    // pb: { xs: 4, sm: 4, md: 2, lg: 6, xl: 8 },
                  }}
                >
                  {isMobile && (
                    <img
                      src={Tunes}
                      alt="Tunes"
                      style={{
                        width: "15%",
                        height: "auto",
                        objectFit: "contain",
                        marginBottom: "30px",
                      }}
                    />
                  )}
                  <Grid
                    container
                    item
                    xs={8}
                    xl={8}
                    lg={8}
                    md={8}
                    sm={8}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      // mt: 2.5,
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={9}
                      sx={{
                        textAlign: "left",
                        color: theme.palette.text.createaccountcolor,
                        fontSize: 38,
                      }}
                      // className="bg-warning"
                    >
                      {action === "update_phone" || action === "forgot_password"
                        ? null
                        : "CREATE NEW ACCOUNT"}
                      {action === "update_phone" ||
                      action === "forgot_password" ? null : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                theme.palette.primary.loginbackgroundsecondary,
                              marginBottom: "50px",
                              marginTop: -20,
                            }}
                          >
                            <span style={{ fontSize: "22px" }}>
                              Already have an account?&nbsp;
                            </span>
                            <button
                              type="button"
                              onClick={() => navigate("/")}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                color: theme.palette.primary.main,
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                            >
                              Login
                            </button>
                          </Typography>
                        </div>
                      )}
                      <Box sx={{ flexDirection: "row", display: "flex" }}>
                        <AutoCompleteFlagDisplay
                          value={
                            values.country_code
                              ? values.country_code
                              : defaultPhoneCode
                          }
                          list={sortedPhoneCode ? sortedPhoneCode : []}
                          textFieldProps={{
                            // placeholder: "Country Code",
                            backgroundColor: "transparent",
                            // color: "#ffffff",
                          }}
                          handleChange={(e, value) => {
                            if (value && value.dial_code !== "+91") {
                              e.preventDefault(); // Prevent value update
                              setSnackbarStatus("warning");
                              setSnackbarMessage(
                                "We are not accepting any code other than +91."
                              );
                              setSnackbarOpen(true);
                              return; // Stop further execution
                            }

                            // If the dial code is "+91", set the value
                            if (value && value.dial_code === "+91") {
                              setFieldValue("country_code", value); // Update the selected country code
                            }
                          }}
                          height="55px"
                          // color="#ffffff"
                          disableClearable
                          // defaultValue={defaultPhoneCode}
                          isOptionEqualToValue={(option, value) =>
                            option.code === value.code
                          }
                          getOptionLabel={(option) => `${option.name}`}
                          renderOption={(props, option) => {
                            return (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />

                                <span style={{ fontSize: "12px" }}>
                                  {" "}
                                  ({option.code}) {option.dial_code}
                                </span>
                              </Box>
                            );
                          }}
                          size="small"
                          // width="24%"
                        />
                        <Field
                          as={MobileNumberField}
                          placeholder={"Enter Mobile Number"}
                          id="phone_number"
                          size="small"
                          name="phone_number"
                          fontSize="38px"
                          value={values.phone_number}
                          width="100%"
                          error={
                            Boolean(errors.phone_number) &&
                            Boolean(touched.phone_number)
                          }
                          helperText={
                            Boolean(touched.phone_number) && errors.phone_number
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography style={{ color: "#ffffff" }}>
                                  {values.country_code?.dial_code}
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "450px",
                          height: "1px",
                          backgroundColor: "#adadad",
                          margin: "17px 0px 40px 3px",
                        }}
                      />
                      <MuiButton
                        loading={loading}
                        name={"CONTINUE"}
                        type="submit"
                        width="450px"
                        height="71px"
                        fontweight="bold"
                        fontSize="31px"
                        letterSpacing="1.2px"
                        padding="17px 132px 17px 142px"
                        borderRadius={"12px"}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                px={3}
                py={1}
                sx={{
                  borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
                }}
              >
                <Grid item sx={{ color: theme.palette.text.gray }}></Grid>
                <Grid item sx={{ color: theme.palette.text.gray }}>
                  {props.subTitle}
                </Grid>
              </Grid>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.primary }}
              />
              <Box p={5}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: theme.typography.fontSize.small,
                      sm: theme.typography.fontSize.normal,
                      lg: theme.typography.fontSize.medium,
                    },
                  }}
                >
                  {props?.title}
                </Typography>
                <Grid item lg={12} md={12} sm={12} container pt={5}>
                  <Field
                    as={MuiTextField}
                    variant="outlined"
                    placeholder={"Email Address"}
                    id="mail_address"
                    size="small"
                    name="mail_address"
                    error={
                      Boolean(errors.mail_address) &&
                      Boolean(touched.mail_address)
                    }
                    helperText={
                      Boolean(touched.mail_address) && errors.mail_address
                    }
                    className="col-12"
                  />
                </Grid>

                {props.field2 && (
                  <Grid item lg={12} md={12} sm={12} container pt={5}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder={"OTP"}
                      id="otp"
                      size="small"
                      name="otp"
                      error={Boolean(errors.otp) && Boolean(touched.otp)}
                      helperText={Boolean(touched.otp) && errors.otp}
                      className="col-12"
                    />
                  </Grid>
                )}
              </Box>

              <DialogActions>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent="space-between"
                  alignItems={"center"}
                  // className="bg-warning"
                  mx={4}
                >
                  <Grid item lg={2} md={2} sm={2}></Grid>

                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    container
                    alignItems={"center"}
                    justifyContent="flex-end"
                    gap={2}
                    // className="bg-info"
                  >
                    <Grid item xs={4}>
                      <MuiButton
                        loading={loading}
                        name={"Submit"}
                        type="submit"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MuiButton
                        name={"Cancel"}
                        // loading={loading}
                        width="100%"
                        cancel
                        onClick={() => {
                          props.onClose();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions> */}
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>

      {console.log("snackbarstaus", snackbarstaus)}
      <MuiSnackbarAlert
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        snackbarstaus={snackbarstaus}
      />
      <AcceptTermsDailogue
        postObj={postObj}
        open={termsAndconditionsopen}
        onClose={() => setTermsandConditionsopen(false)}
      />
    </>
  );
};
export default connect(mapDispatchToProps)(ValidationDailoge);
