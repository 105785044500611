import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Upload from "../../views/ReleaseSubPages/Upload";
import Submission from "../../views/ReleaseSubPages/Submission";
import { Analytics, Schedule } from "@mui/icons-material";
import AnalyticsMain from "../../views/analytics/Analytics";
import SubscriptionTabs from "../../views/subscriptionPlans/SubscriptionTabs";
import TracksAnalytics from "../../views/analytics/components/TracksAnalytics";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ReleaseDate from "../../views/ReleaseSubPages/ReleaseDate";
import Release from "../../views/release/Release";
import Tracks from "../../views/ReleaseSubPages/Tracks";
import SignIn from "../../views/Login/SignIn";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import Logout from "../../views/logout/Logout";
import LabelGridView from "../../views/label/LabelGridView";
import ReleaseInformation from "../../views/ReleaseSubPages/ReleaseInformation";
import Promotion from "../../views/ReleaseSubPages/Promotion";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Register from "../../views/register/Register";
import SubscriptionPlans from "../../views/subscriptionPlans/SubscriptionPlans";
import Subscriptions from "../../views/subscriptions/Subscriptions";
import SubscriptionOrders from "../../views/subscriptionOrders/SubscriptionOrders";
import MyPlans from "../../views/myPlans/MyPlans";
import Profile from "../../views/profile/Profile";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DeliveredReleases from "../../views/deliveredReleases/DeliveredReleases";
import SummarizeIcon from "@mui/icons-material/Summarize";
import NotificationList from "../../views/notification/NotificationList";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dashboard from "../../views/dashboard/Dashboard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaymentSuccessful from "../PaymentSuccessful/PaymentSuccessful";
import PaymentFailed from "../PaymentSuccessful/PaymentFailed";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LabelIcon from "@mui/icons-material/Label";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import UploadIcon from "@mui/icons-material/Upload";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReleaseDetails from "../../views/releaseDetails/ReleaseDetails";
import Issues from "../../views/issues/issues";
import DeliveredReleaseDetails from "../../views/deliveredReleases/DeliveredReleaseDetails";
import CurrentPlans from "../../views/myPlans/CurrentPlans";
import UpgradePlans from "../../views/myPlans/UpgradePlans";
import BankInformation from "../../views/profile/BankInformation";
import A3Reports from "../../views/reports/RevenueAnalytics";
import RevenueReport from "../../views/revenue/RevenueReport";
import RevenueClaim from "../../views/revenueClaim/RevenueClaim";
import NetRevenueByMonth from "../../views/monthwiseRevenue/NetRevenueByMonth";
import ValidationDailoge from "../../views/Login/ValidationDailoge";
import ValidationOtp from "../../views/Login/ValidationOtp";
import AcceptTermsDailogue from "../../views/Login/AcceptTermsDailogue";
import AccountVerified from "../../views/Login/AccountVerified";
import TrackRevenue from "../../views/revenue/TrackRevenue";
const routes = {
  data: [
    {
      category: "Main",
      path: "/released",
      element: <DeliveredReleases />,
      id: "released",
      title: "Released",
      icon: <SummarizeIcon />,
      type: "item",
      roles: [],
      navCategory: "Library",
    },

    {
      category: "Release",
      path: "/release",
      element: <Release />,
      id: "release",
      title: "Submitted",
      icon: <NewReleasesIcon />,
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Library",
    },

    {
      category: "Release",
      path: "/release",
      element: <Release />,
      id: "drafts",
      title: "Drafts",
      icon: <NewReleasesIcon />,
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Library",
    },

    {
      category: "Release",
      path: "/release",
      element: <Release />,
      id: "rejected",
      title: "Rejected",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Library",
    },

    {
      category: "Release",
      path: "/dashboard",
      element: <ReleaseInformation />,
      id: "dashboard",
      title: "Daily Trends",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Insights",
    },

    {
      category: "Main",
      path: "/analytics",
      element: <AnalyticsMain />,
      id: "analytics",
      title: "Stream Analytics",
      icon: <Analytics />,
      type: "item",
      roles: [],
      navCategory: "Insights",
    },

    {
      category: "Release",
      path: "/reports",
      element: <A3Reports />,
      id: "reports",
      title: "Revenue Analytics",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Royalities",
    },
    {
      category: "Release",
      path: "/monthwiseRevenue",
      element: <NetRevenueByMonth />,
      id: "monthwiseRevenue",
      title: "MONTHWISE REVENUE",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Royalities",
    },
    //  {
    //   category: "Release",
    //   path: "/trackRevenue",
    //   element: <TrackRevenue/>,
    //   id: "trackRevenue",
    //   title: "CUMULATIVE TRACKS",
    //   icon: <InfoIcon />,
    //   type: "item",
    //   roles: [],
    //   disable: true,
    //   navCategory: "Royalities",
    // },
    {
      category: "Release",
      path: "/revenueReport",
      element: <RevenueReport />,
      id: "revenueReport",
      title: "Download Report",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Royalities",
    },

    {
      category: "Release",
      path: "/revenueClaim",
      element: <RevenueClaim />,
      id: "revenueClaim",
      title: "PAYMENT",
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Royalities",
    },

    {
      category: "Main",
      path: "/subscriptionPlans",
      element: <SubscriptionPlans />,
      id: "subscriptionPlans",
      title: "Subscription Plans",
      icon: <MonetizationOnIcon />,
      type: "item",
      roles: [],
      disable: true,
      navCategory: "Account Settings",
    },

    {
      category: "Main",
      path: "/subscriptions",
      element: <Subscriptions />,
      id: "My Subscriptions",
      title: "My Subscriptions",
      icon: <SubscriptionsIcon />,
      type: "item",
      roles: [],
      hide: true,
      navCategory: "Account Settings",
    },

    {
      category: "Main",
      path: "/profile",
      element: <Profile />,
      id: "profile",
      title: "Profile",
      icon: <AccountBoxIcon />,
      type: "item",
      roles: [],
      hide: true,
      navCategory: "Account Settings",
    },

    {
      category: "Main",
      path: "/BankInformation",
      element: <BankInformation />,
      id: "BankInformation",
      title: "BANK INFORMATION",
      icon: <AccountBoxIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/myPlans",
      element: <MyPlans />,
      id: "myPlans",
      title: "My Plan",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
      hide: true,
      navCategory: "Account Settings",
    },
    {
      category: "Main",
      path: "/currentPlan",
      element: <CurrentPlans />,
      id: "currentPlan",
      title: "Current Plan",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      navCategory: "Account Settings",
    },

    {
      category: "Auth",
      path: "/",
      element: <SignIn />,
      id: "sign",
      title: localStorage.getItem("Auth") ? null : "Sign In",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Auth",
      path: "/register",
      element: <Register />,
      id: "Register",
      title: "Register",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },
    {
      category: "Auth",
      path: "/ValidateMobile",
      element: <ValidationDailoge />,
      id: "ValidateMobile",
      title: "ValidateMobile",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },
    {
      category: "Auth",
      path: "/AcceptTermsDailogue",
      element: <AcceptTermsDailogue />,
      id: "AcceptTermsDailogue",
      title: "AcceptTermsDailogue",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },
    {
      category: "Auth",
      path: "/ValidateOtp",
      element: <ValidationOtp />,
      id: "ValidateOtp",
      title: "ValidateOtp",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },
    {
      category: "Auth",
      path: "/AccountVerified",
      element: <AccountVerified />,
      id: "AccountVerified",
      title: "AccountVerified",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },
    {
      category: "Section_1",
      path: "/dashboard",
      element: <Dashboard />,
      id: "dashboard",
      title: "Dashboard",
      icon: <DashboardIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Main",
      path: "/ValidateOtp",
      element: <ValidationOtp />,
      id: "ValidateOtp",
      title: "ValidateOtp",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Main",
      path: "/ValidateMobile",
      element: <ValidationDailoge />,
      id: "ValidateMobile",
      title: "ValidateMobile",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Main",
      path: `/release_details`,
      element: <ReleaseDetails />,
      id: "release_details",
      title: "Release Details",
      icon: <AccountTreeIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/myPlans",
      element: <MyPlans />,
      id: "myPlans",
      title: "My Plan",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
      hide: true,
    },

    {
      category: "Main",
      path: "/upgradePlans",
      element: <UpgradePlans />,
      id: "upgradePlan",
      title: "UPGRADE PLAN",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
      hide: true,
    },

    {
      category: "Main",
      path: "/subscriptionListView",
      element: <SubscriptionTabs />,
      id: "subscriptionListView",
      title: "Subscriptions",
      icon: <SubscriptionsIcon />,
      type: "item",
      roles: [],
      // disable: true,
      // hide: true,
    },

    {
      category: "Main",
      path: `/payment`,
      element: <PaymentSuccessful />,
      id: "paymentsuccessful",
      title: "payment Successful",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: `/paymentfailed`,
      element: <PaymentFailed />,
      id: "paymentfailed",
      title: "payment Failed",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: "/requests",
      element: <Issues />,
      id: "requests",
      title: "Requests",
      icon: <ScheduleIcon />,
      type: "item",
      roles: [],
      navCategory: "Account Settings",
    },

    {
      category: "Release",
      path: "/release",
      element: <Release />,
      id: "release",
      title: "Release",
      icon: <NewReleasesIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    // release info
    {
      category: "Release",
      path: "/releaseInfo",
      element: <ReleaseInformation />,
      id: "releaseInfo",
      title: "Release Information",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      path: "/upload",
      element: <Upload />,
      id: "upload",
      title: "Upload",
      icon: <UploadIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      path: "/tracks",
      element: <Tracks />,
      id: "track",
      title: "Tracks",
      icon: <AudiotrackIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      path: "/releaseDate",
      element: <ReleaseDate />,
      id: "releaseDate",
      title: "Release Date",
      icon: <CalendarTodayIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      path: `/promotion`,
      element: <Promotion />,
      id: "promotion",
      title: "Promotion",
      icon: <QueueMusicIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      path: "/submission",
      element: <Submission />,
      id: "submission",
      title: "Submission",
      icon: <TaskAltIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Main",
      path: "/tracksAnalytics",
      element: <TracksAnalytics />,
      id: "tracksAnalytics",
      title: "Track Analytics",
      icon: <Analytics />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: `/deliveredRelease`,
      element: <DeliveredReleaseDetails />,
      id: "delivered_release_details",
      title: "Delivered Release Details",
      icon: <AccountTreeIcon />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: `/notifications`,
      element: <NotificationList />,
      id: "notifications",
      title: "Notifications",
      icon: <NotificationsIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/orders",
      element: <SubscriptionOrders />,
      id: "orders",
      title: "MY ORDERS",
      icon: <ReceiptLongIcon />,
      type: "item",
      roles: [],
      hide: true,
      navCategory: "Account Settings",
    },
    {
      category: "Section_2",
      path: "/label",
      element: <LabelGridView />,
      id: "label",
      title: "Label",
      icon: <LabelIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Main",
      path: "/logout",
      element: <Logout />,
      id: "logout",
      title: "Logout",
      icon: <LogoutOutlined />,
      type: "item",
      roles: [],
      // hide: true,
    },

    {
      category: "Section_2",
      path: "/faq",
      element: <>Faq</>,
      id: "logout",
      title: "Faq",
      icon: <LogoutOutlined />,
      type: "item",
      roles: [],
      // hide: true,
    },

    {
      category: "Section_2",
      path: "/news&update",
      element: <>NEWS & UPDATE</>,
      id: "logout",
      title: "NEWS & UPDATE",
      icon: <LogoutOutlined />,
      type: "item",
      roles: [],
      // hide: true,
    },

    {
      category: "Section_2",
      path: "/single",
      element: <>single</>,
      id: "logout",
      title: "Single",
      icon: <LogoutOutlined />,
      type: "item",
      roles: [],
      // hide: true,
    },

    {
      category: "Section_2",
      path: "/album",
      element: <>album</>,
      id: "logout",
      title: "Album",
      icon: <LogoutOutlined />,
      type: "item",
      roles: [],
      // hide: true,
    },
  ],
};

export default routes;
