import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Grid, IconButton, useTheme, Typography } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../App";
// import RenderMedia from "../../../components/renderMedia/RenderMedia";
import ImageIcon from "@mui/icons-material/Image";
import TopLabelAction from "../../../stores/topLabelReport/TopLabelAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [TopLabelAction.REQUEST_TOPLABEL]),
  pagination: state.topLabel.topLabel || [],
  topLabel: state.topLabel.topLabel || [],
});

function TopLabels(props) {
  const [page_limit] = useState(15);
  const { isRequesting } = props;

  function fetchTopLabel(params) {
    props.dispatch(TopLabelAction.requestTopLabel(params));
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
    },

    {
      width: 280,
      flex: 1,
      field: "label",
      headerName: "Label",
    },

    {
      flex: 1,
      width: 280,
      field: "stream_percentage",
      headerName: "Stream Percentage",
    },

    {
      flex: 1,
      width: 280,
      field: "audio_streams",
      headerName: "Audio Streams",
    },
  ];

  useEffect(() => {
    let params = {
      current_page: "analytics",
    };
    fetchTopLabel(params);
  }, []);

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const rows = [];

  if (props.topLabel) {
    props?.topLabel?.top_labels &&
      props?.topLabel?.top_labels?.length > 0 &&
      props?.topLabel?.top_labels?.map((item, index) => {
        console.log("item: ", item);
        rows.push({
          sl_no: index + 1,
          id: item.key,
          label: item?.label_name,
          stream_percentage: item?.stream_percentage,
          audio_streams: item?.count,
        });
      });
  }

  return (
    <React.Fragment>
      {!isRequesting ? (
        <Grid
          container
          item
          xs={12}
          pr={1}
          xl={12}
          lg={12}
          md={12}
          justifyContent="flex-start"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <LoadingIndicator isActive={isRequesting}>
              <DataGridFunction
                columns={columns}
                rows={rows}
                isRequesting={isRequesting}
                rowHeight={80}
                pagelength={props.pagination.total_page_no}
                page={props.pagination.current_page_no}
                handleChange={handleChange}
              />
            </LoadingIndicator>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(TopLabels);
