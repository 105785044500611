import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grid,
  Typography,
  Text,
  Link,
} from "@mui/material";
import Tunes from "../../Images/A3Tunes.webp";
import { theme } from "../App";
import { useNavigate, useLocation } from "react-router";
import { connect } from "react-redux";
import { Form, Formik, Field, getIn, ErrorMessage, FastField } from "formik";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LoginBackground from "../../components/loginBackground/LoginBackground";
import RegisterAction from "../../stores/register/RegisterAction";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import MuiButton from "../../components/muiButton/MuiButton";
import AccountVerifiedAction from "../../stores/AccountVerified/AccountVerifiedAction";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AccountVerifiedAction.REQUEST_ACCOUNTVERIFIED,
    UserProfileAction.REQUEST_USERPROFILE,
  ]),
  userprofile: state.userprofile.userprofile,
});

const passwordRegExp =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
const AccountVerified = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const postObj = location.state?.action;
  console.log("postObjaccountverfied", postObj);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setshowconfirmPassword(!showconfirmPassword);
  };
  const passwordRef = React.useRef(null);
  const confirmpasswordRef = React.useRef(null);
  const buttonForLoginRef = React.useRef(null);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    const handleResizeLogin = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as per your requirement
    };

    // Initial check on mount
    handleResizeLogin();

    // Listen to window resize events
    window.addEventListener("resize", handleResizeLogin);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResizeLogin);
  }, []);
  function fetchUserprofile(inputCallback) {
    props.dispatch(UserProfileAction.requestUserprofile({}, inputCallback));
  }
  return (
    <Formik
      initialValues={postObj}
      validate={(values) => {
        let errors = {};
        if (values.confirm_password !== values.credentials[0].value) {
          errors.confirm_password = "Passwords do not match";
        }
        if (!values.credentials[0].value) {
          errors.credentials = [{ value: "Password is Required" }];
        } else if (!passwordRegExp.test(values.credentials[0].value)) {
          errors.credentials = [
            {
              value:
                "Password must be at least 8 characters long and contain at least 1 upper case letter, 1 number, and one of these special characters",
            },
          ];
        }
        if (!values.confirm_password) {
          errors.confirm_password = "Confirm Password is Required";
        }

        return errors;
      }}
      onSubmit={(values, formikHelpers) => {
        // if (action === "mobile_otp") {
        setLoading(true);

        const callBackFunction = () => {
          navigate("/myPlans", {
            state: { from: location.pathname },
          });
        };

        let postObjDataVerified = {
          phone_number: postObj.phone_number,
          country_code: postObj.country_code,
          document_consent: {
            terms_and_condition: postObj.document_consent.terms_and_condition,
            privacy_and_policy: postObj.document_consent.privacy_and_policy,
          },
          credentials: values.credentials,
        };
        const params = {};
        const headers = {
          "X-One-Time-Token": location?.state?.token,
        };
        console.log("posteddata", postObjDataVerified);
        props.dispatch(
          AccountVerifiedAction.requestaccountverified(
            postObjDataVerified,
            callBackFunction,
            params,
            headers
          )
        );
      }}
    >
      {({
        errors,
        isValid,
        helperText,
        touched,
        dirty,
        handleChange,
        setFieldValue,
        values,
        submitForm,
      }) => (
        <Form style={{ width: "100%", height: "100vh" }}>
          <Box sx={{ width: "100%", height: "100%" }}>
            <Grid
              container
              style={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                height: "100%",
              }}
            >
              <LoginBackground isMobile={isMobile} />
              <Grid
                item
                xs={12}
                md={isMobile ? 12 : 6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: theme.palette.background.loginbackground,
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                {isMobile && (
                  <img
                    src={Tunes}
                    alt="Tunes"
                    style={{
                      width: "15%",
                      height: "auto",
                      objectFit: "contain",
                      marginBottom: "30px",
                    }}
                  />
                )}
                <Grid
                  container
                  item
                  xs={8}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  sx={{
                    // flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  // className="bg-warning"
                >
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      textAlign: "left",
                      color: theme.palette.text.createaccountcolor,
                      fontSize: {
                        xl: "38px",
                        lg: "28px",
                        md: "20px",
                        sm: "14px",
                        xs: "10px",
                      },
                    }}
                    // className="bg-warning"
                  >
                    ACCOUNT VERIFIED!
                    <Grid
                      container
                      item
                      xs={8}
                      xl={9}
                      lg={8}
                      md={8}
                      sm={8}
                      sx={{ marginTop: { xl: "32px", lg: "24px" } }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{
                          textAlign: "left",
                          color: theme.palette.primary.loginbackgroundsecondary,
                          fontSize: "20px",
                          marginBottom: "9px",
                        }}
                      >
                        Create Password
                      </Grid>
                      <Grid item xs={12} lg={12} xl={11} md={12}>
                        <Field
                          as={MuiTextField}
                          variant="outlined"
                          placeholder="Password"
                          id="credentials"
                          type={showPassword ? "text" : "password"}
                          size="small"
                          name="credentials[0].value"
                          error={Boolean(
                            getIn(touched, "credentials") &&
                              getIn(errors, "credentials")
                          )}
                          backgroundColor="#efefef"
                          width="451px"
                          fieldheight="46px"
                          fcolor="black"
                          borderRadius="5px"
                          inputRef={passwordRef}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton size="medium">
                                  <LockIcon
                                    sx={{
                                      transform: "scale(0.8)",
                                      color: "#808080",
                                    }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                            onKeyPress: (event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                confirmpasswordRef.current &&
                                  confirmpasswordRef.current.focus();
                              }
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="small"
                                  sx={{ mr: 1 }}
                                >
                                  {showPassword ? (
                                    <VisibilityOff
                                      sx={{
                                        fontSize: "1.5rem",
                                        transform: "scale(0.8)",
                                        color: "#808080",
                                      }}
                                    />
                                  ) : (
                                    <Visibility
                                      sx={{
                                        fontSize: "1.5rem",
                                        transform: "scale(0.8)",
                                        color: "#808080",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                        <Typography
                          component="div"
                          sx={{
                            color: theme.palette.text.validationcolor,
                            textAlign: "left",
                            fontSize: "10px",
                            marginLeft: "14px",
                          }}
                        >
                          <ErrorMessage name="credentials[0].value" />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{ marginTop: "25px" }}
                      container
                      item
                      xs={8}
                      xl={9}
                      lg={8}
                      md={8}
                      sm={8}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{
                          textAlign: "left",
                          color: theme.palette.primary.loginbackgroundsecondary,
                          fontSize: "20px",
                          marginBottom: "9px",
                        }}
                      >
                        Confirm Password
                      </Grid>
                      <Grid item xs={12} lg={12} xl={11}>
                        <Field
                          as={MuiTextField}
                          variant="outlined"
                          placeholder="Confirm Password"
                          id="confirm_password"
                          type={showconfirmPassword ? "text" : "password"}
                          size="small"
                          name="confirm_password"
                          error={Boolean(
                            getIn(touched, "confirm_password") &&
                              getIn(errors, "confirm_password")
                          )}
                          //   inputRef={confirmpasswordRef}
                          backgroundColor="#efefef"
                          width="451px"
                          fieldheight="46px"
                          fcolor="black"
                          borderRadius="5px"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton size="small">
                                  <LockIcon
                                    sx={{
                                      transform: "scale(0.8)",
                                      color: "#808080",
                                    }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                            // onKeyPress: (event) => {
                            //   if (event.key === "Enter") {
                            //     // event.preventDefault();
                            //     buttonForLoginRef.current &&
                            //       buttonForLoginRef.current.click();
                            //   }
                            // },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="small"
                                  sx={{ mr: 1 }}
                                >
                                  {showconfirmPassword ? (
                                    <VisibilityOff
                                      sx={{
                                        fontSize: "1.5rem",
                                        transform: "scale(0.8)",
                                        color: "#808080",
                                      }}
                                    />
                                  ) : (
                                    <Visibility
                                      sx={{
                                        fontSize: "1.5rem",
                                        transform: "scale(0.8)",
                                        color: "#808080",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                        <Typography
                          component="div"
                          sx={{
                            color: theme.palette.text.validationcolor,
                            textAlign: "left",
                            fontSize: "10px",
                            marginLeft: "14px",
                          }}
                        >
                          <ErrorMessage name="confirm_password" />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      width={"450px"}
                      container
                      item
                      xs={8}
                      xl={12}
                      lg={8}
                      md={8}
                      sm={8}
                      sx={{
                        marginTop: {
                          xl: "52px",
                          lg: "42px",
                        },
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid item>
                        <MuiButton
                          loading={props.isRequesting}
                          name={"GO TO DASHBOARD"}
                          type="submit"
                          width="266px"
                          fontSize="22px"
                          height="52px"
                          letterSpacing="0.7px"
                          padding="20px"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps)(AccountVerified);
