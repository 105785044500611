import ActionUtility from "../../utilities/ActionUtility";
import ValidationEffect from "./ValidationEffect";

export default class ValidationAction {
  // ValidationAction action with an function declaration
  static REQUEST_VALIDATION_BY_ID = "ValidationAction.REQUEST_VALIDATION_BY_ID";
  static REQUEST_VALIDATION_BY_ID_FINISHED =
    "ValidationAction.REQUEST_VALIDATION_BY_ID_FINISHED";

  static REQUEST_PUT_VALIDATION = "ValidationAction.REQUEST_PUT_VALIDATION";
  static REQUEST_PUT_VALIDATION_FINISHED =
    "ValidationAction.REQUEST_PUT_VALIDATION_FINISHED";

  static REQUEST_POST_VALIDATION = "ValidationAction.REQUEST_POST_VALIDATION";
  static REQUEST_POST_VALIDATION_FINISHED =
    "ValidationAction.REQUEST_POST_VALIDATION_FINISHED";

  // METHODS
  static requestValidationById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ValidationAction.REQUEST_VALIDATION_BY_ID,
        ValidationEffect.requestValidationById,
        id
      );
    };
  }

  static requestPutValidation(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ValidationAction.REQUEST_PUT_VALIDATION,
        ValidationEffect.requestPutValidation,
        data,
        id
      );
    };
  }

  static requestPostValidation(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ValidationAction.REQUEST_POST_VALIDATION,
        ValidationEffect.requestPostValidation,
        data
      );
    };
  }
}
