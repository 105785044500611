import ActionUtility from "../../utilities/ActionUtility";
import RegisterEffect from "./RegisterEffect";

export default class RegisterAction {
  // RegisterAction action with an function declaration

  static REQUEST_POST_REGISTER = "RegisterAction.REQUEST_POST_REGISTER";
  static REQUEST_POST_REGISTER_FINISHED =
    "RegisterAction.REQUEST_POST_REGISTER_FINISHED";

  static REQUEST_POST_MAIL_ADDRESS = "RegisterAction.REQUEST_POST_MAIL_ADDRESS";
  static REQUEST_POST_MAIL_ADDRESS_FINISHED =
    "RegisterAction.REQUEST_POST_MAIL_ADDRESS_FINISHED";

  static REQUEST_POST_MAIL_OTP = "RegisterAction.REQUEST_POST_MAIL_OTP";
  static REQUEST_POST_MAIL_OTP_FINISHED =
    "RegisterAction.REQUEST_POST_MAIL_OTP_FINISHED";
 static REQUEST_POST_MOBILENUMBER = "RegisterAction.REQUEST_POST_MOBILENUMBER";
  static REQUEST_POST_MOBILENUMBER_FINISHED =
    "RegisterAction.REQUEST_POST_MOBILENUMBER_FINISHED";

  static REQUEST_POST_MOBILENUMBER_OTP = "RegisterAction.REQUEST_POST_MOBILENUMBER_OTP";
  static REQUEST_POST_MOBILENUMBER_OTP_FINISHED =
    "RegisterAction.REQUEST_POST_MOBILENUMBER_OTP_FINISHED";
  // Organizationfunction
  static requestPostRegister(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RegisterAction.REQUEST_POST_REGISTER,
        RegisterEffect.requestPostRegister,
        data,
        callBack
      );
    };
  }

  static requestPostMailAddress(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RegisterAction.REQUEST_POST_MAIL_ADDRESS,
        RegisterEffect.requestPostMailAddress,
        data,
        callBack
      );
    };
  }

  static requestPostMailOTP(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RegisterAction.REQUEST_POST_MAIL_OTP,
        RegisterEffect.requestPostMailOTP,
        data,
        callBack
      );
    };
  }
  static requestPostMobileNumber(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RegisterAction.REQUEST_POST_MOBILENUMBER,
        RegisterEffect.requestPostMobileNumber,
        data,
        callBack
      );
    };
  }

  static requestPostMobileNumberOTP(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RegisterAction.REQUEST_POST_MOBILENUMBER_OTP,
        RegisterEffect.requestPostMobileNumberOTP,
        data,
        callBack
      );
    };
  }
}
