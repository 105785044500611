import React, { useEffect, useState } from "react";
import environment from "environment";
import ImageIcon from "@mui/icons-material/Image";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import { Box } from "@mui/material";
import { theme } from "../App";
import a3Logo from "../../Images/a3_web_logo_image.webp";
import "./Rettrive.css";

const Rettrive = (props) => {
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    if (props.thumbnail_url) {
      RetrieveImageUrl();
    }
  }, [props.thumbnail_url, props.current_page, props.params, props.release_id]);

  function RetrieveImageUrl() {
    setLoading(true);
    const access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    const imageUrl = `${environment.api.retriveAsset}?current_page=${props.current_page}&path=thumbnail/${props.thumbnail_url}&release_id=${props.params?.id ?? props.release_id ?? ""}`;

    fetch(imageUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setImgUrl(url);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // Handle error (optional)
      });
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <CircleLoader width={"20px"} height={"20px"} />
      ) : (
        <img
          src={imgUrl || a3Logo}
          alt="Preview"
          onError={(e) => {
            e.target.src = a3Logo;
          }}
          style={{
            width: "100%",
            height: "100%",
            borderTopLeftRadius: props.borderTopLeftRadius ?? "",
            borderTopRightRadius: props.borderTopRightRadius ?? "",
            objectFit: imgUrl ? 'cover' : 'contain', 
          }}
        />
      )}
    </Box>
  );
};

export default Rettrive;
