import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { theme } from "../../App";
import { makeStyles } from "@material-ui/core";
import MuiButton from "../../../components/muiButton/MuiButton";
import { connect } from "react-redux";
import ReleasesAction from "../../../stores/releases/ReleasesAction";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import LatestReleasesBox from "./LatestReleasesBox";
import PageNotFound from "../../../components/PageNotFound";
import LatestDrafts from "../LatestDrafts";
import LatestSubmittedBox from "../Submitted";
import RejectedBox from "../Rejected";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES,
  ]),
  top5Delivered: state.top5Delivered.top5Delivered || [],
});

const LatestActivities = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("activeTab", newValue);
  };

  function fetchTopReleases() {
    let filter = [];
    filter.push(`["status.keyword","must","","match","8"]`);
    let params = {
      current_page: "dashboard",
      filters: `[${filter}]`,
      order_by: `[["main_release_date","desc"]]`,
      page_limit: "6",
    };
    props.dispatch(ReleasesAction.requesTop5DeliveredReleases(params));
  }

  let latestReleaseList = [];
  if (props && props.top5Delivered && props.top5Delivered.hits) {
    props.top5Delivered.hits.hits.length !== 0 &&
      props.top5Delivered.hits.hits.map((item) => {
        latestReleaseList.push(item);
      });
  }

  const latestReleases = useMemo(
    () =>
      latestReleaseList && latestReleaseList.length !== 0 ? (
        <LatestReleasesBox latestReleaseList={latestReleaseList} />
      ) : (
        <PageNotFound page="Reports" />
      ),
    [latestReleaseList]
  );

  useEffect(() => {
    fetchTopReleases();
  }, []);

  return (
    <Box>
      <Grid item container flexDirection={"column"}>
        <Grid item>
          <Box
            sx={{
              backgroundColor: theme.palette.background.header,
            }}
          >
            <Grid
              item
              container
              sx={{
                height: { lg: "20px", laptop: "20px", xl: "41px" },
                paddingX: { lg: "54px", laptop: "54px", xl: "100px" },
              }}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              alignContent={"center"}
            >
              <Grid
                item
                sx={{
                  paddingTop: { xl: "8px" },
                  paddingBottom: { xl: "8px" },
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "100%", sm: "auto" }, // Full width on small screens
                  // height: "36px",
                  fontFamily: "Inter, serif",
                  fontSize: {
                    xl: "20px",
                    laptop: "12px",
                    lg: "12px",
                    xs: "24px",
                    sm: "30px",
                  }, // Responsive font size
                  textTransform: "uppercase",
                  color: "#367989",
                  whiteSpace: "nowrap",
                  overflow: "hidden", // Prevent overflow
                  textOverflow: "ellipsis", // Add ellipsis if text overflows
                }}
              >
                <span style={{ color: "#a09153", fontSize: "inherit" }}>M</span>
                y&nbsp;
                <span style={{ color: "#a09153", fontSize: "inherit" }}>L</span>
                atest&nbsp;
                <span style={{ color: "#a09153", fontSize: "inherit" }}>A</span>
                ctivity
              </Grid>
              <Grid item>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  sx={{
                    "& .MuiTabs-indicator": { display: "none" },

                    "& .MuiTabs-flexContainer": {
                      position: "relative",
                      justifyContent: "space-between",
                    },
                    "& .MuiTab-root": {
                      position: "relative",
                      zIndex: 1,
                      paddingTop: { xl: "10px", lg: "4px", laptop: "4px" },
                      paddingBottom: { xl: "10px", lg: "4px", laptop: "4px" },
                      paddingLeft: { xl: "50px", lg: "18px", laptop: "18px" },
                      paddingRight: 0,
                      fontSize: {
                        xl: "16px",
                        laptop: "10px",
                        lg: "10px",
                        xs: "24px",
                        sm: "30px",
                      },
                      borderRadius: "0 0 10px 0",
                      color: "#adadad",
                      //   border: `0.5px solid ${theme.palette.background.primary}`,
                      backgroundColor: "trasparent",
                      "&.Mui-selected": {
                        // backgroundColor: "#003a4c",
                        color: "#a09153",
                        fontWeight: "bold",
                      },
                    },
                  }}
                >
                  <Tab
                    label={<span>{"SUBMITTED"}</span>}
                    style={{
                      textTransform: "none",
                      alignItems: "end",
                    }}
                  />

                  <Tab
                    label={<span>{"DRAFTS"}</span>}
                    style={{
                      textTransform: "none",
                      alignItems: "end",
                    }}
                  />

                  <Tab
                    label={<span>{"REJECTED"}</span>}
                    style={{
                      textTransform: "none",
                      alignItems: "end",
                    }}
                  />
                </Tabs>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              paddingX: { lg: "54px", laptop: "54px", xl: "100px" },
            }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              {value === 0 && !props.isRequesting ? (
                <LatestSubmittedBox />
              ) : (
                <Box>{/* <LoadingDashboardBgImage /> */}</Box>
              )}
              {value === 1 && !props.isRequesting ? (
                <LatestDrafts />
              ) : (
                <Box>{/* <LoadingDashboardBgImage /> */}</Box>
              )}

              {value === 2 && !props.isRequesting ? (
                <RejectedBox />
              ) : (
                <Box>{/* <LoadingDashboardBgImage /> */}</Box>
              )}

              {/* {value === 3 && !props.isRequesting ? (
                latestReleases
              ) : (
                <Box><LoadingDashboardBgImage /></Box>
              )} */}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(mapStateToProps)(LatestActivities);
