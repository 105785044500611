import { Box, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { useTheme } from "@mui/material";
import UserProfileAction from "../../../stores/userprofile/UserProfileAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserProfileAction.REQUEST_USERPROFILE,
  ]),
  userprofile: state.userprofile.userprofile || [],
});

function AvatarIcon(props) {
  const theme = useTheme();

  function fetchUserprofile() {
    let Id = localStorage.getItem("userId");

    let params = {
      current_page: "profile",
      user_id: Id,
    };
    props.dispatch(UserProfileAction.requestUserprofile(params));
  }

  useEffect(() => {
    fetchUserprofile();
  }, []);

  let FirstLetter = "";

  if (
    props.userprofile &&
    props.userprofile._source &&
    props.userprofile._source.username
  ) {
    FirstLetter = props.userprofile._source.username.slice(0, 1).toUpperCase();
  }

  const [imgUrl, setImgUrl] = useState(null);

  return (
    <Box>
      <Avatar
        alt="Remy Sharp"
        sx={{
          cursor: "pointer",
          backgroundColor: theme.palette.primary.main,
          objectFit: "cover",

          width: { xl: 30, laptop: 16, lg: 16 },
          height: { xl: 30, laptop: 16, lg: 16 },
        }}
        src={imgUrl || ""}
      >
        {FirstLetter}
      </Avatar>
    </Box>
  );
}

export default connect(mapStateToProps)(AvatarIcon);
