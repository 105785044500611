import ActionUtility from "../../utilities/ActionUtility";
import SubscriptionPlansEffect from "./SubscriptionPlansEffect";

export default class SubscriptionPlansAction {
  // SubscriptionPlansAction action with an function declaration
  static REQUEST_SUBSCRIPTION_PLANS =
    "SubscriptionPlansAction.REQUEST_SUBSCRIPTION_PLANS";
  static REQUEST_SUBSCRIPTION_PLANS_FINISHED =
    "SubscriptionPlansAction.REQUEST_SUBSCRIPTION_PLANS_FINISHED";

  static REQUEST_SELECT_SUBSCRIPTION_PLANS =
    "SubscriptionPlansAction.REQUEST_SELECT_SUBSCRIPTION_PLANS";
  static REQUEST_SELECT_SUBSCRIPTION_PLANS_FINISHED =
    "SubscriptionPlansAction.REQUEST_SELECT_SUBSCRIPTION_PLANS_FINISHED";

  static REQUEST_SUBSCRIPTION_BASE_PLANS =
    "SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS";
  static REQUEST_SUBSCRIPTION_BASE_PLANS_FINISHED =
    "SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS_FINISHED";

  static REQUEST_SUBSCRIPTION_BASE_PLANS_FILTER =
    "SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS_FILTER";
  static REQUEST_SUBSCRIPTION_BASE_PLANS_FILTER_FINISHED =
    "SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS_FILTER_FINISHED";

  // METHODS
  // SUBSCRIPTION GET function
  static requestSubscriptionPlans(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionPlansAction.REQUEST_SUBSCRIPTION_PLANS,
        SubscriptionPlansEffect.requestSubscriptionPlans,
        params,
        callBack
      );
    };
  }

  static requestSubscriptionBasePlans(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS,
        SubscriptionPlansEffect.requestSubscriptionBasePlans,
        params
      );
    };
  }

  static requestSubscriptionBasePlansFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionPlansAction.REQUEST_SUBSCRIPTION_BASE_PLANS_FILTER,
        SubscriptionPlansEffect.requestSubscriptionBasePlansFilter,
        params
      );
    };
  }

  static requestSelectSubscriptionPlans(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubscriptionPlansAction.REQUEST_SELECT_SUBSCRIPTION_PLANS,
        SubscriptionPlansEffect.requestSelectSubscriptionPlans,
        params
      );
    };
  }
}
