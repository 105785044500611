import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import MuiButton from "../../components/muiButton/MuiButton";
import { Grid, Chip, Typography, Tooltip } from "@mui/material";
import TrackRevenueAction from "../../stores/trackRevenue/TrackRevenueAction";
// import ClientAction from "../../stores/client/ClientAction";
import LabelAction from "../../stores/label/LabelAction";
// import ReleasesAction from "../../stores/releases/ReleasesAction";
import { theme } from "../../views/App";
import { format } from "date-fns";
import LoadingDashboardBgImage from "../../components/loadingBgImage/LoadingDashboardBgImage";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import TrackwiseRevenueSummary from "./TrackwiseRevenueSummary";
// import CumulativeTracksMultiLevelFilter from "../multiLevelFilters/CumulativeTracksMultiLevelFilter";
import MuiSnackbarAlert from "../../components/muiSnackbar/MuiSnackbarAlert";

// mapstatetoprops function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackRevenueAction.REQUEST_TRACK_REVENUE,
    TrackRevenueAction.REQUEST_TRACK_REVENUE_CLEAR,
    LabelAction.REQUEST_LABELS_FILTER, 
  ]),
  trackRevenue: state.trackRevenue.trackRevenue || [],
  pagination: state.trackRevenue.trackRevenue || [],
  labelFilter: state.labelFilter.labelFilter || [],
});

function TrackRevenue(props) {
  const [page_limit] = useState(15);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [alertPopUp, setAlertPopUp] = useState(false);

  const [client, setClient] = useState(null);
  const [label, setLabel] = useState(null);
  const [trackName, setTrackName] = useState(null);
  const [ISRC, setISRC] = useState(null);
  const [releaseName, setReleaseName] = useState(null);
  const [producerCatalog, setProducerCatalog] = useState(null);
  const [dateFilter, setDateFilter] = useState([null, null]);

  const [openMultiLevelFilter, setMultiLevelFilter] = useState(false);
  const [openTrackRevenueSummary, setTrackRevenueSummary] = useState(false);
  const [chipContent, setChipContent] = useState([]);
  const [filterContents, setFilterContents] = useState([]);
  const [rowData, setRowData] = useState(null);

  const { isRequesting } = props;

  // **************************************************  UseEffect

  function fetchTrackRevenue(params) {
    props.dispatch(TrackRevenueAction.requestTrackRevenue(params));
  }
  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }

  useEffect(() => {
    const param = {
      page_limit: page_limit,
      order_by: `[["created_on","desc"]]`,
    };
    const trackRevenueParam = {
      page_limit: page_limit,
      order_by: `[["created_on","desc"]]`,
      youtube: true,
      current_page: "trackRevenue",
    };
    const filterparam = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "trackRevenue",
    };

    fetchTrackRevenue(trackRevenueParam);
    fetchLabelFilter(filterparam);
  }, []);

  let labelList = [];
  props?.labelFilter?.hits?.hits?.map((item) => {
    if (item?._source?.label_name)
      labelList.push({
        id: item?._id,
        label_name: item._source.label_name,
      });
  });

  function applyFilter() {
    const filter = [];

    if (client) {
      filter.push(`["client_id.keyword","must","","term","${client.id}"]`);
    }

    if (label) {
      filter.push(`["label.keyword","must","","match","${label.id}"]`);
    }

    if (releaseName) {
      filter.push(`["title.keyword","must","","wildcard","*${releaseName}*"]`);
    }

    if (ISRC) {
      filter.push(`["isrc.keyword","must","","match","${ISRC.trim()}"]`);
    }

    if (trackName) {
      filter.push(
        `["title.keyword","must","","wildcard","*${trackName.trim()}*"]`
      );
    }

    if (dateFilter[0] !== null && dateFilter[1] !== null) {
      filter.push(CustomDateFilter());
    }

    if (
      client !== null ||
      label !== null ||
      releaseName !== null ||
      ISRC !== null ||
      (dateFilter[0] !== null && dateFilter[1] !== null) ||
      trackName !== null
    ) {
      const params = {
        filters: `[${filter}]`,
        page_limit: 0,
        page_limit: `${page_limit}`,
        current_page: "trackRevenue",
      };
      fetchTrackRevenue(params);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      const param = {
        page_limit: page_limit,
        order_by: `[["created_on","desc"]]`,
        current_page: "trackRevenue",
      };
      fetchTrackRevenue(param);
      setClearLoader(false);
    }
  }

  const ClearFilter = () => {
    if (
      client ||
      releaseName ||
      trackName ||
      label ||
      ISRC ||
      (dateFilter[0] !== null && dateFilter[1] !== null) ||
      producerCatalog
    ) {
      setClearLoader(true);
      setChipContent([]);
      setFilterContents([]);
      setLabel(null);
      setISRC(null);
      setTrackName(null);
      setReleaseName(null);
      setClient(null);
      setProducerCatalog(null);
      setDateFilter([null, null]);
      setFilterClicked(false);

      const params = {
        order_by: '[["created_on", "desc"]]',
        page_limit: page_limit,
        current_page: "trackRevenue",
      };
      FilterClicked && fetchTrackRevenue(params);
    } else {
      setAlertPopUp(true);
    }
  };

  //********************************************************* pagination

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "client_name",
      headerName: "Client Name",
      width: 150,
      flex: 2,
    },

    {
      field: "title",
      headerName: "Title",
      flex: 2,
      width: 100,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 2,
      width: 100,
    },
    {
      field: "isrc",
      headerName: "ISRC",
      flex: 2,
      width: 100,
    },
    {
      field: "label_name",
      headerName: "Label",
      flex: 2,
      width: 100,
    },
    {
      field: "instrumental",
      headerName: "Instrumental",
      flex: 2,
      width: 100,
      renderCell: (params) => {
        return <span>{params?.row?.instrumental ? "Yes" : "No"}</span>;
      },
    },
  ];

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setClearLoader(false);
    const filter = [];
    let params = {};

    if (client) {
      filter.push(`["client_id.keyword","must","","term","${client.id}"]`);
    }

    if (label) {
      filter.push(`["label.keyword","must","","match","${label.id}"]`);
    }

    if (releaseName) {
      filter.push(`["title.keyword","must","","wildcard","*${releaseName}*"]`);
    }

    if (ISRC) {
      filter.push(`["isrc.keyword","must","","match","${ISRC.trim()}"]`);
    }

    if (trackName) {
      filter.push(
        `["title.keyword","must","","wildcard","*${trackName.trim()}*"]`
      );
    }
    if (dateFilter[0] !== null && dateFilter[1] !== null) {
      filter.push(CustomDateFilter());
    }

    if (
      client !== null ||
      label !== null ||
      releaseName !== null ||
      ISRC !== null ||
      trackName !== null ||
      (dateFilter[0] !== null && dateFilter[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
        current_page: "trackRevenue",
      };
    } else {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
        current_page: "trackRevenue",
      };
    }

    if (value - 1 !== props.pagination.current_page_no) {
      fetchTrackRevenue(params);
    }
  };

  // ********************************************************* DataGrid  rows
  const rows = [];
  if (props.trackRevenue && props.trackRevenue.hits) {
    props.trackRevenue.hits.hits &&
      props.trackRevenue.hits.hits.length > 0 &&
      props.trackRevenue.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          client_id: item._source.client_id,
          client_name: item._source.client_id?.client_name,
          composer: item._source?.composer,
          content_type: item._source.content_type,
          copyright_year: item._source.copyright_year,
          copyrighted_to: item._source.copyrighted_to,
          director: item._source.director,
          featuring: item._source.featuring,
          featuring_actor: item._source.featuring_actor,
          featuring_actress: item._source.featuring_actress,
          genre: item._source.genre,
          ingrooves_tacks_id: item._source.ingrooves_tacks_id,
          instrumental: item._source.instrumental,
          isrc: item._source.isrc,
          label_id: item._source.label?.id,
          label_name: item._source.label?.label_name,
          lyrics: item._source.lyrics,
          lyrics_language: item._source.lyrics_language,
          parental_advisory: item._source.parental_advisory,
          phonographic_copyright_year: item._source.phonographic_copyright_year,
          plan_id: item._source.plan_id,
          plan_name: item._source.plan_id?.plan_name,
          play_link: item._source.play_link,
          preview_start: item._source.preview_start,
          primary_actor: item._source.primary_actor,
          primary_actress: item._source.primary_actress,
          primary_artists: item._source.primary_artists,
          primary_track_type: item._source.primary_track_type,
          producer: item._source.producer,
          producer_catalouge_number: item._source.producer_catalouge_number,
          production_year: item._source.production_year,
          published_by: item._source.published_by,
          publisher: item._source.publisher,
          release_id: item._source.release_id,
          remixer: item._source.remixer,
          revenue: item._source.revenue,
          sub_genre_name: item._source.sub_genre_name,
          title: item._source.title,
          track_title_language: item._source.track_title_language,
          views: item._source.views,
        });
      });
  }

  // props?.client?.hits?.hits?.map((item) => {
  //   if (item?._source?.client_name)
  //     clientList.push({
  //       id: item?._id,
  //       client_name: item._source.client_name,
  //     });
  // });

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     applyFilter();
  //   }
  // };

  function CustomDateFilter() {
    let filterString = ``;

    if (dateFilter[1].$d || dateFilter[0].$d)
      filterString = `["created_on","must","lte","range","${format(
        dateFilter[1].$d,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        dateFilter[0].$d,
        "yyyy-MM-dd"
      )}"]`;
    else {
      filterString = `["created_on","must","lte","range","${new Date(
        dateFilter[1]
      )
        .toISOString()
        .slice(0, 10)}"],["created_on","must","gte","range","${new Date(
        dateFilter[0]
      )
        .toISOString()
        .slice(0, 10)}"]`;
    }

    return filterString;
  }

  useEffect(() => {
    applyFilter();
  }, [
    client,
    label,
    ISRC,
    trackName,
    dateFilter,
    releaseName,
    producerCatalog,
  ]);

  useEffect(() => {
    getChipContent();
  }, [filterContents]);

  function getChipContent() {
    const content = [];
    if (client) content.push({ id: "1", name: client.client_name });
    if (releaseName) content.push({ id: "2", name: releaseName });
    if (trackName) content.push({ id: "3", name: trackName });
    if (label) content.push({ id: "4", name: label.label_name });
    if (ISRC) content.push({ id: "5", name: ISRC });
    if (dateFilter[0] && dateFilter[1]) {
      let stringDate = ``;
      dateFilter.map((item, index) => {
        const inputDate = new Date(dateFilter[index]?.$d || dateFilter[index]);
        const day = inputDate.getDate().toString().padStart(2, "0");
        const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
        const year = inputDate.getFullYear();
        stringDate = stringDate + `${day}/${month}/${year}`;
        if (index === 0) {
          stringDate += " - ";
        }
      });
      content.push({ id: "6", name: stringDate });
    }
    if (producerCatalog) content.push({ id: "7", name: producerCatalog });

    setChipContent(content);
  }

  function updateFilterContents() {
    filterContents.map((filter) => {
      if (filter.id === "1") setClient(filter.data);
      if (filter.id == "2") setReleaseName(filter.data);
      if (filter.id == "3") setTrackName(filter.data);
      if (filter.id == "4") setLabel(filter.data);
      if (filter.id == "5") setISRC(filter.data);
      if (filter.id == "6") setDateFilter(filter.data);
      if (filter.id == "7") setProducerCatalog(filter.data);
    });
    getChipContent();
  }

  function statusText(chipID) {
    if (chipID == "1") return "Client: ";
    if (chipID == "2") return "Release Name: ";
    if (chipID == "3") return "Track Name: ";
    if (chipID == "4") return "Label: ";
    if (chipID == "5") return "ISRC: ";
    if (chipID == "6") return "Release Date: ";
    if (chipID == "7") return "Producer Catalog No: ";
  }

  function deleteChip(filterID) {
    if (filterID == "1") setClient(null);
    if (filterID == "2") setReleaseName(null);
    if (filterID == "3") setTrackName(null);
    if (filterID == "4") setLabel(null);
    if (filterID == "5") setISRC(null);
    if (filterID == "6") dateFilter([null, null]);
    if (filterID == "7") setProducerCatalog(null);

    let newChipContent = chipContent;
    newChipContent = newChipContent.filter((item) => item.id != filterID);
    let newFilterContents = filterContents;
    newFilterContents = newFilterContents.filter((item) => item.id != filterID);

    setChipContent(newChipContent);
    setFilterContents(newFilterContents);
  }

  return (
    <React.Fragment>
     
          <>
            <style
              dangerouslySetInnerHTML={{
                __html: `
        @keyframes anima {
          0% {
            transform: translateY(-50px);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
      `,
              }}
            />
            {chipContent?.length > 0 && (
              <Box
                sx={{
                  backgroundColor: "white",
                  border: `1px solid ${theme.palette.common.grey}`,
                  boxShadow: "2px 2px 8px 0 rgba(0, 0, 0, 0.3)",
                  marginX: "5px",
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: "4px",
                  animation: `anima 0.7s ease-in-out`,
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  sx={{ paddingY: "10px", paddingX: "10px" }}
                >
                  <Grid item sx={{ fontWeight: "bold" }}>
                    Filters Applied:
                  </Grid>
                  <Grid xs={11} sm={10} sx={{ paddingLeft: "10px" }}>
                    {chipContent.map((item) => (
                      <>
                        <Typography
                          sx={{
                            display: "inline-block",
                            paddingTop: "2px",
                          }}
                        >
                          {statusText(item.id)}&nbsp;
                        </Typography>
                        <Chip
                          label={item.name}
                          onDelete={() => {
                            deleteChip(item.id);
                          }}
                          variant="outlined"
                          size="small"
                          sx={{ marginRight: "5px" }}
                        />
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            )}
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="space-between"
              pr={1}
              pb={1}
            >
              <Grid item container xl={9} lg={9} md={12} sm={12} xs={12}></Grid>

              <Grid
                item
                xl={3}
                lg={3}
                md={6}
                sm={7}
                // mt={{ md: 1, lg: 0, sm: 1, xl: 0 }}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                  mt={-1}
                >
                  <Grid item>
                    <Tooltip title="Apply Filters" arrow placement="left">
                      <Box
                        sx={{
                          padding: "4px",
                          border: `1px solid ${theme.palette.common.grey}`,
                          borderRadius: "4px",
                          "&:hover": {
                            boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.4)",
                          },
                        }}
                      >
                        <FilterAltRoundedIcon
                          onClick={() => {
                            setMultiLevelFilter(true);
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item xl={4} lg={4} md={3} sm={4}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearFilter}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {navigator.onLine ? (
              <Box>
                <Grid
                  display="flex"
                  justifyContent="center"
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                >
                  {" "}
                  <Grid
                    conatiner
                    item
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    xs={12}
                    pr={1}
                  >
                    <LoadingIndicator isActive={isRequesting}>
                      {!isRequesting && props.trackRevenue?.hits?.hits ? (
                        <DataGridFunction
                          curser={"pointer"}
                          rows={rows}
                          columns={columns}
                          rowHeight={80}
                          pagelength={props.pagination.total_page_no}
                          page={props.pagination.current_page_no}
                          handleChange={handleChange}
                          height={filterContents.length > 0 && "75vh"}
                          onRowClick={(e) => {
                            setRowData(e.row);
                            setTrackRevenueSummary(true);
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh",
                          }}
                        >
                          <LoadingDashboardBgImage />
                        </Box>
                      )}
                    </LoadingIndicator>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              alert("No Internet Connection") || (
                <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
              )
            )}

            {/* <CumulativeTracksMultiLevelFilter
              open={openMultiLevelFilter}
              onClose={() => {
                setMultiLevelFilter(false);
              }}
              chipContent={chipContent}
              setChipContent={setChipContent}
              filterContents={filterContents}
              setFilterContents={setFilterContents}
              isRequesting={props.isRequesting}
              setFilterClicked={setFilterClicked}
              updateFilterContents={updateFilterContents}
              deleteChip={deleteChip}
              dropDownLists={{
                labelList: labelList,
                // clientList: clientList,
              }}
            /> */}
            <TrackwiseRevenueSummary
              open={openTrackRevenueSummary}
              onClose={() => {
                setTrackRevenueSummary(false);
              }}
              trackID={rowData?.id}
              trackName={rowData?.title}
            />
            <MuiSnackbarAlert
              open={alertPopUp}
              onClose={() => setAlertPopUp(false)}
              message={"Please Add Filters !!"}
            />
          </>
       
     
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(TrackRevenue);
