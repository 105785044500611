import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { theme } from "../../views/App";
import { fieldToTextField } from "formik-material-ui";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CircularProgress from "@mui/material/CircularProgress";
import environment from "environment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AppleIcon from "@mui/icons-material/Apple";
import PropTypes from "prop-types";
const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog({
  disabled,
  readOnly,
  disableCloseOnSelect,
  diasbleSvg,
  disableClearable,
  textFieldProps,
  size,
  limitTags,
  fieldValue,
  renderOption,
  freeSolo,
  filterOptions,
  onChange,
  margin,
  padding,
  openProp,
  onInputChange,
  getOptionLabel,
  values,
  inputValue,
  fetchartistFilter,
  isOptionEqualToValue,
  value,
  InputProps,
  renderTags,
  remove,
  push,
  //   artistFieldRef,
  ...props
}) {
  const [open, toggleOpen] = React.useState(false);

  const useStyles = makeStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      zIndex: 1,
      overflow: "hidden",
    },
  });

  const {
    form: { setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();

  const handleClose = () => {
    toggleOpen(false);
  };

  const [selectedArtists, setSelectedArtists] = React.useState([]);
  const [youtubeData, setYoutubeData] = React.useState(null);
  const [appleMusicData, setAppleMusicData] = React.useState(null);
  const [spotifyData, setSpotifyData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const RenderArtistsSearchList = ({
    platformData,
    platformName,
    isLoading,
    selectedArtistsArray,
  }) => {
    return (
      <Box>
        {(platformData || (isLoading && Boolean(isLoading.length))) && (
          <Typography
            sx={{
              backgroundColor: `${theme.palette.background.light}`,
              height: "30px",
              borderBottom: `1px solid #bebec0`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingX: "10px",
            }}
          >
            <span
              style={{ flex: 1, display: "flex", justifyContent: "center" }}
            >
              {platformName}{" "}
            </span>
            {!platformData && isLoading.includes(platformName) ? (
              <CircularProgress size={20}></CircularProgress>
            ) : null}
          </Typography>
        )}

        {platformData &&
          (platformData.length > 0 ? (
            <Box
              className="scrollable"
              style={{
                height: "200px",
                border: "1px solid grey",
                backgroundColor: "white",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              <List>
                {platformData?.map((item, index) => (
                  <ListItem key={`${item}-${index}`} button>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedArtists.some(
                            (artist) =>
                              artist.stream_platform_artist_id ===
                              item.artist_id
                          )}
                        />
                      }
                      label=""
                      name="selected_artist"
                      onChange={(e, checked) => {
                        let post_obj = {};

                        if (platformName === "Youtube") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_image_url,
                            stream_platform_profile_url: item.profile_url,
                            stream_platform_id: "3",
                            stream_platform_name: platformName,
                            generate: false,
                          });

                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                        if (platformName === "Spotify") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_images_url[0],
                            stream_platform_profile_url: item.profile_url,

                            stream_platform_id: "1",
                            stream_platform_name: platformName,
                            generate: false,
                          });
                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                        if (platformName === "Apple Music") {
                          Object.assign(post_obj, {
                            stream_platform_artist_id: item.artist_id,
                            stream_platform_artist_name: item.artist_name,
                            stream_platform_artist_profile_url:
                              item.artist_image_url,
                            stream_platform_profile_url: item.profile_url,
                            stream_platform_id: "2",
                            stream_platform_name: platformName,
                            generate: false,
                          });
                          selectedArtistsArray = UpdateSelectedArtists(
                            selectedArtistsArray,
                            post_obj,
                            checked
                          );

                          setSelectedArtists(selectedArtistsArray);
                        }
                      }}
                    />
                    <ListItemAvatar>
                      <Avatar
                        alt={item.artist_name}
                        src={
                          platformName === "Spotify"
                            ? item.artist_images_url[0]
                            : platformName === "Youtube"
                            ? item.artist_image_url
                            : platformName === "Apple Music"
                            ? item.artist_image_url
                            : ""
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      // primary={item.artist_name}
                      sx={{
                        color: "#000000",
                      }}
                    >
                      {item.artist_name}
                    </ListItemText>

                    <Typography
                      component={"a"}
                      href={
                        platformName === "Spotify"
                          ? item.profile_url
                          : platformName === "Youtube"
                          ? item.platform
                          : platformName === "Apple Music"
                          ? item.profile_url
                          : null
                      }
                      target="_blank"
                    >
                      <OpenInNewIcon />
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Box
              style={{
                border: "1px solid grey",
                backgroundColor: "white",
                overflowY: "auto",
                padding: 2,
              }}
            >
              <Typography component={"p"} textAlign={"center"}>
                No Artists found!
              </Typography>
            </Box>
          ))}
      </Box>
    );
  };

  function UpdateSelectedArtists(previousArray, updatedObject, checked) {
    let idToUpdate = updatedObject.stream_platform_id;
    let updatedArray;

    if (checked) {
      if (
        previousArray.some((item) => item.stream_platform_id === idToUpdate)
      ) {
        updatedArray = previousArray.map((item) =>
          item.stream_platform_id === idToUpdate ? updatedObject : item
        );
      } else {
        updatedArray = [...previousArray, updatedObject];
      }
    } else {
      updatedArray = previousArray.filter(
        (item) => item.stream_platform_id !== idToUpdate
      );
    }

    return updatedArray;
  }

  function fetchPlatformArtists(platform, query) {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let API = "";
    if (platform === "YouTube")
      API = `${environment.api.baseApi}/search_musiclabels_artist_youtube?q=${query}&current_page=releaseInfo`;
    if (platform === "Apple Music")
      API = `${environment.api.baseApi}/applemusic_artist?q=${query}&current_page=releaseInfo`;
    if (platform === "Spotify")
      API = `${environment.api.baseApi}/spotify_artist?q=${query}&current_page=releaseInfo`;

    fetch(API, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response) return;

        const platforms = {
          "Apple Music": {
            responseKey: "apple_music_artist_details",
            setData: setAppleMusicData,
          },
          YouTube: {
            responseKey: "youtube_music_artist_details",
            setData: setYoutubeData,
          },
          Spotify: {
            responseKey: "spotify_artist_details",
            setData: setSpotifyData,
          },
        };

        const platformConfig = platforms[platform];
        if (platformConfig) {
          const { responseKey, setData } = platformConfig;

          if (response.status && response.status === "Failed") {
            setData([]);
          } else {
            const artistData = response[responseKey];
            setData(artistData || []);
          }
        }
      })
      .catch((err) => {});
  }

  return (
    <Box>
      <Autocomplete
        classes={{
          paper: classes.root,
        }}
        disableCloseOnSelect={disableCloseOnSelect}
        disableClearable={true}
        readOnly={readOnly ? readOnly : false}
        {...props}
        {...field}
        onChange={
          onChange
            ? onChange
            : (_, value) => {
                if (
                  value &&
                  typeof value === "object" &&
                  Object.keys(value).length === 1 &&
                  value.hasOwnProperty("artist_name")
                ) {
                  const artistNameValue = value["artist_name"];
                  if (
                    typeof artistNameValue === "string" &&
                    artistNameValue.startsWith("Add ")
                  ) {
                    toggleOpen(true);
                    let extractedName;
                    const inputString = value.artist_name;
                    const prefix = 'Add "';
                    const suffix = '"';

                    const startIndex =
                      inputString.indexOf(prefix) + prefix.length;
                    const endIndex = inputString.lastIndexOf(suffix);

                    if (
                      startIndex >= 0 &&
                      endIndex >= 0 &&
                      endIndex > startIndex
                    ) {
                      extractedName = inputString.substring(
                        startIndex,
                        endIndex
                      );
                    }

                    fetchPlatformArtists("YouTube", extractedName);
                    fetchPlatformArtists("Apple Music", extractedName);
                    fetchPlatformArtists("Spotify", extractedName);
                  }
                } else {
                  setFieldValue(name, value);
                }
              }
        }
        onInputChange={onInputChange}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={renderOption}
        freeSolo={true}
        // getoptionselected={(item, current) => item.value === current.value}

        renderInput={(props) => (
          <TextField
            InputLabelProps={{ shrink: true }}
            {...props}
            {...textFieldProps}
            helperText={helperText}
            error={error}
            size={size}
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {value &&
                    value.stream_platforms &&
                    value.stream_platforms.length !== 0 &&
                    value.stream_platforms.map((item, index) => {
                      return item.stream_platform_artist_id &&
                        item.stream_platform_id === "1" ? (
                        <IconButton
                          key={`${item}-${index}`}
                          onClick={() => {
                            item?.stream_platform_profile_url
                              ? window.open(item.stream_platform_profile_url)
                              : alert("Artist Id not found");
                          }}
                          // className=" p-0 m-0 mx-1"
                        >
                          <FontAwesomeIcon
                            style={{
                              color: `${theme.palette.primary.main}`,
                              height: "18px",
                              width: "18px",
                            }}
                            icon={faSpotify}
                          ></FontAwesomeIcon>
                        </IconButton>
                      ) : item.stream_platform_artist_id &&
                        item.stream_platform_id === "2" ? (
                        <IconButton
                          key={`${item}-${index}`}
                          onClick={() => {
                            item?.stream_platform_profile_url
                              ? window.open(item.stream_platform_profile_url)
                              : alert("Artist Id not found");
                          }}
                          className=" p-0 m-0 mx-1"
                        >
                          <AppleIcon
                            sx={{
                              color: `${theme.palette.primary.main}`,
                              fontSize: "20px",
                            }}
                          />
                        </IconButton>
                      ) : item.stream_platform_artist_id &&
                        item.stream_platform_id === "3" ? (
                        <IconButton
                          key={`${item}-${index}`}
                          onClick={() => {
                            item?.stream_platform_profile_url
                              ? window.open(item.stream_platform_profile_url)
                              : alert("Artist Id not found");
                          }}
                          className=" p-0 m-0 mx-1"
                        >
                          <YouTubeIcon
                            sx={{
                              color: `${theme.palette.primary.main}`,
                              fontSize: "20px",
                            }}
                          />
                        </IconButton>
                      ) : null;
                    })}
                </InputAdornment>
              ),
            }}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              //   svg: { display: diasbleSvg ? diasbleSvg : "block" },
              margin: margin,
              padding: padding,
              width: "100%",
              backgroundColor:"#808080",
              height: "30px",

            }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Grid item>
          {
            <>
              <RenderArtistsSearchList
                platformData={youtubeData}
                platformName={"Youtube"}
                isLoading={isLoading}
                selectedArtistsArray={selectedArtists}
              />
              <RenderArtistsSearchList
                platformData={appleMusicData}
                platformName={"Apple Music"}
                isLoading={isLoading}
                selectedArtistsArray={selectedArtists}
              />
              <RenderArtistsSearchList
                platformData={spotifyData}
                platformName={"Spotify"}
                isLoading={isLoading}
                selectedArtistsArray={selectedArtists}
              />
              {(youtubeData || spotifyData || appleMusicData) && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingY: "3px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setYoutubeData(null);
                      setAppleMusicData(null);
                      setSpotifyData(null);
                      setIsLoading(null);
                      toggleOpen(false);
                      remove();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{
                      ml: 1,
                    }}
                    onClick={() => {
                      let post_stream_platforms = [];

                      [
                        { platform_id: "1", platform_name: "spotify" },
                        { platform_id: "2", platform_name: "apple_music" },
                        {
                          platform_id: "3",
                          platform_name: "youtube_music",
                        },
                      ].map((platform) => {
                        if (selectedArtists.length > 0) {
                          if (
                            !selectedArtists.some(
                              (artist) =>
                                artist.stream_platform_id ===
                                platform.platform_id
                            )
                          ) {
                            post_stream_platforms.push({
                              stream_platform_artist_id: "",
                              stream_platform_artist_name: "",
                              stream_platform_artist_profile_url: "",
                              stream_platform_id: platform.platform_id,
                              stream_platform_name: platform.platform_name,
                              generate: true,
                            });
                          } else {
                            post_stream_platforms.push(
                              selectedArtists.filter(
                                (artist) =>
                                  artist.stream_platform_id ===
                                  platform.platform_id
                              )[0]
                            );
                          }
                        } else {
                          post_stream_platforms.push({
                            stream_platform_artist_id: "",
                            stream_platform_artist_name: "",
                            stream_platform_artist_profile_url: "",
                            stream_platform_id: platform.platform_id,
                            stream_platform_name: platform.platform_name,
                            generate: true,
                          });
                        }
                      });

                      let postData = {
                        artist_name: props?.innerref?.current,
                        stream_platforms: post_stream_platforms,
                      };

                      let access_token = localStorage.getItem("Auth")
                        ? JSON.parse(localStorage.getItem("Auth")).access_token
                        : "";

                      fetch(
                        `${environment.api.artist}/add?current_page=releaseInfo`,
                        {
                          method: "POST",
                          headers: {
                            Authorization: "Bearer " + access_token,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(postData),
                        }
                      )
                        .then((res) => res.json())
                        .then((response) => {
                          setFieldValue(name, {
                            artist_id: response.id,
                            artist_name: response.data.artist_name,
                            stream_platforms: response.data.stream_platforms,
                          });

                          const params = {
                            page_limit: "none",
                            order_by: `[["created_on","desc"]]`,
                            current_page: "releaseInfo",
                          };

                          fetchartistFilter(params);
                          toggleOpen(false);
                          setYoutubeData(null);
                          setAppleMusicData(null);
                          setSpotifyData(null);
                          setSelectedArtists([]);
                        });
                    }}
                  >
                    Add
                  </Button>
                </Box>
              )}
            </>
          }
        </Grid>
      </Dialog>
    </Box>
  );
}
FreeSoloCreateOptionDialog.propTypes = {
  innerref: PropTypes.oneOfType([
    // Added propTypes validation for innerref
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};
