import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { theme } from "../App";
import { useNavigate } from "react-router";

export default function LibraryOptions(props) {
  let navigate = useNavigate();
  function handleClick(event, value) {
    event.preventDefault();

    if (event.target.innerText?.toLowerCase() === "released") {
      navigate("/released");
    }
    if (event.target.innerText?.toLowerCase() === "submitted") {
      navigate("/release", {
        state: {
          status: { id: 2, status_name: "Submitted" },
        },
      });
    }
    if (event.target.innerText?.toLowerCase() === "draft") {
      navigate("/release", {
        state: {
          status: { id: 1, status_name: "Draft" },
        },
      });
    }
    if (event.target.innerText?.toLowerCase() === "rejected") {
      navigate("/release", {
        state: {
          status: { id: 9, status_name: "Rejected" },
        },
      });
    }
  }

  const breadcrumbsData = [
    {
      label: "RELEASED",
      path: "/released",
    },
    {
      label: "SUBMITTED",
      path: "/release",
    },
    {
      label: "DRAFT",
      path: "/release",
    },
    {
      label: "REJECTED",
      path: "/release",
    },

    // You can add more objects here if needed
  ];

  let filteredBreadcrumbsData = [];

  filteredBreadcrumbsData = breadcrumbsData;

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {filteredBreadcrumbsData.map((breadcrumb, index) => (
          <Link
            key={index}
            underline={"none"}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontFamily: "BookAntiqua",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "1.6px",
              textAlign: "left",
              color: "#e3e3e3",

              position: "relative", // Needed for the pseudo-element
              "&::after": {
                content: '""',
                position: "absolute",
                width: "100%", // Match the width of the text
                height: "5px", // Thickness of the underline
                backgroundColor: theme.palette.primary.main, // Color of the underline
                bottom: "-5px", // Position below the text
                left: 0,
                borderRadius: "2.5px", // Add border radius to the underline
                transition: "transform 0.3s ease", // Smooth hover effect
                transform:
                  props.pathname?.toLowerCase() ===
                  breadcrumb.label?.toLowerCase()
                    ? "scaleX(1)"
                    : "scaleX(0)", // Initially hide the underline
                transformOrigin: "left center",
              },
              "&:hover::after": {
                transform: "scaleX(1)", // Show the underline on hover
              },
            }}
          >
            {breadcrumb.label}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}
