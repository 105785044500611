import React from "react";
import { TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";

export default function MobileNumberField(props) {
    const handleKeyPress = (e) => {
    const input = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }

    // Automatically insert a space after 5 digits
    if (input.length === 5 && e.key !== "Backspace") {
      e.target.value = input.slice(0, 5) + " " + input.slice(5);
    }
  };

 


  return (
    <FormControl fullWidth>
      <TextField
        type="text"
        inputProps={{
          maxLength: 11, // Allow for 10 digits + 1 space
          onKeyPress: handleKeyPress,
          className: "mobile-number-field-input",
        }}
        {...props}
        autoComplete="off"
        variant="standard"
        sx={{
          width: props.width ? props.width : "100%",
          fontSize: props.fontSize ? props.fontSize : "30px",
          height: props.fieldheight ? props.fieldheight : "100%",
          "& input[type='text']": {
            MozAppearance: "textfield", // Firefox
            appearance: "textfield", // Chrome, Safari
          },
          "& input::placeholder": {
            fontSize: "22px", // Font size for the placeholder
          },
          "& input:-ms-input-placeholder": {
            fontSize: "16px", // Font size for the placeholder in Internet Explorer
          },
          "& input[type='text']::-webkit-inner-spin-button": {
            WebkitAppearance: "none", // Chrome, Safari
            margin: 0,
          },
          "& input[type='text']::-webkit-outer-spin-button": {
            WebkitAppearance: "none", // Chrome, Safari
            margin: 0,
          },
          "& .MuiInput-underline:before": {
            borderBottom: "none", // Remove the underline
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none", // Remove underline on hover
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none", // Remove underline when focused
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):after": {
            borderBottom: "none", // Remove underline on hover
          },
        }}
        helperText={props.helperText}
        FormHelperTextProps={{
          sx: { fontSize: "14px" }, // Set the font size of the error message
        }}
      />
    </FormControl>
  );
}
